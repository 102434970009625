import React from "react";

const SeasonDisplay = ({ season, label }) => {
  return season ? (
    <h5 className="text-center">
      <small>{label || "Season"}: </small>
      <b>{season.name}</b>
    </h5>
  ) : null;
};

export default SeasonDisplay;
