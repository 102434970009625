import React, { Component } from "react";

import { getCurrentUser } from "../../services/userService";

import { navigateBack } from "../common/customs/customLinks";
import HeaderContext from "../../context/headerContext";
import HorizontalTabs from "../common/dataSorting/horizontalTabs";
import SuperAdminUsersView from "./superAdminUsersView";
import SuperAdminLeaguesView from "./superAdminLeaguesView";
import SuperAdminMessages from "./superAdminMessages";
import SuperAdminHelpers from "./superAdminHelpers";
import IconRender from "../common/icons/iconRender";

class SuperAdminHome extends Component {
  static contextType = HeaderContext;
  state = {
    loading: true,
    selectedTab: "leagues",
    selectedLeague: null,
  };

  async componentDidMount() {
    const user = getCurrentUser();
    if (!user.role.includes("super") && !user.role.includes("internal"))
      return navigateBack(this.props.history, this.context);

    this.setState({ loading: false });
  }

  setLoading = (bool) => {
    this.setState({ loading: bool });
  };

  handleSelectTab = (tab) => {
    this.setState({ selectedTab: tab.name });
  };

  handleSelectLeague = (league) => {
    this.setState({ selectedTab: "users", selectedLeague: league });
  };

  handleDeselectLeague = () => {
    this.setState({ selectedLeague: null });
  };

  render() {
    const { loading, selectedTab, selectedLeague } = this.state;

    return (
      <React.Fragment>
        <HorizontalTabs
          tabs={[
            { name: "users", icon: () => <IconRender name="team" /> },
            { name: "leagues", icon: () => <IconRender name="building" /> },
            { name: "messages", icon: () => <IconRender name="email" /> },
            { name: "helpers", icon: () => <IconRender name="help" /> },
          ]}
          selectedTab={selectedTab}
          onSelectTab={this.handleSelectTab}
        />
        {selectedTab === "users" ? (
          <SuperAdminUsersView
            loading={loading}
            setLoading={this.setLoading}
            selectedLeague={selectedLeague}
            onDeselectLeague={this.handleDeselectLeague}
          />
        ) : selectedTab === "leagues" ? (
          <SuperAdminLeaguesView
            loading={loading}
            setLoading={this.setLoading}
            onSelectLeague={this.handleSelectLeague}
          />
        ) : selectedTab === "messages" ? (
          <SuperAdminMessages
            location={this.props.location}
            history={this.props.history}
          />
        ) : selectedTab === "helpers" ? (
          <SuperAdminHelpers
            location={this.props.location}
            history={this.props.history}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

export default SuperAdminHome;
