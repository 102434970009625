import React from "react";
import Form from "../common/form/form";
import Joi from "joi-browser";

import MiniHeader from "../common/pageComponents/miniHeader";
import StepProgressBar from "../common/dataSorting/stepProgressBar";
import TooltipButton from "../common/tooltip/tooltipButton";
import HeaderContext from "../../context/headerContext";
import { createGettingStartedData } from "../../services/orgService";
import toast from "../../utils/toast";
import allowables from "../../utils/allowables";
import { getCurrentUser } from "../../services/userService";
import { CLink } from "../common/customs/customLinks";

class QuickStartForm extends Form {
  static contextType = HeaderContext;
  state = {
    data: {
      seasonName: "",
      divisionName: "",
      team1Name: "",
      team2Name: "",
      reverseFixtures: false,
    },
    errors: {},
    currentStep: 0,
    header: "Create a Season",
    whatsNext: false,
    createdMatchID: "",
  };

  steps = [
    {
      name: "Season",
      header: "Create a Season",
      validation: () => {
        return this.validateProperty({
          name: "seasonName",
          value: this.state.data.seasonName,
        });
      },
    },
    {
      name: "Division",
      header: "Create a Division",
      validation: () => {
        return this.validateProperty({
          name: "divisionName",
          value: this.state.data.divisionName,
        });
      },
    },
    {
      name: "Teams",
      header: "Create Teams",
      validation: () => {
        return (
          this.validateProperty({
            name: "team2Name",
            value: this.state.data.team2Name,
          }) ||
          this.validateProperty({
            name: "team1Name",
            value: this.state.data.team1Name,
          })
        );
      },
    },
    {
      name: "Matches",
      header: "Create Schedule",
      validation: () => {
        return this.validate();
      },
    },
  ];

  schema = {
    seasonName: Joi.string().required().min(3).max(50).label("Season Name"),
    divisionName: Joi.string().required().min(3).max(50).label("Division Name"),
    team1Name: Joi.string().required().min(3).max(50).label("First Team Name"),
    team2Name: Joi.string()
      .required()
      .min(3)
      .max(50)
      .label("Second Team Name")
      .invalid(Joi.ref("team1Name")),
    reverseFixtures: Joi.boolean().required(),
  };

  componentDidMount() {
    this.context.setLoading(false);
  }

  changeStep = (step) => {
    if (step > this.steps.length) return this.doSubmit();
    this.setState({ currentStep: step, header: this.steps[step - 1]?.header });
  };

  handleStepValidation = () => {
    return this.steps[this.state.currentStep - 1]?.validation();
  };

  doSubmit = async () => {
    this.context.setLoading(true);
    const res = await createGettingStartedData(this.state.data);
    if (res.status === 200) {
      toast.success("Done!!!");
      this.setState({
        currentStep: -1,
        whatsNext: true,
        createdMatchID: res.data.matches?.insertedIds?.["0"],
      });
    } else {
      toast.error(res.data);
      const text = res.data.toLowerCase();
      if (text.includes("already in use") || text.includes("the same")) {
        const errorStep = text.includes("season")
          ? 1
          : text.includes("division")
          ? 2
          : text.includes("team")
          ? 3
          : 4;
        this.changeStep(errorStep);
      }
    }
    this.context.setLoading(false);
  };

  render() {
    const { currentStep, header, whatsNext, createdMatchID } = this.state;
    const user = getCurrentUser();

    return (
      <div className="centered-small-input-area">
        <MiniHeader>Quick Start</MiniHeader>
        <div className="form-divided-section">
          {currentStep === 0 ? (
            <div>
              <h5 className="text-center">
                <b>Welcome to the Quick Start Wizard</b>
              </h5>
              <p>
                Congratulations on creating your league! Now, let's get your
                league up and running in no time. The Quick Start Wizard is
                designed to guide you step-by-step through the essential setup
                processes. Here's what you'll accomplish:
              </p>
              <ol>
                <li>
                  <b>Create a Season</b>: Name your upcoming season.
                </li>
                <li>
                  <b>Add a Division</b>: Establish a division to categorize your
                  teams and streamline your league structure.
                </li>
                <li>
                  <b>Form Teams</b>: Start with two teams and get your players
                  grouped and ready.
                </li>
                <li>
                  <b>Schedule Matches</b>: Create some matches to kick off your
                  season.
                </li>
              </ol>
              <div className="row">
                <div className="col">
                  <button
                    className="btn btn-lg btn-block btn-info"
                    onClick={() => this.changeStep(1)}
                  >
                    Let's Go
                  </button>
                </div>
                <div className="col-2">
                  <TooltipButton
                    tooltipText="You can always come back to this later from your league page."
                    tooltipDirection="left"
                    navigatePath={"/league"}
                    buttonClass="btn-sm btn-block btn-dark"
                  >
                    Skip
                  </TooltipButton>
                </div>
              </div>
            </div>
          ) : currentStep > 0 ? (
            <div>
              <StepProgressBar
                steps={this.steps}
                currentStep={currentStep}
                onSelectStep={this.changeStep}
              />
              <MiniHeader>{header}</MiniHeader>
              <div className="form-divided-section">
                <form onSubmit={this.handleSubmit}>
                  {currentStep === 1 ? (
                    <div>
                      <p>
                        The season is the backbone of your league. By separating
                        your league into seasons we can easily carry over
                        divisions, teams, and players at the end of the season,
                        allowing you to start the next one with minimal setup.
                        All matches played are saved in our archive so you'll
                        always have access to prior season stats.
                      </p>
                      {this.renderInput(
                        "seasonName",
                        "Season Name",
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        `Summer ${new Date().getFullYear()}`
                      )}
                    </div>
                  ) : currentStep === 2 ? (
                    <div>
                      <p>
                        Every season can host multiple divisions. The division
                        will be used to separate league standings, playoff
                        brackets, and individual leaderboards. Each team must
                        belong to a division.
                      </p>
                      {this.renderInput(
                        "divisionName",
                        "Division Name",
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        "Primera Division"
                      )}
                    </div>
                  ) : currentStep === 3 ? (
                    <div>
                      <p>
                        In order to record a match you will need some teams.
                        Create two teams here, you can always edit the names or
                        create more teams later on. Team creation can be fully
                        handled by your captains by sending invitations or
                        providing the registration link from your League Page.
                      </p>
                      {this.renderInput(
                        "team1Name",
                        "First Team Name",
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        "Boca Juniors"
                      )}
                      {this.renderInput(
                        "team2Name",
                        "Second Team Name",
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        "River Plate"
                      )}
                    </div>
                  ) : currentStep === 4 ? (
                    <div>
                      <p>
                        Now that we have two teams we can create a scorecard.{" "}
                        {allowables.refOrUmp(user.sport)}s are assigned to each
                        scorecard and are responsible for inputting the match
                        events.
                      </p>
                      {this.renderCheckbox(
                        "reverseFixtures",
                        "Create Reverse Fixtures",
                        null,
                        {
                          header: "Create Reverse Fixtures",
                          text: `The wizard will create a Regular Season match between your two teams.
                        Activate the checkbox to also create a second match with the teams reversed.`,
                          direction: "right",
                          className: "icon-mobile",
                        }
                      )}
                    </div>
                  ) : null}
                </form>
                <br />
                <div className="row">
                  <div className="col text-left">
                    <button
                      className="btn btn-md btn-sm btn-dark"
                      onClick={() => this.changeStep(currentStep - 1)}
                    >
                      Previous
                    </button>
                  </div>
                  <div className="col text-right">
                    <button
                      className="btn btn-md btn-sm btn-info"
                      onClick={() => this.changeStep(currentStep + 1)}
                      disabled={this.handleStepValidation()}
                    >
                      {currentStep === this.steps.length ? "Save Data" : "Next"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : whatsNext ? (
            <div>
              <h5 className="text-center">
                <b>What's Next</b>
              </h5>
              <ul>
                <li>
                  You're ready to go with your first match. Go to the scorecard
                  to start entering match events.
                  <br />
                  <CLink
                    path={
                      createdMatchID
                        ? `/assignedmatches?q=${createdMatchID}`
                        : "/matches"
                    }
                    button={true}
                  >
                    Go to Scorecard
                  </CLink>
                  <br />
                  <br />
                </li>
                <li>
                  If you'd like to manage your league settings including setting
                  up your league homepage, invite users, or get your free agent
                  and team captain registration page link, go to your League
                  page.
                  <br />
                  <CLink path="/league" button={true}>
                    Go to League Page
                  </CLink>
                  <br />
                  <br />
                </li>
                <li>
                  Are you setting up your league without the help of team
                  captains?
                  <br />
                  <CLink path="/teams?q=new" button={true}>
                    Add another Team
                  </CLink>
                  &nbsp;&nbsp;&nbsp;
                  <CLink path="/players?q=new" button={true}>
                    Add a Player
                  </CLink>
                  <br />
                  <br />
                </li>
                <li>
                  Need more guidance? Contact support at{" "}
                  <a href={`mailto:${allowables.supportEmail}`}>
                    accounts@ultimatescoreboard.com
                  </a>{" "}
                  and we're happy to help in any way we can.
                </li>
              </ul>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default QuickStartForm;
