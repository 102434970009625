import http from "./httpService";
import { getCurrentUser } from "./userService";
import { callbackProgress } from "../utils/progress";

export async function getDivisions(callback, includeArchived) {
  const user = getCurrentUser();
  try {
    return await http.get(
      http.divisionsEndpoint +
        "/orgs/" +
        (includeArchived ? "all/" : "") +
        user.orgID,
      callback && {
        onDownloadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function getDivisionsForRegistration(orgID) {
  try {
    return await http.get(http.divisionsEndpoint + "/registration/" + orgID);
  } catch (ex) {
    return ex.response;
  }
}

export async function getDivisionsBySeason(seasonID, callback) {
  try {
    return await http.get(
      http.divisionsEndpoint + "/season/" + seasonID,
      callback && {
        onDownloadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function getDivision(id, callback) {
  try {
    return await http.get(
      http.divisionsEndpoint + "/" + id,
      callback && {
        onDownloadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function saveDivision(id, division, callback) {
  const user = getCurrentUser();
  division.orgID = user.orgID;
  division.orgName = user.orgName;
  division.activeFlag = 1;

  if (id === "new") {
    try {
      return await http.post(
        http.divisionsEndpoint,
        division,
        callback && {
          onUploadProgress: function (progress) {
            callbackProgress(progress, callback);
          },
        }
      );
    } catch (ex) {
      return ex.response;
    }
  } else {
    try {
      return await http.put(
        http.divisionsEndpoint + "/" + id,
        division,
        callback && {
          onUploadProgress: function (progress) {
            callbackProgress(progress, callback);
          },
        }
      );
    } catch (ex) {
      return ex.response;
    }
  }
}

export async function deleteDivision(id, callback) {
  try {
    return await http.delete(
      http.divisionsEndpoint + "/" + id,
      callback && {
        onUploadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function purgeDivision(id, callback) {
  try {
    return await http.delete(
      http.divisionsEndpoint + "/purge/" + id,
      callback && {
        onUploadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function multiToggleDivisions(divisionIDs, type, callback) {
  try {
    return await http.put(
      http.divisionsEndpoint + "/multi/" + type,
      { divisionIDs },
      callback && {
        onUploadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}
