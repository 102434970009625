import React, { useState } from "react";

import RefereeingInstructionImage from "../../instructions/refereeingInstructionImage";
import HorizontalButtons from "./../../common/dataSorting/horizontalButtons";

const DiamondInstructions = () => {
  const [page, setPage] = useState("pre-match");

  return (
    <React.Fragment>
      <HorizontalButtons
        buttons={[
          "pre-match",
          "match page overview",
          "at bat",
          "undoing",
          "completing",
        ]}
        onClick={setPage}
        selectedType={page}
      />
      <br />
      {page === "pre-match" && (
        <React.Fragment>
          <p>
            Check that you have selected the correct match by verifying the
            teams, date and time. Click Start Match when ready.
            <br />
          </p>
          <RefereeingInstructionImage location="diamond/preMatch.jpg" />
        </React.Fragment>
      )}
      {page === "match page overview" && (
        <React.Fragment>
          <p>
            First up is the approvals section, you will mostly only use this
            after the match has finished. Here you will indicate team captain
            approvals, leave your comments for the League admins, and submit
            your final scorecard to the database.
            <br />
          </p>
          <RefereeingInstructionImage location="diamond/matchPageButtons.jpg" />
          <hr />
          <p>
            Below the approvals is the match header, which contains the broad
            strokes of the match information. Here you will set your timer if
            you are using one, change the inning, and view the game score. The
            Undo button is also in this section in case you make a mistake when
            selecting an event.
            <br />
          </p>
          <RefereeingInstructionImage location="diamond/matchPageHeader.jpg" />
          <hr />
          <p>
            The teams section is where all of the events will take place during
            the game. In this section you select the pitcher for each team and
            begin the At Bat portion of the game.
            <br />
          </p>
          <RefereeingInstructionImage location="diamond/matchPageTeams.jpg" />
        </React.Fragment>
      )}
      {page === "at bat" && (
        <React.Fragment>
          <p>
            Click the At Bat button for the team that is up to bat. If you have
            not already done so you will be prompted to select the pitcher for
            the opposing team. If your League is doing self pitching select
            Unknown/Unregistered Player and pitching stats will not be tracked.
            <br />
          </p>
          <RefereeingInstructionImage location="diamond/selectPitcher.jpg" />
          <hr />
          <p>
            Once a pitcher is selected click the At Bat button again. Here you
            will select the batter. The number before each player's name is
            their order in the lineup. This should be set by the team captain
            prior to each game. Also appearing next to each player's name is the
            number of plate appearances they have had in this game. With these
            two items and the player image you should be able to select the
            correct batter. It is very important for tracking statistics that
            the correct pitcher and batter are selected.
            <br />
          </p>
          <RefereeingInstructionImage location="diamond/selectBatter.jpg" />
          <hr />
          <p>
            Now you have reached the main At Bat page. Here you can track Balls
            and Strikes (you can also track Fouls separately for Kickball). If
            after you start tracking the count you realize that you have
            selected the wrong batter you can click Back to Batter Select
            without losing the count. If there is a pitching change click Close,
            select the new pitcher, then reopen the At Bat screen. You will be
            returned to where you were with the selected batter and count
            intact.
            <br />
            The pitching count tracker is provided for your convenience, the
            final ball and strike tallies are not tracked for each at bat.
            <br />
          </p>
          <RefereeingInstructionImage location="diamond/atBatMain.jpg" />
          <hr />
          <p>
            Once the at bat is complete it is time to enter the result. The
            three main options are Out, Walk, and Hit. Once you select one of
            those options you will be given some sub-options to select from. The
            at bat is not finalized until you click OK on the confirmation popup
            after selecting a sub-option. If a run is scored as a result of the
            at bat you will add it on the confirmation screen. Adding runs here
            will not only increment the score but will make sure the batter gets
            the RBI credit they are due. There is also run counter on the main
            match page in each team segment but that is only for runs which
            happen outside the context of an at bat.
            <br />
          </p>
          <RefereeingInstructionImage location="diamond/atBatFinalize.jpg" />
          <hr />
          <p>
            Upon confirming the at bat result you will be returned to the main
            match page. If there was a run scored during the at bat you will see
            that the score has been incremented. Even if the result of the at
            bat was an out the out counter will not update, this will have to be
            done manually.
          </p>
        </React.Fragment>
      )}
      {page === "undoing" && (
        <React.Fragment>
          <p>
            If you need to change the result of an at bat for any reason you
            must undo the original at bat and go through the at bat process
            again. To undo an at bat click the Undo button on the match header
            section. This will bring up a list of all the at bats in the game,
            in reverse chronological order. For convenience outs are highlighted
            in red, and hits in green.
            <br />
          </p>
          <RefereeingInstructionImage location="diamond/undo.jpg" />
          <hr />
          <p>
            Find the at bat that you wish to undo or redo and click it. Click
            the OK button on the confirmation popup and the at bat will be
            removed from the game. Any RBIs during that at bat will be removed
            from the team score. If a correction needed to be made you should
            now go through the normal at bat process.
            <br />
          </p>
          <RefereeingInstructionImage location="diamond/confirmDelete.jpg" />
        </React.Fragment>
      )}
      {page === "completing" && (
        <React.Fragment>
          <p>
            When the game is over you should show the scorecard on your phone or
            tablet to the captain of each team. This is only to give them an
            overview of the final score. Every player will have the ability to
            view the full scorecard on their own account once you have submitted
            it. If they agree with the score then click the red approval button
            for their team. The button will turn green to indicate that the
            captain has accepted the result. If the captain does not accept the
            result then leave the button unclicked and red.
          </p>
          <p>
            Captain approvals is an optional feature, your League may not have
            it enabled. If the buttons do not appear on your page you can skip
            this step as your League does not require it.
            <br />
          </p>
          <RefereeingInstructionImage location="diamond/finishMatch.jpg" />
          <hr />
          <p>
            If you have any comments to make on how the match went you will
            enter them before clicking Finish Match. It is especially important
            to submit your comments if one or more teams does not approve of the
            scorecard, as the reviewing admin will be able to see your comments
            while reviewing the match, this can help clear up a lot of
            confusion.
            <br />
          </p>
          <RefereeingInstructionImage location="diamond/refereeComments.jpg" />
          <hr />
          <p>
            After confirming with each team captain and adding your comments it
            is time to submit this scorecard. Click the Finish Match button to
            send the completed scorecard to the database. Once you confirm this
            you cannot get the scorecard back so please make sure that all the
            information is correct.
          </p>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default DiamondInstructions;
