import React from "react";
import Modal from "react-modal";

import { popupStyle } from "./../../../utils/styleUtil";
import PageBottom from "../pageComponents/pageBottom";

const CustomConfirm = ({
  dialog,
  callback,
  isOpen,
  close,
  id,
  focused,
  yesNo,
  nextButton,
  split,
  pageBottom,
  negativeCallback,
  options,
  holdSubmitButton,
  manuallyClose,
  children,
  centerAllText,
  alsoRunOnClose,
}) => {
  const answered = (answer) => {
    if (answer) callback();
    else if (negativeCallback) negativeCallback();
    if (!manuallyClose || !answer) close(id ? id : false);
  };
  const handleOptionClick = (option) => {
    callback(option);
  };
  const text = dialog && split ? dialog.split(split) : [dialog];

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => {
        if (alsoRunOnClose) alsoRunOnClose();
        close(id ? id : false);
      }}
      closeOnDocumentClick
      ariaHideApp={false}
      style={popupStyle}
    >
      <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
        {text.map((t, i) => (
          <React.Fragment key={i}>
            <div
              className={
                centerAllText
                  ? "text-center"
                  : i === text.length - 1
                  ? "text-center"
                  : ""
              }
              key={i}
            >
              {t}
              {i !== text.length - 1 && <br />}
            </div>
          </React.Fragment>
        ))}
        <br />
        {children && (
          <React.Fragment>
            {children}
            <br />
          </React.Fragment>
        )}
        {options && (
          <div className="row">
            {options.map((option, i) => {
              return (
                <div
                  className="col text-center clickable"
                  onClick={() => handleOptionClick(option)}
                  key={i}
                >
                  <button className={option.className}>{option.label}</button>
                </div>
              );
            })}
          </div>
        )}
        {!options && (
          <div
            className="row"
            style={{ paddingLeft: "10px", paddingRight: "10px" }}
          >
            <div className="col text-left">
              <button
                className="btn btn-sm btn-dark"
                onClick={() => answered(false)}
                autoFocus={focused}
              >
                {yesNo ? " No " : "Cancel"}
              </button>
            </div>
            {(!holdSubmitButton ||
              (holdSubmitButton &&
                holdSubmitButton.enteredValue ===
                  holdSubmitButton.mustMatchValue)) && (
              <div className="col text-right">
                <button
                  className="btn btn-sm btn-info"
                  onClick={() => answered(true)}
                  autoFocus={!focused}
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {nextButton ? "Next" : yesNo ? "Yes" : "OK"}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </button>
              </div>
            )}
          </div>
        )}
      </div>
      {pageBottom && <PageBottom cookieOnly={true} />}
    </Modal>
  );
};

export default CustomConfirm;
