import React, { useState, useContext } from "react";
import Avatar from "../common/pageComponents/avatarEditor";
import OrgLogo from "../common/pageComponents/orgLogo";
import toast from "../../utils/toast";
import CustomConfirm from "../common/customs/customConfirm";
import { uploadOrgLogo, removeOrgLogo } from "../../services/ftpService";
import PaidFeature from "../common/tooltip/paidFeature";
import HeaderContext from "./../../context/headerContext";

const OrgLogoEditor = ({ org }) => {
  const { refreshOrg, setProgress, setLoading } = useContext(HeaderContext);
  const [removeOpen, setRemoveOpen] = useState(false);

  const removeLogo = async () => {
    setLoading(true);
    setProgress([1]);
    const res = await removeOrgLogo();
    if (res.status === 200) {
      toast.success(res.data);
      await refreshOrg();
    } else toast.error(res.data);
    setLoading(false);
  };

  return (
    <React.Fragment>
      <br />
      <br />
      {org.logo && (
        <React.Fragment>
          <OrgLogo _id={org.logo} name={org.name} />
          <br />
          <br />
          <button
            className="btn btn-sm btn-danger"
            onClick={() => setRemoveOpen(true)}
          >
            Remove Logo
          </button>
        </React.Fragment>
      )}
      <br />
      <br />
      Aspect Ratio for League Logo is 3:1
      <PaidFeature />
      <Avatar
        width={300}
        height={100}
        uploadFunction={(base64Data) => uploadOrgLogo(base64Data)}
        saveTitle={"Save Logo"}
        minZoom={25}
        maxZoom={300}
      />
      <CustomConfirm
        dialog="Are you sure you want to remove your logo?"
        callback={removeLogo}
        isOpen={removeOpen}
        close={setRemoveOpen}
      />
    </React.Fragment>
  );
};

export default OrgLogoEditor;
