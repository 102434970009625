import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import HeaderContext from "../../context/headerContext";
// import { getMatchesByCode } from "../../services/refereeScorecardService";
// import toasts from "../../utils/toast";
import MatchesCardView from "../matches/matchesCardView";
import { navigateTo } from "../common/customs/customLinks";

const RefereeScorecards = ({ matches = [] }) => {
  const header = useContext(HeaderContext);
  const history = useHistory();

  const handleSelectMatch = (match) => {
    navigateTo(`/referees?page=scorecard&q=${match._id}`, history, header);
  };

  return (
    <div>
      <MatchesCardView matches={matches} onClick={handleSelectMatch} />
    </div>
  );
};

export default RefereeScorecards;
