import React from "react";

import FormLabel from "./formLabel";
import WarningHeader from "../pageComponents/warningHeader";

const TextArea = ({
  name,
  label,
  error,
  type,
  tooltip,
  paidFeature,
  rows,
  boldLabel,
  ...rest
}) => {
  const labelText = boldLabel ? <b>{label}</b> : label;
  return (
    <div className="form-group">
      <FormLabel
        tooltip={tooltip}
        paidFeature={paidFeature}
        label={labelText}
        htmlFor={name}
      />
      <textarea
        {...rest}
        style={{ width: "100%" }}
        rows={rows}
        name={name}
        id={name}
        className="form-control custom-form"
      />
      {error && <WarningHeader>{error}</WarningHeader>}
    </div>
  );
};

export default TextArea;
