import React from "react";
import IconRender from "../icons/iconRender";

const WarningHeader = ({ overrideIconName, children }) => {
  if (!children) return null;

  return (
    <div className="text-center api-error">
      <div style={{ float: "left", marginRight: 5 }}>
        <IconRender
          name={overrideIconName || "info"}
          className="icon-mobile"
          style={{ marginLeft: 10 }}
        />
      </div>
      <div style={{ float: "right" }}>
        <IconRender
          name={overrideIconName || "info"}
          className="icon-mobile"
          style={{ marginRight: 10, marginLeft: 5 }}
        />
      </div>
      {children}
    </div>
  );
};

export default WarningHeader;
