import React, { Component } from "react";
import { FaSortDown, FaSortUp } from "react-icons/fa";

class TableHeader extends Component {
  raiseSort = (path) => {
    const sortColumn = { ...this.props.sortColumn };
    if (sortColumn.path === path)
      sortColumn.order = sortColumn.order === "asc" ? "desc" : "asc";
    else {
      sortColumn.path = path;
      sortColumn.order = path?.toLowerCase().includes("name")
        ? "asc"
        : this.props.initialSortOrder || "asc";
    }
    this.props.onSort(sortColumn);
  };

  renderSortIcon = (column) => {
    const { sortColumn } = this.props;
    if (sortColumn) {
      if (column.path !== sortColumn.path) return null;
      if (sortColumn.order === "asc") return <FaSortUp />;
      return <FaSortDown />;
    }
  };

  render() {
    if (this.props.sortColumn) {
      return (
        <thead className={this.props.headerClass}>
          <tr>
            {this.props.columns.map((column) => (
              <th
                key={column.path || column.key}
                className="clickable"
                onClick={() => this.raiseSort(column.path)}
              >
                {column.label} {this.renderSortIcon(column)}
              </th>
            ))}
          </tr>
        </thead>
      );
    } else {
      return (
        <thead className={this.props.headerClass}>
          <tr>
            {this.props.columns.map((column) => (
              <th key={column.path || column.key}>{column.label}</th>
            ))}
          </tr>
        </thead>
      );
    }
  }
}

export default TableHeader;
