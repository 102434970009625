import React from "react";

import MiniHeader from "../../common/pageComponents/miniHeader";
import { getConnectedAccountFeeInfo } from "../../../utils/paymentsUtil";
import { ForeignTransactionInfo } from "../../contactPolicies/finePrint";

const OwnerPriceInfo = ({ org }) => {
  const feeInfo = getConnectedAccountFeeInfo();

  return (
    <React.Fragment>
      <MiniHeader>
        <div className="row">
          <div className="col">
            <h4>
              <small>Payment collected from:</small> {org.stripe.whoPays}
            </h4>
          </div>
          <div className="col">
            <h4>
              <small>Price per {org.stripe.whoPays}:</small>{" "}
              {org.stripe.amount.toFixed(2)} {org.stripe.currency.toUpperCase()}
            </h4>
          </div>
        </div>
      </MiniHeader>
      <div className="form-divided-section">
        <ul>
          <li>
            Payments collected on Ultimate Scoreboard via your Stripe connected
            account are charged a fee of{" "}
            {(feeInfo.stripePercentage + feeInfo.usbPercentage).toFixed(2)}% and
            $
            {(feeInfo.stripePerTransaction + feeInfo.usbPerTransaction).toFixed(
              2
            )}{" "}
            per transaction.*
          </li>
          <li>
            You can update your pricing structure on the{" "}
            <a href="/league?q=options&section=payment">League settings page</a>
            .
          </li>
          <li>
            Any changes you make here do not affect refunds or payments on your
            connected Stripe account, they are for informational purposes only.
          </li>
        </ul>
        <ForeignTransactionInfo />
      </div>
    </React.Fragment>
  );
};

export default OwnerPriceInfo;
