import React, { useState, useContext } from "react";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import { IconContext } from "react-icons";
import { revokeCookieAcceptance } from "../../services/userService";
import HeaderContext from "../../context/headerContext";
import MiniHeader from "../common/pageComponents/miniHeader";
import { getConnectedAccountFeeInfo } from "../../utils/paymentsUtil";
import allowables from "../../utils/allowables";
import WarningHeader from "../common/pageComponents/warningHeader";

const ForeignTransactionInfo = () => (
  <div>
    *
    <small>
      Transactions requiring currency conversion may incur additional fees. See
      your Stripe account for more information.
    </small>
  </div>
);

const FinePrint = ({ openAll, closeFinePrint, standalone }) => {
  const { toggleContactForm } = useContext(HeaderContext);
  const [prorationOpen, setProrationOpen] = useState(openAll);
  const [subscriptionsOpen, setSubscriptionsOpen] = useState(openAll);
  const [referralsOpen, setReferralsOpen] = useState(openAll);
  const [securityOpen, setSecurityOpen] = useState(openAll);
  const [privacyOpen, setPrivacyOpen] = useState(openAll);
  const [cookiesOpen, setCookiesOpen] = useState(openAll);
  const [usagePoliciesOpen, setUsagePoliciesOpen] = useState(openAll);

  const textStyle = { fontSize: "14px" };
  const feeInfo = getConnectedAccountFeeInfo();

  return (
    <IconContext.Provider value={{ className: "icon-raised" }}>
      <MiniHeader>Ultimate Scoreboard Policies & Information</MiniHeader>
      <div className="form-divided-section">
        <div
          className="clickable"
          onClick={() => setPrivacyOpen(privacyOpen ? false : true)}
        >
          <h6>
            Privacy & Data Storage&nbsp;
            {privacyOpen ? <FaArrowUp /> : <FaArrowDown />}
          </h6>
        </div>
        {privacyOpen && (
          <div style={textStyle}>
            <p>
              We limit the amount of information we collect from you, and we{" "}
              <i>never</i> sell it. We only send email in very limited
              circumstances, when registration or password reset links are
              requested, when you change your subscription package, or when you
              ask us a question. We do send updates when we have added a new
              feature that we think is relevant to your account, but you can opt
              out of those in your notification settings.
            </p>
            <p>
              Your League data is maintained as long as the League owner or an
              admin keeps an active account. We periodically check for and
              remove stale Leagues that have not been accessed by an admin for
              more than a year. All that is required to keep your League active
              is for an admin to have accessed the site within the last year.
              Data that is subject to this policy includes Leagues, Divisions,
              Teams, Players, Matches, User Accounts.
            </p>
          </div>
        )}
        <div
          className="clickable"
          onClick={() => setCookiesOpen(cookiesOpen ? false : true)}
        >
          <h6>
            Cookie Policy&nbsp;{cookiesOpen ? <FaArrowUp /> : <FaArrowDown />}
          </h6>
        </div>
        {cookiesOpen && (
          <div style={textStyle}>
            <p>
              We maintain the minimal amount of cookies for the site to
              function. Cookies must be accepted in order to use the site, it
              cannot function without them. We check for your approval of our
              cookies every six months.
            </p>
            <ul>
              <b>Ultimate Scoreboard Cookies</b>
              <li>
                User token: Your authenticated login token. Stored for six
                months from the time of login. Required to access the database
                and private API
              </li>
            </ul>
            <ul>
              <b>Third Party Cookies</b>
              <li>
                Stripe, our payment provider, uses some cookies to keep your
                card information secure. These only apply to League owners. For
                more information visit{" "}
                <a href="https://stripe.com/privacy-center/legal">
                  https://stripe.com/privacy-center/legal
                </a>
              </li>
            </ul>
            <p>
              If you wish to revoke your acceptance of cookies click below. If
              you are logged in you will be logged out.
            </p>
            <div onClick={() => revokeCookieAcceptance(true)} className="clink">
              Revoke Cookie Permissions
            </div>
            <br />
          </div>
        )}
        <div
          className="clickable"
          onClick={() => setUsagePoliciesOpen(usagePoliciesOpen ? false : true)}
        >
          <h6>
            Sitewide Usage Policies&nbsp;
            {usagePoliciesOpen ? <FaArrowUp /> : <FaArrowDown />}
          </h6>
        </div>
        {usagePoliciesOpen && (
          <div style={textStyle}>
            <p>
              <b>
                If you see any content that is offensive or in violation of our
                policies please contact us at{" "}
                <a href={`mailto:${allowables.supportEmail}`}>
                  {allowables.supportEmail}
                </a>
              </b>
            </p>
            <ul>
              <p>
                <b>General</b>
                <br />
                Ultimate Scoreboard provides services to Leagues and Players at
                its discretion. We do not tolerate the use of the site for any
                purposes other than originally intended, and reserve the right
                to remove any user we deem to be violating that intention.
              </p>
            </ul>
            <ul>
              <p>
                <b>Images</b>
                <br />
                All users are allowed to upload thier own images to this site.
                Profile images must be a picture of the user's own face and you
                must own the rights to the image. Users found to be violating
                this policy will be blocked from uploading their profile image.
                <br />
                <br />
                For team and league logos the image must be PG rated, if in
                doubt please err on the side of caution. For team logos Ultimate
                Scoreboard will consult with the League owner to make a
                determination on questionable images, but site admininstrators
                hold the final decision.
              </p>
            </ul>
            <ul>
              <p>
                <b>Comments</b>
                <br />
                Team captains and referees/umpires have the ability to leave
                their comments on a scorecard. Team captain comments are visible
                to all users within that league and as such a League owner may
                want to request certain decorum. Ultimate Scoreboard does not
                police vulgar or offensive language used in any user inputted
                text across the site. It is up to the League owner and admins to
                make sure that their players and referees use appropriate
                language.
              </p>
            </ul>
          </div>
        )}
        <div
          className="clickable"
          onClick={() => setSecurityOpen(securityOpen ? false : true)}
        >
          <h6>
            Card Security & Payments&nbsp;
            {securityOpen ? <FaArrowUp /> : <FaArrowDown />}
          </h6>
        </div>
        {securityOpen && (
          <div style={textStyle}>
            <p>
              All of our payments are handled by{" "}
              <a
                href="https://www.stripe.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Stripe
              </a>
              , a third party payment processing company. We never see your card
              details.
            </p>
            <p>
              If you are a League owner using our platform to accept player
              payments we collect{" "}
              {(feeInfo.stripePercentage + feeInfo.usbPercentage).toFixed(2)}%
              of player fees plus $
              {(
                feeInfo.stripePerTransaction + feeInfo.usbPerTransaction
              ).toFixed(2)}{" "}
              per transaction to cover our incurred processing fees.* Please
              adjust your pricing accordingly. There is no charge for payments
              you collect in cash or outside our site. You can manually mark the
              player or team as paid on the Payments page to make sure you are
              keeping track of everything.
            </p>
            <ForeignTransactionInfo />
            <br />
          </div>
        )}
        <div
          className="clickable"
          onClick={() => setSubscriptionsOpen(subscriptionsOpen ? false : true)}
        >
          <h6>
            Subscriptions&nbsp;
            {subscriptionsOpen ? <FaArrowUp /> : <FaArrowDown />}
          </h6>
        </div>
        {subscriptionsOpen && (
          <div style={textStyle}>
            <p>
              Subscription tiers are measured by how many teams are allowed to
              be registered for your League. You can add unlimited divisions,
              players, and matches as needed to suit your requirements.
            </p>
            <p>
              You cannot create more teams than allowed by your subscription
              tier. If you have more teams registered than your subscription
              tier allows (this can happen if your subscription expires, or you
              change to a lower tier) your data will remain intact for one year.
            </p>
            <p>
              However, until you upgrade your subscription or remove the
              requisite number of teams your functions will be limited. You will
              not be able to:
            </p>
            <ul>
              <li>Create Teams</li>
              <li>Create Matches</li>
              <li>Create Schedules (with the match creator)</li>
              <li>Submit Scorecards</li>
              <li>Restart Matches</li>
              <li>Accept Matches</li>
            </ul>
          </div>
        )}
        <div
          className="clickable"
          onClick={() => setReferralsOpen(referralsOpen ? false : true)}
        >
          <h6>
            Referrals&nbsp;
            {referralsOpen ? <FaArrowUp /> : <FaArrowDown />}
          </h6>
        </div>
        {referralsOpen && (
          <div style={textStyle}>
            <p>
              If a league signs up using the referral link available on your
              profile page Ultimate Scoreboard will pay the referrer the first
              months subscription for each new league, under the following terms
              and conditions:
            </p>
            <ul>
              <li>
                The league owner must be brand new to Ultimate Scoreboard and
                create a new account on the referral page. Referring a user with
                an existing account (even if they are not already an owner) will
                not result in a referral payment.
              </li>
              <li>
                The referrer must maintain their Ultimate Scoreboard account for
                at least one month following the new league starting their
                subscription, and they must monitor their associated email
                address for information regarding the payment.
              </li>
              <li>
                The referral bonus will be a one time payment in the amount of
                the monthly subscription price of the new subscription,
                regardless of subscription cycle selected.
              </li>
              <li>
                Any new leagues created by the referred owner are also eligible
                for referral payments.
              </li>
              <li>
                The bonus payment will be made within 10 days following the end
                of the month in which the subscription was purchased. Payment
                can be made by Venmo, Zelle, or paper check.
              </li>
            </ul>
          </div>
        )}
        <div
          className="clickable"
          onClick={() => setProrationOpen(prorationOpen ? false : true)}
        >
          <h6>
            Proration&nbsp;{prorationOpen ? <FaArrowUp /> : <FaArrowDown />}
          </h6>
        </div>
        {prorationOpen && (
          <div style={textStyle}>
            <p>
              All changes to subscriptions are billed immediately using the
              period length to prorate the new cost. For example, if you upgrade
              from a monthly Tier 1 subscription to a monthly Tier 2
              subscription halfway through the month then you will be charged
              immediately for half a month of a Tier 2 subscription, minus half
              a month of a Tier 1 subscription. Your monthly renewal date will
              remain the same.
            </p>
            <p>
              If you change your subscription from monthly to yearly or vice
              versa your old subscription will be credited at the prorated
              amount. You new subscription will charge immediately at the full
              amount and your billing date will be reset.
            </p>
            <p>
              If your new subscription price is less than your current
              subscription a credit in the amount of the proration will be added
              to your account and automatically applied to future payments.
            </p>
          </div>
        )}
        <br />
        <div className="pop-box" style={{ padding: "5px" }}>
          <WarningHeader>
            <b>Refund and Cancellation Policy</b>
          </WarningHeader>
          <div style={textStyle}>
            <p>
              Subscriptions are billed at the beginning of every period and are
              <b> non-refundable</b>. A cancelled subscription will remain
              active until the end of the billing period. Downgrading your
              subscription to a lower tier will result in a prorated credit
              being applied to future payments.
              <br />
              <br />
              For players paying league dues the refund policy is set by the
              league owner, please contact them for more information.
            </p>
          </div>
        </div>
        <br />
        <div
          className="nav-item nav-link clickable"
          onClick={() => {
            if (closeFinePrint) closeFinePrint();
            toggleContactForm();
          }}
        >
          Contact Customer Support
        </div>
      </div>
    </IconContext.Provider>
  );
};

export { ForeignTransactionInfo };
export default FinePrint;
