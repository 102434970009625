import http from "./httpService";
import { callbackProgress } from "../utils/progress";

export async function sendLink(email, link, name, subject, teamName, callback) {
  try {
    const data = { email, link, name, subject, teamName };
    return await http.post(
      http.emailsEndpoint,
      data,
      callback && {
        onUploadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function sendContactUsEmail(body) {
  try {
    return await http.post(http.emailsEndpoint + "/contactus", body);
  } catch (ex) {
    return ex.response;
  }
}
