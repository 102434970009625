import React, { useContext, useState } from "react";
import { setRefereeCode } from "../../services/userService";
import HeaderContext from "../../context/headerContext";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { navigateTo } from "../common/customs/customLinks";
import CustomConfirm from "../common/customs/customConfirm";
import IconRender from "../common/icons/iconRender";
import TooltipButton from "../common/tooltip/tooltipButton";
import { removeLiveScorecard } from "../../services/liveScoreService";

const RefereesOrgHeader = ({ org, page }) => {
  const header = useContext(HeaderContext);
  const history = useHistory();

  const [logoutOpen, setLogoutOpen] = useState(false);

  if (!org) return null;

  const handleLogout = async () => {
    header.setLoading(true);
    const startedMatchID = localStorage.getItem("_id");
    if (startedMatchID) {
      await removeLiveScorecard(startedMatchID);
      localStorage.clear();
    }
    setRefereeCode(null);
    navigateTo("/referees", history, header);
    header.setLoading(false);
  };

  return (
    <div className="pop-box">
      <div style={{ float: "right" }}>
        <TooltipButton
          tooltipText="Logout"
          tooltipDirection="left"
          onClick={() => setLogoutOpen(true)}
          buttonClass="btn btn-sm btn-dark"
          aria-label="logout"
        >
          <IconRender name="logout" />
        </TooltipButton>
      </div>
      <div style={{ float: "left" }}>
        <TooltipButton
          tooltipText={
            page === "scorecards" ? "Refresh Scorecards" : "Back to Scorecards"
          }
          tooltipDirection="right"
          onClick={() =>
            navigateTo("/referees?page=scorecards", history, header)
          }
          buttonClass="btn btn-sm btn-secondary"
          aria-label="back"
        >
          <IconRender
            name={page === "scorecards" ? "refresh" : "chevronleft"}
          />
        </TooltipButton>
      </div>
      <h5 className="text-center">
        <b>{org.name}</b>
      </h5>
      <CustomConfirm
        dialog={`Are you sure you want to log out from ${org.name}?`}
        isOpen={logoutOpen}
        callback={handleLogout}
        close={() => {
          setLogoutOpen(false);
        }}
        yesNo={true}
      />
    </div>
  );
};

export default RefereesOrgHeader;
