import React, { useState, useEffect } from "react";

import MultiSelect from "../common/pageComponents/multiSelect";
import TooltipButton from "../common/tooltip/tooltipButton";
import IconRender from "../common/icons/iconRender";
import SideBySideView from "../common/pageComponents/sideBySideView";

const DivisionsButtonLineup = ({
  user,
  divisions,
  multiSelectColumns,
  onMultiToggle,
  onResetSuspensions,
}) => {
  const [buttons, setButtons] = useState([]);

  useEffect(() => {
    setButtons(getButtons());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [divisions]);

  const onEditLock = (divisionIDs) => {
    onMultiToggle(divisionIDs, "lockediting");
  };
  const onRosterLock = (divisionIDs) => {
    onMultiToggle(divisionIDs, "lockrosters");
  };

  const getButtons = () => {
    let buttons = [
      <TooltipButton
        buttonClass="btn-md btn-info"
        tooltipText="Add New Division"
        tooltipDirection="bottom"
        navigatePath={"/divisions?q=new"}
      >
        <IconRender name="add" className="icon-md" />
      </TooltipButton>,
    ];

    if (divisions.length > 0) {
      if (user.sport.toLowerCase().includes("soccer"))
        buttons.push(
          <TooltipButton
            buttonClass="btn-md btn-light"
            tooltipText="Reset Accumulated Cards"
            onClick={onResetSuspensions}
            tooltipDirection="bottom"
          >
            <IconRender name="reset" className="icon-md" />
          </TooltipButton>
        );
      buttons.push(
        <MultiSelect
          data={divisions}
          keyValue="_id"
          columnsToShow={multiSelectColumns}
          header="divisions"
          title2="divisions Roster Locks"
          onSubmit2={onRosterLock}
          title="divisions Editing Locks"
          onSubmit={onEditLock}
          type="toggle"
          buttonIcon={<IconRender name="settings" className="icon-md" />}
          buttonClass="btn-md"
          tooltipText="Toggle Division Settings"
        />
      );
    }

    return buttons;
  };

  return (
    user.role.includes("admin") &&
    user.orgID && (
      <SideBySideView
        Components={buttons}
        groupClassName="button-group-box"
        separateRows
        minWidth={200}
      />
    )
  );
};

export default DivisionsButtonLineup;
