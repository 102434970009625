import React, { useState, useEffect } from "react";
import Modal from "react-modal";

import { popupStyle } from "../../../utils/styleUtil";

const CustomPrompt = ({
  dialog,
  callback,
  isOpen,
  close,
  id,
  yesNo,
  split,
  manuallyClose,
  initialUserInput,
  minWidth = 300,
  centerAllText,
  okOnly,
  jsxDialog,
}) => {
  const [userInput, setUserInput] = useState("");

  useEffect(() => {
    setUserInput(initialUserInput || "");
  }, [initialUserInput]);

  const answered = (answer) => {
    if (answer && callback) {
      setUserInput("");
      callback(userInput);
    }
    if (!manuallyClose) close(id ? id : false);
  };

  const text = dialog && split ? dialog.split(split) : [dialog];

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => {
        close(id ? id : false);
      }}
      closeOnDocumentClick
      ariaHideApp={false}
      style={popupStyle}
    >
      <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
        {jsxDialog
          ? jsxDialog
          : text.map((t, i) => (
              <React.Fragment key={i}>
                <div
                  className={
                    centerAllText
                      ? "text-center"
                      : i === text.length - 1
                      ? "text-center"
                      : ""
                  }
                  key={i}
                >
                  {t}
                </div>
                {i !== text.length - 1 && <br />}
              </React.Fragment>
            ))}
        <br />
        <input
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
          autoFocus
          style={{ borderRadius: "5px", minWidth, width: "100%" }}
          className="form-control custom-form"
          name="modal-input"
          aria-label="modal-input"
        />
        <br />
        <br />
        <div
          className="row"
          style={{ paddingLeft: "10px", paddingRight: "10px" }}
        >
          {!okOnly ? (
            <div className="col text-left">
              <button
                className="btn btn-sm btn-dark"
                onClick={() => answered(false)}
              >
                {yesNo ? " No " : "Cancel"}
              </button>
            </div>
          ) : null}
          <div className="col text-right">
            <button
              className="btn btn-sm btn-info"
              onClick={() => answered(true)}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{yesNo ? "Yes" : "OK"}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CustomPrompt;
