import React from "react";

const LiveInfo = ({ liveData, org }) => {
  if (!liveData)
    return org?.misc?.liveScorecards ? (
      <div className="pop-box text-center">
        Live updates will be available when this scorecard is started
      </div>
    ) : null;

  return (
    <div className="pop-box text-center">
      <b>This scorecard is receiving live updates</b>
      <br />
      <small>
        Last Scorecard Change:{" "}
        {new Date(liveData.lastUpdated).toLocaleTimeString()}
      </small>
    </div>
  );
};

export default LiveInfo;
