import React from "react";

import Invoice from "./invoice";
import BasicModal from "../../common/pageComponents/basicModal";
import { popupStyle } from "../../../utils/styleUtil";
import MiniHeader from "../../common/pageComponents/miniHeader";

const InvoiceModal = ({ isOpen, closeModal, customer, invoice }) => {
  return (
    <BasicModal isOpen={isOpen} onClose={closeModal} style={popupStyle}>
      <MiniHeader>Upcoming Invoice</MiniHeader>
      <div className="form-divided-section">
        <Invoice customer={customer} invoice={invoice} />
      </div>
    </BasicModal>
  );
};

export default InvoiceModal;
