import React from "react";

import allowables from "../../../utils/allowables";
import WarningHeader from "../../common/pageComponents/warningHeader";
import { getDiscountInfo, isPaid } from "../../../utils/paymentsUtil";
import PlayerDiscountForm from "./playerDiscountForm";
import { renderPaymentInfoLine } from "../../../utils/textUtils";
import IconRender from "../../common/icons/iconRender";

const PlayerPaymentForm = ({
  player,
  org,
  paymentData,
  startCheckoutSession,
  paymentDetails,
  user,
  team,
  appliedDiscount,
  onApplyDiscount,
}) => {
  if (!org || !paymentData) return null;

  const userPaid = isPaid(paymentDetails);

  const { amountRemaining, discountAmount, initialAmount, discountUsed } =
    getDiscountInfo(
      paymentDetails,
      paymentData,
      userPaid ? null : appliedDiscount
    );

  return (
    <div>
      <div>
        {userPaid ? (
          <WarningHeader overrideIconName="checkedbox">
            League Dues Paid
          </WarningHeader>
        ) : null}
        {paymentData.whoPays === "Team" && user._id !== team.teamCaptainID ? (
          <WarningHeader>
            Only the team captain can pay league dues
          </WarningHeader>
        ) : (
          <div>
            {renderPaymentInfoLine("Payment Type", paymentData.whoPays)}

            {renderPaymentInfoLine(
              "Name",
              paymentData.whoPays === "Team"
                ? team?.name
                : allowables.splitName(player?.name)
            )}

            {!userPaid
              ? renderPaymentInfoLine(
                  "Total Amount Due",
                  initialAmount.toFixed(2) +
                    " " +
                    org.stripe.currency.toUpperCase()
                )
              : null}

            {renderPaymentInfoLine(
              "Amount Paid",
              paymentDetails?.external
                ? "Marked as paid by admin"
                : (paymentDetails?.amountPaid
                    ? paymentDetails?.amountPaid.toFixed(2)
                    : "0.00") +
                    " " +
                    org.stripe.currency.toUpperCase()
            )}

            {userPaid
              ? renderPaymentInfoLine(
                  "Date Paid",
                  allowables.dateTime(paymentDetails.datePaid, true).date
                )
              : null}

            {userPaid && paymentDetails.receiptUrl ? (
              <a
                href={paymentDetails.receiptUrl}
                rel="noopener noreferrer"
                target="_blank"
              >
                Receipt <IconRender name="externallink" />
              </a>
            ) : null}

            <PlayerDiscountForm
              item={org.stripe?.whoPays === "Player" ? player : team}
              userPaid={userPaid}
              appliedDiscount={discountUsed}
              currency={paymentData.currency}
              discountAmount={discountAmount}
              onApplyDiscount={onApplyDiscount}
            />

            <div className="pop-box">
              <WarningHeader>
                <b>League Dues Refund Policy</b>
              </WarningHeader>
              <p
                className="text-left"
                style={{ paddingLeft: 15, paddingRight: 15 }}
              >
                {org.misc?.refundPolicy ||
                  "Your league has not set up a refund policy."}
              </p>
            </div>

            <div className="pop-box text-center">
              {renderPaymentInfoLine(
                "Amount Due",
                (userPaid ? "0.00" : amountRemaining.toFixed(2)) +
                  " " +
                  org.stripe.currency.toUpperCase(),
                true
              )}
            </div>

            {!userPaid ? (
              <button
                className="btn btn-info btn-block"
                onClick={startCheckoutSession}
              >
                <b>
                  {!userPaid && discountAmount >= initialAmount
                    ? "Click to Finalize Discount"
                    : "Pay League Dues"}
                </b>
              </button>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
};

export default PlayerPaymentForm;
