import React, { Component } from "react";
import { nameButtonText } from "../../../utils/matchFunctions";
import BasicModal from "../../common/pageComponents/basicModal";
import { popupStyle } from "../../../utils/styleUtil";

class MatchPopupSoccer extends Component {
  render() {
    const {
      players,
      team,
      onClick,
      popupOpen,
      closeModal,
      id,
      teamHeader,
      eventHeader,
      color,
      chronoEventsNames,
    } = this.props;

    const mapCards = () => {
      let mappedPlayers = [...players];
      const cards = chronoEventsNames.filter(
        (e) => e.eventType.includes("Card") && e.eventType.includes(team)
      );
      mappedPlayers.forEach((p) => {
        p.yellowCards = cards.filter(
          (c) =>
            c.eventType.includes("Yellow") &&
            c._id === (p.profileID?._id || p._id)
        ).length;
        p.redCards = cards.filter(
          (c) =>
            c.eventType.includes("Red") && c._id === (p.profileID?._id || p._id)
        ).length;
      });
      return mappedPlayers;
    };

    return (
      <BasicModal
        isOpen={popupOpen}
        onClose={closeModal}
        header={
          <div className={color}>
            <h4 className={"text-center " + color}>{teamHeader}</h4>
            <h5 className={"text-center " + color}>{eventHeader}</h5>
          </div>
        }
        style={popupStyle}
      >
        <div>
          {!id.includes("Card") && (
            <React.Fragment>
              <div className="row">
                <div className="col">
                  <button
                    key="og"
                    id="og"
                    onClick={() =>
                      onClick(
                        { _id: "og", name: "Own Goal", teamName: teamHeader },
                        id
                      )
                    }
                    className="btn btn-block btn-md btn-light"
                  >
                    Own Goal
                  </button>
                </div>
                <div className="col">
                  <button
                    key="unk"
                    id="unk"
                    onClick={() =>
                      onClick(
                        {
                          _id: "unk",
                          name: "Unknown Player",
                          teamName: teamHeader,
                        },
                        id
                      )
                    }
                    className="btn btn-block btn-md btn-light"
                  >
                    Unknown/Unregistered Player
                  </button>
                </div>
              </div>
              <br />
            </React.Fragment>
          )}

          <div className="row">
            <div className="col">
              {mapCards().map((p) => (
                <React.Fragment key={p._id}>
                  <button
                    key={p._id}
                    id={p._id}
                    aria-label="player-event-button"
                    onClick={() => onClick(p, id)}
                    className={
                      "btn btn-block btn-md " +
                      (p.redCards > 0 || p.yellowCards > 1
                        ? "soccer-red-card"
                        : p.yellowCards > 0
                        ? "soccer-yellow-card"
                        : p.suspendedFlag > 0
                        ? "btn-dark"
                        : "btn-light")
                    }
                  >
                    {nameButtonText(p, null, p.suspendedFlag > 0)}
                  </button>
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </BasicModal>
    );
  }
}

export default MatchPopupSoccer;
