import React, { useState, useEffect } from "react";

import SoccerLeaderboardTable from "../reports/soccer/soccerLeaderboardTable";
import FootballLeaderboardTable from "../reports/football/footballLeaderboardTable";
import DiamondLeaderboardTable from "../reports/diamond/diamondLeaderboardTable";
import MiniHeader from "../common/pageComponents/miniHeader";
import ComponentLoading from "../common/loading/componentLoading";
import { getMyHistory } from "../../services/playerService";
import toast from "../../utils/toast";
import WarningHeader from "../common/pageComponents/warningHeader";

const UserPlayerHistory = ({ isOpen }) => {
  const [openComps, setOpenComps] = useState({});
  const [historicalData, setHistoricalData] = useState(null);
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    setLoading(true);
    const res = await getMyHistory();
    if (res.status === 200) {
      setHistoricalData(res.data);
    } else toast.error(res.data);
    setLoading(false);
  };

  useEffect(() => {
    if (isOpen) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  if (!isOpen) return null;

  const hasNoHistory = () => {
    return (
      !historicalData ||
      (!historicalData.totals?.Soccer &&
        !historicalData.totals?.Football &&
        !historicalData.totals?.["Diamond Sports"])
    );
  };

  return (
    <div className="form-divided-section">
      {loading ? (
        <ComponentLoading />
      ) : hasNoHistory() ? (
        <WarningHeader>There is no data to display</WarningHeader>
      ) : (
        <div>
          {historicalData?.totals?.Soccer && (
            <div>
              <MiniHeader
                iconName="soccer"
                onClick={() =>
                  setOpenComps({ ...openComps, soccer: !openComps.soccer })
                }
              >
                {" "}
                Soccer{" "}
              </MiniHeader>
              {openComps.soccer ? (
                <div className="form-divided-section">
                  <SoccerLeaderboardTable
                    data={historicalData?.totals.Soccer}
                    searchQuery={null}
                    onSelectTable={() => {}}
                    spectate={true}
                    isHistorical={true}
                    keyProperty={"orgID"}
                  />
                </div>
              ) : null}
            </div>
          )}
          {historicalData?.totals?.Football && (
            <div>
              <MiniHeader
                iconName="football"
                onClick={() =>
                  setOpenComps({ ...openComps, football: !openComps.football })
                }
              >
                {" "}
                Football{" "}
              </MiniHeader>
              {openComps.football ? (
                <div className="form-divided-section">
                  <FootballLeaderboardTable
                    data={historicalData?.totals.Football}
                    searchQuery={null}
                    onSelectTable={() => {}}
                    spectate={true}
                    isHistorical={true}
                    keyProperty={"orgID"}
                  />
                </div>
              ) : null}
            </div>
          )}
          {historicalData?.totals["Diamond Sports"] && (
            <div>
              <MiniHeader
                iconName="baseballglove"
                onClick={() =>
                  setOpenComps({ ...openComps, diamond: !openComps.diamond })
                }
              >
                {" "}
                Baseball/Softball/Kickball{" "}
              </MiniHeader>
              {openComps.diamond ? (
                <div className="form-divided-section">
                  <DiamondLeaderboardTable
                    data={historicalData?.totals["Diamond Sports"]}
                    searchQuery={null}
                    onSelectTable={() => {}}
                    spectate={true}
                    isHistorical={true}
                    keyProperty={"orgID"}
                    isMyHistory={true}
                  />
                </div>
              ) : null}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default UserPlayerHistory;
