import React, { useState, useContext, useEffect } from "react";

import MiniHeader from "../common/pageComponents/miniHeader";
import HeaderContext from "../../context/headerContext";
import { getAllLiveScorecardsByExtension } from "../../services/liveScoreService";
import toast from "../../utils/toast";
import MatchesCardView from "../matches/matchesCardView";
import SpectateMatchModal from "./spectateMatchModal";
import useSearchParams from "../../hooks/useSearchParams";

const SpectateLiveScorecards = (props) => {
  const params = useSearchParams(props.location);
  const { setLoading } = useContext(HeaderContext);
  const [scorecards, setScorecards] = useState([]);
  const [selectedMatch, setSelectedMatch] = useState(null);
  const [matchOpen, setMatchOpen] = useState(false);

  const loadData = async () => {
    setLoading(true);
    const res = await getAllLiveScorecardsByExtension(
      props.location.pathname.slice(1)
    );
    if (res.status === 200) {
      setScorecards(res.data);
    } else {
      toast.error(res.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (params.matchID) {
      const linkedMatch = scorecards.find((m) => m._id === params.matchID);
      if (linkedMatch) {
        setSelectedMatch(linkedMatch);
        setMatchOpen(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, scorecards]);

  return (
    <div>
      <MiniHeader iconName="refresh" onClick={loadData}>
        Live Scorecards
      </MiniHeader>
      <div className="form-divided-section" style={{ padding: 5 }}>
        {scorecards.length > 0 ? (
          <div>
            <div className="text-center">
              <small>Tap a card to receive live updates</small>
            </div>
            <MatchesCardView
              matches={scorecards}
              onClick={(match) => {
                setSelectedMatch(match);
                setMatchOpen(true);
                loadData();
              }}
              liveScorecards={true}
            />
          </div>
        ) : (
          <p className="text-center cardview-card">
            There are no live scorecards for this league
          </p>
        )}
      </div>
      <SpectateMatchModal
        match={selectedMatch}
        popupOpen={matchOpen}
        closeModal={() => {
          setMatchOpen(false);
          props.history.replace("?live");
        }}
        org={props.pageOrg}
      />
    </div>
  );
};

export default SpectateLiveScorecards;
