import React, { Component } from "react";
import StandingsTable from "./standingsTable";
import { getStandings } from "../../services/matchService";
import HeaderContext from "../../context/headerContext";
import WarningHeader from "../common/pageComponents/warningHeader";

class Standings extends Component {
  static contextType = HeaderContext;
  state = {
    matches: [],
    standings: [],
    groupStandings: [],
    groups: [],
  };

  downloadColumns = [
    "position",
    "name",
    "points",
    "wins",
    "losses",
    "ties",
    "gamesPlayed",
    "goalsScored",
    "goalsConceded",
    "goalDifference",
  ];

  componentDidMount() {
    this.getData();
  }
  componentDidUpdate(prevProps) {
    if (this.props.divisionID !== prevProps.divisionID) {
      this.getData();
    }
  }

  getData = async () => {
    this.context.setLoading(true);
    this.context.setProgress([1]);
    if (!this.props.divisionID) return this.context.setLoading(false);
    const response = await getStandings(this.props.divisionID, {
      callback: this.indicateProgress,
      bar: 0,
    });
    if (response.status === 200) {
      this.setState({
        standings: response.data.standings,
        groupStandings: response.data.groupStandings,
        groups: response.data.groups,
      });
    }
    this.context.setLoading(false);
  };

  getRankingsList = () => {
    let rankingsList = ["Goal/Point Difference", "Goals/Points Scored"];
    if (this.props.org.misc && this.props.org.misc.rankingType === "Wins")
      rankingsList.unshift("Mini-League");
    else rankingsList.push("Mini-League");
    rankingsList.unshift("Points or Win/Loss Record");
    return rankingsList;
  };

  indicateProgress = (progress, location) => {
    let { progress: currentProgress } = this.context;
    currentProgress[location.bar] =
      ((progress.loaded / progress.total) * 100) / currentProgress.length;
    this.context.setProgress(currentProgress);
  };

  render() {
    const { standings, groups, groupStandings } = this.state;

    return (
      <div>
        {standings.length === 0 &&
          groups.length === 0 &&
          this.props.divisionID && (
            <WarningHeader>
              There have been no regular season or group matches completed in
              this division.
            </WarningHeader>
          )}
        {standings.length !== 0 && (
          <StandingsTable
            data={standings}
            sport={this.props.org?.sport.toLowerCase()}
            type={this.props.org?.misc?.rankingType}
          />
        )}
        {groupStandings.length !== 0 &&
          groups.map((g) => (
            <div key={g}>
              <StandingsTable
                data={groupStandings[g]}
                label={"Group: " + g}
                sport={this.props.org?.sport.toLowerCase()}
                type={this.props.org.misc?.rankingType}
              />
            </div>
          ))}
      </div>
    );
  }
}

export default Standings;
