import React from "react";

import allowables from "../../utils/allowables";
import { CLink, CBack } from "../common/customs/customLinks";

const MatchInfoHeader = ({ match, spectate, hideCompletionInfo }) => {
  if (!match) return null;
  const dateDisplay = allowables.dateTime(match.dateTime);

  return (
    <div>
      {!hideCompletionInfo && (
        <div className="pop-box text-center">
          <b>
            {match.matchAccepted === 1
              ? "This scorecard has been accepted. No further changes are expected."
              : match.matchComplete === 1
              ? "This scorecard was submitted but is not yet accepted. The result is still subject to review."
              : "This match has not been completed."}
          </b>
        </div>
      )}
      <div className="row pop-box" style={{ marginBottom: "5px" }}>
        <div className="col text-center">
          <small className="text-muted">
            Match Type:
            <br />
          </small>
          {spectate ? (
            match.type
          ) : (
            <CLink
              path={`/reports?${
                (match.type === "Playoff" ? "brackets=" : "standings=") +
                match.divisionID
              }`}
            >
              {match.type}
            </CLink>
          )}
        </div>
        {dateDisplay && (
          <div className="col text-center">
            {dateDisplay.date}
            <br />
            <b>{dateDisplay.time}</b>
          </div>
        )}
        <div className="col text-center">
          <small className="text-muted">
            Referee:
            <br />
          </small>
          {allowables.splitName(match.refereeName)}
        </div>
        {!spectate && (
          <div style={{ margin: "auto", float: "right" }}>
            <CBack>Go Back</CBack>
          </div>
        )}
      </div>
    </div>
  );
};

export default MatchInfoHeader;
