import React from "react";
import Table from "../common/table/table";
import { CLink } from "../common/customs/customLinks";
import TeamLogo from "../common/pageComponents/teamLogo";
import ExcelDownload from "../common/pageComponents/excelDownload";
const downloadColumns = [
  "position",
  "name",
  "points",
  "wins",
  "losses",
  "ties",
  "gamesPlayed",
  "goalsScored",
  "goalsConceded",
  "goalDifference",
];
const StandingsTable = ({
  data,
  onSort,
  sortColumn,
  label,
  sport,
  spectate,
  type,
}) => {
  const columns = [
    {
      path: "position",
      label: (
        <ExcelDownload
          data={data}
          columns={downloadColumns}
          dataName="Standings"
        />
      ),
    },
    {
      path: "logo",
      label: "",
      content: (t) => <TeamLogo team={t} name={t.name} size={35} />,
    },
    {
      path: "name",
      label: "Team Name",
      content: (t) =>
        spectate ? (
          t.name
        ) : (
          <CLink path={`/teams?info&q=${t._id}`}>{t.name}</CLink>
        ),
    },
    {
      path: "w/l",
      label: "Record",
      content: (t) => (
        <div>{`${t.wins}-${t.losses}${
          t.ties || ["soccer"].includes(sport) ? "-" + t.ties : ""
        }`}</div>
      ),
    },
    { path: "gamesPlayed", label: "Played" },
    {
      path: "goalsScored",
      label: ["soccer"].includes(sport)
        ? "Goals Scored"
        : sport.includes("diamond")
        ? "Runs Scored"
        : "Points Scored",
    },
    {
      path: "goalsConceded",
      label: ["soccer"].includes(sport)
        ? "Goals Conceded"
        : sport.includes("diamond")
        ? "Runs Against"
        : "Points Against",
    },
    {
      path: "goalDifference",
      label: ["soccer"].includes(sport)
        ? "Goal Difference"
        : sport.includes("diamond")
        ? "Run Differential"
        : "Point Differential",
    },
  ];
  if (["soccer"].includes(sport.toLowerCase()) || type === "Elo")
    columns.splice(3, 0, {
      path: "points",
      label: type === "Elo" ? "Elo Rating" : "Points",
    });

  if (type === "Elo") columns.splice(5, 4);

  return (
    <Table
      columns={columns}
      data={data}
      sortColumn={sortColumn}
      onSort={onSort}
      keyProperty={"_id"}
      tableClass="table-custom"
      headerClass="thead-custom"
      bodyClass="tbody-custom"
      highlightOnHover={true}
      label={label}
    />
  );
};

export default StandingsTable;
