import React, { useState, useEffect, useContext } from "react";
import CustomConfirm from "../common/customs/customConfirm";
import MiniHeader from "../common/pageComponents/miniHeader";
import NonFormSelect from "../common/form/nonFormSelect";
import allowables from "../../utils/allowables";
import toast from "../../utils/toast";
import { createSoccerEventText } from "../../utils/soccerFunctions";
import { createFootballEventText } from "../../utils/footballFunctions";
import { atBatName } from "../../utils/diamondFunctions";
import OrgContext from "../../context/orgContext";

const EventEditModal = ({
  sport,
  teams,
  players,
  originalEvent,
  onSave,
  id,
  isOpen,
  onClose,
  allEvents,
}) => {
  const org = useContext(OrgContext);
  const [newEvent, setNewEvent] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmText, setConfirmText] = useState("");

  const teamOrder = allowables.teams(sport);

  useEffect(() => {
    if (originalEvent) {
      const ev = { ...originalEvent };
      ev.index = allEvents.findIndex((e) => e.eventID === ev.eventID);
      setNewEvent(ev);
    }
  }, [originalEvent, allEvents]);

  if (!originalEvent || !newEvent) return null;

  const getPlayerOptions = (addUnknown, addOg, opposite) => {
    const team =
      sport === "soccer"
        ? teams[newEvent.eventType?.slice(0, 4)]
        : sport.includes("diamond")
        ? getTeamInfo(newEvent.teamID, opposite)
        : sport.includes("football")
        ? getTeamInfo(newEvent.teamID)
        : null;

    let options = [];
    if (addOg)
      options.push({
        _id: "og",
        name: "Own Goal",
        teamName: team.name,
      });
    if (addUnknown)
      options.push({
        _id: "unk",
        name: "Unknown/Unregistered Player",
        teamName: team.name,
      });

    options.push(...players[team._id === teams.home._id ? "home" : "away"]);
    return options;
  };

  const getSelectedPlayer = (_id) => {
    const player = [
      {
        _id: "unk",
        name: "Unknown/Unregistered Player",
      },
      {
        _id: "og",
        name: "Own Goal",
      },
      ...players.home,
      ...players.away,
    ].find((p) => (p.profileID?._id || p._id) === _id);

    return {
      teamID: getTeamInfo(newEvent.teamID)?._id,
      teamName: getTeamInfo(newEvent.teamID)?.name,
      ...player,
      _id: player?.profileID?._id || player?._id || "",
    };
  };

  const getTeamInfo = (teamID, opposite) => {
    let teamSide =
      sport === "soccer"
        ? newEvent.eventType?.slice(0, 4)
        : (sport.includes("diamond") || sport.includes("football")) && teamID
        ? teams.home._id === teamID
          ? "home"
          : "away"
        : null;

    if (opposite) teamSide = teamSide === "home" ? "away" : "home";
    return teams[teamSide];
  };

  const getInDepthAtBatOptions = () => {
    const inDepths =
      newEvent.result === "out"
        ? [
            "strike out",
            "fly out",
            "ground out",
            "fielder's choice",
            "misc out",
          ]
        : newEvent.result === "walk"
        ? ["on balls", "hit by pitch", "balk"]
        : newEvent.result === "hit"
        ? ["single", "double", "triple", "home run"]
        : newEvent.result === "stolen base"
        ? ["stole home"]
        : [];
    return inDepths.map((inDepth) => {
      return { _id: inDepth, name: allowables.capLetterOne(inDepth) };
    });
  };

  const getEventStyleOptions = () => {
    if (newEvent.eventType === "Penalty") return [];

    const options = [];
    if (
      newEvent.eventType === "Touchdown" ||
      newEvent.eventType === "Extra Point"
    )
      options.push(
        { _id: "passer", name: "Passing" },
        { _id: "rusher", name: "Rushing" }
      );
    if (newEvent.eventType === "Extra Point")
      options.push({ _id: "kicker", name: "Kicking" });
    if (newEvent.eventType === "Defense")
      options.push(
        { _id: "interception", name: "Interception" },
        { _id: "fumble", name: "Fumble Recovery" },
        { _id: "sack", name: "Sack" },
        { _id: "puntReturn", name: "Punt Return for Touchdown" },
        { _id: "safety", name: "Safety" }
      );
    return options;
  };

  const indexSwitchPicker = {
    _id: "index",
    label: "Event Position",
    options: new Array(allEvents.length)
      .fill(null)
      .map((e, idx) => {
        return {
          _id: idx,
          name:
            idx === 0
              ? "Move to Bottom"
              : idx === allEvents.length - 1
              ? "Move to Top"
              : String(idx + 1),
        };
      })
      .reverse(),
    value: newEvent.index,
  };
  const rows = {
    soccer: [
      indexSwitchPicker,
      {
        _id: "team",
        label: "Team",
        options: [teams[teamOrder[0]], teams[teamOrder[1]]],
        value: teams[newEvent.eventType?.slice(0, 4)]?._id,
      },
      {
        _id: "type",
        label: "Event Type",
        options: [
          { _id: "Goals", name: "Goal" },
          { _id: "YellowCards", name: "Yellow Card" },
          { _id: "RedCards", name: "Red Card" },
        ],
        value: newEvent.eventType?.slice(8),
      },
      {
        _id: "player",
        label: "Player",
        options: getPlayerOptions(
          newEvent.eventType?.includes("Goal"),
          newEvent.eventType?.includes("Goal")
        ),
        value: getSelectedPlayer(newEvent._id)?._id,
      },
      {
        _id: "assist",
        label: "Assist",
        options: newEvent.eventType?.includes("Goal")
          ? getPlayerOptions(newEvent.eventType?.includes("Goal"))
          : [],
        value: getSelectedPlayer(newEvent.assistID)?._id,
        placeholder: "None",
      },
    ],
    football: [
      indexSwitchPicker,
      {
        _id: "team",
        label: "Team",
        options: [teams[teamOrder[0]], teams[teamOrder[1]]],
        value: getTeamInfo(newEvent.teamID)._id,
      },
      {
        _id: "type",
        label: "Event Type",
        options: [
          { _id: "Touchdown", name: "Touchdown" },
          { _id: "Extra Point", name: "Extra Point" },
          { _id: "Field Goal", name: "Field Goal" },
          { _id: "Defense", name: "Defense" },
          { _id: "Penalty", name: "Penalty" },
        ],
        value: newEvent.eventType,
      },
      {
        _id: "eventStyle",
        label: "Event Method",
        options: getEventStyleOptions(),
        value: newEvent.eventStyle,
      },
      {
        _id: "player",
        label: newEvent.eventStyle === "passer" ? "Passer" : "Player",
        options: newEvent.eventStyle === "safety" ? [] : getPlayerOptions(true),
        value: getSelectedPlayer(newEvent._id)?._id,
      },
      {
        _id: "receiver",
        label: "Receiver",
        options: newEvent.eventStyle === "passer" ? getPlayerOptions(true) : [],
        value: getSelectedPlayer(newEvent.player2ID)?._id,
      },
      {
        _id: "points",
        label: newEvent.eventType === "Penalty" ? "Yards" : "Points",
        options: (newEvent.eventType === "Extra Point"
          ? ["1", "2"]
          : newEvent.eventStyle === "interception" ||
            newEvent.eventStyle === "fumble"
          ? ["0", "6"]
          : newEvent.eventType === "Penalty"
          ? new Array(121).fill(null).map((_, idx) => String(idx))
          : []
        ).map((n) => {
          return { _id: n, name: n };
        }),
        value: newEvent.points,
      },
    ],
    diamond: [
      indexSwitchPicker,
      {
        _id: "team",
        label: "Team",
        options: [teams[teamOrder[0]], teams[teamOrder[1]]],
        value: getTeamInfo(newEvent.teamID)._id,
      },
      {
        _id: "pitcher",
        label: "Pitcher",
        options: getPlayerOptions(true, false, true),
        value: getSelectedPlayer(newEvent.pitcherID)._id,
      },
      {
        _id: "batter",
        label: atBatName(org).er,
        options: getPlayerOptions(true, false),
        value: getSelectedPlayer(newEvent._id)?._id,
      },
      {
        _id: "result",
        label: `${atBatName(org).action} Result`,
        options: [
          { _id: "out", name: "Out" },
          { _id: "walk", name: "Walk" },
          { _id: "hit", name: "Hit" },
          { _id: "stolen base", name: "Stolen Base" },
        ],
        value: newEvent.result,
      },
      {
        _id: "inDepth",
        label: "Result Detail",
        options: getInDepthAtBatOptions(),
        value: newEvent.inDepth,
      },
      {
        _id: "rbis",
        label: "RBIs",
        options: ["0", "1", "2", "3", "4"].map((n) => {
          return { _id: n, name: n };
        }),
        value: newEvent.rbis,
      },
    ],
  };

  const handleOptionSelect = (event, type) => {
    const value = event.target.value;
    let updatedEvent = { ...newEvent };
    switch (type) {
      case "team":
        if (sport === "soccer")
          updatedEvent.eventType =
            (teams.home._id === value ? "home" : "away") +
            updatedEvent.eventType?.slice(4);
        else if (sport.includes("diamond") || sport.includes("football")) {
          updatedEvent.teamID = value;
          updatedEvent.teamName = getTeamInfo(value).name;
          if (updatedEvent.eventStyle === "safety") {
            updatedEvent.name = getTeamInfo(value).name;
          }
        }
        updatedEvent._id = "";
        updatedEvent.player2ID = "";
        updatedEvent.pitcherID = "";
        break;
      case "player":
      case "batter":
        updatedEvent._id = value;
        updatedEvent.name = getSelectedPlayer(value).name;
        break;
      case "assist":
        updatedEvent.assistID = value;
        updatedEvent.assistName = getSelectedPlayer(value).name;
        break;
      case "pitcher":
        updatedEvent.pitcherID = value;
        updatedEvent.pitcherName = getSelectedPlayer(value).name;
        break;
      case "receiver":
        updatedEvent.player2ID = value;
        updatedEvent.player2Name = getSelectedPlayer(value).name;
        break;
      case "type":
        if (sport === "soccer") {
          // cannot select unregistered or own goal for yellow/red card
          if (
            value.includes("Card") &&
            ["og", "unk"].includes(updatedEvent._id)
          ) {
            updatedEvent._id = "";
          }
          updatedEvent.eventType = updatedEvent.eventType?.slice(0, 8) + value;
        } else if (sport === "football") {
          updatedEvent.eventType = value;
          updatedEvent.eventStyle = "";
          updatedEvent.points =
            value === "Defense" ? "0" : value === "Penalty" ? "10" : "1";
          if (value === "Field Goal") updatedEvent.eventStyle = "kicker";
        }
        break;
      case "eventStyle":
        updatedEvent.eventStyle = value;
        if (sport.includes("football")) {
          if (value === "safety") {
            updatedEvent._id = "team";
            updatedEvent.name = updatedEvent.teamName;
          } else if (value === "sack") updatedEvent.points = "0";
        }
        break;
      case "result":
        updatedEvent.inDepth = "";
        updatedEvent[type] = value;
        break;
      default:
        updatedEvent[type] = value;
    }

    setNewEvent(updatedEvent);
  };

  const createEventType = () => {
    return sport === "soccer"
      ? newEvent.eventType
      : sport.includes("diamond")
      ? {
          result: newEvent.result,
          inDepth: newEvent.inDepth,
          rbis: Number(newEvent.rbis),
        }
      : sport.includes("football")
      ? {
          eventType: newEvent.eventType,
          eventStyle: newEvent.eventStyle,
          points: newEvent.points,
        }
      : null;
  };

  const raiseSaveEvent = () => {
    // pass the event back for editing
    const playerBatter =
      getSelectedPlayer(newEvent._id) || newEvent.eventStyle === "safety"
        ? {
            _id: newEvent._id,
            name: newEvent.name,
            teamID: newEvent.teamID,
            teamName: newEvent.teamName,
          }
        : {};

    const secondPlayer = sport.includes("diamond")
      ? getSelectedPlayer(newEvent.pitcherID)
      : sport.includes("football")
      ? getSelectedPlayer(newEvent.player2ID)
      : sport.includes("soccer")
      ? getSelectedPlayer(newEvent.assistID)
      : null;

    onSave(
      originalEvent,
      playerBatter,
      createEventType(),
      secondPlayer,
      teams.home._id === playerBatter.teamID ? "home" : "away",
      newEvent.index
    );
    onClose(id);
  };

  const handleAlert = () => {
    // validate the event
    if (sport.includes("diamond")) {
      if (!getSelectedPlayer(newEvent.pitcherID)._id)
        return toast.error("Please select a pitcher");
      else if (!newEvent.inDepth)
        return toast.error("Please select the result detail");
      else if (newEvent.inDepth === "home run" && newEvent.rbis === "0")
        return toast.error("A home run must have at least 1 RBI");
    }
    if (sport.includes("football")) {
      switch (newEvent.eventType) {
        case "Penalty":
          newEvent.eventStyle = newEvent.eventType.toLowerCase();
          break;
        case "Field Goal":
          newEvent.eventStyle = "kicker";
          break;
        default:
      }

      if (!newEvent.eventStyle)
        return toast.error("Please select an event method");
      if (newEvent.eventStyle === "passer" && !newEvent.player2ID)
        return toast.error("For passing events please select a receiver");
    }
    if (
      !getSelectedPlayer(newEvent._id)._id &&
      newEvent.eventStyle !== "safety"
    )
      return toast.error(
        "Please select a " + (sport.includes("diamond") ? "batter" : "player")
      );

    if (sport === "soccer" || sport === "football") {
      const originalEventText =
        sport === "soccer"
          ? createSoccerEventText(
              getSelectedPlayer(originalEvent._id),
              originalEvent.eventType,
              originalEvent.assistName
            )
          : sport === "football"
          ? createFootballEventText(
              originalEvent,
              Number(originalEvent.points) !== 0
            )
          : "";
      const newEventText =
        sport === "soccer"
          ? createSoccerEventText(
              getSelectedPlayer(newEvent._id),
              newEvent.eventType,
              newEvent.assistName
            )
          : sport === "football"
          ? createFootballEventText(newEvent, Number(newEvent.points) !== 0)
          : "";

      setConfirmText(
        "EDIT EVENT%s%%s%ORIGINAL: " +
          originalEventText +
          "%s%%s%NEW: " +
          newEventText
      );
      setConfirmOpen(true);
    } else {
      // if there is no confirmation screen call the event handler directly
      raiseSaveEvent();
    }
  };

  return (
    <CustomConfirm
      callback={handleAlert}
      isOpen={isOpen}
      close={onClose}
      focused={true}
      id={id}
      manuallyClose={true}
    >
      <MiniHeader>Edit Event</MiniHeader>
      <div className="form-divided-section">
        <p className="text-center">
          <b>
            {originalEvent.text.split("%s%").map((t, idx) => (
              <span key={idx}>
                {t}
                <br />
              </span>
            ))}
          </b>
        </p>
        {rows[sport.includes("diamond") ? "diamond" : sport].map((row, idx) => {
          if (
            row.options.length === 0 ||
            (!org?.misc?.soccerSportOptions?.trackAssists &&
              row._id === "assist")
          )
            return null;

          return (
            <NonFormSelect
              key={idx}
              name={row.label}
              label={row.label}
              options={row.options}
              onChange={(_id) => handleOptionSelect(_id, row._id)}
              value={row.value}
              noDefaultOption={
                ![
                  "player",
                  "batter",
                  "pitcher",
                  "eventStyle",
                  "receiver",
                  "inDepth",
                  "assist",
                ].includes(row._id)
              }
              splitNames
              override_id={
                ["player", "batter", "pitcher", "receiver", "assist"].includes(
                  row._id
                )
                  ? (option) => option.profileID?._id || option._id
                  : null
              }
              overrideDefaultPlaceholder={row.placeholder}
            />
          );
        })}
      </div>
      <CustomConfirm
        dialog={confirmText}
        isOpen={confirmOpen}
        callback={raiseSaveEvent}
        close={() => setConfirmOpen(false)}
        split="%s%"
        centerAllText
      />
    </CustomConfirm>
  );
};

export default EventEditModal;
