import React from "react";

import PageBottom from "../common/pageComponents/pageBottom";
import Help from "./help";
import FaqMain from "./faqMain";
import SideBySideView from "./../common/pageComponents/sideBySideView";

const HelpPageMain = () => {
  return (
    <React.Fragment>
      <SideBySideView
        Components={[<Help />, <FaqMain />]}
        separateRows={true}
      />
      <PageBottom />
    </React.Fragment>
  );
};

export default HelpPageMain;
