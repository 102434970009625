import React, { useEffect, useState, useRef } from "react";

import MiniHeader from "../common/pageComponents/miniHeader";
import LiveScorecardsTable from "../scorecards/liveScorecardsTable";
import LiveScorecard from "../scorecards/liveScorecard";
import StandingsTable from "../reports/standingsTable";
import PlayoffBracketCanvas from "../brackets/playoffBracketCanvas";
import SportLeaderboard from "../reports/sportLeaderboard";
import allowables from "../../utils/allowables";
import {
  getAllLiveScorecards,
  getLiveScorecard,
} from "../../services/liveScoreService";
import {
  getMatch,
  getStandings,
  getPlayoffMatches,
  getMatchesByLastUpdate,
} from "../../services/matchService";
import { getLeaderboard } from "../../services/playerService";
import MatchesTable from "../matches/matchesTable";

// refresh intervals for different types of scoreboard, in seconds
const scorecardRefreshRate = 20;
const dataRefreshRate = 120;

const types = {
  AllLiveScorecards: {
    header: "Live Scorecards",
    Component: LiveScorecardsTable,
    initialProps: { scorecards: [] },
    dataProp: "scorecards",
    refreshRate: scorecardRefreshRate,
  },
  RotatingScorecards: {
    header: "Live Matches",
    initialProps: { teamOrder: ["home", "away"] },
    Component: LiveScorecard,
    refreshRate: scorecardRefreshRate,
  },
  Brackets: {
    header: "Bracket",
    initialProps: { matches: [] },
    Component: PlayoffBracketCanvas,
    refreshRate: dataRefreshRate,
  },
  Standings: {
    header: "Standings",
    Component: StandingsTable,
    initialProps: {
      data: [],
      sport: "soccer",
    },
    refreshRate: dataRefreshRate,
  },
  Leaderboards: {
    header: "Leaderboards",
    Component: SportLeaderboard,
    initialProps: {
      sport: "soccer",
      leaderboard: [],
    },
    refreshRate: dataRefreshRate,
  },
  CompletedMatches: {
    header: "Completed Matches",
    Component: MatchesTable,
    initialProps: {
      matchesByPage: [],
    },
    refreshRate: dataRefreshRate,
  },
  AcceptedMatches: {
    header: "Accepted Matches",
    Component: MatchesTable,
    initialProps: {
      matchesByPage: [],
    },
    refreshRate: dataRefreshRate,
  },
};

const SingleScoreboard = ({
  index,
  org,
  type,
  onRemove,
  _id,
  divisionName,
  limit,
}) => {
  const [scoreboard, setScoreboard] = useState(null);
  const [componentProps, setComponentProps] = useState({});
  const [refreshTimer, setRefreshTimer] = useState(null);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);

  const scorecardIndexRef = useRef(0);

  const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay));

  const refreshData = async (firstCall) => {
    let currentEmpty = false;
    setIsRefreshing(true);

    // force a small delay on each refresh before continuing so component can fade in/out to show it's loading
    await sleep(firstCall ? 0 : 1500);

    let allScorecardsResponse;
    if (type === "RotatingScorecards" || type === "AllLiveScorecards") {
      allScorecardsResponse = await getAllLiveScorecards();
      if (allScorecardsResponse.data.length === 0) currentEmpty = true;
    }

    if (!currentEmpty) {
      if (type === "RotatingScorecards") {
        if (allScorecardsResponse?.status === 200) {
          const newScorecardIds = allScorecardsResponse.data.map((s) => s._id);
          const newScorecardIndex =
            scorecardIndexRef.current + 1 > newScorecardIds.length - 1
              ? 0
              : scorecardIndexRef.current + 1;

          scorecardIndexRef.current = newScorecardIndex;
          const matchRes = await getMatch(newScorecardIds[newScorecardIndex]);
          const liveScorecard = await getLiveScorecard(
            newScorecardIds[newScorecardIndex],
            true
          );
          if (matchRes.status === 200 && liveScorecard.status === 200) {
            setComponentProps({
              matchData: matchRes.data,
              liveData: liveScorecard.data,
              teamOrder: allowables.teams(org.sport),
              org,
            });
          }
        }
      } else if (type === "AllLiveScorecards") {
        if (allScorecardsResponse?.status === 200) {
          setComponentProps({
            scorecards: allScorecardsResponse.data,
          });
        }
      } else if (type === "Standings") {
        const standingsRes = await getStandings(_id);
        if (standingsRes.status === 200) {
          if (!standingsRes.data.standings.length) currentEmpty = true;
          setComponentProps({
            data: standingsRes.data.standings,
          });
        }
      } else if (type === "Brackets") {
        const matchesRes = await getPlayoffMatches(_id);
        if (matchesRes.status === 200) {
          if (!matchesRes.data.length) currentEmpty = true;
          setComponentProps({ matches: matchesRes.data });
        }
      } else if (type === "Leaderboards") {
        const leaderboardRes = await getLeaderboard(_id);
        if (leaderboardRes.status === 200) {
          if (!leaderboardRes.data.leaderboard?.length) currentEmpty = true;
          setComponentProps({
            leaderboard: leaderboardRes.data.leaderboard,
            sport: org.sport,
          });
        }
      } else if (type.toLowerCase().includes("matches")) {
        const matchesRes = await getMatchesByLastUpdate(
          _id,
          type.replace("Matches", "").toLowerCase(),
          limit
        );
        if (!matchesRes.data.length) currentEmpty = true;
        setComponentProps({
          matchesByPage: matchesRes.data,
          isReviewTable: true,
        });
      }
    }

    setIsEmpty(currentEmpty);
    setIsRefreshing(false);
  };

  useEffect(() => {
    const thisScoreboard = types[type];
    setScoreboard(thisScoreboard);
    setComponentProps(thisScoreboard?.initialProps);

    if (!refreshTimer && thisScoreboard) {
      refreshData(true);
      setRefreshTimer(
        setInterval(() => {
          refreshData();
        }, thisScoreboard.refreshRate * 1000)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  useEffect(() => {
    return () => {
      clearInterval(refreshTimer);
    };
  }, [refreshTimer]);

  if ((!scoreboard || !componentProps) && !isEmpty) return null;

  return (
    <div className={isRefreshing ? "fade-in-out" : ""}>
      <MiniHeader onRemove={() => onRemove(index)}>
        {divisionName
          ? types[type].header + " - " + divisionName
          : types[type].header}
      </MiniHeader>
      <div className="form-divided-section">
        {isEmpty ? (
          <p className="text-center">
            <b>There are no {types[type].header} to display</b>
          </p>
        ) : (
          <scoreboard.Component
            {...componentProps}
            isScoreboard={true}
            sport={org.sport.toLowerCase()}
            org={org}
          />
        )}
      </div>
    </div>
  );
};

export default SingleScoreboard;
