import React, { useState, useEffect } from "react";

import MultiSelect from "../common/pageComponents/multiSelect";
import NonFormSelect from "./../common/form/nonFormSelect";
import NonFormInput from "./../common/form/nonFormInput";
import TooltipButton from "../common/tooltip/tooltipButton";
import SideBySideView from "../common/pageComponents/sideBySideView";
import IconRender from "../common/icons/iconRender";

const TeamsButtonLineup = ({
  user,
  captainsTeam,
  teams,
  divisions,
  multiSelectColumns,
  onMultiEdit,
  selectedDivision,
  setSelectedDivision,
  enteredGroupName,
  setEnteredGroupName,
}) => {
  const [buttons, setButtons] = useState([]);

  useEffect(() => {
    setButtons(getButtons());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [divisions]);

  const getButtons = () => {
    let buttons = [];
    if (user.role.includes("admin")) {
      buttons.push(
        <TooltipButton
          buttonClass="btn-md btn-info"
          tooltipText={"Add New Team"}
          tooltipDirection="bottom"
          navigatePath={"/teams?q=new"}
          testId="add-new-team"
        >
          <IconRender name="add" className="icon-md" />
        </TooltipButton>,
        <MultiSelect
          data={teams}
          keyValue="_id"
          columnsToShow={multiSelectColumns}
          onSubmit={onMultiEdit}
          title="teams"
          type="edit"
          minRowHeight={30}
          buttonIcon={<IconRender name="settings" className="icon-md" />}
          buttonClass="btn-md"
          tooltipText="Mass Edit Team Settings"
          tooltipDirection={"bottom"}
          buttonTestId="multi-edit-teams"
        >
          <div>
            <br />
            <div className="row">
              <div className="col">
                <NonFormSelect
                  name="divisionID"
                  label="Select Division:&nbsp;"
                  options={divisions}
                  onChange={(event) => setSelectedDivision(event.target.value)}
                  value={selectedDivision || "Make a selection..."}
                />
              </div>
              <div className="col">
                <NonFormInput
                  name="groupName"
                  type="text"
                  label="Group"
                  value={enteredGroupName}
                  onChange={(event) => setEnteredGroupName(event.target.value)}
                />
              </div>
            </div>
            <br />
          </div>
        </MultiSelect>
      );
    } else if (user.role.includes("captain")) {
      if (!captainsTeam)
        buttons.push(
          <TooltipButton
            buttonClass="btn-md btn-info"
            tooltipText={"Create My Team"}
            tooltipDirection="bottom"
            navigatePath="/teams?q=new"
            testId="create-team"
          >
            <IconRender name="add" className="icon-md" />
          </TooltipButton>
        );
      else {
        buttons.push(
          <TooltipButton
            buttonClass="btn-md btn-light"
            tooltipText={"Manage My Team"}
            tooltipDirection="bottom"
            navigatePath={`/teams?info&q=${captainsTeam._id}`}
            testId="manage-team"
          >
            <IconRender name="clipboard" className="icon-md" />
          </TooltipButton>,
          <TooltipButton
            buttonClass="btn-md btn-secondary"
            tooltipText={"Edit My Team"}
            tooltipDirection="bottom"
            navigatePath={`/teams?q=${captainsTeam._id}`}
            testId="edit-team"
          >
            <IconRender name="edit" className="icon-md" />
          </TooltipButton>
        );
      }
    }

    return buttons;
  };

  if (!["admin", "captain"].some((r) => user.role.includes(r))) return null;

  return (
    <SideBySideView
      Components={buttons}
      groupClassName="button-group-box"
      separateRows
      minWidth={200}
    />
  );
};

export default TeamsButtonLineup;
