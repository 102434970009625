import React from "react";
import BasicModal from "../common/pageComponents/basicModal";
import { popupStyle } from "../../utils/styleUtil";
import LeagueContactInfo from "../leagueWebpage/leagueContactInfo";
import ContactUsForm from "./contactUsForm";

const ContactUsModal = ({ isOpen, setOpen, org }) => {
  return (
    <BasicModal
      isOpen={isOpen}
      onClose={() => setOpen(false)}
      style={{ content: { ...popupStyle.content, height: "65%" } }}
    >
      <LeagueContactInfo org={org} />
      <hr className="custom" />
      <ContactUsForm successCallback={() => this.props.setOpen(false)} />
    </BasicModal>
  );
};

export default ContactUsModal;
