import React from "react";
import allowables from "../../../utils/allowables";
import IconRender from "../../common/icons/iconRender";
import { mapTennisPoints } from "../../../utils/textUtils";

const SimpleTeamSegment = ({
  org,
  match,
  teamOrder,
  onEvent,
  onUndo,
  complexScore,
}) => {
  const sport = allowables.translateSportName(org);

  return (
    <div>
      {sport === "Tennis" ? (
        <p className="text-center">
          {!complexScore?.isFinished ? (
            <b>Tap to add point</b>
          ) : (
            "Match has finished. Review and Submit this scorecard"
          )}
        </p>
      ) : null}
      <div className="row">
        {teamOrder.map((team) => {
          return (
            <div className="col text-center" key={team}>
              {sport === "Tennis" ? (
                <div
                  className="pop-box text-center tennis-score-set points"
                  style={{
                    visibility: complexScore?.isFinished ? "hidden" : "",
                    width: 50,
                    margin: "auto",
                    marginBottom: 10,
                  }}
                >
                  {mapTennisPoints(
                    complexScore,
                    complexScore.curScoreIndex[team]
                  )}
                </div>
              ) : null}
              <button
                className="btn btn-lg btn-block btn-info"
                onClick={() => onEvent(null, { increment: 1, team: team })}
                aria-label={
                  sport === "Tennis" ? match[team + "TeamName"] : "add-point"
                }
                style={{ visibility: complexScore?.isFinished ? "hidden" : "" }}
              >
                {sport === "Tennis" ? (
                  match[team + "TeamName"]
                ) : (
                  <IconRender name="plus" />
                )}
              </button>
              <br />
              {sport !== "Tennis" ? (
                <button
                  className="btn btn-lg btn-block btn-dark"
                  onClick={() => onEvent(null, { increment: -1, team: team })}
                  aria-label="subtract-point"
                >
                  <IconRender name="minus" />
                </button>
              ) : null}
            </div>
          );
        })}
      </div>
      {sport === "Tennis" ? (
        <button
          className="btn btn-dark btn-block"
          onClick={() => onUndo(null, null, null, true)}
          disabled={
            complexScore?.totalPoints.home + complexScore?.totalPoints?.away ===
            0
          }
        >
          Undo Last Point
        </button>
      ) : null}
    </div>
  );
};

export default SimpleTeamSegment;
