import React from "react";

const HorizontalButtons = ({
  buttons,
  onClick,
  selectedType,
  custom,
  colors,
}) => {
  return (
    <div className="row" style={{ marginTop: 5, marginBottom: 5 }}>
      {buttons.map((b) => {
        const id = b.id ? b.id : b;
        const name = b.name ? b.name : b;
        return (
          <div className="col text-center" key={id} style={{ margin: 5 }}>
            <button
              className={
                custom
                  ? "clickable text-center custom-select-item" +
                    (selectedType === id ? "-selected" : "")
                  : "btn btn-sm btn-block btn-" +
                    (selectedType === id ? "info font-weight-bold" : "light")
              }
              style={
                selectedType === id
                  ? {
                      backgroundColor: colors && colors.bg,
                      color: colors && colors.text,
                    }
                  : {}
              }
              onClick={() => onClick(b)}
            >
              {name.charAt(0).toUpperCase() + name.slice(1)}
            </button>
          </div>
        );
      })}
    </div>
  );
};

export default HorizontalButtons;
