import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import SingleTopic from "./singleTopic";
import { CLink } from "../common/customs/customLinks";

const Help = () => {
  const history = useHistory();
  const [openItem, setOpenItem] = useState(null);

  useEffect(() => {
    setOpenItem(history.location.search.slice(1));
  }, [history.location.search]);

  const pushHistory = (id) => {
    history.replace(`/help?${id}`);
    setOpenItem(id);
  };

  return (
    <React.Fragment>
      <h2 className="text-center">Help</h2>
      <SingleTopic
        id="leagues"
        title="Leagues"
        toggle={pushHistory}
        open={openItem}
      >
        <p>
          The League is the platform upon which your whole organizational
          structure will be built. A League can consist of many divisions,
          teams, players, and referees all under a single umbrella. A League is
          specific to a single sport. If your organization provides multiple
          sports you must maintain multiple accounts (can be under a single
          email address) to manage each sport.
        </p>
      </SingleTopic>
      <SingleTopic
        id="seasons"
        title="Seasons"
        toggle={pushHistory}
        open={openItem}
      >
        <p>
          A Season is used to group Divisions and set a defined time period for
          matches to take place. Each division must belong to a season. At the
          end of a season all of the data in that season can be managed together
          in any way you wish.
        </p>
      </SingleTopic>
      <SingleTopic
        id="divisions"
        title="Divisions and Groups"
        toggle={pushHistory}
        open={openItem}
      >
        <h6>Divisions</h6>
        <p>
          Within your League you can have multiple divisions. Each division will
          maintain a separate standings table, playoff bracket, and leaderboard.
        </p>
        <h6>Groups</h6>
        <p>
          A group is used within a division when you want to separate standings
          tables but keep the leaderboard combined. Usually the group format
          will be used to decide which teams advance to the playoff round. To
          create group matches each team must be assigned to a group, and teams
          within each group must be in the same division.
        </p>
      </SingleTopic>
      <SingleTopic
        id="teams"
        title="Teams"
        toggle={pushHistory}
        open={openItem}
      >
        <p>
          The team is use to combine players and create matches. A team must
          always be tied to a division. You must have at least two teams in the
          same division before you can create a match.
        </p>
      </SingleTopic>
      <SingleTopic
        id="players"
        title="Players"
        toggle={pushHistory}
        open={openItem}
      >
        <p>
          The player is used to track the leaderboards within a Division. A
          player must always be tied to a team. You can move players from team
          to team as needed. By sending the player signup link out to each
          specific person they are able to register themselves as that player,
          and easily track their statistics and upcoming matches. If you choose
          to make your League private (not allow spectating) then this must be
          done to ensure that users can have access to see their upcoming
          matches.
        </p>
      </SingleTopic>
      <SingleTopic
        id="teamCaptain"
        title="Team Captains"
        toggle={pushHistory}
        open={openItem}
      >
        <p>
          Especially for larger Leagues the Team Captains will be the most
          important users in your League. The best way to get started in to
          invite a Team Captain to register their team directly from your League
          homepage under the "Invite Users" section.
        </p>
        <h6>Registering a team</h6>
        <p>
          A user who has registered as a Team Captain is able to create a single
          team and add it to a division. Once their team is created they will
          have full access to create new players for their team, and send
          invitations to users to register as those created players. Delegating
          the player creation out to the team captains is the best way to reduce
          your manual entry load.
        </p>
        <p>
          Once the Team Captain has registered their team they will be able to
          make edits until you lock editing for that division. After that they
          will have to make a request to an Admin should they want to make an
          update. Once players have been created an admin can select any player
          with an attached user to be Team Captain, and the rights to add or
          edit players for that team will be switched over.
        </p>
        <h6>Match Review</h6>
        <p>
          The Team Captain is also in charge of verifying that the referee has
          the correct score and events at the end of the match. If there is a
          disagreement on the scorecard the Team Captain will be responsible for
          adding their comment to the scorecard before the admin reviews it.
        </p>
      </SingleTopic>
      <SingleTopic
        id="referees"
        title="Referees/Umpires"
        toggle={pushHistory}
        open={openItem}
      >
        A referee or umpire has limited access to private League data. They are
        only able to see matches that have been assigned to them.
      </SingleTopic>
      <SingleTopic
        id="matches"
        title="Matches"
        toggle={pushHistory}
        open={openItem}
      >
        <p>There are five different types of matches that you can create.</p>
        <ul>
          <li>Regular Season</li>
          <p>Will count towards the main standings table</p>
          <li>Playoff</li>
          <p>Will be displayed on the bracket section</p>
          <li>Group</li>
          <p>
            Will be displayed under standings but separated from the main
            standings table
          </p>
          <li>Seeding</li>
          <p>Will not display on any standings or brackets</p>
          <li>Friendly</li>
          <p>
            Will not display on any standings or brackets and statistics will
            not count towards player leaderboards
          </p>
        </ul>
        All matches in the same division no matter which type will count towards
        a single leaderboard (except Friendlies).
      </SingleTopic>
      <SingleTopic
        id="refereeing"
        title="Refereeing a Match"
        toggle={pushHistory}
        open={openItem}
      >
        <h6>Starting the Match</h6>
        <p>
          Verify that you have the correct match selected and click Start Match.
          Click Yes on the popup box. If your sport tracks suspended players
          they will show up to the right of the buttons. Check that suspended
          players are not preparing to participate.
        </p>
        <h6>During the match</h6>
        <p>
          Depending on the sport there are different options available on the
          match screen, but the basics remain the same. As the referee you will
          be expected to enter events as they happen, and select the correct
          player for each event. If the player does not appear on the popup
          screen then they are not registered. You should select unknown player
          and leave a comment in the referee comments section before submitting
          the match. An admin will be able to make the necessary edits before
          accepting the match.
        </p>
        <p>
          If you make a mistake in selecting an event then the Events tab is
          where all of the match events will be listed in order, from there you
          can remove any of them from the scorecard.
        </p>
        <p>
          Events that you enter during the match are not submitted live to the
          database. However, the events and score are stored locally on your
          phone or computer until you submit the scorecard, at which time the
          events are committed to the database and removed from your device. You
          can navigate away from the match page and the events will be waiting
          for you when you come back.
        </p>
        <h6>Submitting the match</h6>
        <p>
          Before submitting your scorecard you should double check with the team
          captains that they agree with the events as you recorded them. If your
          League requires approvals then get verbal approval from each captain
          and click the approval button for each team. If one or both captains
          do not agree with your scorecard you will leave their team as
          unapproved. You will still be able to submit the scorecard but this
          will signify to the reviewer that the team captain will have a comment
          incoming.
        </p>
        <p>
          Write in any comments you may have about the game, especially if one
          or more teams refuse to approve the scorecard. This will allow the
          approving admin to have arguments from all sides in one place during
          their review.
        </p>
        <p>Click the Submit Match button</p>
        <h6>Cancelling the match</h6>
        <p>
          If you want to discard your scorecard for whatever reason click the
          Cancel Scorecard button. This will erase all of your input. You can
          only have a single match scorecard running at a time, so if you need
          to referee another match you will first need to submit or cancel the
          current scorecard.
        </p>
      </SingleTopic>
      <SingleTopic
        id="matchReview"
        title="Reviewing a Match"
        toggle={pushHistory}
        open={openItem}
      >
        <p>
          When a scorecard is submitted by the referee/umpire it needs to be
          reviewed and accepted before it will count towards standings tables or
          leaderboards. Playoff brackets will display the pending result but the
          winning team will not be bolded until the match is accepted.
        </p>
        <p>
          If you have elected to allow for team captain approval and comments
          you should allow some time for each captain to submit any comments
          they may have. If the match was not approved by one or both captains
          then the Comments and Approvals button at the top will be highlighted
          in red. Click on that button to open and view any comments left by
          team captains or the referee.
        </p>
        <p>
          After viewing comments you should confirm that the score and match
          events are correct. You can click the View Events button to see each
          match event in the order that it happened, or a summary of the events
          is presented below.
        </p>
        <h6>Making Changes</h6>
        <p>
          If any changes are needed to the scorecard then click on the Edit
          Match button. This will allow you to referee the match with the
          submitted events as a starting point. You can remove an event by
          clicking on it on the Events tab, or add new events as needed. Once
          you are done making changes click on the Save Changes button to commit
          your changes to the scorecard and return to the match review screen
          with your new changes. Clicking the Reset Changes button will undo any
          edits that you made and return you to the match review screen with
          only the original match events intact.
        </p>
        <h6>Restarting the Match</h6>
        <p>
          Clicking the Restart Match button will remove all events, approvals
          and comments from the scorecard and send the match back to Upcoming to
          be refereed again. Once this action is taken the match data cannot be
          retrieved.
        </p>
        <h6>Accepting the Match</h6>
        <p>
          Click on the Accept Match button once you are sure that all match
          events are correct. This will send the match through to be evaluated
          for standings and leaderboards. Once a match is accepted it cannot be
          edited any more. By default all comments and approvals will be removed
          from the match upon acceptance. If you would like to keep approvals
          and/or comments visible after acceptance then you will need to uncheck
          one or both of the boxes before accepting.
        </p>
      </SingleTopic>
      <SingleTopic
        id="playoff"
        title="Playoff Brackets"
        toggle={pushHistory}
        open={openItem}
      >
        <p>
          Playoff Brackets are simple to create using the bracket creation tool.
          The first round will be filled in with your teams and the rest of the
          bracket will become dummy matches. You can edit the dummy matches to
          update the teams as results come in, or use the auto-populate feature
          when accepting a match and that will be done for you. Keep your
          bracket up to date and all your players can see their future matchups.
        </p>
        <CLink path="/create" color={true}>
          Create Matches
        </CLink>
        <br />
        <h5>Creating Custom Brackets</h5>
        <p>
          The playoff bracket is displayed using the round and match number as
          separators. The matches will display in ascending order within each
          round. Match numbers can be in any increments but the round number
          should start at 1 and increment by 1. For the main bracket you can
          have up to 10 rounds, or 1024 teams.
        </p>
        <h6>The Preliminary Round</h6>
        <p>
          The number of teams in the main bracket must be an exponentiation of
          2. To handle a bracket with an uneven number of teams you will need to
          have a preliminary round to pare the teams down. The preliminary round
          should be numbered as 0, the matches will again display in ascending
          order based on the match number. The preliminary round is only a
          single round of matches. All winners should go on to be placed in the
          main bracket.
        </p>
        <h6>The Secondary Bracket (Loser's Bracket)</h6>
        <p>
          For double elimination tournaments when a team loses they will be sent
          to the secondary bracket and have another chance to win their way into
          the final. The secondary bracket is handled in the same way as the
          main bracket, but the round numbering starts at 11.
          <br />
          <br />
          In a double elimination tournament the winner of the Secondary bracket
          will be playing the winner of the Main bracket. To display this match
          above the main bracket enter the round number 99. If the team from the
          Secondary bracket wins this match then there should be a second chance
          final as the team from the Main bracket has only lost once. To make
          this match display above the other finals enter the round number 100.
        </p>
      </SingleTopic>
    </React.Fragment>
  );
};

export default Help;
