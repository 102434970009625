import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { IconContext } from "react-icons";
import { RiMoneyDollarCircleFill } from "react-icons/ri";
import Tooltip from "./tooltip";
import { navigateTo } from "../customs/customLinks";
import HeaderContext from "../../../context/headerContext";

const PaidFeature = ({ direction, extraClass }) => {
  const header = useContext(HeaderContext);
  const history = useHistory();

  return (
    <IconContext.Provider
      value={{
        className: "icon-feature" + (extraClass ? ` ${extraClass}` : ""),
      }}
    >
      <Tooltip
        content={
          <span>
            <b>This is a Paid Feature</b>
            <br />
            Subscription will be required for full access
            <br />
          </span>
        }
        direction={direction ? direction : "top-right"}
      >
        <div
          className="clickable"
          onClick={() => navigateTo("/subscriptions", history, header)}
        >
          <RiMoneyDollarCircleFill />
        </div>
      </Tooltip>
    </IconContext.Provider>
  );
};

export default PaidFeature;
