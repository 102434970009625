import React, { useState, useEffect } from "react";

import allowables from "../../utils/allowables";
import SearchBox from "../common/dataSorting/searchBox";
import HorizontalButtons from "./../common/dataSorting/horizontalButtons";
import MailingList from "../common/dataSorting/mailingList";
import Table from "./../common/table/table";

const VaccineTable = ({
  users,
  addUserToList,
  userList,
  submitChanges,
  deselectAll,
  selectAll,
}) => {
  const [filteredUsers, setFilteredUsers] = useState(
    users.sort((a, b) => {
      let value = 1;
      if (!a.vaccine && b.vaccine) value = -1;
      if (a.vaccine && b.vaccine && a.vaccine.reviewed < b.vaccine.reviewed)
        value = -1;
      return value;
    })
  );
  const [search, setSearch] = useState("");
  const [selectedType, setSelectedType] = useState("all");

  const columns = [
    {
      path: "name",
      label: "Name",
      content: (u) => allowables.splitName(u.name),
    },
    { path: "email", label: "Email" },
    {
      path: "vaccineName",
      label: "Brand",
      content: (u) => (u.vaccine ? u.vaccine.name : "n/a"),
    },
    {
      path: "vaccineDate",
      label: "Date",
      content: (u) =>
        u.vaccine && u.vaccine.date
          ? allowables.dateTime(u.vaccine.date).date
          : "n/a",
    },
    {
      path: "reviewStatus",
      label: "Status",
      content: (u) =>
        u.vaccine && u.vaccine.reviewed
          ? "Reviewed"
          : u.vaccine && !u.vaccine.reviewed
          ? "Not Reviewed"
          : "Not Submitted",
    },
  ];

  const setFilters = () => {
    let pageUsers = [...users];
    if (search) {
      const query = search.toLowerCase();
      pageUsers = pageUsers.filter(
        (u) =>
          allowables.splitName(u.name).toLowerCase().includes(query) ||
          u.email.toLowerCase().includes(query)
      );
    }
    if (selectedType && selectedType !== "all") {
      pageUsers = pageUsers.filter((u) =>
        selectedType === "reviewed"
          ? u.vaccine && u.vaccine.reviewed
          : selectedType === "not reviewed"
          ? u.vaccine && u.vaccine.date && u.vaccine.name && !u.vaccine.reviewed
          : !u.vaccine || !u.vaccine.date || !u.vaccine.name
      );
    }

    setFilteredUsers(pageUsers);
  };

  useEffect(() => {
    setFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, selectedType, users]);

  return (
    <React.Fragment>
      <HorizontalButtons
        buttons={["all", "reviewed", "not reviewed", "not submitted"]}
        selectedType={selectedType}
        onClick={setSelectedType}
        custom={true}
      />
      <br />
      <div className="text-center">
        Select all the users with information you have reviewed and click the
        Submit Changes button. A user who has already been Reviewed will be
        marked as Not Reviewed. Users who have not submitted their information
        cannot have their status updated.
      </div>
      <div className="row">
        <div className="col">
          <button
            className="btn btn-block btn-sm btn-info"
            onClick={submitChanges}
            disabled={userList.length === 0}
          >
            Submit Changes
          </button>
        </div>
        <div className="col-2">
          <button
            className="btn btn-block btn-sm btn-dark"
            onClick={deselectAll}
            disabled={userList.length === 0}
          >
            Deselect All
          </button>
        </div>
        <div className="col-2">
          <button
            className="btn btn-block btn-sm btn-dark"
            onClick={() => selectAll(filteredUsers)}
          >
            Select All Visible
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <SearchBox
            value={search}
            onChange={setSearch}
            placeholder="Search by name or email..."
          />
        </div>
        <div className="col-2">
          <MailingList
            users={filteredUsers}
            buttonTitle="Create Mailing List"
            secondaryTitle={
              selectedType.charAt(0).toUpperCase() + selectedType.slice(1)
            }
            margin="20px"
          />
        </div>
      </div>
      <Table
        columns={columns}
        data={filteredUsers}
        keyProperty={"_id"}
        tableClass="table-custom"
        headerClass="thead-custom"
        bodyClass="tbody-custom"
        highlightOnHover={true}
        backgroundStyle="bg-info text-white"
        onSelect={addUserToList}
        selectedRows={userList}
      />
    </React.Fragment>
  );
};

export default VaccineTable;
