import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import toast from "../../utils/toast";

import AddBoard from "./addBoard";
import SingleScoreboard from "./singleScoreboard";
import { splitQuery } from "../../utils/splitQuery";
import { getDivisions } from "../../services/divisionService";
import HeaderContext from "../../context/headerContext";

const ScoreboardsHome = ({ org }) => {
  const { setLoading } = useContext(HeaderContext);
  let history = useHistory();
  const [scoreboards, setScoreboards] = useState([]);
  const [divisions, setDivisions] = useState([]);

  const loadDivisions = async () => {
    setLoading(true);
    const divisionsRes = await getDivisions();
    if (divisionsRes.status === 200) {
      setDivisions(divisionsRes.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadDivisions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const query = splitQuery(history.location.search);
    // iterate over the search query and add the boards
    let currentScoreboards = [];
    query.forEach((q) => {
      let type = "";
      let _id = "";
      let limit = "";
      if (q.includes("=")) q = q.split("=")[1];
      if (q.includes("_")) {
        type = q.split("_")[0];
        _id = q.split("_")[1];
        limit = q.split("_")[2];
      } else type = q;

      if (type)
        currentScoreboards = handleAddScoreboard(
          { type, _id, limit },
          currentScoreboards
        );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const replaceSearchString = (currentScoreboards) => {
    history.replace(
      `?boards=${currentScoreboards
        .map(
          (cs) =>
            `${cs.type}${
              cs._id ? `_${cs._id}${cs.limit ? `_${cs.limit}` : ""}` : ""
            }`
        )
        .join("&")}`
    );
  };

  const handleAddScoreboard = (scoreboardInfo, refreshedScoreboards) => {
    if (
      !refreshedScoreboards &&
      scoreboards.find(
        (s) =>
          (s.type === scoreboardInfo.type && !scoreboardInfo._id) ||
          (s._id &&
            s.type === scoreboardInfo.type &&
            s._id === scoreboardInfo._id)
      )
    )
      return toast.error(
        `${scoreboardInfo.type} have already been added to this board for ${scoreboardInfo.name}`
      );
    const currentScoreboards = refreshedScoreboards || [...scoreboards];

    scoreboardInfo.name = scoreboardInfo._id
      ? (
          divisions.find((d) => d._id === scoreboardInfo._id) || {
            _id: "all",
            name: "All Divisions",
          }
        ).name
      : null;

    currentScoreboards.push(scoreboardInfo);
    setScoreboards(currentScoreboards);

    if (!refreshedScoreboards) replaceSearchString(currentScoreboards);

    return currentScoreboards;
  };

  const handleRemoveScoreboard = (idx) => {
    const currentScoreboards = [...scoreboards];
    currentScoreboards.splice(idx, 1);
    setScoreboards(currentScoreboards);
    replaceSearchString(currentScoreboards);
  };

  return (
    <div>
      {scoreboards.map((s, idx) => {
        return (
          <SingleScoreboard
            key={s.type + s._id}
            index={idx}
            type={s.type}
            _id={s._id}
            divisionName={s.name}
            limit={s.limit}
            onRemove={handleRemoveScoreboard}
            org={org}
          />
        );
      })}
      {!scoreboards.length && (
        <p className="text-center">
          <b>
            Create your own customized view by adding individual components.
            <br />
            All components will automatically refresh to stay up to date with
            the latest information.
            <br />
            When you're done, bookmark the page and load the same board again
          </b>
        </p>
      )}
      <AddBoard
        onSubmit={handleAddScoreboard}
        currentBoards={scoreboards}
        divisions={divisions}
      />
    </div>
  );
};

export default ScoreboardsHome;
