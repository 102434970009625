import React from "react";

import Checkbox from "../form/checkBox";

const CheckboxGroup = ({
  checkboxes,
  onChange,
  containerClassName,
  colCount = 1,
}) => {
  let cols = [];
  let rows = [];
  for (let i = 1; i <= checkboxes.length; i++) {
    const cb = checkboxes[i - 1];
    if (i % colCount === 0) {
      cols.push(cb);
      rows.push(JSON.parse(JSON.stringify(cols)));
      cols.length = 0;
    } else {
      cols.push(cb);
    }
  }
  if (cols.length > 0) rows.push(JSON.parse(JSON.stringify(cols)));

  return (
    <div className={containerClassName}>
      {rows.map((row, idx) => (
        <div className="row" key={idx}>
          {row.map((cb) => (
            <div className="col" key={cb.id}>
              <Checkbox
                key={cb.id}
                onChange={() => onChange(cb.id)}
                value={cb.value}
                label={cb.label}
                isGroup={true}
              />
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default CheckboxGroup;
