import React, { useState } from "react";
import orderBy from "lodash/orderBy";

import SearchBox from "../common/dataSorting/searchBox";
import MiniHeader from "../common/pageComponents/miniHeader";
import LeaderboardCardView from "../reports/leaderboardCardView";
import ModalPicker from "../common/dataSorting/modalPicker";
import IconRender from "../common/icons/iconRender";

const SpectateLeaderboard = ({
  leaderboard,
  sport,
  onHeaderClick,
  onSelectPlayer,
  selectedDiamondBoard,
  setSelectedDiamondBoard,
}) => {
  const [query, setQuery] = useState("");
  const [sort, setSort] = useState({ path: "name", order: "asc" });

  const filterLeaderboard = () => {
    let filteredLeaderboard = leaderboard;
    if (!sport.toLowerCase().includes("diamond")) {
      if (query !== "")
        filteredLeaderboard = leaderboard.filter(
          (p) =>
            p.name.toLowerCase().includes(query.toLowerCase()) ||
            p.teamName.toLowerCase().includes(query.toLowerCase())
        );

      filteredLeaderboard = orderBy(filteredLeaderboard, sort.path, sort.order);
    } else {
      if (query !== "")
        filteredLeaderboard = {
          batting: leaderboard.batting.filter(
            (p) =>
              p.name.toLowerCase().includes(query.toLowerCase()) ||
              p.teamName.toLowerCase().includes(query.toLowerCase())
          ),
          pitching: leaderboard.pitching.filter(
            (p) =>
              p.name.toLowerCase().includes(query.toLowerCase()) ||
              p.teamName.toLowerCase().includes(query.toLowerCase())
          ),
        };

      filteredLeaderboard = {
        batting: orderBy(filteredLeaderboard.batting, sort.path, sort.order),
        pitching: orderBy(filteredLeaderboard.pitching, sort.path, sort.order),
      };
    }
    return filteredLeaderboard;
  };

  const handleSort = (path) => {
    let order = sort.order;
    if (path === sort.path) order = order === "asc" ? "desc" : "asc";
    else if (path.includes("events")) order = "desc";
    else order = "asc";
    setSort({ path, order });
  };

  const sportSortOptions = {
    soccer: () => [
      { _id: "events.Goals", name: "Goals" },
      { _id: "events.Assists", name: "Assists" },
      { _id: "events.YellowCards", name: "Yellow Cards" },
      { _id: "events.RedCards", name: "Red Cards" },
    ],
    football: () => [{ _id: "events.touchdowns", name: "Touchdowns" }],
    "diamond sports": () => {
      if (selectedDiamondBoard === "batting")
        return [
          { _id: "homeRuns", name: "Home Runs" },
          { _id: "rbis", name: "RBIs" },
          { _id: "battingAverage", name: "Batting Average" },
        ];
      else if (selectedDiamondBoard === "pitching")
        return [
          { _id: "inningsPitched", name: "Innings Pitched" },
          { _id: "strikeouts", name: "Strike Outs" },
          { _id: "era", name: "ERA" },
        ];
      else return [];
    },
  };

  const sortOptions = [
    { _id: "name", name: "Player Name" },
    { _id: "teamName", name: "Team Name" },
    ...sportSortOptions[sport](),
  ];

  return (
    <div>
      <MiniHeader onClick={onHeaderClick}>Leaderboards</MiniHeader>
      <div className="form-divided-section fill">
        {leaderboard.length === 0 ? (
          <p className="text-center cardview-card">
            There is no leaderboard to display for this division
          </p>
        ) : (
          <div>
            <ModalPicker
              buttonLabel="Sort"
              label="Sort Leaderboard"
              options={sortOptions}
              selectedValue={sort.path}
              selectedValueLabel={
                <div>
                  {sortOptions.find((o) => o._id === sort.path)?.name}&nbsp;
                  <IconRender
                    name={sort.order === "asc" ? "chevronup" : "chevrondown"}
                  />
                </div>
              }
              onSelect={(path) => handleSort(path._id)}
            />
            <SearchBox
              placeholder="Search player or team name..."
              value={query}
              onChange={setQuery}
            />
            <LeaderboardCardView
              leaderboard={filterLeaderboard()}
              sport={sport}
              selectedDiamondBoard={selectedDiamondBoard}
              onSelectDiamondBoard={setSelectedDiamondBoard}
              onSelectPlayer={onSelectPlayer}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SpectateLeaderboard;
