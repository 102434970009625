import React from "react";

import { FaCaretLeft, FaCaretRight } from "react-icons/fa";
import { IconContext } from "react-icons";

const HorizontalCounter = ({
  value,
  setValue,
  header,
  minValue,
  maxValue,
  className,
  index,
  label = "",
}) => {
  const handleValueChange = (change, position) => {
    if (position !== undefined) {
      let current = [...value];
      if (current[position] === minValue && change === -1) return;
      if (current[position] === maxValue && change === 1) return;
      current[position] = current[position] + change;
      setValue(current);
    } else {
      if (value === minValue && change === -1) return;
      if (value === maxValue && change === 1) return;
      setValue(value + change);
    }
  };

  return (
    <IconContext.Provider value={{ className }}>
      <h6 className="text-center">{header}</h6>
      <div className="row">
        <div
          className="col text-right clickable"
          onClick={() => handleValueChange(-1, index)}
          aria-label={label + "-counter-down"}
        >
          <FaCaretLeft />
        </div>
        <div className="col-2 text-center">
          <b>
            <h5>{index !== undefined ? value[index] : value}</h5>
          </b>
        </div>
        <div
          className="col text-left clickable"
          onClick={() => handleValueChange(1, index)}
          aria-label={label + "-counter-up"}
        >
          <FaCaretRight />
        </div>
      </div>
    </IconContext.Provider>
  );
};

export default HorizontalCounter;
