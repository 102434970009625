import React, { useState } from "react";

import RefereeingInstructionImage from "../../instructions/refereeingInstructionImage";
import VerticalButtons from "../../common/dataSorting/verticalButtons";

const SoccerInstructions = () => {
  const [page, setPage] = useState("pre-match");

  return (
    <React.Fragment>
      <VerticalButtons
        buttons={[
          "pre-match",
          "overview",
          "goals",
          "cards",
          "events",
          "penalties",
          "review",
          "submit",
        ]}
        onClick={setPage}
        selectedType={page}
      />
      <br />
      {page === "pre-match" && (
        <React.Fragment>
          <p>
            Check that you have selected the correct match by verifying the
            teams, date and time, and field. Tap Start Match when ready.
            <br />
          </p>
          <RefereeingInstructionImage location="PreMatchScreen.jpg" />
        </React.Fragment>
      )}
      {page === "overview" && (
        <React.Fragment>
          <p>
            There are four different tabs on the scorecard screen.
            <br />
          </p>
          <p>
            The Game tab is where you will spend most of your time. Here is
            where you add all the events that happen during the match.
            <br />
          </p>
          <RefereeingInstructionImage location="Game Screen.jpg" />
          <hr />
          <p>
            On the Events tab you will see all of the events you added on the
            Game tab. From here you can undo any event.
            <br />
          </p>
          <RefereeingInstructionImage location="Events Screen.jpg" />
          <hr />
          <p>
            The Review tab gives you an overview of everything that happened
            during the match. If your League uses captain approvals you can show
            this screen to the team captain and receive their verbal approval.
            <br />
          </p>
          <RefereeingInstructionImage location="Review Screen.jpg" />
          <hr />
          <p>
            Finally the Submit tab contains a space for you to enter your
            comments about the match, and a place to finally submit the
            completed scorecard.
            <br />
          </p>
          <RefereeingInstructionImage location="Submit Screen.jpg" />
        </React.Fragment>
      )}
      {page === "goals" && (
        <React.Fragment>
          <p>
            When a goal is scored tap the Goal button. This will bring up the
            list of players registered to that team. Tap the player who scored
            and confirm. If the player is unregistered or you do not know who
            scored tap Unknown/Unregistered Player and leave a note in the
            comments section before submitting the scorecard. An admin can make
            any required updates before accepting the card.
            <br />
          </p>
          <RefereeingInstructionImage location="Goal Popup.jpg" />
          <br />
          <br />
          <RefereeingInstructionImage location="Confirm Goal Alert.jpg" />
          <hr />
          <p>
            Once the goal is confirmed the score will increment and the player
            will be added to the list of goalscorers.
            <br />
          </p>
        </React.Fragment>
      )}
      {page === "cards" && (
        <React.Fragment>
          <p>
            Yellow and Red Cards work in much the same way as goals, but they
            can only be given to registered players. Tap the Yellow or Red Card
            button to bring up a list of players for that team, select the
            carded player and confirm. Suspended players are not blocked from
            receiving yellow and red cards.
            <br />
          </p>
          <RefereeingInstructionImage location="Yellow Card Popup.jpg" />
          <br />
          <hr />
          <p>
            A player who has received a card will be highlighted on the event
            popup screen as a reminder. When giving a second yellow card the
            player will automatically be converted to a red card.
            <br />
          </p>
          <RefereeingInstructionImage location="Confirm Yellow Card Alert.jpg" />
          <br />
          <hr />
          <p>
            The player will now be highlighted red on the event popup screen and
            can no longer be given any cards. You will however still be able to
            credit the player with goals.
            <br />
          </p>
          <RefereeingInstructionImage location="Already Sent Off Alert.jpg" />
        </React.Fragment>
      )}
      {page === "events" && (
        <React.Fragment>
          <p>
            If you need to change an event you should undo the original and go
            through the event process again. From the Events tab tap the event
            you want to undo and confirm.
            <br />
          </p>
          <RefereeingInstructionImage location="Confirm Undo Event Alert.jpg" />
        </React.Fragment>
      )}
      {page === "penalties" && (
        <React.Fragment>
          <p>
            You can enter penalties at any time regardless of the score or type
            of match.
            <br />
            <br />
            From the Game tab tap the Go To Penalties button to open the
            penalties popup.
            <br />
          </p>
          <RefereeingInstructionImage location="Penalties Popup.jpg" />
          <hr />
          <p>
            This screen is very simple as the penalty takers are not tracked.
            Tap + to increment the score for each team as They convert their
            kick. If you make a mistake tap - to decrement.
            <br />
            <br />
            When penalties have concluded tap the Submit Scorecard button to
            submit this scorecard. If you still need to add approvals and
            comments you can tap Close and the result of the penalties will be
            saved. Now you can continue with approvals, comments and then
            submit.
          </p>
        </React.Fragment>
      )}
      {page === "review" && (
        <React.Fragment>
          <p>
            When the game is over you should show the Review tab of the
            scorecard on your phone or tablet to the captain of each team. This
            is only to give them an overview of the final score. Every player
            will have the ability to view the full scorecard on their own
            account once you have submitted it. If they agree with the score
            then you will tap the red approval button for their team. The button
            will turn green to indicate that the captain has accepted the
            result. If the captain does not accept the result then leave the
            button untapped and red.
          </p>
          <p>
            Captain approvals is an optional feature, your League may not have
            it enabled. If the buttons do not appear on your page you can skip
            this step as your League does not require it.
            <br />
          </p>
          <RefereeingInstructionImage location="Review Screen.jpg" />
          <br />
          <br />
          <RefereeingInstructionImage location="Review Screen Approved.jpg" />
        </React.Fragment>
      )}
      {page === "submit" && (
        <React.Fragment>
          <p>
            If you have any comments to make on how the match went you will
            enter them on the Submit tab. It is especially important to enter
            your comments if one or more teams does not approve of the
            scorecard, as the reviewing admin will be able to see your comments
            while reviewing the match, this can help clear up a lot of
            confusion. Your comments are not visible to players.
          </p>
          <p>
            Now it is time to submit this scorecard. Tap the Submit Scorecard
            button to send the completed scorecard to the database. Once you
            confirm this you cannot get the scorecard back so please make sure
            that all the information is correct.
            <br />
          </p>
          <RefereeingInstructionImage location="Submit Screen Confirm Popup.jpg" />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default SoccerInstructions;
