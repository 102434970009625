import React, { useState } from "react";
import { FaStar, FaClipboardList } from "react-icons/fa";

import MatchHeader from "../assignedMatch/matchHeader";
import ReviewInfoSegment from "../matchReview/reviewInfoSegment";
import HorizontalTabs from "../common/dataSorting/horizontalTabs";
import EventsTab from "../assignedMatch/eventsTab";
import allowables from "../../utils/allowables";
import { mapTennisScore } from "ultimatescoreboard-shared";
import SimpleScoringInfo from "../assignedMatch/simple/simpleScoringInfo";

const LiveScorecard = ({
  org,
  matchData,
  liveData,
  teamOrder,
  isScoreboard,
  spectate,
}) => {
  const [selectedTab, setSelectedTab] = useState("info");
  const tabs = [{ name: "info", icon: FaStar }];
  if (!matchData || !liveData) return null;

  let complexScore;
  const translatedSportName = allowables.translateSportName(org);
  if (translatedSportName === "Tennis") {
    complexScore = mapTennisScore(
      matchData?.divisionID?.settings?.tennisOptions,
      liveData?.events || matchData.chronoEventsNames
    );
  }

  if (!matchData.sport.toLowerCase().includes("simple")) {
    tabs.push({ name: "events", icon: FaClipboardList });
  } else {
    if (translatedSportName === "Tennis") {
      tabs.push({ name: "scoring", icon: FaClipboardList });
    }
  }

  return (
    <div>
      {!isScoreboard && tabs.length > 1 ? (
        <HorizontalTabs
          tabs={tabs}
          selectedTab={selectedTab}
          onSelectTab={(tab) => setSelectedTab(tab.name)}
        />
      ) : null}
      <MatchHeader
        org={org}
        match={matchData}
        teamOrder={teamOrder}
        goals={{ home: liveData.homeTeamScore, away: liveData.awayTeamScore }}
        penalties={{ home: liveData.homeTeamPKs, away: liveData.awayTeamPKs }}
        spectate={spectate}
        complexScore={complexScore}
        division={matchData.divisionID}
      />
      {translatedSportName !== "Tennis" ? (
        <div className="pop-box">
          {selectedTab === "info" ? (
            <ReviewInfoSegment
              data={matchData}
              teams={teamOrder}
              liveEvents={liveData.events}
              alreadyPopped={true}
            />
          ) : selectedTab === "events" ? (
            <EventsTab
              chronoEventsNames={liveData.events}
              sport={matchData.sport}
              match={matchData}
              spectate={true}
            />
          ) : null}
        </div>
      ) : selectedTab === "scoring" ? (
        <div className="pop-box">
          <SimpleScoringInfo org={org} match={matchData} />
        </div>
      ) : null}
    </div>
  );
};

export default LiveScorecard;
