import React, { useState, useEffect } from "react";

import Loading from "../common/loading/loading";
import { saGetLeagues } from "../../services/superAdminService";
import toast from "../../utils/toast";
import HorizontalButtons from "../common/dataSorting/horizontalButtons";
import orderBy from "lodash/orderBy";
import SearchBox from "../common/dataSorting/searchBox";
import SuperAdminLeaguesTable from "./superAdminLeaguesTable";
import SuperAdminLeaguePopup from "./superAdminLeaguePopup";
import allowables from "../../utils/allowables";

const SuperAdminLeaguesView = ({ loading, setLoading, onSelectLeague }) => {
  const [sport, setSport] = useState("Soccer");
  const [sortColumn, setSortColumn] = useState({ path: "name", order: "asc" });
  const [searchQuery, setSearchQuery] = useState("");
  const [leagues, setLeagues] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedLeague, setSelectedLeague] = useState(null);

  const getLeagues = async () => {
    const translatedSport = sport === "Generic" ? "Simple Scoring" : sport;
    const res = await saGetLeagues(translatedSport);
    if (res.status === 200) setLeagues(res.data);
    else toast.error(res.data);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    getLeagues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sport]);

  const handleSelectLeague = (league) => {
    setSelectedLeague(league);
    setModalOpen(true);
  };

  const getData = () => {
    let filteredLeagues = [...leagues];
    if (searchQuery)
      filteredLeagues = filteredLeagues.filter((u) =>
        u.name.toLowerCase().includes(searchQuery.toLowerCase())
      );

    const sortedLeagues = orderBy(
      filteredLeagues,
      [sortColumn.path],
      [sortColumn.order]
    );

    return sortedLeagues;
  };

  return loading ? (
    <Loading />
  ) : (
    <React.Fragment>
      <HorizontalButtons
        buttons={allowables.sports().map((sport) => sport.name)}
        onClick={setSport}
        selectedType={sport}
      />
      <SearchBox
        value={searchQuery}
        onChange={setSearchQuery}
        placeholder="Filter by league name..."
      />
      Showing {getData().length} of {leagues.length}.
      {loading ? (
        <Loading />
      ) : (
        <SuperAdminLeaguesTable
          leagues={getData()}
          sortColumn={sortColumn}
          onSort={(sortCol) => setSortColumn(sortCol)}
          setSelectedLeague={handleSelectLeague}
          onSelectLeague={onSelectLeague}
        />
      )}
      {selectedLeague && (
        <SuperAdminLeaguePopup
          isOpen={modalOpen}
          onClose={setModalOpen}
          league={selectedLeague}
        />
      )}
    </React.Fragment>
  );
};

export default SuperAdminLeaguesView;
