import React, { useState } from "react";
import MiniHeader from "../common/pageComponents/miniHeader";
import { getCurrentUser } from "../../services/userService";
import SingleTactic from "./singleTactic";
import IconRender from "../common/icons/iconRender";
import CustomConfirm from "../common/customs/customConfirm";

const TacticGroup = ({ group, onEdit, onDelete }) => {
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [visibleTactic, setVisibleTactic] = useState(0);
  const [selectedTactic, setSelectedTactic] = useState(null);

  const user = getCurrentUser();

  const handleNavigation = (movement) => {
    const newPage = visibleTactic + movement;
    if (newPage < 0 || newPage > group.tactics.length - 1) return;
    setVisibleTactic(visibleTactic + movement);
  };

  return (
    <div>
      <MiniHeader>{group._id}</MiniHeader>
      <div className="form-divided-section">
        {group.tactics.map((tactic, idx) => (
          <div
            key={idx}
            style={{ display: idx === visibleTactic ? "" : "none" }}
          >
            <div className="row">
              <div className="col-2 text-right">
                {visibleTactic === 0 ? null : (
                  <button
                    className="btn btn-sm btn-light"
                    disabled={visibleTactic === 0}
                    onClick={() => handleNavigation(-1)}
                  >
                    <IconRender name="chevronleft" />
                  </button>
                )}
              </div>
              <div className="col text-center">
                <div style={{ float: "right" }}>
                  <b>
                    {visibleTactic + 1}/{group.tactics.length}
                  </b>
                </div>
                <h5>
                  <b>{tactic.name}</b>
                </h5>
                <p>{tactic.description}</p>
              </div>
              <div className="col-2 text-left">
                {visibleTactic === group.tactics.length - 1 ? null : (
                  <button
                    className="btn btn-sm btn-light"
                    disabled={visibleTactic === group.tactics.length - 1}
                    onClick={() => handleNavigation(1)}
                  >
                    <IconRender name="chevronright" />
                  </button>
                )}
              </div>
            </div>
            <SingleTactic
              key={idx}
              id={`${group._id}_${idx}`}
              tactic={tactic}
              isLocked={true}
              onDelete={(tactic) => {
                setDeleteOpen(true);
                setSelectedTactic(tactic);
              }}
              onEdit={(tactic) => onEdit(tactic)}
              user={user}
            />
          </div>
        ))}
      </div>
      <CustomConfirm
        dialog={"Delete this tactic"}
        isOpen={deleteOpen}
        callback={async () => {
          const deleted = await onDelete(selectedTactic._id);
          if (deleted) setVisibleTactic((vt) => (vt > 0 ? vt - 1 : 0));
        }}
        close={() => setDeleteOpen(false)}
        focused={true}
        yesNo={true}
      />
    </div>
  );
};

export default TacticGroup;
