import React from "react";
import allowables from "../../../utils/allowables";
import FormLabel from "./formLabel";
import WarningHeader from "../pageComponents/warningHeader";

const Select = ({
  name,
  label,
  options,
  error,
  tooltip,
  paidFeature,
  labelButton,
  ...rest
}) => {
  return (
    <div className="form-group">
      <FormLabel
        tooltip={tooltip}
        paidFeature={paidFeature}
        label={label}
        htmlFor={name}
        labelButton={labelButton}
      />
      <select
        id={name}
        name={name}
        {...rest}
        className="form-control custom-form"
      >
        <option key="default">Make a selection...</option>
        {options.map((option) => (
          <option
            className="custom-form-select"
            key={option._id}
            value={option.name}
            aria-label="select-option"
          >
            {allowables.splitName(option.name)}
          </option>
        ))}
      </select>
      {error && <WarningHeader>{error}</WarningHeader>}
    </div>
  );
};

export default Select;
