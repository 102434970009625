import React from "react";
import Form from "../common/form/form";
import Joi from "joi-browser";
import { getDiscount, saveDiscount } from "../../services/discountService";
import toast from "../../utils/toast";
import HeaderContext from "../../context/headerContext";
import BasicModal from "../common/pageComponents/basicModal";
import { popupStyle } from "../../utils/styleUtil";
import { getCurrentUser } from "../../services/userService";

class DiscountForm extends Form {
  static contextType = HeaderContext;
  state = {
    data: {
      name: "",
      code: "",
      discountAmount: 0,
      discountPercentage: 0,
      active: true,
    },
    baseState: {
      name: "",
      code: "",
      discountAmount: 0,
      discountPercentage: 0,
      active: true,
    },
    errors: {},
  };

  schema = {
    name: Joi.string().required().min(3).max(50).label("Name"),
    code: Joi.string()
      .required()
      .allow("")
      .min(4)
      .max(25)
      .label("Discount Code"),
    discountAmount: Joi.number()
      .optional()
      .min(0)
      .integer()
      .label("Discount Amount"),
    discountPercentage: Joi.number()
      .optional()
      .min(0)
      .max(100)
      .integer()
      .label("Discount Percentage"),
    active: Joi.boolean().required().label("Active"),
  };

  componentDidUpdate(prevProps) {
    if (prevProps.discount?._id !== this.props.discount?._id)
      this.componentDidMount();
  }

  async componentDidMount() {
    this.context.setLoading(true);
    if (this.props.discount && this.props.discount?._id !== "new") {
      const res = await getDiscount(this.props.discount?._id);
      if (res.status === 200) {
        this.setState({
          data: this.mapToViewModel(res.data),
          baseState: this.mapToViewModel(res.data),
        });
      } else toast.error(res.data);
    }
    this.context.setLoading(false);
  }

  mapToViewModel(discount) {
    const info = discount?.discountInfo;
    return {
      name: discount?.name || "",
      code: discount?.code || "",
      discountAmount: info?.amount || discount.discountAmount || 0,
      discountPercentage: info?.percentage || discount.discountPercentage || 0,
      active: typeof discount.active === "boolean" ? discount.active : true,
    };
  }

  doSubmit = async () => {
    this.context.setLoading(true);
    const discount = {
      name: this.state.data.name,
      code: this.state.data.code,
      active: this.state.data.active,
      discountInfo: {
        amount: Number(this.state.data.discountAmount),
        percentage: Number(this.state.data.discountPercentage),
      },
      orgID: getCurrentUser().orgID,
    };

    const res = await saveDiscount(this.props.discount?._id, discount);
    if (res.status === 200) {
      toast.success(res.data);
      this.props.setIsOpen(false);
      this.props.onSuccess();
    } else toast.error(res.data);
    this.context.setLoading(false);
  };

  render() {
    if (!this.props.discount) return null;

    return (
      <BasicModal
        isOpen={this.props.isOpen}
        onClose={() => this.props.setIsOpen(false)}
        style={popupStyle}
      >
        {this.renderWholeForm(
          "Discount",
          null,
          {
            direction: "right",
            listHeader: "Discount Types",
            listItems: [
              "Code: Enter a discount code to allow all users to utilize the discount equally",
              "Targeted: Leave the discount code blank to be able to apply the discount only to teams/players you specify",
            ],
          },
          [
            this.renderInput("name", "Name"),
            this.renderInput(
              "code",
              "Discount Code",
              "",
              "",
              "",
              "",
              "",
              null,
              null,
              null,
              null,
              null,
              true,
              (e) => {
                e.target.value = e.target.value.toUpperCase();
                return e;
              }
            ),
            this.renderInput("discountAmount", "Discount Amount", "", "number"),
            this.renderInput(
              "discountPercentage",
              "Discount Percentage",
              "",
              "number"
            ),
            this.renderCheckbox("active", "Active"),
          ],
          false,
          {
            isNew: this.props.discount?._id === "new",
            inputDivClass: "form-divided-section",
          }
        )}
      </BasicModal>
    );
  }
}

export default DiscountForm;
