import React, { useState, useEffect } from "react";
import GoogleMapReact from "google-map-react";
import BasicModal from "./basicModal";
import { popupStyle } from "../../../utils/styleUtil";
import MiniHeader from "./miniHeader";

const Map = ({
  mapOpen,
  setMapOpen,
  mapCenter,
  pins = [],
  zoom = 11,
  children,
}) => {
  const [center, setCenter] = useState({ lng: 0, lat: 0 });
  useEffect(() => {
    setCenter({ lat: mapCenter?.latitude, lng: mapCenter?.longitude });
  }, [mapCenter]);

  if (!mapCenter) return null;

  return (
    <BasicModal
      isOpen={mapOpen}
      onClose={() => setMapOpen(false)}
      style={{ content: { ...popupStyle.content } }}
    >
      <div>{children}</div>
      <MiniHeader>{mapCenter.name}</MiniHeader>
      <div
        style={{ height: 500, width: 500, padding: 10 }}
        className="text-center"
      >
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.REACT_APP_MAPS_KEY || "" }}
          center={center}
          zoom={zoom}
        >
          {pins.map((p) => {
            if (p?.latitude && p?.longitude)
              return (
                <div
                  lat={p.latitude}
                  lng={p.longitude}
                  className={"map-location-pointer"}
                >
                  <p className="map-location-name">{p.name}</p>
                </div>
              );
            return null;
          })}
        </GoogleMapReact>
      </div>
    </BasicModal>
  );
};

export default Map;
