import React from "react";

import allowables from "../../utils/allowables";

const ReviewApprovalSegment = ({
  org,
  openProtests,
  protestOpen,
  data,
  user,
  textChange,
  clearProtest,
  submitProtest,
  protestText,
  teams,
}) => {
  if (
    org.captainApproval === "Yes" &&
    (data.matchAccepted !== 1 ||
      data.homeTeamProtest ||
      data.homeTeamProtestString ||
      data.awayTeamProtest ||
      data.awayTeamProtestString)
  )
    return (
      <React.Fragment>
        <button
          className={`btn btn-sm btn-block${
            data.homeTeamProtest || data.awayTeamProtest
              ? " btn-dark"
              : " btn-info"
          }`}
          onClick={openProtests}
        >
          This match was completed with
          {data.homeTeamProtest || data.awayTeamProtest ? "out" : ""} full
          approval
          <br />
          {protestOpen
            ? "Close Comments and Approvals"
            : "View Comments and Approvals"}
        </button>

        <div className="row">
          {teams.map((t) => (
            <div className="col" key={t}>
              {protestOpen && (
                <div
                  style={{
                    borderWidth: 5,
                    borderStyle: "solid",
                    borderRadius: "5px",
                    padding: "5px",
                  }}
                  className={
                    (data[t + "TeamProtest"] ? "dark" : "info") +
                    "-border text-center"
                  }
                >
                  <h6>
                    {data[t + "TeamName"]} -{" "}
                    {data[t + "TeamProtest"] ? "Not Approved" : "Approved"}
                  </h6>
                  <p
                    className="text-left"
                    style={{ paddingLeft: 10, paddingRight: 10 }}
                  >
                    {data[t + "TeamProtestString"] || `No comment submitted.`}
                  </p>
                </div>
              )}
            </div>
          ))}
        </div>
        {user.role.includes("player") &&
          protestOpen &&
          !data.matchAccepted &&
          (data.homeTeamCaptainID === user._id ||
            data.awayTeamCaptainID === user._id) && (
            <React.Fragment>
              <textarea
                className="custom-text-area"
                rows="3"
                cols="50"
                style={{ width: "100%" }}
                name="ProtestString"
                id="ProtestString"
                value={protestText}
                placeholder={
                  org.misc && org.misc.commentHint
                    ? org.misc.commentHint
                    : allowables.defaultCommentHint
                }
                onChange={(e) => textChange(e.target.value)}
              />
              <br />
              <div className="row">
                <div className="col">
                  {protestText && (
                    <button
                      onClick={clearProtest}
                      className="btn btn-md btn-dark"
                    >
                      Clear Comment
                    </button>
                  )}
                </div>
                <div className="col text-right">
                  <button
                    onClick={submitProtest}
                    className="btn btn-md btn-info"
                  >
                    {(data.homeTeamCaptainID === user._id &&
                      data.homeTeamProtestString) ||
                    (data.awayTeamCaptainID === user._id &&
                      data.awayTeamProtestString)
                      ? "Replace Comment"
                      : "Submit Comment"}
                  </button>
                </div>
              </div>
            </React.Fragment>
          )}
        {user.role.includes("admin") && protestOpen && (
          <div
            style={{
              border: "1px solid black",
              borderRadius: "5px",
              padding: "5px",
            }}
          >
            <h6>Referee Comments</h6>
            {data.refereeComments || "Referee did not submit a comment"}
          </div>
        )}
      </React.Fragment>
    );
  else return null;
};

export default ReviewApprovalSegment;
