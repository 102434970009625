/* eslint-disable no-unreachable */
import React from "react";
import Joi from "joi-browser";
import Form from "../common/form/form";
import { Redirect } from "react-router-dom";
import toast from "../../utils/toast";
import { loadStripe } from "@stripe/stripe-js";

import { getOrgbyID } from "../../services/orgService";
import {
  registerFreeAgent,
  registerTeamCaptain,
} from "../../services/userService";
import OrgLogo from "../common/pageComponents/orgLogo";
import CombinedRegistrationForm from "./combinedRegistrationForm";
import HeaderContext from "../../context/headerContext";
import { getPrepaymentCheckout } from "../../services/paymentService";
import { stripeKey } from "../../utils/paymentsUtil";
import NonFormSelect from "../common/form/nonFormSelect";
import MiniHeader from "../common/pageComponents/miniHeader";
import QRCodeRender from "../common/pageComponents/qrCode";
import allowables from "../../utils/allowables";
import WarningHeader from "../common/pageComponents/warningHeader";
import { getDivisionsForRegistration } from "../../services/divisionService";

const regTypes = [
  { _id: "freeAgent", name: "Free Agent" },
  { _id: "teamCaptain", name: "Team Captain" },
];
class FreeAgentRegistrationPage extends Form {
  static contextType = HeaderContext;
  state = {
    data: {
      name: "",
      divisionID: "",
    },
    errors: {},
    divisions: [],
    league: {
      name: "",
      logo: "",
    },
    noInfo: false,
    regType: "",
    activeLinks: [],
    regNames: {
      freeAgent: "Free Agent",
      teamCaptain: "Team Captain",
    },
    teamData: {
      name: "",
      divisionID: "",
    },
  };

  schema = {
    name: Joi.string().required().min(3).max(99).label("Team Name"),
    divisionID: Joi.string().required().label("Division"),
  };

  async componentDidMount() {
    this.context.setLoading(true);
    this.context.setProgress([1]);
    if (!this.props.location.search) return this.setState({ noInfo: true });
    const queryParams = this.props.location.search.split("&");
    const orgID = queryParams.find((q) => q.includes("id")).split("=")[1];
    const orgRes = await getOrgbyID(orgID, {
      callback: this.indicateProgress,
      bar: 0,
    });
    if (orgRes.status === 200) {
      const divisionsRes = await getDivisionsForRegistration(orgID);
      let activeRegTypes = [];
      const activeLinks = orgRes.data.misc?.activeRegistrationLinks;
      if (activeLinks) {
        Object.keys(activeLinks).forEach((k) => {
          if (activeLinks[k]) activeRegTypes.push(k);
        });
      }
      if (divisionsRes.status === 200) {
        this.setState({
          league: orgRes.data,
          activeLinks: activeRegTypes,
          divisions: divisionsRes.data,
        });
      } else toast.error(divisionsRes.data);
    } else this.setState({ noInfo: true });
    this.context.setLoading(false);
  }

  indicateProgress = (progress, location) => {
    let { progress: currentProgress } = this.context;
    currentProgress[location.bar] =
      ((progress.loaded / progress.total) * 100) / currentProgress.length;
    this.context.setProgress(currentProgress);
  };

  handleSubmit = async (data, formType) => {
    this.context.setLoading(true);
    this.context.setProgress([1]);
    let userData = { ...data };
    userData.orgID = this.state.league._id;
    userData.name = data.firstName + "%20%" + data.lastName;
    delete userData.firstName;
    delete userData.lastName;

    if (this.state.regType === "freeAgent") {
      const res = await registerFreeAgent(userData, formType, {
        callback: this.indicateProgress,
        bar: 0,
      });

      if (res.status === 200) {
        return window.location.assign("/profile");
      } else if (res.status === 402) {
        // redirect to payment page
        toast.info(
          `${this.state.league.name} requires payment of league dues before completing registration. Please wait to be redirected to make your payment.`
        );
        const checkoutRes = await getPrepaymentCheckout(
          this.state.league._id,
          userData,
          this.props.location.pathname + this.props.location.search
        );
        if (checkoutRes.status === 200) {
          const stripePromise = await loadStripe(stripeKey(), {
            stripeAccount: checkoutRes.data.accountID,
          });
          return setTimeout(() => {
            stripePromise.redirectToCheckout({
              sessionId: checkoutRes.data.sessionID,
            });
          }, 1000);
        } else toast.error(checkoutRes.data);
      } else {
        toast.error(res.data);
      }
    } else if (this.state.regType === "teamCaptain") {
      let teamError;
      if (!this.state.data.name) teamError = "Team Name is required";
      else if (
        !this.state.data.divisionID ||
        this.state.data.divisionID.includes("...")
      )
        teamError = "Please select a division";

      if (teamError) {
        this.context.setLoading(false);
        return toast.error(teamError);
      }
      // register a new team captain
      const res = await registerTeamCaptain(
        userData,
        formType,
        this.state.data,
        {
          callback: this.indicateProgress,
          bar: 0,
        }
      );

      if (res.status === 200) {
        return window.location.assign("/profile");
      } else toast.error(res.data);
    }
    this.context.setLoading(false);
  };

  render() {
    const { league, noInfo, regType, activeLinks } = this.state;

    return noInfo ? (
      <Redirect
        to={{
          pathname: "/not-found",
        }}
      />
    ) : (
      <div className="centered-small-input-area">
        <div className="text-center">
          {league.logo && (
            <OrgLogo _id={league.logo} name={league.name} width={300} />
          )}
          <MiniHeader>
            Register for
            <br />
            <b>{league.name}</b>
          </MiniHeader>
          <div className="form-divided-section">
            {regTypes.length === 0 ? (
              <WarningHeader>
                This league does not allow direct player registration. Please
                contact your league admin or team captain to register.
              </WarningHeader>
            ) : (
              <div>
                <NonFormSelect
                  name="type"
                  label="I am a:&nbsp;"
                  options={regTypes}
                  onChange={(e) => this.setState({ regType: e.target.value })}
                  value={this.state.regType}
                  overrideDefaultPlaceholder="..."
                />

                {regType === "freeAgent" ? (
                  <div>
                    <h5>
                      <b>Register as a Free Agent</b>
                    </h5>
                    <p>
                      Register as an individual player and wait to be placed on
                      a team. A team captain may recruit you or a league admin
                      may place you on your final team.
                      <br />
                      If you are already part of an existing team playing in
                      this league please do not use this page and instead ask
                      your team captain to send you a registration link.
                    </p>
                  </div>
                ) : regType === "teamCaptain" ? (
                  <div>
                    <h5>
                      <b>Register your Team</b>
                    </h5>
                    <p>
                      Create a new team and register them into the division you
                      select. After signing up you will be able to add further
                      details to your team and invite your players.
                    </p>
                    {this.state.divisions.length ? (
                      <div>
                        <MiniHeader>Team Information</MiniHeader>
                        <div className="form-divided-section">
                          <form onSubmit={(e) => e.preventDefault()}>
                            {this.renderInput("name", "Team Name")}
                            {this.renderSelectByValueOption(
                              "divisionID",
                              "Division",
                              this.state.divisions,
                              "_id"
                            )}
                          </form>
                        </div>
                      </div>
                    ) : (
                      <WarningHeader>
                        There are no available divisions to register into
                      </WarningHeader>
                    )}
                  </div>
                ) : null}
                {league.misc?.requirePaymentBeforeSignup === "Yes" && (
                  <h6>
                    <b>
                      You will be redirected to pay your league dues online
                      prior to your registration completing
                    </b>
                  </h6>
                )}
                <br />
              </div>
            )}

            {(regType === "teamCaptain" && this.state.divisions.length) ||
            regType === "freeAgent" ? (
              activeLinks.includes(regType) ? (
                <CombinedRegistrationForm onSubmit={this.handleSubmit} />
              ) : (
                <WarningHeader>
                  This league does not allow{" "}
                  {regTypes.find((r) => regType === r._id).name} signups
                  <br />
                  Please contact an admin to register
                </WarningHeader>
              )
            ) : null}
          </div>
        </div>
        <QRCodeRender
          value={`${allowables.url}${this.props.location.pathname}${this.props.location.search}`}
        />
      </div>
    );
  }
}

export default FreeAgentRegistrationPage;
