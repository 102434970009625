import React from "react";

const VerticalButtons = ({
  buttons,
  onClick,
  selectedType,
  custom,
  colors,
}) => {
  return (
    <div className="row">
      <div className="col">
        {buttons.map((b) => {
          const id = b.id ? b.id : b;
          const name = b.name ? b.name : b;
          return (
            <div className="col text-center" key={id}>
              <button
                className={
                  custom
                    ? "clickable text-center custom-select-item" +
                      (selectedType === id ? "-selected" : "")
                    : "btn btn-sm btn-block btn-" +
                      (selectedType === id ? "info font-weight-bold" : "light")
                }
                style={
                  selectedType === id
                    ? {
                        backgroundColor: colors && colors.bg,
                        color: colors && colors.text,
                      }
                    : {}
                }
                onClick={() => onClick(b)}
              >
                {name.charAt(0).toUpperCase() + name.slice(1)}
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default VerticalButtons;
