import React, { Component } from "react";
import { getPlayoffMatches } from "../../services/matchService";
import HeaderContext from "../../context/headerContext";
import PlayoffBracketCanvas from "../brackets/playoffBracketCanvas";
import WarningHeader from "../common/pageComponents/warningHeader";

class Brackets extends Component {
  static contextType = HeaderContext;
  state = {
    divisionPlayoffMatches: [],
  };

  componentDidMount() {
    this.getData();
  }
  componentDidUpdate(prevProps) {
    if (this.props.divisionID !== prevProps.divisionID) {
      this.getData();
    }
  }

  indicateProgress = (progress, location) => {
    let { progress: currentProgress } = this.context;
    currentProgress[location.bar] =
      ((progress.loaded / progress.total) * 100) / currentProgress.length;
    this.context.setProgress(currentProgress);
  };

  getData = async () => {
    this.context.setLoading(true);
    this.context.setProgress([1]);
    if (!this.props.divisionID) return this.context.setLoading(false);
    const response = await getPlayoffMatches(this.props.divisionID, {
      callback: this.indicateProgress,
      bar: 0,
    });
    if (response.status === 200) {
      this.setState({
        divisionPlayoffMatches: response.data,
      });
    }
    this.context.setLoading(false);
  };

  render() {
    const { divisionID } = this.props;

    return (
      <div>
        {this.state.divisionPlayoffMatches.length === 0 && divisionID && (
          <WarningHeader>
            There are no brackets available for this division.
          </WarningHeader>
        )}
        {this.state.divisionPlayoffMatches.length !== 0 && (
          <PlayoffBracketCanvas matches={this.state.divisionPlayoffMatches} />
        )}
      </div>
    );
  }
}

export default Brackets;
