import React, { Component } from "react";
import toast from "../../utils/toast";
import orderBy from "lodash/orderBy";

import MatchesTableCardSwitcher from "../matches/matchesTableCardSwitcher";
import SearchBox from "../common/dataSorting/searchBox";
import { getCompleteMatches } from "../../services/matchService";
import { getDivisions } from "../../services/divisionService";
import { getCurrentUser } from "../../services/userService";
import { splitQuery } from "../../utils/splitQuery";
import QuickLinks from "../common/pageComponents/quickLinks";
import HorizontalButtons from "../common/dataSorting/horizontalButtons";
import ExcelDownload from "../common/pageComponents/excelDownload";
import allowables from "../../utils/allowables";
import HeaderContext from "../../context/headerContext";
import NonFormSelect from "../common/form/nonFormSelect";

class MatchReview extends Component {
  static contextType = HeaderContext;
  state = {
    matches: [],
    divisions: [],
    selectedType: "review",
    matchTypes: [],
    selectedMatchType: { _id: "all", name: "All Match Types" },
    sortColumn: { path: "dateTime", order: "asc" },
    searchQuery: "",
    selectedDivision: { _id: "all", name: "All Divisions" },
  };

  refreshMatchesOnly = async () => {
    const res = await getCompleteMatches({
      callback: this.indicateProgress,
      bar: 0,
    });
    if (res.status === 200) {
      this.setState({ matches: res.data });
    } else toast.error(res.data);
  };

  async componentDidMount() {
    this.context.setLoading(true);
    this.context.setProgress([1]);
    await this.refreshMatchesOnly();
    const divisionsRes = await getDivisions({
      callback: this.indicateProgress,
      bar: 1,
    });
    if (divisionsRes.status === 200) {
      this.setState({
        divisions: [
          { _id: "all", name: "All Divisions" },
          ...divisionsRes.data,
        ],
        matchTypes: this.getMatchTypes(),
      });
      this.assignQuery(divisionsRes.data);
    } else toast.error(divisionsRes.data);
    this.context.setLoading(false);
  }

  getMatchTypes = () => {
    return [
      { _id: "all", name: "All Match Types" },
      ...allowables.matchTypes.map((t) => {
        return {
          _id: t.toLowerCase(),
          name: t,
        };
      }),
    ];
  };

  assignQuery = (divisions) => {
    const query = splitQuery(this.props.location.search);
    let { searchQuery, sortColumn, selectedType } = this.state;
    let selectedDivisionID = this.state.selectedDivision._id;
    let selectedMatchType = this.state.selectedMatchType._id;
    query.forEach((q) => {
      const thisQ = q.split("=");
      searchQuery = thisQ[0].includes("search") ? thisQ[1] : searchQuery;
      selectedType = thisQ[0].includes("type")
        ? thisQ[1].split("%20").join(" ")
        : selectedType;
      selectedMatchType = thisQ[0].includes("matchType")
        ? thisQ[1].split("%20").join(" ")
        : selectedMatchType;
      selectedDivisionID = thisQ[0].includes("division")
        ? thisQ[1]
        : selectedDivisionID;
      sortColumn.path = thisQ[0].includes("path") ? thisQ[1] : sortColumn.path;
      sortColumn.order = thisQ[0].includes("order")
        ? thisQ[1]
        : sortColumn.order;
    });
    const selectedDivision =
      divisions.find((d) => d._id === selectedDivisionID) ||
      this.state.selectedDivision;
    selectedMatchType = this.getMatchTypes().find(
      (t) => selectedMatchType === t._id
    );
    this.setState({
      searchQuery,
      sortColumn,
      selectedType,
      selectedMatchType,
      selectedDivision,
    });
  };

  indicateProgress = (progress, location) => {
    let { progress: currentProgress } = this.context;
    currentProgress[location.bar] =
      ((progress.loaded / progress.total) * 100) / currentProgress.length;
    this.context.setProgress(currentProgress);
  };

  downloadColumns = [
    "dateTime",
    "homeTeamName",
    "awayTeamName",
    "homeTeamAbbreviation",
    "awayTeamAbbreviation",
    "fieldName",
    "groupName",
    "type",
    "divisionName",
    "round",
    "matchNumber",
    "refereeName",
    "sport",
    "matchComplete",
    "matchAccepted",
    "homeTeamGoals",
    "awayTeamGoals",
    "homeTeamPKs",
    "awayTeamPKs",
    "refereeComments",
    "homeTeamProtest",
    "awayTeamProtest",
    "homeTeamProtestString",
    "awayTeamProtestString",
  ];

  pushHistoryString = (
    search,
    sortColumn,
    selectedType,
    selectedDivision,
    selectedMatchType
  ) => {
    const sort = sortColumn
      ? `path=${sortColumn.path}&order=${sortColumn.order}`
      : `path=${this.state.sortColumn.path}&order=${this.state.sortColumn.order}`;
    this.props.history.replace(
      `?search=${search || this.state.searchQuery}&${sort}&type=${
        selectedType || this.state.selectedType
      }&divisionID=${
        selectedDivision
          ? selectedDivision._id
          : this.state.selectedDivision._id
      }&matchType=${
        selectedMatchType
          ? selectedMatchType._id
          : this.state.selectedMatchType._id
      }`
    );
  };

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
    this.pushHistoryString(null, sortColumn);
  };

  handleSelectType = (selectedType) => {
    this.setState({ selectedType });
    this.pushHistoryString(null, null, selectedType);
  };

  handleSelectMatchType = (event) => {
    const selectedMatchType = this.state.matchTypes.find(
      (t) => t._id === event.target.value
    );
    this.setState({ selectedMatchType });
    this.pushHistoryString(null, null, null, null, selectedMatchType);
  };

  handleSearch = (query) => {
    this.setState({ searchQuery: query });
    this.pushHistoryString(query);
  };

  handleSelectDivision = (event) => {
    const selectedDivision = this.state.divisions.find(
      (d) => d._id === event.target.value
    );

    this.setState({ selectedDivision });
    this.pushHistoryString(null, null, null, selectedDivision);
  };

  filterMatches = () => {
    let {
      matches,
      selectedType,
      selectedMatchType,
      sortColumn,
      searchQuery,
      selectedDivision,
      divisions,
    } = this.state;
    const user = getCurrentUser();
    let filteredMatches = [];
    matches.forEach((m) => {
      let searchMatch,
        typeMatch,
        matchType,
        divisionMatch = false;
      if (
        (searchQuery &&
          (m.homeTeamName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            m.awayTeamName
              .toLowerCase()
              .includes(searchQuery.toLowerCase()))) ||
        !searchQuery
      )
        searchMatch = true;
      if (
        (selectedType === "accepted" && m.matchAccepted === 1) ||
        (selectedType === "review" && m.matchAccepted === 0) ||
        (selectedType === "my review" &&
          m.matchAccepted === 0 &&
          (m.homeTeamCaptainID === user._id ||
            m.awayTeamCaptainID === user._id))
      )
        typeMatch = true;
      if (
        selectedMatchType._id === "all" ||
        m.type.toLowerCase().includes(selectedMatchType.name.toLowerCase())
      )
        matchType = true;
      if (
        selectedDivision._id === "all" ||
        selectedDivision._id === m.divisionID
      )
        divisionMatch = true;
      if (searchMatch && typeMatch && matchType && divisionMatch)
        filteredMatches.push(m);
    });

    const sortedMatches = orderBy(
      filteredMatches,
      [sortColumn.path],
      [sortColumn.order]
    );

    let dbDivisions = orderBy(divisions, ["name"], ["asc"]);
    dbDivisions.forEach((d) => {
      d.numberOfMatches = matches.filter((m) => m.divisionID === d._id).length;
    });
    dbDivisions = [
      {
        _id: "all",
        name: "All Divisions",
        numberOfMatches: matches.length,
      },
      ...dbDivisions,
    ];

    return [sortedMatches, dbDivisions];
  };

  render() {
    const {
      selectedType,
      sortColumn,
      searchQuery,
      selectedMatchType,
      selectedDivision,
      divisions,
      matchTypes,
    } = this.state;
    const [matches] = this.filterMatches();
    const user = getCurrentUser();
    const options = user.role.includes("captain") ? ["my review"] : [];

    return (
      <React.Fragment>
        <HorizontalButtons
          buttons={[...options, "review", "accepted"]}
          onClick={this.handleSelectType}
          selectedType={selectedType}
        />
        <div className="row">
          <div className="col text-center">
            <NonFormSelect
              name="_id"
              label=""
              options={divisions}
              onChange={this.handleSelectDivision}
              value={selectedDivision._id}
              noDefaultOption={true}
            />
          </div>
          <div className="col text-center">
            <NonFormSelect
              name="_id"
              label=""
              options={matchTypes}
              onChange={this.handleSelectMatchType}
              value={selectedMatchType._id}
              noDefaultOption={true}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <SearchBox
              onChange={this.handleSearch}
              value={searchQuery}
              placeholder="Search by team name..."
            />
          </div>
          <div className="col-2">
            <ExcelDownload
              data={this.state.matches}
              columns={this.downloadColumns}
              dataName="played matches"
              tooltipDirection="top-left"
            />
          </div>
        </div>
        <MatchesTableCardSwitcher
          matches={matches}
          isReviewTable={true}
          onSort={this.handleSort}
          sortColumn={sortColumn}
          location={this.props.location}
          onViewVideo={this.handleViewVideo}
          onRefresh={this.refreshMatchesOnly}
        />
        <QuickLinks
          fullBoard={true}
          org={this.props.org ? this.props.org : null}
        />
      </React.Fragment>
    );
  }
}

export default MatchReview;
