import React, { useEffect, useState } from "react";

import SideBySideView from "../common/pageComponents/sideBySideView";
import TooltipButton from "../common/tooltip/tooltipButton";
import IconRender from "../common/icons/iconRender";

const SeasonsButtonLineup = () => {
  const [buttons, setButtons] = useState([]);

  useEffect(() => {
    setButtons(getButtons());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getButtons = () => {
    let buttons = [
      <TooltipButton
        buttonClass="btn-md btn-info"
        tooltipText="Add New Season"
        tooltipDirection="bottom"
        navigatePath={"/seasons?q=new"}
      >
        <IconRender name="add" className="icon-md" />
      </TooltipButton>,
    ];
    return buttons;
  };
  return (
    <SideBySideView
      Components={buttons}
      groupClassName="button-group-box"
      separateRows
      minWidth={200}
    />
  );
};

export default SeasonsButtonLineup;
