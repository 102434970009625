import React from "react";
import { QRCode } from "react-qrcode-logo";

const QRCodeRender = ({ value, size = 100, margin }) => {
  if (!value) return null;

  return (
    <div className="row" style={{ margin: margin || 0 }}>
      <div className="col text-center">
        <QRCode
          value={value}
          size={size}
          quietZone={3}
          logoImage="/logos/iconOnSplash.png"
          logoWidth={size / 4}
          logoHeight={size / 4}
          logoOpacity={1}
          logoPadding={2}
          qrStyle="dots"
          eyeColor="#831fe0"
          eyeRadius={[
            { inner: [5, 5, 5, 5], outer: [5, 5, 0, 5] },
            { inner: [5, 5, 5, 5], outer: [5, 5, 5, 0] },
            { inner: [5, 5, 5, 5], outer: [5, 0, 5, 5] },
          ]}
        />
      </div>
    </div>
  );
};

export default QRCodeRender;
