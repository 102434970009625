import React, { useState } from "react";
import BasicModal from "./../../common/pageComponents/basicModal";
import CustomConfirm from "../../common/customs/customConfirm";
import allowables from "../../../utils/allowables";
import toast from "../../../utils/toast";
import { nameButtonText } from "../../../utils/matchFunctions";
import HorizontalCounter from "./../../common/pageComponents/horizontalCounter";
import VerticalCounter from "../../common/pageComponents/verticalCounter";
import useWindowDimensions from "../../../utils/useWindowDimensions";
import DiamondSelectedPlayer from "./diamondSelectedPlayer";
import { atBatName } from "../../../utils/diamondFunctions";
import { popupStyle } from "../../../utils/styleUtil";

const DiamondPopupEvent = ({
  isOpen,
  toggleModal,
  match,
  team,
  pitcher,
  players,
  onFinalizeEvent,
  onChangePitcher,
  org,
}) => {
  const [batter, setBatter] = useState(null);
  const [count, setCount] = useState([0, 0, 0]);
  const [result, setResult] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [inDepth, setInDepth] = useState(null);
  const [rbis, setRbis] = useState(0);
  const { width } = useWindowDimensions();

  const handleClose = () => {
    toggleModal(null);
    setResult(null);
    setInDepth(null);
    setCount([0, 0, 0]);
    setBatter(null);
    setRbis(0);
  };

  const possibleResults = () => {
    if (!result) return [];
    if (result === "out")
      return ["strike out", "fly out", "ground out", "fielder's choice"];
    if (result === "walk") return ["on balls", "hit by pitch", "balk"];
    if (result === "hit") return ["single", "double", "triple", "home run"];
  };

  const countOptions = () => {
    const hideFoulBalls = org?.misc?.diamondSportOptions?.hideFoulBalls;
    const options = ["Balls", "Strikes"];
    if (!hideFoulBalls) options.push("Fouls");
    return options;
  };

  const sendEvent = () => {
    if (!batter || !result || !inDepth)
      return toast.error(
        "All fields were not set, please return to batter select and try again."
      );
    if (inDepth === "home run" && rbis === 0)
      return toast.error(
        "RBIs should be at least one if player has hit a home run."
      );
    if (rbis === 4 && inDepth !== "home run")
      return toast.error("Only a home run can have four RBIs");
    handleClose();
    onFinalizeEvent(batter, { result, inDepth, rbis, team });
  };

  return (
    <BasicModal isOpen={isOpen} onClose={toggleModal} style={popupStyle}>
      <h3 className="text-center">
        {match[team + "TeamName"]} {atBatName(org).action}
      </h3>
      {!batter && (
        <React.Fragment>
          <h5 className="text-center">Select {atBatName(org).er}</h5>
          <button
            className="btn btn-sm btn-block btn-light"
            onClick={() => setBatter({ _id: "unk", name: "Unknown Player" })}
          >
            Unknown/Unregistered Player
          </button>
          <br />
          {players.map((p) => {
            if (p.shirtNumber && p.shirtNumber === -1) return null;
            return (
              <button
                key={p._id}
                className="btn btn-block btn-light"
                onClick={() => setBatter(p)}
              >
                {nameButtonText(p, true)}
              </button>
            );
          })}
        </React.Fragment>
      )}
      {batter && (
        <React.Fragment>
          <button
            className="btn btn-block btn-dark btn-sm"
            onClick={() => setBatter(null)}
          >
            Back to {atBatName(org).er} Select
          </button>
          <br />
          <h5 className="text-center">{nameButtonText(batter)}</h5>
          <hr />
          <div className="row">
            <div className="col text-center">
              {width < 750 ? (
                <VerticalCounter
                  title={"Runs Scored"}
                  updater={setRbis}
                  value={rbis}
                  min={0}
                  max={4}
                />
              ) : (
                <HorizontalCounter
                  header="Runs Scored"
                  maxValue={4}
                  minValue={0}
                  value={rbis}
                  setValue={setRbis}
                  className="icon-info icon-lg"
                />
              )}
            </div>
            {pitcher && (
              <div className="col">
                <DiamondSelectedPlayer
                  player={pitcher}
                  header="Pitcher"
                  onClick={onChangePitcher}
                />
              </div>
            )}
          </div>
          <div className="row">
            <div className="col">
              {result && (
                <button
                  className="btn btn-sm btn-block btn-dark"
                  onClick={() => setResult(null)}
                >
                  Back to {atBatName(org).action}
                </button>
              )}
              <h5 className="text-center">{atBatName(org).action} Result</h5>
              {result && (
                <h5 className="text-center">
                  {allowables.capLetterOne(result)}
                </h5>
              )}
              {!result
                ? ["out", "walk", "hit"].map((r) => (
                    <button
                      key={r}
                      className="btn btn-block btn-light"
                      onClick={() => setResult(r)}
                      aria-label={`diamond-result-${r}`}
                    >
                      {allowables.capLetterOne(r)}
                    </button>
                  ))
                : possibleResults().map((r) => (
                    <button
                      key={r}
                      className="btn btn-block btn-info"
                      onClick={() => {
                        setInDepth(r);
                        setConfirmOpen(true);
                      }}
                      aria-label={`diamond-inDepth-${r}`}
                    >
                      {allowables.capLetterOne(r)}
                    </button>
                  ))}
            </div>

            <div className="col">
              <button
                className="btn btn-block btn-sm btn-dark"
                onClick={() => setCount([0, 0, 0])}
              >
                Reset Count
              </button>
              <br />
              {width > 750 ? (
                <div className="row">
                  {countOptions().map((e, i) => (
                    <div className="col text-center" key={e}>
                      <VerticalCounter
                        title={e}
                        updater={setCount}
                        value={count}
                        index={i}
                        min={0}
                        max={4}
                      />
                    </div>
                  ))}
                </div>
              ) : (
                countOptions().map((e, i) => (
                  <HorizontalCounter
                    key={e}
                    header={e}
                    maxValue={4}
                    minValue={0}
                    index={i}
                    value={count}
                    setValue={setCount}
                    className="icon-info icon-md"
                  />
                ))
              )}
            </div>
          </div>
        </React.Fragment>
      )}
      <CustomConfirm
        dialog={`Confirm ${atBatName(
          org
        ).action.toLowerCase()} result for ${allowables.splitName(
          batter && batter.name
        )}:%
        ${allowables.capLetterOne(result)}: ${allowables.capLetterOne(
          inDepth
        )} - ${rbis} RBI${rbis !== 1 ? "s" : ""}`}
        isOpen={confirmOpen}
        callback={sendEvent}
        close={setConfirmOpen}
        split="%"
      >
        <HorizontalCounter
          header="Runs Scored"
          maxValue={4}
          minValue={0}
          value={rbis}
          setValue={setRbis}
          className="icon-info icon-lg"
          label="rbi"
        />
      </CustomConfirm>
      <br />
      <br />
    </BasicModal>
  );
};

export default DiamondPopupEvent;
