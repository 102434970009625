import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import { splitQuery } from "../utils/splitQuery";
import CustomConfirm from "./common/customs/customConfirm";
import MainTitle from "./common/loading/mainTitle";

// this component handles deeplinking into the mobile app
class DeepLinkRedirect extends Component {
  state = {
    url: "",
    openInApp: false,
    decideOpen: true,
  };

  componentDidMount() {
    // deep linking is not set up in the mobile app
    // for now we will immediately call the seturl code and direct the user to the web page
    // future plan to offer a popup confirmation to ask the user if they want to open in the app
    this.setUrl();
  }

  setUrl = () => {
    let url = "";
    try {
      const query = splitQuery(this.props.location.search);
      let pathname = query[0].split("=")[1];
      let search = query.slice(1).join("&");
      if (pathname) url = pathname + "?" + search;
      else url = "not-found";
    } catch (error) {
      url = "not-found";
    }
    this.setState({ url, openInApp: false });
  };

  render() {
    if (this.state.url) {
      if (!this.state.openInApp) return <Redirect to={this.state.url} />;
      else {
        // the url should be set up to point to the mobile app (i.e. ultimatescoreboard://deeplinkuri?queryparams)
      }
    }
    return null;

    // eslint-disable-next-line no-unreachable
    return (
      <div className="text-center">
        <MainTitle />
        <CustomConfirm
          dialog={"Open in the mobile app?"}
          isOpen={this.state.decideOpen}
          callback={() => this.setState({ openInApp: true })}
          negativeCallback={this.setUrl}
          close={() => {
            this.setUrl();
            this.setState({ decideOpen: !this.state.decideOpen });
          }}
          id={"decideOpen"}
          focused={true}
          yesNo={true}
        />
      </div>
    );
  }
}

export default DeepLinkRedirect;
