import React from "react";
import Form from "../common/form/form";
import Joi from "joi-browser";

import BasicModal from "../common/pageComponents/basicModal";
import { popupStyle } from "../../utils/styleUtil";
import TooltipButton from "../common/tooltip/tooltipButton";
import IconRender from "../common/icons/iconRender";
import allowables from "../../utils/allowables";

class PlayerEditor extends Form {
  state = {
    baseState: {
      name: "",
      number: "",
      backgroundColor: "",
    },
    data: {
      number: "",
      name: "",
      backgroundColor: "",
    },
    errors: {},
  };

  schema = {
    number: Joi.number().integer().min(0).max(999).label("Player Number"),
    name: Joi.string().min(0).max(5).label("Player Name"),
    backgroundColor: Joi.string().allow("").max(50).label("Shirt Color"),
  };

  componentDidUpdate(prevProps) {
    if (this.props.visible) return;
    if (prevProps.player !== this.props.player)
      this.setState({
        data: this.mapToViewModel(this.props.player),
        baseState: this.mapToViewModel(this.props.player),
        errors: {},
      });
  }

  mapToViewModel(player) {
    return {
      number: player?.number,
      name: player?.name,
      backgroundColor: player?.backgroundColor,
    };
  }

  doSubmit = () => {
    this.props.onSave(this.state.data);
  };

  render() {
    const { visible, setVisible, player, team } = this.props;

    if (!player) return null;

    return (
      <BasicModal
        isOpen={visible}
        onClose={setVisible}
        style={{
          content: {
            ...popupStyle.content,
            minWidth: "50%",
            minHeight: "50%",
          },
        }}
      >
        {this.renderWholeForm(
          "",
          null,
          null,
          <div>
            {this.renderInput("number", "Number", "", "number")}
            {this.renderInput("name", "Name")}
            <div className="row">
              <div className="col">
                {this.renderColorPicker("backgroundColor", "Shirt Color")}
              </div>
              <div className="col text-right">
                <br />
                <TooltipButton
                  tooltipText="Set to Home Color"
                  tooltipDirection="top"
                  onClick={() =>
                    this.setState({
                      data: {
                        ...this.state.data,
                        backgroundColor: team.teamColor1,
                      },
                    })
                  }
                  buttonClass="btn btn-md btn-info"
                  style={{
                    backgroundColor: team.teamColor1,
                    color: allowables.getTextColor(team.teamColor1),
                  }}
                >
                  <IconRender name="reset" />
                </TooltipButton>
                <br />
                <TooltipButton
                  tooltipText="Set to Away Color"
                  tooltipDirection="top"
                  onClick={() =>
                    this.setState({
                      data: {
                        ...this.state.data,
                        backgroundColor: team.teamColor2,
                      },
                    })
                  }
                  buttonClass="btn btn-md btn-info"
                  style={{
                    backgroundColor: team.teamColor2,
                    color: allowables.getTextColor(team.teamColor2),
                  }}
                >
                  <IconRender name="reset" />
                </TooltipButton>
              </div>
            </div>
          </div>
        )}
      </BasicModal>
    );
  }
}

export default PlayerEditor;
