import React, { useEffect, useState } from "react";

import { getMyPayments } from "../../services/paymentService";
import MiniHeader from "../common/pageComponents/miniHeader";
import toast from "../../utils/toast";
import { renderPaymentInfoLine } from "../../utils/textUtils";
import allowables from "../../utils/allowables";
import IconRender from "../common/icons/iconRender";
import { isPaid } from "../../utils/paymentsUtil";
import ComponentLoading from "../common/loading/componentLoading";
import WarningHeader from "../common/pageComponents/warningHeader";
import PlayerDiscountForm from "../payment/playerPayments/playerDiscountForm";

const UserPaymentHistory = ({ isOpen, id }) => {
  const [loading, setLoading] = useState(false);
  const [openPayments, setOpenPayments] = useState({});
  const [payments, setPayments] = useState([]);

  const getData = async () => {
    setLoading(true);
    const res = await getMyPayments(id === "dues");
    if (res.status === 200) {
      setPayments(res.data);
    } else toast.error(res.data);
    setLoading(false);
  };

  useEffect(() => {
    if (isOpen) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const renderPayment = (payment, index, type) => {
    if (!isPaid(payment)) return null;

    const key = type + "_" + index;
    const isOpen = openPayments[key];
    const amountPaid = payment.amountPaid || payment.amount;

    return (
      <div key={key}>
        <MiniHeader
          iconName={isOpen ? "chevronup" : "chevrondown"}
          onClick={() =>
            setOpenPayments({
              ...openPayments,
              [key]: !openPayments[key],
            })
          }
        >
          {payment.orgName}: {payment.seasonName}
        </MiniHeader>
        {isOpen ? (
          <div className="form-divided-section">
            {payment.markedUnpaid ? (
              <WarningHeader>
                Payment was completed but marked as unpaid by a league admin on{" "}
                {allowables.dateTime(payment.dateCreated, true).date}.
              </WarningHeader>
            ) : null}
            {payment.orgName
              ? renderPaymentInfoLine("League", payment.orgName)
              : null}
            {payment.seasonName
              ? renderPaymentInfoLine("Season", payment.seasonName)
              : null}
            {payment.divisionName
              ? renderPaymentInfoLine("Division", payment.divisionName)
              : null}
            {payment.teamName
              ? renderPaymentInfoLine("Team", payment.teamName)
              : null}
            {payment.paymentType
              ? renderPaymentInfoLine("Payment Type", payment.paymentType)
              : null}
            {renderPaymentInfoLine(
              "Date and Time",
              allowables.shortDate(payment.datePaid, true)
            )}
            {renderPaymentInfoLine(
              "Amount Paid",
              `${amountPaid.toFixed(2)} ${payment.currency.toUpperCase()}`
            )}
            {payment.discountUsed ? (
              <PlayerDiscountForm
                appliedDiscount={payment.discountUsed}
                userPaid={true}
                discountAmount={payment.discountUsed.amount}
                currency={payment.currency}
              />
            ) : null}
            {payment.receiptUrl ? (
              <a
                href={payment.receiptUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                Receipt <IconRender name="externallink" />
              </a>
            ) : payment.external ? (
              "Payment was made outside Ultimate Scoreboard"
            ) : null}
          </div>
        ) : null}
      </div>
    );
  };

  return !isOpen ? null : (
    <div className="form-divided-section">
      {loading ? (
        <ComponentLoading />
      ) : (
        <div>
          {payments.length ? (
            payments.map((p, idx) => renderPayment(p, idx, id))
          ) : (
            <WarningHeader>There are no payments to display</WarningHeader>
          )}
        </div>
      )}
    </div>
  );
};

export default UserPaymentHistory;
