import React from "react";
import { isPaid } from "../../utils/paymentsUtil";
import ProfileStatus from "./profileStatus";

const PaymentStatus = ({ org, player, user }) => {
  if (
    !org ||
    !user ||
    !user.role ||
    !user.role.includes("player") ||
    !player._id ||
    org.stripe.amount === 0
  )
    return null;

  if (
    org.stripe?.whoPays === "Team" &&
    player.teamID?.teamCaptainID !== user._id
  )
    return null;

  const userPaid = isPaid(
    (org.stripe.whoPays === "Player" ? player : player.teamID)?.payment
  );

  return (
    <ProfileStatus
      isComplete={userPaid}
      incompleteText="Pay your league dues"
      completeText="League dues paid"
      link="/payments"
      highlight={true}
    />
  );
};

export default PaymentStatus;
