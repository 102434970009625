import React, { useState, useEffect, useContext } from "react";
import toast from "../../utils/toast";

import {
  getUsersForOrg,
  getCurrentUser,
  updateCustomFlags,
} from "../../services/userService";
import HeaderContext from "../../context/headerContext";
import CustomFlagsTable from "./customFlagsTable";
import NotFound from "../notFound";
import MiniHeader from "../common/pageComponents/miniHeader";

const CustomFlagsView = ({ org, location }) => {
  const { setProgress, setLoading } = useContext(HeaderContext);
  const [users, setUsers] = useState([]);
  const [toAcceptList, setToAcceptList] = useState([]);
  const [flagName, setFlagName] = useState("");
  const [flagIndex, setFlagIndex] = useState("");

  useEffect(() => {
    refreshData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const refreshData = async () => {
    setLoading(true);
    setProgress([1]);
    const idx = location.search.split("=");
    if (org.misc && org.misc["customFlag" + idx[1]]) {
      setFlagName(org.misc["customFlag" + idx[1]]);
      setFlagIndex(idx[1]);
    } else {
      setFlagName(null);
      return setLoading(false);
    }
    const user = getCurrentUser();
    if (user.role.includes("admin")) {
      const usersRes = await getUsersForOrg({
        callback: (p) => setProgress(p),
        bar: 0,
      });
      if (usersRes.status === 200) {
        setUsers(usersRes.data);
      } else toast.error(usersRes.data);
    }
    setToAcceptList([]);
    setLoading(false);
  };

  const addUserToList = (user) => {
    let list = [...toAcceptList];
    if (list.find((u) => u._id === user._id))
      list.splice(list.map((u) => u._id).indexOf(user._id), 1);
    else list.push(user);
    setToAcceptList(list);
  };

  const deselectAll = () => {
    setToAcceptList([]);
  };

  const handleSubmitChanges = async () => {
    setLoading(true);
    setProgress([1]);
    const res = await updateCustomFlags(flagIndex, toAcceptList, {
      callback: (p) => setProgress(p),
      bar: 0,
    });
    if (res.status === 200) {
      toast.success(res.data);
      refreshData();
    } else toast.error(res.data);
    setLoading(false);
  };

  return flagName ? (
    <div>
      <MiniHeader>{flagName}</MiniHeader>
      <div className="form-divided-section">
        <CustomFlagsTable
          flagName={flagName}
          flagIndex={flagIndex}
          users={users}
          addUserToList={addUserToList}
          userList={toAcceptList.map((u) => u._id)}
          onSubmitChanges={handleSubmitChanges}
          deselectAll={deselectAll}
        />
      </div>
    </div>
  ) : (
    <NotFound text="No custom flag set up at this index." />
  );
};

export default CustomFlagsView;
