import React from "react";

import FormLabel from "./formLabel";

const Switch = ({
  label,
  value,
  paidFeature,
  onChange,
  tooltip,
  labelStyle,
  disabled,
}) => {
  return (
    <div>
      <FormLabel
        tooltip={tooltip}
        paidFeature={paidFeature}
        label={label}
        htmlFor={label}
        labelStyle={labelStyle}
      />
      <label className="switch">
        <input
          id={label}
          type="checkbox"
          onChange={onChange}
          checked={value}
          disabled={disabled}
        />
        <span className="slider"></span>
      </label>
    </div>
  );
};

export default Switch;
