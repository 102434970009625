import React, { useState, useEffect, useContext } from "react";
import toast from "../../utils/toast";
import orderBy from "lodash/orderBy";

import { getUsersForOrg } from "../../services/userService";
import SearchBox from "./../common/dataSorting/searchBox";
import ImageViewUserTable from "./imageViewUserTable";
import MailingList from "../common/dataSorting/mailingList";
import HeaderContext from "../../context/headerContext";
import allowables from "../../utils/allowables";
import CheckboxGroup from "../common/dataSorting/checkboxGroup";
import useWindowDimensions from "../../utils/useWindowDimensions";

const ImageView = () => {
  const { width } = useWindowDimensions();
  const { setLoading, setProgress } = useContext(HeaderContext);
  const [selectedTypes, setSelectedTypes] = useState({
    hasImage: true,
    noImage: true,
    blocked: false,
  });
  const [sortColumn, setSortColumn] = useState({ path: "name", order: "asc" });
  const [query, setQuery] = useState("");
  const [users, setUsers] = useState([]);

  const loadData = async () => {
    setLoading(true);
    setProgress(1);
    const res = await getUsersForOrg({
      callback: (p) => setProgress(p),
      bar: 0,
    });
    if (res.status === 200) {
      setUsers(res.data);
    } else toast.error(res.data);
    setLoading(false);
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSort = (col) => {
    let newSort = { path: col.path, order: "asc" };
    if (col.path === sortColumn.path)
      newSort.order = sortColumn.order === "asc" ? "desc" : "asc";
    setSortColumn(newSort);
  };

  const getPageData = () => {
    let data = [...users];

    data = data.filter(
      (d) =>
        (selectedTypes.hasImage &&
          ((d.profileID.hasAvatar &&
            !d.profileID.hasAvatar.toLowerCase().includes("blocked")) ||
            d.playerID?.image)) ||
        (selectedTypes.noImage &&
          !d.profileID.hasAvatar &&
          !d.playerID?.image) ||
        (selectedTypes.blocked &&
          d.profileID.hasAvatar &&
          d.profileID.hasAvatar.toLowerCase().includes("blocked"))
    );

    if (query) {
      const q = query.toLowerCase();
      data = data.filter(
        (d) =>
          allowables.splitName(d.name).toLowerCase().includes(q) ||
          (d.teamName && d.teamName.toLowerCase().includes(q))
      );
    }
    data = orderBy(data, [sortColumn.path], [sortColumn.order]);

    return data;
  };

  return (
    <div>
      <CheckboxGroup
        header="Filter Data"
        onChange={(id) => {
          let currentTypes = { ...selectedTypes };
          currentTypes[id] = !currentTypes[id];
          setSelectedTypes(currentTypes);
        }}
        containerClassName="pop-box"
        colCount={width > 500 ? 3 : 1}
        checkboxes={[
          {
            id: "hasImage",
            value: selectedTypes.hasImage,
            label: "Has Image",
          },
          {
            id: "noImage",
            value: selectedTypes.noImage,
            label: "No Image",
          },
          {
            id: "blocked",
            value: selectedTypes.blocked,
            label: "Blocked",
          },
        ]}
      />
      <br />
      <div className="row">
        <div className="col">
          <SearchBox
            value={query}
            onChange={setQuery}
            placeholder="Search by player or team name..."
          />
        </div>
        <div className="col-2">
          <MailingList
            users={getPageData()}
            buttonTitle="Create Mailing List"
            margin="20px"
          />
        </div>
      </div>
      <ImageViewUserTable
        users={getPageData()}
        onSort={handleSort}
        sortColumn={sortColumn}
        selectedTypes={selectedTypes}
      />
    </div>
  );
};

export default ImageView;
