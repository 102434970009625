import React, { useState } from "react";
import { GoDesktopDownload } from "react-icons/go";

import allowables from "../../utils/allowables";
import HorizontalButtons from "../common/dataSorting/horizontalButtons";
import Input from "../common/form/input";
import Switch from "../common/form/switch";
import TooltipButton from "../common/tooltip/tooltipButton";
import MiniHeader from "../common/pageComponents/miniHeader";

const BracketButtons = ({
  rounds,
  selectedBracket,
  setSelectedBracket,
  orientation,
  setOrientation,
  bracketSize,
  setBracketSize,
  webpage,
  showFullTeamNames,
  setShowFullTeamNames,
  downloadBracketImage,
  hideFormatter,
}) => {
  const [sizeInput, setSizeInput] = useState(bracketSize);
  const [showFormats, setShowFormats] = useState(false);

  const buttons = ["main"];
  if (rounds.prelim.length > 0) buttons.unshift("prelim");
  if (rounds.secondary.length > 0) buttons.push("secondary");

  const handleSelectBracket = (bracket) => {
    if (bracket !== "main" || hideFormatter) setOrientation("portrait");
    else setOrientation("landscape");
    setSelectedBracket(bracket);
  };

  const handleUpdateSizeInput = (d, value) => {
    try {
      let input = { ...sizeInput };
      input[d] = Number(value);
      setSizeInput(input);
      setBracketSize(input);
    } catch (error) {
      // ignore error here if user somehow entered non numeric input
    }
  };

  return (
    <div>
      {hideFormatter ? null : (
        <div>
          <MiniHeader
            iconName={showFormats ? "chevronup" : "chevrondown"}
            onClick={() => setShowFormats(!showFormats)}
          >
            Format Bracket
          </MiniHeader>
          {showFormats && (
            <div className="form-divided-section">
              <div className="row">
                <div className="col text-center">
                  <b>Dimensions</b>
                  {["width", "height"].map((d) => (
                    <div className="col text-center" key={d}>
                      <Input
                        name={d}
                        type="number"
                        label={allowables.capLetterOne(d)}
                        value={sizeInput[d]}
                        onChange={(event) =>
                          handleUpdateSizeInput(d, event.target.value)
                        }
                      />
                    </div>
                  ))}
                </div>
                <div className="col text-center">
                  <Switch
                    onChange={() => setShowFullTeamNames(!showFullTeamNames)}
                    value={showFullTeamNames}
                    label={"Show Full Team Names"}
                    labelStyle={{ fontWeight: "bold" }}
                  />
                  <Switch
                    onChange={() =>
                      setOrientation(
                        orientation === "portrait" ? "landscape" : "portrait"
                      )
                    }
                    value={orientation === "portrait"}
                    label={"Portrait Mode"}
                    labelStyle={{ fontWeight: "bold" }}
                    disabled={selectedBracket !== "main"}
                  />
                  <b>Download</b>
                  <TooltipButton
                    tooltipText="Save Bracket as Image"
                    tooltipDirection="left"
                    onClick={downloadBracketImage}
                    buttonClass="btn btn-md btn-info"
                  >
                    <GoDesktopDownload />
                  </TooltipButton>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {buttons.length > 1 ? (
        <div>
          <MiniHeader>Select Section</MiniHeader>
          <div className="form-divided-section">
            <HorizontalButtons
              buttons={buttons}
              onClick={handleSelectBracket}
              selectedType={selectedBracket}
              colors={{
                bg: webpage?.themeTextColor,
                text: webpage?.logoBgColor,
              }}
              custom={true}
            />
          </div>
        </div>
      ) : (
        <br />
      )}
    </div>
  );
};

export default BracketButtons;
