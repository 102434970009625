import React from "react";
import Loading from "./loading";
import ProgressIndicator from "./progressIndicator";

const FullLoading = ({ progress, setWidth, orientation }) => {
  return (
    <React.Fragment>
      <Loading setWidth={setWidth} orientation={orientation} />
      <br />
      {progress && progress.length > 0 && (
        <ProgressIndicator progress={progress} />
      )}
    </React.Fragment>
  );
};

export default FullLoading;
