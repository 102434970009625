import React, { useState, useEffect } from "react";

import QRCode from "../common/pageComponents/qrCode";

const SpectateQR = ({ division }) => {
  const [link, setLink] = useState("");

  useEffect(() => {
    const updatedLink =
      window.location.origin +
      window.location.pathname +
      "?division=" +
      division._id;
    setLink(updatedLink);
  }, [division]);

  return (
    <React.Fragment>
      <div className="text-center">
        <QRCode value={link} size={75} />
      </div>
    </React.Fragment>
  );
};

export default SpectateQR;
