import http from "./httpService";

async function sendError(error) {
  try {
    return await http.post(http.errorEndpoint, error);
  } catch (ex) {
    return ex.response;
  }
}

export { sendError };
