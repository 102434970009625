import React, { useContext } from "react";

import { navigateTo } from "../common/customs/customLinks";
import allowables from "../../utils/allowables";
import Table from "./../common/table/table";
import HeaderContext from "../../context/headerContext";

const CodeOfConductUserList = ({ players, users, history }) => {
  const header = useContext(HeaderContext);

  const columns = [
    {
      path: "name",
      label: "Name",
      content: (p) => allowables.splitName(p.name),
    },
    {
      path: "email",
      label: (!users ? "Pending " : "") + "Email",
      content: (p) => p.email || "n/a",
    },
    {
      path: "teamName",
      label: "Team",
    },
    {
      path: "typedName",
      label: "Typed Name",
      content: (p) => p.codeOfConduct && p.codeOfConduct.typedName,
    },
    {
      path: "location",
      label: "Location Taken",
      content: (p) =>
        p.codeOfConduct?.location
          ? p.codeOfConduct?.location?.retrieved
            ? "Yes"
            : "No"
          : "",
    },
    {
      path: "date",
      label: "Date",
      content: (p) =>
        p.codeOfConduct && p.codeOfConduct.date
          ? allowables.dateTime(p.codeOfConduct.date).date
          : "",
    },
  ];

  return players.length > 0 ? (
    <React.Fragment>
      <div className="text-center">
        <small>
          IP address and location (with user permission) are taken at time of
          acceptance. Not guaranteed to be accurate, can be spoofed or blocked
          by players using a VPN.
        </small>
      </div>
      <Table
        columns={columns}
        data={players}
        keyProperty={"_id"}
        tableClass="table-custom"
        headerClass="thead-custom"
        bodyClass="tbody-custom"
        highlightOnHover={true}
        onSelect={(player) =>
          navigateTo(`/players?info&q=${player._id}`, history, header)
        }
      />
    </React.Fragment>
  ) : (
    <p>
      <b>None</b>
    </p>
  );
};

export default CodeOfConductUserList;
