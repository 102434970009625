import React from "react";

const DevAlert = () => {
  if (process.env.NODE_ENV === "production") return null;

  return (
    <div
      className="bg-danger text-center light-text main-border sticky-top"
      style={{ height: 25 }}
    >
      In {process.env.NODE_ENV} environment
    </div>
  );
};

export default DevAlert;
