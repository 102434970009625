import React from "react";

const CodeOfConductStatus = ({ user, table }) => {
  // status codes: 1: accepted, 2: not accepted
  const status =
    user && user.codeOfConduct && user.codeOfConduct.accepted ? 1 : 2;
  return (
    <React.Fragment>
      <div
        className={
          "text-center text-white " + (status === 1 ? "bg-info" : "bg-dark")
        }
        style={{ margin: "5px", padding: "5px" }}
      >
        {!table ? (
          <b>
            Your vaccine information{" "}
            {status === 1
              ? "has been accepted"
              : status === 2
              ? "is under review"
              : "has not been submitted"}
          </b>
        ) : (
          <div>{status === 1 ? "Accepted" : "Not Accepted"}</div>
        )}
      </div>
    </React.Fragment>
  );
};

export default CodeOfConductStatus;
