import React, { useState } from "react";
import orderBy from "lodash/orderBy";
import { FaSortDown, FaSortUp } from "react-icons/fa";

import BasicModal from "./../pageComponents/basicModal";
import SearchBox from "./searchBox";
import allowables from "../../../utils/allowables";
import MiniHeader from "../pageComponents/miniHeader";
import toast from "../../../utils/toast";

const MailingList = ({
  buttonTitle,
  buttonStyle,
  secondaryTitle,
  users,
  margin,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [query, setQuery] = useState("");
  const [sortColumn, setSortColumn] = useState({ path: "name", order: "asc" });
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);

  const updateSelectedUsers = (user) => {
    const idMap = selectedUsers.map((u) => u._id);
    let included = false;
    if (idMap.includes(user._id)) included = true;
    if (!included) setSelectedUsers([...selectedUsers, user]);
    else {
      let newSelection = [...selectedUsers];
      newSelection.splice(
        selectedUsers.findIndex((u) => u._id === user._id),
        1
      );
      setSelectedUsers(newSelection);
    }
    setCopiedToClipboard(false);
  };

  const getSortIcon = (col) => {
    if (col !== sortColumn.path) return;
    return sortColumn.order === "asc" ? <FaSortUp /> : <FaSortDown />;
  };

  const handleSort = (col) => {
    const order =
      sortColumn.path === col && sortColumn.order === "asc" ? "desc" : "asc";
    setSortColumn({ path: col, order });
  };

  const createMailingList = () => {
    return selectedUsers.map((u) => u.email).join(",");
  };

  const copyToClipboard = () => {
    const mailingList = selectedUsers.map((u) => u.email).join(";");
    navigator.clipboard.writeText(mailingList);
    setCopiedToClipboard(true);
    toast.success("List copied to clipboard");
  };

  const filterUsers = () => {
    let filteredUsers = users.filter((u) => u.email);
    if (query) {
      const q = query.toLowerCase();
      filteredUsers = filteredUsers.filter(
        (u) =>
          allowables.splitName(u.name).toLowerCase().includes(q) ||
          u.email.toLowerCase().includes(q)
      );
    }
    const sortedUsers = orderBy(
      filteredUsers,
      [sortColumn.path],
      [sortColumn.order]
    );
    return sortedUsers;
  };

  return (
    <React.Fragment>
      <div style={{ marginTop: margin || 0 }}>
        <button
          className={buttonStyle || "btn btn-sm btn-info"}
          onClick={() => setModalOpen(true)}
        >
          {buttonTitle}
        </button>
      </div>
      <BasicModal
        isOpen={modalOpen}
        onClose={setModalOpen}
        alsoRunOnClose={() => setSelectedUsers([])}
      >
        <MiniHeader>Create Mailing List</MiniHeader>
        <div className="form-divided-section">
          <div className="row">
            <div className="col-2">
              {selectedUsers.length > 0 && (
                <React.Fragment>
                  <a
                    href={`mailto:?bcc=${createMailingList()}`}
                    className="btn btn-sm btn-block btn-info"
                  >
                    Email This List
                  </a>
                  <button
                    className="btn btn-sm btn-block btn-secondary"
                    onClick={() => copyToClipboard()}
                  >
                    {copiedToClipboard
                      ? "List Copied to Clipboard"
                      : "Copy List to Clipboard"}
                  </button>
                </React.Fragment>
              )}
            </div>
            <div className="col">
              <h4 className="text-center">{secondaryTitle}</h4>
              <h5 className="text-center">
                <small>Selected: </small>
                {selectedUsers.length}
              </h5>
            </div>
            <div className="col-2">
              <button
                className="btn btn-info btn-sm btn-block"
                onClick={() => setSelectedUsers(filterUsers())}
              >
                Select All Visible
              </button>
              <button
                className="btn btn-dark btn-sm btn-block"
                onClick={() => setSelectedUsers([])}
              >
                Select None
              </button>
            </div>
          </div>
          <hr />
          <div className="text-center">
            Select users and click Email This List to open your default email
            program with a bcc mailing list of selected users.
            <br />
            Or Click Copy List to Clipboard to paste the list elsewhere.
          </div>
          <SearchBox
            value={query}
            onChange={setQuery}
            placeholder="Search by name or email"
          />
          <div className="row">
            {["name", "email"].map((e) => (
              <div
                key={e}
                className="col clickable"
                onClick={() => handleSort(e)}
              >
                <b>{e.charAt(0).toUpperCase() + e.slice(1)}</b> {getSortIcon(e)}
              </div>
            ))}
          </div>
          {filterUsers().map((u) => (
            <React.Fragment key={u._id}>
              <div
                className={
                  "row clickable" +
                  (selectedUsers.map((u) => u._id).includes(u._id)
                    ? " bg-info"
                    : "")
                }
                style={{ borderBottom: "1px solid grey" }}
                onClick={() => updateSelectedUsers(u)}
              >
                <div className="col">{allowables.splitName(u.name)}</div>
                <div className="col">{u.email}</div>
              </div>
            </React.Fragment>
          ))}
        </div>
      </BasicModal>
    </React.Fragment>
  );
};

export default MailingList;
