import React from "react";
import range from "lodash/range";
import PropTypes from "prop-types";

const Pagination = ({
  itemsCount,
  pageSize,
  onPageChange,
  currentPage,
  onPreviousPage,
  onNextPage,
}) => {
  const pageCount = itemsCount / pageSize;
  if (pageCount <= 1) return null;
  const pages = range(1, pageCount + 1);

  return (
    <nav aria-label="Page navigation">
      <ul className="pagination">
        <li
          key="previous"
          className={
            currentPage === 1 ? "page-item disabled" : "page-item clickable"
          }
        >
          <span className="page-link" onClick={() => onPreviousPage()}>
            &laquo;
          </span>
          <span className="sr-only">Previous</span>
        </li>
        {pages.map((page) => (
          <li key={page} className="page-item" style={{ cursor: "pointer" }}>
            <span
              className={
                "page-link" +
                (page === currentPage ? " page-link-selected" : "")
              }
              onClick={() => onPageChange(page)}
            >
              {page}
            </span>
          </li>
        ))}
        <li
          key="next"
          className={
            currentPage >= Math.ceil(pageCount)
              ? "page-item disabled"
              : "page-item clickable"
          }
        >
          <span className="page-link" onClick={() => onNextPage()}>
            &raquo;
          </span>
          <span className="sr-only">Next</span>
        </li>
      </ul>
    </nav>
  );
};

Pagination.propTypes = {
  itemsCount: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPreviousPage: PropTypes.func.isRequired,
  onNextPage: PropTypes.func.isRequired,
};

export default Pagination;
