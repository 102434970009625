import React from "react";
import { HexColorPicker, HexColorInput } from "react-colorful";

import FormLabel from "./formLabel";
import WarningHeader from "../pageComponents/warningHeader";

const ColorPicker = ({
  name,
  label,
  error,
  value,
  onChange,
  tooltip,
  paidFeature,
  disabled,
}) => {
  if (disabled)
    return (
      <div>
        <FormLabel
          infoTooltip={tooltip}
          paidFeature={paidFeature}
          label={label}
          htmlFor={name}
        />
        {value ? (
          <div
            className="color-square-large"
            style={{ backgroundColor: value }}
          />
        ) : (
          <p>No color selected</p>
        )}
      </div>
    );

  const pickerWidth = 200;

  return (
    <div className="form-group">
      <FormLabel
        infoTooltip={tooltip}
        paidFeature={paidFeature}
        label={label}
        htmlFor={name}
      />
      <HexColorPicker
        color={value}
        onChange={onChange}
        style={{
          width: pickerWidth,
          height: pickerWidth,
        }}
      />
      <HexColorInput
        color={value}
        onChange={onChange}
        style={{
          width: pickerWidth - 10,
          margin: 5,
          textAlign: "center",
        }}
      />
      {error && <WarningHeader>{error}</WarningHeader>}
    </div>
  );
};

export default ColorPicker;
