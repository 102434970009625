import React from "react";

import BasicModal from "../../common/pageComponents/basicModal";
import allowables from "../../../utils/allowables";
import FinePrint from "../../contactPolicies/finePrint";
import FinalTotals from "./finalTotals";
import { getTierDescription } from "../../../utils/paymentsUtil";
import MiniHeader from "../../common/pageComponents/miniHeader";

const ConfirmSubscriptionModal = ({
  selectedTier,
  currentTier,
  customer,
  onUpdateSubscription,
  confirmOpen,
  closeConfirm,
  cancel,
  amountToCharge,
}) => {
  const defaultCard = customer && customer.defaultCard;
  const revert = cancel || selectedTier.id === "free";

  return (
    <BasicModal isOpen={confirmOpen} onClose={closeConfirm}>
      <MiniHeader>
        {(revert ? "Cancel" : "Confirm") +
          (currentTier && !revert ? " Update to" : !revert ? " New" : "") +
          " Subscription"}
      </MiniHeader>
      <div className="form-divided-section">
        <div>
          {currentTier ? (
            <React.Fragment>
              <h5 className="text-center">Current Tier</h5>
              <h6>{getTierDescription(currentTier)}</h6>
              <div>
                {currentTier.cancel_at_period_end
                  ? "Will end on "
                  : "Next bills on "}
                {
                  allowables.dateTime(
                    currentTier.current_period_end * 1000,
                    true
                  ).date
                }
              </div>
              <hr />
            </React.Fragment>
          ) : (
            <div />
          )}
          <h4 className="text-center">Selected Tier</h4>
          {currentTier ? (
            revert ? (
              <React.Fragment>
                <h5>Revert to free tier after current period ends</h5>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <h5>{getTierDescription(selectedTier)}</h5>
                {currentTier.id === selectedTier.id &&
                currentTier.cycle === selectedTier.cycle ? (
                  <div>Continue subscription</div>
                ) : (
                  <div>
                    {currentTier.id !== selectedTier.id
                      ? currentTier.name.charAt(0) < selectedTier.name.charAt(0)
                        ? "Upgrade Subscription"
                        : "Downgrade Subscription"
                      : `Change billing cycle to ${selectedTier.cycle}`}
                  </div>
                )}
              </React.Fragment>
            )
          ) : selectedTier.id !== "free" ? (
            <React.Fragment>
              <h5>{getTierDescription(selectedTier)}</h5>
            </React.Fragment>
          ) : (
            <div />
          )}
        </div>
        <hr />
        <FinePrint closeFinePrint={closeConfirm} />
        <hr />
        <FinalTotals
          amounts={amountToCharge}
          card={defaultCard}
          revert={revert}
        />
        <hr />
        {(currentTier || selectedTier.id !== "free") && (
          <button
            className={"btn btn-md btn-block btn-" + (revert ? "dark" : "info")}
            onClick={onUpdateSubscription}
          >
            {revert ? "Revert to free tier" : "Subscribe"}
          </button>
        )}
      </div>
    </BasicModal>
  );
};

export default ConfirmSubscriptionModal;
