import React from "react";

const PasswordRequirements = () => {
  return (
    <div>
      <small>
        <b>Password Requirements</b>
        <br />
        <ul>
          <li>Minimum 8 characters</li>
          <li>One lower case letter</li>
          <li>One upper case letter</li>
          <li>One number</li>
        </ul>
      </small>
    </div>
  );
};

export default PasswordRequirements;
