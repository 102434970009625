import React, { useState } from "react";

import { mapSoccerEvents } from "../../utils/soccerFunctions";
import { mapFootballEvents } from "../../utils/footballFunctions";
import { mapDiamondEvents } from "../../utils/diamondFunctions";
import ListSection from "../common/pageComponents/listSection";
import CustomConfirm from "./../common/customs/customConfirm";

const EventsTab = ({
  chronoEventsNames,
  match,
  sport,
  onUndo,
  onEdit,
  spectate,
}) => {
  const [dialog, setDialog] = useState("");
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [alertRemoveOpen, setAlertRemoveOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const header =
    chronoEventsNames.length > 0
      ? "Click Event to Edit or Remove"
      : "You haven't added any events yet";
  const mapFunction = sport.toLowerCase().includes("soccer")
    ? mapSoccerEvents
    : sport.toLowerCase().includes("football")
    ? mapFootballEvents
    : sport.toLowerCase().includes("diamond")
    ? mapDiamondEvents
    : () => {
        return [];
      };

  const handleAlert = (event) => {
    if (spectate) return;
    setSelectedEvent(event);
    setDialog(event.text);
    setConfirmOpen(true);
  };

  const handleOptionClick = (option) => {
    switch (option.label) {
      case "Edit":
        onEdit(selectedEvent);
        break;
      case "Remove":
        handleAlertRemove();
        break;
      default:
    }

    setConfirmOpen(false);
  };

  const handleAlertRemove = () => {
    setDialog(`REMOVE: ${selectedEvent.text}`);
    setAlertRemoveOpen(true);
  };

  let alertOptions = [
    { label: "Cancel", className: "btn btn-sm btn-dark" },
    { label: "Edit", className: "btn btn-sm btn-light" },
    { label: "Remove", className: "btn btn-sm btn-info" },
  ];

  return (
    <div>
      {!spectate && (
        <h5 className="text-center">
          <b>{header}</b>
        </h5>
      )}
      <ListSection
        items={mapFunction(match, chronoEventsNames)}
        onClick={handleAlert}
        ariaLabel="match-event"
        spectate={spectate}
        splitLines={"%s%"}
      />
      <CustomConfirm
        dialog={dialog}
        isOpen={confirmOpen}
        callback={handleOptionClick}
        close={setConfirmOpen}
        options={alertOptions}
        split="%s%"
        centerAllText
      />
      <CustomConfirm
        dialog={dialog}
        isOpen={alertRemoveOpen}
        close={setAlertRemoveOpen}
        callback={() => onUndo(selectedEvent)}
        split="%s%"
        centerAllText
      />
    </div>
  );
};

export default EventsTab;
