import http from "./httpService";
import { getCurrentUser } from "./userService";
import { callbackProgress } from "../utils/progress";

export async function getOrg(callback) {
  const user = getCurrentUser();
  try {
    return await http.get(
      http.orgEndpoint + "/user/" + user._id,
      callback && {
        onDownloadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function getOrgbyID(id, callback) {
  try {
    return await http.get(
      http.orgEndpoint + "/" + id,
      callback && {
        onDownloadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function saveOrg(orgID, org, callback) {
  let user = getCurrentUser();
  org.ownerID = user._id;
  org.ownerName = user.name;
  org.ownerEmail = user.email;
  if (orgID === "new") {
    user.role = "adminowner";
    let existingOrg = await getOrg();
    if (existingOrg.status === 200)
      return { status: 400, data: "User already has org." };
    try {
      return await http.post(
        http.orgEndpoint,
        org,
        callback && {
          onUploadProgress: function (progress) {
            callbackProgress(progress, callback);
          },
        }
      );
    } catch (ex) {
      return ex.response;
    }
  } else {
    return { status: 400, data: "Cannot edit org" };
  }
}

export async function updateOrg(org, callback) {
  const user = getCurrentUser();
  org._id = user.orgID;
  org.name = user.orgName;
  org.ownerID = user._id;
  org.ownerName = user.name;
  org.ownerEmail = user.email;
  try {
    return await http.put(
      http.orgEndpoint + "/" + org._id,
      org,
      callback && {
        onUploadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function updateCodeOfConduct(codeOfConduct) {
  try {
    return await http.put(
      http.orgEndpoint + "/update/codeofconduct",
      codeOfConduct
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function updateWebpage(webpage) {
  try {
    return await http.put(http.orgEndpoint + "/update/webpage", webpage);
  } catch (ex) {
    return ex.response;
  }
}

export async function updateRefereeCode(refereeCode) {
  try {
    return await http.put(http.orgEndpoint + "/update/refereecode", {
      refereeCode,
    });
  } catch (ex) {
    return ex.response;
  }
}

export async function purgeOrg(user, callback) {
  try {
    return await http.delete(
      http.orgEndpoint + "/purge/" + user.orgID,
      callback && {
        onDownloadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function sendAccountAuth(email, link, regType, callback) {
  try {
    return await http.post(
      http.orgEndpoint + "/newaccount",
      {
        email,
        link,
        regType,
      },
      callback && {
        onUploadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function getPendingAccountInfo(orgID, callback) {
  try {
    return await http.get(
      http.orgEndpoint + "/pendingaccounts/" + orgID,
      callback && {
        onDownloadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function removePendingAccount(account, callback) {
  try {
    return await http.put(
      http.orgEndpoint + "/pendingaccounts",
      account,
      callback && {
        onUploadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function createGettingStartedData(data, callback) {
  try {
    return await http.post(
      http.orgEndpoint + "/gettingstarted",
      data,
      callback && {
        onUploadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}
