import React, { useContext } from "react";
import HeaderContext from "../../../context/headerContext";
import toast from "../../../utils/toast";
import { addCustomer } from "../../../services/paymentService";

const NoSuchCustomer = ({ onAddSuccessCallback }) => {
  const { setLoading } = useContext(HeaderContext);

  const handleAddCustomer = async () => {
    setLoading(true);
    const res = await addCustomer();
    if (res.status === 200) {
      toast.success(res.data);
      return onAddSuccessCallback();
    } else toast.error(res.data);
    setLoading(false);
  };

  return (
    <div className="pop-box">
      <div className="text-center">
        It looks like your league isn't on our Stripe customer list. Click the
        button below to create an account to get started.
      </div>
      <br />
      <button className="btn btn-info btn-block" onClick={handleAddCustomer}>
        Add League to Customer List
      </button>
    </div>
  );
};

export default NoSuchCustomer;
