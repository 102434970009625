import React from "react";
import MiniHeader from "../pageComponents/miniHeader";

const ComponentLoading = ({ header }) => {
  return (
    <div>
      {header ? (
        <MiniHeader className="text-center">{header}</MiniHeader>
      ) : null}
      <div className="loading-line" />
      <div className="loading-line" />
      <div className="loading-line" />
    </div>
  );
};

export default ComponentLoading;
