import React from "react";

import PaymentStatus from "./paymentStatus";
import CodeOfConductStatus from "./codeOfConductStatus";
import VaccineStatus from "./vaccineStatus";
import ImageStatus from "./imageStatus";
import MiniHeader from "../common/pageComponents/miniHeader";
import NextMatchStatus from "./nextMatchStatus";

const ProfileStatuses = ({ org, player, user, nextMatch }) => {
  if (!user?._id || !user?.orgID) return null;
  return (
    <div>
      <MiniHeader>Next Match Checklist</MiniHeader>
      <div className="form-divided-section">
        <PaymentStatus org={org} player={player} user={user} />
        <NextMatchStatus nextMatch={nextMatch} user={user} />
        <CodeOfConductStatus org={org} user={user} />
        <VaccineStatus org={org} user={user} />
        <ImageStatus org={org} user={user} />
      </div>
    </div>
  );
};

export default ProfileStatuses;
