import React, { useState, useEffect, useContext } from "react";
import toast from "../../utils/toast";

import CodeOfConduct from "./codeOfConduct";
import { getPlayers } from "../../services/playerService";
import {
  getUsersForOrg,
  getCurrentUser,
  updateUserInfo,
  getUserLocation,
} from "../../services/userService";
import { updateCodeOfConduct } from "../../services/orgService";
import CodeOfConductUserList from "./codeOfConductUserList";
import CodeOfConductModal from "./codeOfConductModal";
import Checkbox from "../common/form/checkBox";
import SearchBox from "../common/dataSorting/searchBox";
import MailingList from "../common/dataSorting/mailingList";
import allowables from "../../utils/allowables";
import CustomConfirm from "../common/customs/customConfirm";
import NonFormInput from "../common/form/nonFormInput";
import PageBottom from "../common/pageComponents/pageBottom";
import HeaderContext from "../../context/headerContext";
import CheckboxGroup from "../common/dataSorting/checkboxGroup";
import useWindowDimensions from "../../utils/useWindowDimensions";
import WarningHeader from "../common/pageComponents/warningHeader";

const CodeofConductInfo = ({ org, history }) => {
  const { setLoading, refreshOrg } = useContext(HeaderContext);
  const { width } = useWindowDimensions();

  const [players, setPlayers] = useState([]);
  const [cocOpen, setCocOpen] = useState(false);
  const [query, setQuery] = useState("");
  const [user, setUser] = useState();
  const [checked, setChecked] = useState(false);
  const [cocEditOpen, setCocEditOpen] = useState(false);
  const [typedName, setTypedName] = useState("");
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [selectedTypes, setSelectedTypes] = useState({
    accepted: false,
    notAccepted: true,
    noUser: true,
  });
  const [userLocation, setUserLocation] = useState({ retrieved: false });

  const load = async (user) => {
    setLoading(true);
    if (user?.role.includes("admin")) {
      const playersRes = await getPlayers();
      const usersRes = await getUsersForOrg();
      if (playersRes.status === 200) {
        if (usersRes.status === 200) {
          let players = [];
          playersRes.data.forEach((p) => {
            let player = { ...p };
            let user = usersRes.data.find(
              (u) => (u.playerID?._id || u.playerID) === p._id
            );
            if (user) {
              player.email = user.email;
              player.codeOfConduct = user.codeOfConduct;
            }
            players.push(player);
          });
          setPlayers(players);
        } else toast.error(usersRes.data);
      } else toast.error(playersRes.data);
    } else {
      getUserLocation((pos) => setUserLocation(pos));
      setCocOpen(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    const user = getCurrentUser();
    setUser(user);
    load(user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkCocAcceptance = () => {
    if (!org || !org.codeOfConduct || org.codeOfConduct.length === 0)
      return true;
    return (
      org.codeOfConduct &&
      org.codeOfConduct.length > 0 &&
      user.codeOfConduct &&
      user.codeOfConduct.accepted
    );
  };

  const handleAcceptCode = async () => {
    if (!checked)
      return toast.error(
        "Please check the box indicating you have read and accept the code of conduct"
      );
    setLoading(true);
    let updatedLocation = { ...userLocation };
    if (!updatedLocation.retrieved)
      getUserLocation((pos) => (updatedLocation = pos));

    user.codeOfConduct = {
      accepted: true,
      date: new Date(),
      typedName,
      location: userLocation,
    };
    const res = await updateUserInfo(user);
    if (res.status !== 200) {
      user.codeOfConduct = null;
      toast.error(res.data);
    }
    setLoading(false);
  };

  const doSubmit = async (codeOfConduct) => {
    setLoading(true);
    const res = await updateCodeOfConduct(codeOfConduct);
    if (res.status === 200) {
      toast.success("Code of Conduct Updated");
      await refreshOrg();
      setCocEditOpen(false);
      setCocOpen(true);
      return await load(getCurrentUser());
    } else toast.error(res.data);
    setLoading(false);
  };

  const getPageData = () => {
    let filtered = [...players];
    if (query)
      filtered = players.filter(
        (p) =>
          p.name
            .split("%20%")
            .join(" ")
            .toLowerCase()
            .includes(query.toLowerCase()) ||
          (p.email && p.email.toLowerCase().includes(query.toLowerCase()))
      );

    filtered = filtered.filter((p) => {
      let select = false;
      if (
        (selectedTypes.accepted &&
          p.codeOfConduct &&
          p.codeOfConduct.accepted) ||
        (selectedTypes.notAccepted &&
          p.userID &&
          (!p.codeOfConduct || !p.codeOfConduct.accepted)) ||
        (selectedTypes.noUser && !p.userID)
      )
        select = true;
      return select;
    });

    filtered.sort((a, b) => {
      let move = 0;
      if (
        a.codeOfConduct &&
        b.codeOfConduct &&
        a.codeOfConduct.date > b.codeOfConduct.date
      )
        move = -1;
      if (a.codeOfConduct && !b.codeOfConduct) move = 1;
      return move;
    });

    return filtered;
  };

  return (
    <React.Fragment>
      {user?.role.includes("admin") && (
        <React.Fragment>
          <button
            className="btn btn-block btn-sm btn-info"
            onClick={() => setCocEditOpen(true)}
          >
            Edit Code of Conduct
          </button>
          <br />
          {org.codeOfConduct && org.codeOfConduct.length > 0 ? (
            <div className="row">
              <div className="col">
                <button
                  className="btn btn-block btn-sm btn-light"
                  onClick={() => setCocOpen(cocOpen ? false : true)}
                >
                  {cocOpen ? "Hide" : "Show"} Code of Conduct
                </button>
              </div>
              <div className="col-2">
                <MailingList
                  users={getPageData()}
                  buttonTitle="Create Mailing List"
                />
              </div>
            </div>
          ) : (
            <WarningHeader>
              You have not set up your code of conduct
            </WarningHeader>
          )}
        </React.Fragment>
      )}
      {user?.role?.includes("player") && !checkCocAcceptance() ? (
        <WarningHeader>You have not accepted the code of conduct</WarningHeader>
      ) : null}
      {cocOpen && (
        <React.Fragment>
          <CodeOfConduct org={org} />
        </React.Fragment>
      )}
      {user?.role.includes("player") && !checkCocAcceptance() ? (
        <div>
          <div className="row">
            <div className="col">
              <Checkbox
                onChange={setChecked}
                value={checked}
                label="Check Box to Acknowledge"
              />
            </div>
            <div className="col" style={{ marginTop: 5 }}>
              {checked && (
                <button
                  className="btn btn-md btn-info"
                  onClick={() => setConfirmOpen(true)}
                >
                  Accept Code of Conduct
                </button>
              )}
            </div>
          </div>
          <CustomConfirm
            dialog={`Please type your name below to indicate your acceptance of this code of conduct.%
            You must type your name exactly as it appears here or you will not be able to continue.`}
            callback={handleAcceptCode}
            isOpen={confirmOpen}
            close={setConfirmOpen}
            holdSubmitButton={{
              enteredValue: typedName.toLowerCase(),
              mustMatchValue: allowables.splitName(user.name).toLowerCase(),
            }}
            split="%"
          >
            <NonFormInput
              name="typedName"
              type="text"
              label={`${allowables.splitName(user.name)}`}
              value={typedName}
              onChange={(event) => setTypedName(event.target.value)}
            />
          </CustomConfirm>
        </div>
      ) : user?.role.includes("player") ? (
        <React.Fragment>
          {checkCocAcceptance() &&
            user.codeOfConduct &&
            user?.codeOfConduct.date && (
              <h5 className="text-center">
                You accepted this Code of Conduct on{" "}
                {allowables.dateTime(user.codeOfConduct.date).date}
              </h5>
            )}
        </React.Fragment>
      ) : null}
      {user?.role.includes("admin") && (
        <div>
          <CheckboxGroup
            header="Filter Data"
            onChange={(id) => {
              let currentTypes = { ...selectedTypes };
              currentTypes[id] = !currentTypes[id];
              setSelectedTypes(currentTypes);
            }}
            containerClassName="pop-box"
            colCount={width > 500 ? 3 : 1}
            checkboxes={[
              {
                id: "accepted",
                value: selectedTypes.accepted,
                label: "Accepted",
              },
              {
                id: "notAccepted",
                value: selectedTypes.notAccepted,
                label: "Not Accepted",
              },
              {
                id: "noUser",
                value: selectedTypes.noUser,
                label: "Player without User",
              },
            ]}
          />
          <SearchBox
            value={query}
            onChange={setQuery}
            placeholder="Search by player name or email..."
          />
          <CodeOfConductUserList
            players={getPageData()}
            users={!selectedTypes.noUser}
            history={history}
          />
          <CodeOfConductModal
            onClose={setCocEditOpen}
            isOpen={cocEditOpen}
            org={org}
            doSubmit={doSubmit}
          />
        </div>
      )}
      <PageBottom />
    </React.Fragment>
  );
};

export default CodeofConductInfo;
