import React from "react";
import { IconContext } from "react-icons";

const ListSection = ({ items, onClick, ariaLabel, spectate, splitLines }) => {
  const renderText = (text) => {
    if (splitLines) {
      const split = text.split(splitLines);
      return split.map((line, idx) => (
        <React.Fragment key={idx}>
          {line}
          {idx < split.length && <br />}
        </React.Fragment>
      ));
    }
    return text;
  };

  return (
    <React.Fragment>
      {items.map((e, i) => {
        const even = i % 2 === 0;
        return (
          <React.Fragment key={i}>
            <div
              className={`row ${onClick && !spectate ? "clickable" : ""} ${
                even ? "light-bg" : ""
              }`}
              onClick={onClick ? () => onClick(e) : () => {}}
              style={{
                marginLeft: 15,
                marginRight: 15,
                paddingTop: 10,
                paddingBottom: 10,
              }}
              aria-label={ariaLabel}
            >
              {e.icon && e.icon.icon && (
                <IconContext.Provider value={{ className: e.icon.color }}>
                  <div className="col-3 text-center">
                    <e.icon.icon />
                  </div>
                </IconContext.Provider>
              )}
              <div className="col text-left">{renderText(e.text)}</div>
            </div>
            <div
              className="muted-bg"
              style={{ height: 5, marginLeft: 15, marginRight: 15 }}
            />
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

export default ListSection;
