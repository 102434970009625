import React from "react";
import MiniHeader from "../common/pageComponents/miniHeader";

const HomepageTextSection = ({ header, text }) => {
  return (
    <div className="centered-small-input-area">
      <MiniHeader>{header}</MiniHeader>
      <div className="form-divided-section">{text}</div>
    </div>
  );
};

export default HomepageTextSection;
