import React, { useContext } from "react";

import OrgContext from "../../context/orgContext";
import allowables from "../../utils/allowables";

const EventImage = ({ player, size = 50, borderRadius = 10, fromUser }) => {
  const org = useContext(OrgContext);
  if (org && org.misc && org.misc.userImages === "No") return null;

  const getInitials = (name) => {
    const split = name.split("%20%");
    return (
      split[0].charAt(0).toUpperCase() +
      (split[1] ? split[1].charAt(0).toUpperCase() : "")
    );
  };

  const getName = () => {
    if (player && player.name) {
      const initials = getInitials(player.name);
      return initials;
    }
  };

  const imageStyle = {
    width: size,
    height: size,
    borderRadius: borderRadius,
    margin: "auto",
    fontSize: size / 2.5,
  };

  function getFallbackImage(event) {
    event.target.src = allowables.fallbackImage();
  }

  let imageLocation = player?.profileID?.hasAvatar || player?.image;
  if (
    !imageLocation ||
    player?.profileID?.hasAvatar?.toLowerCase().includes("blocked")
  )
    imageLocation = fromUser ? player?.playerID?.image : player?.image;

  if (imageLocation) {
    return (
      <div style={imageStyle}>
        <img
          onError={(e) => getFallbackImage(e)}
          src={
            "https://storage.googleapis.com/ultimatescoreboard-userimages/" +
            imageLocation +
            ".png"
          }
          alt={player.name ? getInitials(player.name) : "img"}
          style={imageStyle}
        />
      </div>
    );
  } else if (getName())
    return (
      <div
        className="bg-info round-corners text-center"
        style={{
          ...imageStyle,
          padding: size / 6,
        }}
      >
        <b>{getName()}</b>
      </div>
    );
  else return null;
};

export default EventImage;
