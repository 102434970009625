import React, { Component } from "react";

import Tooltip from "../common/tooltip/tooltip";
import Table from "../common/table/table";
import IconRender from "../common/icons/iconRender";
import { getCurrentUser } from "../../services/userService";
import allowables from "../../utils/allowables";
import TooltipButton from "../common/tooltip/tooltipButton";
import { CLink } from "../common/customs/customLinks";

class LiveScorecardsTable extends Component {
  state = {
    teams: allowables.teams(getCurrentUser().sport),
  };

  columns = [
    {
      path: "dateTime",
      label: "Match Date",
      content: (m) =>
        m.dateTime ? (
          <Tooltip content={<span>All times are local</span>} direction="down">
            <p>
              {allowables.dateTime(m.dateTime).date}{" "}
              <b>{allowables.dateTime(m.dateTime).time}</b>
            </p>
          </Tooltip>
        ) : null,
    },
    {
      path: this.state.teams[0] + "TeamName",
      label:
        this.state.teams[0].charAt(0).toUpperCase() +
        this.state.teams[0].slice(1) +
        " Team",
      content: (m) => m[this.state.teams[0] + "TeamName"],
    },
    {
      path: this.state.teams[0] + "TeamScore",
      label: "",
    },
    {
      path: this.state.teams[1] + "TeamScore",
      label: "",
    },
    {
      path: this.state.teams[1] + "TeamName",
      label:
        this.state.teams[1].charAt(0).toUpperCase() +
        this.state.teams[1].slice(1) +
        " Team",
      content: (m) => m[this.state.teams[1] + "TeamName"],
    },
    {
      path: "refereeName",
      label: allowables.refOrUmp(getCurrentUser().sport),
      content: (m) => allowables.splitName(m.refereeName),
    },
  ];

  removeButton = {
    key: "delete",
    content: (scorecard) => (
      <TooltipButton
        onClick={() => this.props.onRemoveScorecard(scorecard)}
        buttonClass="btn btn-dark btn-sm"
        tooltipText={`Remove this scorecard`}
        tooltipDirection="left"
      >
        <IconRender name="delete" />
      </TooltipButton>
    ),
  };

  constructor(props) {
    super(props);
    const user = getCurrentUser();
    if (user.role.includes("admin") && !this.props.isScoreboard)
      this.columns.push(this.removeButton);
    else if (!this.props.isScoreboard)
      this.columns.unshift({
        path: "view",
        label: "",
        content: (m) => (
          <CLink path={`/matches?q=${m._id}`}>
            <IconRender name="info" className="icon-md icon-mobile" />
          </CLink>
        ),
      });
  }

  render() {
    return (
      <Table
        columns={this.columns}
        data={this.props.scorecards}
        keyProperty={"_id"}
        tableClass="table-custom"
        headerClass="thead-custom"
        bodyClass="tbody-custom"
        highlightOnHover={true}
      />
    );
  }
}

export default LiveScorecardsTable;
