import React, { useState, useEffect, useContext } from "react";

import { unsubscribe } from "../services/userService";
import HeaderContext from "../context/headerContext";
import allowables from "../utils/allowables";

const Unsubscribe = () => {
  const { setLoading } = useContext(HeaderContext);

  const [unsubscribed, setUnsubscribed] = useState(false);

  const doUnsubscribe = async () => {
    setLoading(true);
    const res = await unsubscribe(window.location.search.slice(1));
    if (res.status === 200) setUnsubscribed(true);
    else setUnsubscribed(false);
    setLoading(false);
  };

  useEffect(() => {
    doUnsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <h3 className="text-center">
        <b>Unsubscribe</b>
      </h3>
      {unsubscribed ? (
        <p className="text-center">
          You have been unsubscribed from the mailing list
        </p>
      ) : (
        <div>
          <p className="text-center">
            Unsubscribing was unsuccessful. Please reload the page to try again.
            If the problem persists please contact{" "}
            <a href={`mailto:${allowables.supportEmail}`}>
              {allowables.supportEmail}
            </a>
          </p>
          <button
            className="btn btn-block btn-info"
            onClick={() => window.location.reload()}
          >
            Reload
          </button>
        </div>
      )}
    </div>
  );
};

export default Unsubscribe;
