import React from "react";
import Form from "../common/form/form";
import Joi from "joi-browser";
import toast from "../../utils/toast";

import { updateWebpage } from "./../../services/orgService";
import { removeWebpageImage } from "../../services/ftpService";
import ImagePickers from "./imagePickers";
import HeaderContext from "../../context/headerContext";
import MiniHeader from "../common/pageComponents/miniHeader";

class SetUpForm extends Form {
  static contextType = HeaderContext;
  state = {
    data: {
      header: "",
      text: "",
      videoUrl: "",
      logoBgColor: "#ffffff",
      themeTextColor: "#000000",
      showFreeAgentLink: false,
    },
    baseState: {
      header: "",
      text: "",
      videoUrl: "",
      logoBgColor: "",
      themeTextColor: "",
      showFreeAgentLink: false,
    },
    errors: {},
  };

  componentDidMount(webpage) {
    const updatedWebpage = webpage || this.props.pageOrg?.webpage;
    if (updatedWebpage) {
      this.setState({
        data: this.mapToViewModel(updatedWebpage),
        baseState: updatedWebpage,
      });
    }
  }

  mapToViewModel = (webpage) => {
    return {
      header: webpage?.header || "",
      text: (webpage?.text && webpage.text.split("%20%").join("\n")) || "",
      videoUrl: webpage?.videoUrl || "",
      logoBgColor: webpage?.logoBgColor || "",
      themeTextColor: webpage?.themeTextColor || "",
      showFreeAgentLink: webpage?.showFreeAgentLink || false,
    };
  };

  schema = {
    header: Joi.string().optional().allow("").label("Header/Slogan"),
    text: Joi.string().required().max(3000).label("Text"),
    videoUrl: Joi.string().optional().allow("").label("Youtube Video URL"),
    logoBgColor: Joi.string()
      .required()
      .min(7)
      .max(7)
      .label("Theme Background Color"),
    themeTextColor: Joi.string()
      .required()
      .min(7)
      .max(7)
      .label("Theme Background Color"),
    showFreeAgentLink: Joi.boolean().optional().label("Show Free Agent Link"),
  };

  handleDeleteImage = async (imageName, location) => {
    this.context.setLoading(true);
    this.context.setProgress([1]);
    const res = await removeWebpageImage(imageName, location);
    if (res.status === 200) {
      toast.success(res.data);
      await this.props.onRefresh(this.props.location.pathname.slice(1));
      this.componentDidMount();
    } else toast.error(res.data);
    this.context.setLoading(false);
  };

  doSubmit = async () => {
    let { data } = this.state;
    const text = data.text.split("\n").join("%20%");
    data.text = text;
    let org = { ...this.props.pageOrg };
    data.images = org.webpage ? org.webpage.images : [];
    data.bannerImage = org.webpage ? org.webpage.bannerImage : "";
    this.context.setLoading(true);
    this.context.setProgress([1]);
    const res = await updateWebpage(data);
    if (res.status === 200) {
      toast.success("Updated");
      const org = await this.props.onRefresh(
        this.props.location.pathname.slice(1)
      );
      this.componentDidMount(org.webpage);
    } else toast.error(res.data);
    this.context.setLoading(false);
  };

  render() {
    return (
      <div>
        <MiniHeader>Edit League Homepage</MiniHeader>
        <div className="form-divided-section">
          <p className="text-center">
            As the league owner you have sole access to set up this page. To see
            how your saved changes have affected the page click the home tab.
          </p>
          <br />
          <ImagePickers
            org={this.props.pageOrg}
            onDeleteImage={this.handleDeleteImage}
          />
          <br />
          <br />
          <form onSubmit={this.handleSubmit}>
            <div className="row">
              <div className="col">
                {this.renderColorPicker(
                  "logoBgColor",
                  "Theme Background Color",
                  "",
                  {
                    header: "Theme Background Color",
                    text: `This color will be the background for your webpage.
              If the color you want is not displayed you can enter any 6 digit hexadecimal color.`,
                    direction: "right",
                    className: "icon-info",
                  }
                )}
              </div>
              <div className="col">
                {this.renderColorPicker(
                  "themeTextColor",
                  "Theme Highlight Color",
                  "",
                  {
                    header: "Theme Highlight Color",
                    text: `This color will be the used as the highlight for your buttons on your webpage`,
                    direction: "right",
                    className: "icon-info",
                  }
                )}
              </div>
            </div>
            {this.renderInput("header", "Header/Slogan", "", "", "", "", "", {
              header: "Header/Slogan",
              text: `This will display just below your logo and selection buttons`,
              direction: "right",
              className: "icon-info",
            })}
            {this.renderTextArea("text", "Body Text", "", "8", {
              header: "Body Text",
              text: `This is the main text for your page, it will display below your images on the left side.
            Enter text exactly as you want it displayed, all line breaks will be maintained`,
              direction: "right",
              className: "icon-info",
            })}
            {this.renderSwitch(
              "showFreeAgentLink",
              "Show Free Agent Link",
              "",
              {
                header: "Show Free Agent Link",
                text: `The link for a player to sign up for your League as a free agent will display on the webpage.`,
                direction: "right",
                className: "icon-info",
              }
            )}
            {this.renderInput("videoUrl", "Video URL", "", "", "", "", "", {
              header: "Video URL",
              text: `Link a video here and have it embed on your page.
            Most popular video sharing sites can be embedded, such as YouTube, Facebook, Twitch, etc.
            If you aren't sure if a video will embed try your URL anyway, you can always change it later.`,
              direction: "right",
              className: "icon-info",
            })}
            {this.renderValidatedButton("Save Changes")}
          </form>
        </div>
      </div>
    );
  }
}

export default SetUpForm;
