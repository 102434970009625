import http from "./httpService";
import { getRefereeCode } from "./userService";

const route = (byCode) => (byCode ? "/bycode/" : "/byauth/");

export async function startLiveScorecard(matchID) {
  const code = getRefereeCode();
  try {
    return await http.post(
      http.liveScoreEndpoint + route(code) + matchID,
      code
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function removeLiveScorecard(matchID) {
  const code = getRefereeCode();
  try {
    return await http.put(
      http.liveScoreEndpoint + "/removescorecard" + route(code) + matchID,
      code
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function sendEventToLiveScorecard(matchID, scorecard) {
  const code = getRefereeCode();
  try {
    return await http.put(
      http.liveScoreEndpoint + "/event" + route(code) + matchID,
      {
        scorecard,
        refereeCode: code,
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function removeEventFromLiveScorecard(matchID, scorecard) {
  const code = getRefereeCode();
  try {
    return await http.put(
      http.liveScoreEndpoint + "/removeevent" + route(code) + matchID,
      {
        scorecard,
        refereeCode: code,
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function syncLiveScorecard(matchID, scorecard) {
  const code = getRefereeCode();
  try {
    return await http.put(
      http.liveScoreEndpoint + "/sync" + route(code) + matchID,
      {
        scorecard,
        refereeCode: code,
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function getLiveScorecard(matchID, authed, extension) {
  try {
    if (authed)
      return await http.get(http.liveScoreEndpoint + "/byauth/" + matchID);
    else
      return await http.get(
        http.liveScoreEndpoint + "/byextension/" + extension + "/" + matchID
      );
  } catch (ex) {
    return ex.response;
  }
}

export async function getAllLiveScorecards() {
  try {
    return await http.get(http.liveScoreEndpoint);
  } catch (ex) {
    return ex.response;
  }
}

export async function getAllLiveScorecardsByExtension(extension) {
  try {
    return await http.get(
      http.liveScoreEndpoint + "/all/byextension/" + extension
    );
  } catch (ex) {
    return ex.response;
  }
}
