import React from "react";

const WebpageDivider = ({ color }) => {
  return (
    <div
      style={{ borderTop: "3px solid " + (color || "#831fe0"), margin: 15 }}
    />
  );
};

export default WebpageDivider;
