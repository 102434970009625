import React from "react";
import Modal from "react-modal";

import { popupStyle } from "../../../utils/styleUtil";

const CustomAlert = ({
  dialog,
  callback,
  isOpen,
  close,
  id,
  split,
  children,
}) => {
  const answered = (answer) => {
    if (answer && callback) callback();
    close(id ? id : false);
  };

  const text = dialog && split ? dialog.split(split) : [dialog];

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => {
        close(id ? id : false);
      }}
      closeOnDocumentClick
      ariaHideApp={false}
      style={popupStyle}
    >
      <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
        {text.map((t, i) => (
          <React.Fragment key={i}>
            <div className={i === text.length - 1 ? "text-center" : ""} key={i}>
              {t}
            </div>
            {i !== text.length - 1 && <br />}
          </React.Fragment>
        ))}
        <br />
        {children && (
          <React.Fragment>
            {children}
            <br />
          </React.Fragment>
        )}
        <div
          className="row"
          style={{ paddingLeft: "10px", paddingRight: "10px" }}
        >
          <div className="col text-center">
            <button
              className={`btn btn-sm btn-${children ? "dark" : "info"}`}
              onClick={() => answered(true)}
              autoFocus
            >
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{children ? "Cancel" : "OK"}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CustomAlert;
