import React, { Component } from "react";
import toast from "../../utils/toast";

import { getDivisions } from "../../services/divisionService";
import { getTeamsByDivision } from "../../services/teamService";
import {
  createAndSaveSchedule,
  getStandings,
} from "../../services/matchService";
import { getReferees } from "../../services/userService";
import NonFormSelect from "./../common/form/nonFormSelect";
import CombinedCreateForm from "./combinedCreateForm";
import { CLink } from "../common/customs/customLinks";
import QuickLinks from "../common/pageComponents/quickLinks";
import HorizontalButtons from "../common/dataSorting/horizontalButtons";
import HeaderContext from "../../context/headerContext";
import MiniHeader from "../common/pageComponents/miniHeader";
import { getFields } from "../../services/fieldService";

const sections = [
  { id: "division", name: "Create Regular Season Matches" },
  { id: "playoff", name: "Create Playoff Matches" },
  { id: "group", name: "Create Group Matches" },
];
class CreateHome extends Component {
  static contextType = HeaderContext;
  state = {
    divisions: [],
    divisionID: "",
    referees: [],
    groups: [],
    teams: [],
    teamsByGroup: {},
    standings: [],
    schedule: sections[0],
    fields: [],
  };

  async componentDidMount() {
    this.context.setLoading(true);
    this.context.setProgress([1, 1, 1]);
    const divisionsRes = await getDivisions({
      callback: this.indicateProgress,
      bar: 0,
    });
    const refereesRes = await getReferees({
      callback: this.indicateProgress,
      bar: 1,
    });
    const fieldsRes = await getFields({
      callback: this.indicateProgress,
      bar: 2,
    });
    if (divisionsRes.status === 200) {
      if (refereesRes.status === 200) {
        if (fieldsRes.status === 200) {
          this.setState({
            divisions: divisionsRes.data,
            referees: refereesRes.data,
            fields: fieldsRes.data,
          });
        } else toast.error(fieldsRes.data);
      } else toast.error(refereesRes.data);
    } else toast.error(divisionsRes.data);

    this.context.setLoading(false);
  }

  indicateProgress = (progress, location) => {
    let { progress: currentProgress } = this.context;
    currentProgress[location.bar] =
      ((progress.loaded / progress.total) * 100) / currentProgress.length;
    this.context.setProgress(currentProgress);
  };

  handleDivisionChange = async (event) => {
    const { value: divisionID } = event.target;
    if (!divisionID) return;
    this.context.setLoading(true);
    this.context.setProgress([1]);
    const teamsRes = await getTeamsByDivision(divisionID, {
      callback: this.indicateProgress,
      bar: 0,
    });
    const standingsRes = await getStandings(divisionID);
    if (teamsRes.status === 200) {
      if (standingsRes.status === 200) {
        let groups = [];
        let teamsByGroup = {};
        teamsRes.data.forEach((t) => {
          if (t.groupName && !groups.includes(t.groupName))
            groups.push(t.groupName);
          if (t.groupName)
            teamsByGroup[t.groupName] = teamsByGroup[t.groupName]
              ? [...teamsByGroup[t.groupName], t]
              : [t];
        });
        this.setState({
          teams: teamsRes.data,
          standings: standingsRes.data.standings,
          groups,
          teamsByGroup,
          divisionID,
        });
      } else toast.error(standingsRes.data);
    } else toast.error(teamsRes.data);

    this.context.setLoading(false);
  };

  handleSubmit = async (data) => {
    const {
      homeAway,
      createSecondaryBracket,
      seeding,
      numberOfTeams,
      refereeID,
      scheduleDates,
      numberOfFields,
      startDate,
      timeBetweenMatches,
      timeBetweenRounds,
      selectedFields,
    } = data;
    const { schedule, divisionID, groups } = this.state;
    if (
      scheduleDates === "Yes" &&
      (selectedFields.length === 0 ||
        !startDate ||
        !timeBetweenMatches ||
        !timeBetweenRounds)
    ) {
      return toast.error(
        "If Schedule Matches is set to Yes then all subsequent fields must be filled in."
      );
    }
    if (schedule.id === "playoff") {
      if (!seeding || seeding.includes("selection"))
        return toast.error(
          "Seeding choice is required for playoff bracket creation"
        );
    }

    if (schedule.id === "division" || schedule.id === "group") {
      if (!homeAway || homeAway.includes("selection"))
        return toast.error(
          "Home & Away choice is required for schedule creation"
        );
    }
    this.context.setLoading(true);
    this.context.setProgress([1]);
    const response = await createAndSaveSchedule(
      schedule.id,
      divisionID,
      refereeID,
      schedule.id === "playoff"
        ? seeding
        : homeAway === "No"
        ? "single"
        : "double",
      this.state.standings,
      Number(numberOfTeams),
      groups,
      {
        scheduleDates,
        numberOfFields: Number(numberOfFields),
        startDate,
        timeBetweenMatches: Number(timeBetweenMatches),
        timeBetweenRounds: Number(timeBetweenRounds),
      },
      createSecondaryBracket,
      selectedFields
    );
    if (response.status === 200) {
      const division = this.state.divisions.find((d) => d._id === divisionID);
      const location =
        schedule.id === "playoff"
          ? `/reports?brackets=${divisionID}`
          : `/matches?id=${divisionID}&name=${division ? division.name : ""}`;
      return (window.location = location);
    } else toast.error(response.data);
    this.context.setLoading(false);
  };

  render() {
    const {
      divisions,
      divisionID,
      teams,
      schedule,
      standings,
      referees,
      fields,
      groups,
      teamsByGroup,
    } = this.state;
    return (
      <React.Fragment>
        <div className="row">
          <div className="col">
            <CLink path={"/matches?q=new"} color={true}>
              Create Individual Match
            </CLink>
          </div>
        </div>
        <br />
        <HorizontalButtons
          buttons={sections}
          onClick={(schedule) => this.setState({ schedule })}
          selectedType={schedule.id}
          buttonSize="md"
        />
        <br />
        {schedule && (
          <NonFormSelect
            name="division"
            label="Select Division:&nbsp;"
            options={divisions}
            onChange={this.handleDivisionChange}
            value={divisionID}
          />
        )}
        {schedule.id && divisionID && (
          <div>
            <MiniHeader>{schedule.name}</MiniHeader>
            <CombinedCreateForm
              scheduleType={schedule.id}
              divisionID={divisionID}
              teams={teams}
              standings={standings}
              referees={referees}
              fields={fields}
              groups={groups}
              teamsByGroup={teamsByGroup}
              onSubmit={this.handleSubmit}
              org={this.props.org}
              history={this.props.history}
            />
          </div>
        )}

        <br />
        <hr />
        <QuickLinks
          fullBoard={true}
          org={this.props.org ? this.props.org : null}
        />
      </React.Fragment>
    );
  }
}

export default CreateHome;
