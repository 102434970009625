import React, { useContext, useState } from "react";

import MiniHeader from "../common/pageComponents/miniHeader";
import {
  deleteMessage,
  getMessages,
  postMessage,
  lockMessaging,
} from "../../services/messageService";
import toast from "../../utils/toast";
import HeaderContext from "../../context/headerContext";
import EventImage from "../assignedMatch/eventImage";
import { getCurrentUser } from "../../services/userService";
import { CLink } from "../common/customs/customLinks";
import allowables from "../../utils/allowables";
import WarningHeader from "../common/pageComponents/warningHeader";
import Pagination from "../common/dataSorting/pagination";
import NonFormSelect from "../common/form/nonFormSelect";
import OrgContext from "../../context/orgContext";
import usePagination from "../../hooks/usePagination";
import CustomConfirm from "../common/customs/customConfirm";
import IconRender from "../common/icons/iconRender";
import TooltipButton from "../common/tooltip/tooltipButton";

const TeamMessages = (props) => {
  const { setLoading } = useContext(HeaderContext);
  const org = useContext(OrgContext);
  const [newMessageOpen, setNewMessageOpen] = useState(true);
  const [typedMessage, setTypedMessage] = useState("");
  const [messages, setMessages] = useState({ messages: [], totalMessages: 0 });
  const [deleteMessageOpen, setDeleteMessageOpen] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState("");
  const [recover, setRecover] = useState(false);

  const user = getCurrentUser();

  const loadData = async (page, limit) => {
    setLoading(true);
    const res = await getMessages(limit, page - 1);
    if (res.status === 200) {
      setMessages(res.data);
    } else toast.error(res.data);
    setLoading(false);
  };

  const {
    currentPage,
    currentLimit: messagesPerPage,
    handleLimitChange,
    handlePageChange,
  } = usePagination(props.location, props.history, 25, loadData, "type=team");

  const handlePostMessage = async () => {
    setLoading(true);
    const res = await postMessage({ text: typedMessage });
    if (res.status === 200) {
      setTypedMessage("");
      setNewMessageOpen(false);
      toast.success(res.data);
      loadData(1, messagesPerPage);
    } else toast.error(res.data);
    setLoading(false);
  };

  const handleLockMessaging = async () => {
    setLoading(true);
    const res = await lockMessaging();
    if (res.status === 200) {
      setNewMessageOpen(false);
      toast.success(res.data);
      loadData(1, messagesPerPage);
    } else toast.error(res.data);
    setLoading(false);
  };

  const handleDeleteMessage = async () => {
    setLoading(true);
    const res = await deleteMessage(selectedMessage._id, recover);
    if (res.status === 200) {
      toast.success(res.data);
      loadData(currentPage, messagesPerPage);
    } else toast.error(res.data);
    setLoading(false);
  };

  const renderMessageDate = (date) => {
    return (
      <div className="col-3" style={{ marginRight: 5 }}>
        <small>{allowables.shortDate(date, true)}</small>
      </div>
    );
  };

  const renderMessageText = (message, isMyMessage) => {
    if (message.deleted) return <p>***message deleted***</p>;

    const profanityFilter =
      typeof org?.misc?.profanityFilter === "boolean"
        ? org?.misc?.profanityFilter
        : true;

    return (
      <div>
        <div className="row">
          {isMyMessage ? renderMessageDate(message.date) : null}
          <div className="col">
            <CLink path={`/players?info&q=${message.userID?.playerID?._id}`}>
              <b>
                {allowables.splitName(message.userID?.name)}
                {message.isCaptain ? " (c)" : ""}
              </b>
            </CLink>
          </div>
          {!isMyMessage ? renderMessageDate(message.date) : null}
        </div>
        <p style={{ whiteSpace: "pre-line" }}>
          {profanityFilter ? message.text : message.originalText}
        </p>
      </div>
    );
  };

  const renderMessage = (message, key) => {
    const isMyMessage = getCurrentUser()._id === message.userID?._id;

    return (
      <div key={key}>
        <div style={{ float: isMyMessage ? "right" : "left" }}>
          <EventImage player={message.userID} size={50} fromUser={true} />
        </div>
        {isMyMessage ? (
          <div style={{ float: isMyMessage ? "left" : "right", margin: 5 }}>
            <TooltipButton
              tooltipText={
                (message.deleted ? "Recover" : "Delete") + " Message"
              }
              tooltipDirection="right"
              onClick={() => {
                setSelectedMessage(message);
                setDeleteMessageOpen(true);
                setRecover(message.deleted);
              }}
              buttonClass={
                "btn btn-sm btn-" + (message.deleted ? "secondary" : "dark")
              }
            >
              <IconRender name={message.deleted ? "undo" : "delete"} />
            </TooltipButton>
          </div>
        ) : null}
        <div
          className={`pop-box text-${isMyMessage ? "right" : "left"} ${
            message.isCaptain ? " bg-muted" : ""
          }`}
          style={{
            marginLeft: isMyMessage ? 0 : 60,
            marginRight: isMyMessage ? 60 : 0,
            paddingRight: isMyMessage ? 10 : 20,
          }}
        >
          {renderMessageText(message, isMyMessage)}
        </div>
      </div>
    );
  };

  return (
    <div className="centered-small-input-area">
      {messages.blocked ? (
        <div>
          <WarningHeader>
            You team has been blocked from the messaging system
            <br />
            <br />
            <b>Reason:</b> {messages.blocked}
          </WarningHeader>
          <p className="text-center">
            For further explanation please contact{" "}
            {user.role.includes("captain") ? (
              <a href={`mailto:${allowables.supportEmail}`}>
                Ultimate Scoreboard Support
              </a>
            ) : (
              "your team captain"
            )}
          </p>
        </div>
      ) : (
        <div>
          {messages.locked ? (
            <WarningHeader>
              Messaging has been locked by the team captain
            </WarningHeader>
          ) : (
            <div>
              <MiniHeader
                iconName="email"
                onClick={() => setNewMessageOpen(!newMessageOpen)}
              >
                New Message
              </MiniHeader>
              {newMessageOpen ? (
                <div className="form-divided-section">
                  <textarea
                    className="custom-text-area"
                    rows="3"
                    cols="50"
                    style={{ width: "100%" }}
                    name="newMessage"
                    id="newMessage"
                    value={typedMessage}
                    placeholder="Write your message..."
                    onChange={(e) => setTypedMessage(e.target.value)}
                  />
                  <div className="row">
                    <div className="col">
                      <p>
                        <small>
                          Team messages are private to the players on this team.
                        </small>
                      </p>
                    </div>
                    <div className="col-3 text-right">
                      <button
                        className="btn btn-md btn-info"
                        onClick={handlePostMessage}
                      >
                        Post
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <br />
              )}
            </div>
          )}
          <div>
            <div className="row">
              <div className="col">
                <button
                  className="btn btn-block btn-sm btn-secondary"
                  onClick={() => handleLimitChange(messagesPerPage)}
                >
                  Get New Messages
                </button>
              </div>
              {user?.role?.includes("captain") ? (
                <div className="col-2">
                  <TooltipButton
                    tooltipText={
                      (messages.locked ? "Unlock" : "Lock") + " Messaging"
                    }
                    tooltipDirection="left"
                    onClick={handleLockMessaging}
                    buttonClass="btn btn-md btn-dark"
                  >
                    <IconRender name={(messages.locked ? "un" : "") + "lock"} />
                  </TooltipButton>
                </div>
              ) : null}
            </div>
            {messages.messages.length ? (
              <div className="row">
                <div className="col">
                  <p className="header-p text-center">
                    Showing message {(currentPage - 1) * messagesPerPage + 1} -{" "}
                    {messages.totalMessages <
                    (currentPage - 1) * messagesPerPage + messagesPerPage
                      ? messages.totalMessages
                      : (currentPage - 1) * messagesPerPage +
                        messagesPerPage}{" "}
                    of {messages.totalMessages}.
                  </p>
                </div>
                <div className="col">
                  <NonFormSelect
                    name="limit"
                    label="Mesages per Page:"
                    options={[
                      { _id: "25", name: "25" },
                      { _id: "100", name: "100" },
                    ]}
                    onChange={(event) => handleLimitChange(event.target.value)}
                    value={messagesPerPage}
                    noDefaultOption={true}
                  />
                </div>
              </div>
            ) : null}
            {messages.messages.map((m, idx) => renderMessage(m, idx))}
          </div>
          {messages.messages.length === 0 ? (
            <WarningHeader>There are no messages</WarningHeader>
          ) : (
            <Pagination
              itemsCount={messages.totalMessages}
              pageSize={messagesPerPage}
              currentPage={currentPage}
              onPageChange={handlePageChange}
              onPreviousPage={() => handlePageChange(currentPage - 1)}
              onNextPage={() => handlePageChange(currentPage + 1)}
            />
          )}
          <CustomConfirm
            dialog={recover ? "Recover this message?" : "Delete this message?"}
            isOpen={deleteMessageOpen}
            callback={handleDeleteMessage}
            close={() => setDeleteMessageOpen(false)}
            yesNo={true}
            split="%splitit%"
          />
        </div>
      )}
    </div>
  );
};

export default TeamMessages;
