import allowables from "./allowables";
import { GiBaseballBat, GiBaseballGlove, GiDustCloud } from "react-icons/gi";
import { IoMdBaseball } from "react-icons/io";

export function diamondLeaderboardDownload(leaderboard) {
  if (leaderboard.length === 0) return [{}];
  const allIDs = new Set([
    ...leaderboard.batting.map((b) => b._id),
    ...leaderboard.pitching.map((p) => p._id),
  ]);
  let download = [];
  allIDs.forEach((i) => {
    let batter = leaderboard.batting.find((b) => b._id === i);
    let pitcher = leaderboard.pitching.find((p) => p._id === i);
    batter = batter || {};

    download.push({
      name: batter.name || pitcher.name,
      teamName: batter.teamName || pitcher.teamName,
      batting_atBats: batter.atBats || 0,
      batting_battingAverage: batter.battingAverage || 0,
      batting_hits: batter.hits || 0,
      batting_homeRuns: batter.homeRuns || 0,
      batting_onBasePct: batter.onBasePct || 0,
      batting_onBasePlusSlugging: batter.onBasePlus || 0,
      batting_rbis: batter.rbis || 0,
      batting_sluggingPct: batter.slugging || 0,
      batting_strikeouts: batter.strikeouts || 0,
      batting_walks: batter.walks || 0,
      pitching_era: pitcher ? pitcher.era : "n/a",
      pitching_hits: pitcher ? pitcher.hits : "n/a",
      pitching_homeRuns: pitcher ? pitcher.homeRuns : 0,
      pitching_inningsPitched: pitcher ? pitcher.inningsPitched : 0,
      pitching_outs: pitcher ? pitcher.outs : "n/a",
      pitching_runs: pitcher ? pitcher.runs : "n/a",
      pitching_strikeouts: pitcher ? pitcher.strikeouts : "n/a",
      pitching_walks: pitcher ? pitcher.walks : "n/a",
      pitching_walksPlusHitsPerInningPitched: pitcher
        ? pitcher.walksPlusHits
        : "n/a",
    });
  });
  return download;
}

export function createDiamondEvent(batter, event, pitcher) {
  return [
    {
      eventID: new Date().getTime(),
      _id: batter.profileID?._id || batter._id,
      name: allowables.splitName(batter.name),
      teamID: batter.teamID,
      teamName: batter.teamName,
      pitcherID: pitcher.profileID?._id || pitcher._id,
      pitcherName: allowables.splitName(pitcher.name),
      result: event.result,
      inDepth: event.inDepth,
      rbis: event.rbis,
    },
  ];
}

export function mapDiamondEvents(match, events) {
  let mappedEvents = [];
  events.forEach((e) => {
    const icon =
      e.result === "out"
        ? { icon: GiBaseballGlove, color: "custom-dark-text" }
        : e.result === "hit"
        ? { icon: GiBaseballBat, color: "main-theme" }
        : e.result === "walk"
        ? { icon: IoMdBaseball, color: "main-theme" }
        : e.result.includes("stole")
        ? { icon: GiDustCloud, color: "success-text" }
        : null;
    let newEvent = { ...e };
    newEvent.text = `${e.teamName}: ${e.name}%s%${allowables.capLetterOne(
      e.result
    )}: ${allowables.capLetterOne(e.inDepth)}${
      e.rbis > 0 ? ` - ${e.rbis} RBI${e.rbis !== 1 ? "s" : ""}` : ""
    }%s%Pitcher: ${e.pitcherName}`;
    newEvent.icon = icon;
    mappedEvents.push(newEvent);
  });
  return mappedEvents.reverse();
}

export const template = { away: 0, home: 0 };
export function getBoxScore(
  chronoEventsNames,
  teams,
  outsPerInning = 3,
  inningsPerGame = 9
) {
  /* 
      format:
      {
          runs: {
              home: Number,
              away: Number
          },
          hits: {
              home: Number,
              away: Number
          },
          !we do not currently track errors
          errors: {
              home: Number,
              away: Number
          },
          boxScore: [
              {
                  away: Number,
                  home: Number
              }
          ]
      }
   */

  let boxScore = {
    runs: { ...template },
    errors: { ...template },
    hits: { ...template },
    boxScore: new Array(inningsPerGame).fill(null).map(() => {
      return { home: "", away: "" };
    }),
  };
  let outsCount = { ...template };
  let currentInningIndex = 0;
  chronoEventsNames.forEach((event) => {
    const team = teams.home._id === event.teamID ? "home" : "away";
    // add to the running totals
    if (event.result === "hit") boxScore.hits[team] = boxScore.hits[team] + 1;
    if (event.result === "error")
      boxScore.errors[team] = boxScore.errors[team] + 1;

    boxScore.boxScore[currentInningIndex][team] = ["", "X"].includes(
      boxScore.boxScore[currentInningIndex][team]
    )
      ? 0
      : boxScore.boxScore[currentInningIndex][team];

    if (event.rbis > 0) {
      boxScore.runs[team] = boxScore.runs[team] + event.rbis;
      boxScore.boxScore[currentInningIndex][team] =
        boxScore.boxScore[currentInningIndex][team] + event.rbis;
    }

    // increment outs
    if (event.result === "out") outsCount[team] = outsCount[team] + 1;

    // increment inning if necessary
    if (
      event.result === "out" &&
      outsCount.home > 0 &&
      outsCount.away > 0 &&
      (outsCount.away + outsCount.home) % (outsPerInning * 2) === 0
    ) {
      currentInningIndex++;
      if (currentInningIndex >= inningsPerGame)
        boxScore.boxScore.push({ home: "", away: "" });
    }

    // add X if game is over
    if (
      currentInningIndex >= inningsPerGame - 1 &&
      boxScore.runs.home > boxScore.runs.away &&
      event.result === "out" &&
      outsCount.away > outsCount.home &&
      outsCount.away % outsPerInning === 0 &&
      outsCount.home % outsPerInning === 0
    ) {
      boxScore.boxScore[currentInningIndex].home = "X";
    }
  });

  return boxScore;
}

export function atBatName(org) {
  const text =
    org?.misc?.diamondSportOptions?.sportName === "Kickball" ? "K" : "AB";
  return {
    er: text === "K" ? "Kicker" : "Batter",
    ing: text === "K" ? "Kicking" : "Batting",
    action: text === "K" ? "Kick" : "At Bat",
    article: text === "K" ? "a" : "an",
    abbr: text === "K" ? "KA" : "AB",
    pitchingAbbr: text === "K" ? "KA" : "H",
    pitchingFull: text === "K" ? "Kicks" : "Hits",
  };
}
