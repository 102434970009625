import React, { useState, useEffect, useContext } from "react";

import { getBoxScore, template } from "../../../utils/diamondFunctions";
import OrgContext from "../../../context/orgContext";

const BoxScore = ({ chronoEventsNames, teams }) => {
  const org = useContext(OrgContext);
  const outsPerInning = org?.misc?.diamondSportOptions?.outsPerInning || 3;
  const inningsPerGame = org?.misc?.diamondSportOptions?.inningsPerGame || 9;

  const [boxScore, setBoxScore] = useState({
    runs: { ...template },
    errors: { ...template },
    hits: { ...template },
    boxScore: [{ ...template }],
  });

  useEffect(() => {
    setBoxScore(
      getBoxScore(chronoEventsNames, teams, outsPerInning, inningsPerGame)
    );
  }, [chronoEventsNames, teams, outsPerInning, inningsPerGame]);

  return (
    <table className="box-score-table" aria-label="box-score">
      <thead className="box-score-header">
        <tr>
          <th className="box-score-header">Team</th>
          {boxScore.boxScore.map((bs, idx) => (
            <th className="box-score-inning" key={idx}>
              {idx + 1}
            </th>
          ))}
          {["R", "H", "E"].map((t) => (
            <th className="box-score-inning total" key={t}>
              {t}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {["away", "home"].map((team) => (
          <tr
            className="box-score-body"
            aria-label={`box-score-row-${team}`}
            key={team}
          >
            <td className="box-score-body">{teams[team].name}</td>
            {boxScore.boxScore.map((bs, idx) => (
              <td
                key={idx}
                className="box-score-inning"
                aria-label={`box-score-${idx + 1}-${team}`}
              >
                {bs[team]}
              </td>
            ))}
            {["runs", "hits", "errors"].map((t) => (
              <td
                className="box-score-inning total"
                aria-label={`box-score-${t}-${team}`}
                key={t}
              >
                {boxScore[t][team]}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default BoxScore;
