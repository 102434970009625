import React, { useState } from "react";
import BasicModal from "../common/pageComponents/basicModal";
import MiniHeader from "../common/pageComponents/miniHeader";
import allowables from "../../utils/allowables";
import toast from "../../utils/toast";

const fields = [
  { prop: "role", name: "Role", display: (u) => u.role || "unregistered" },
  {
    prop: "sport",
    name: "Sport",
    display: (u) =>
      u.sport?.toLowerCase().includes("diamond")
        ? u.sport + " (" + u.orgID?.misc?.diamondSportOptions?.sportName + ")"
        : u.sport || "no sport",
  },
  { prop: "teamName", name: "Team", display: (u) => u.teamName || "n/a" },
  {
    prop: "notificationSettings",
    name: "Ultimate Scoreboard Email Notification Settings",
    display: (u) => u.notificationSettings?.ultimateScoreboard || "default",
  },
  {
    prop: "lastActive",
    name: "Last Active",
    display: (u) =>
      u.lastActive
        ? allowables.shortDate(u.lastActive, true) + " (local)"
        : "n/a",
  },
];
const UserInfoModal = ({ userInfo, setUserInfo }) => {
  const [templatesExpanded, setTemplatesExpanded] = useState(false);

  const copyToClipboard = (user) => {
    const emailText = createUndeliverableRegistrationEmailTemplate(user);
    navigator.clipboard.writeText(emailText);
    toast.success(`Copied to clipboard`);
  };

  const createUndeliverableRegistrationEmailTemplate = (user) => {
    return `
        Hi ${allowables.splitName(
          user.teamID?.teamCaptainID?.name || user.orgID?.ownerName
        )},\n
        Your registration email to ${allowables.splitName(user.name)} at ${
      user.email
    } was returned as undeliverable.\n
    Please double check the email address and resend the registration email.\n
    If the problem persists please contact Ultimate Scoreboard support at ${
      allowables.supportEmail
    }.
      `;
  };

  const mapInfo = (index, field) => {
    if (!userInfo) return;
    const user = Array.isArray(userInfo) ? userInfo[index] : userInfo;

    if (field) return user[field];
    return (
      <div className="pop-box" key={index}>
        <h5 className="text-center">
          <b>{user.orgName}</b>
        </h5>
        <ul>
          {fields.map((f) => (
            <li key={f.prop}>
              <small>
                <i>{f.name}: </i>
              </small>
              {f.display ? f.display(user) : user[f.prop]}
            </li>
          ))}
        </ul>
        {!user.role ? (
          <MiniHeader onClick={() => setTemplatesExpanded(!templatesExpanded)}>
            Email Templates
          </MiniHeader>
        ) : null}

        {templatesExpanded && !user.role ? (
          <div className="pop-box text-center">
            <h6>
              <b>Undeliverable Registration Email</b>
            </h6>
            <p>
              Use when attending to an undeliverable user registration email.
              Double check the League name, Team name, and email template body
              before sending.
              <br />
              Send this email to{" "}
              {user.teamID?.teamCaptainID
                ? "the team captain"
                : "the league owner"}
              :{" "}
              <a
                href={`mailto:${
                  user.teamID?.teamCaptainID?.email || user.orgID?.ownerEmail
                }`}
              >
                {user.teamID?.teamCaptainID?.email || user.orgID?.ownerEmail}
              </a>
            </p>
            <button
              className="btn btn-sm btn-info"
              onClick={() => copyToClipboard(user)}
            >
              Copy Email Template
            </button>
          </div>
        ) : null}
      </div>
    );
  };

  return (
    <BasicModal isOpen={!!userInfo} onClose={() => setUserInfo(null)}>
      <MiniHeader>{allowables.splitName(mapInfo(0, "name"))}</MiniHeader>
      <div className="form-divided-section">
        <div className="text-center">
          <a href={`mailto:${mapInfo(0, "email")}`}>{mapInfo(0, "email")}</a>
        </div>
        {Array.isArray(userInfo)
          ? userInfo.map((ui, idx) => mapInfo(idx))
          : mapInfo()}
      </div>
    </BasicModal>
  );
};

export default UserInfoModal;
