import React from "react";

const RefereeingInstructionImage = ({ location }) => {
  return (
    <React.Fragment>
      <img
        src={
          "https://storage.googleapis.com/ultimatescoreboard-misc/" + location
        }
        alt={location}
        width={"auto"}
        height={400}
        style={{ border: "1px solid #831fe0", borderRadius: "5px" }}
      />
    </React.Fragment>
  );
};

export default RefereeingInstructionImage;
