import http from "./httpService";
import { getCurrentUser } from "./userService";
import { callbackProgress } from "../utils/progress";

export async function getMatches(callback) {
  const user = getCurrentUser();
  try {
    return await http.get(
      http.matchesEndpoint + "/orgs/" + user.orgID,
      callback && {
        onDownloadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function getCompleteMatches(callback) {
  const user = getCurrentUser();
  try {
    return await http.get(
      http.matchesEndpoint + "/orgs/complete/" + user.orgID,
      callback && {
        onDownloadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function getPlayoffMatches(divisionID, callback) {
  try {
    return await http.get(
      http.matchesEndpoint + "/playoffs/" + divisionID,
      callback && {
        onDownloadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function getMatchesByDivision(divisionID, callback) {
  try {
    return await http.get(
      http.matchesEndpoint + "/divisions/" + divisionID,
      callback && {
        onDownloadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function getMatch(id, callback) {
  try {
    return await http.get(
      http.matchesEndpoint + "/" + id,
      callback && {
        onDownloadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function getMatchesForUser(callback) {
  try {
    return await http.get(
      http.matchesEndpoint + "/users/mymatches",
      callback && {
        onDownloadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function getCompletedMatches(callback) {
  try {
    return await http.get(
      http.matchesEndpoint + "/users/completedmatches",
      callback && {
        onDownloadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function getAcceptedMatches(callback) {
  try {
    return await http.get(
      http.matchesEndpoint + "/users/acceptedmatches",
      callback && {
        onDownloadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function getMatchesByTeam(teamID, callback) {
  try {
    return await http.get(
      http.matchesEndpoint + "/teams/" + teamID,
      callback && {
        onDownloadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function getStandings(divisionID, callback) {
  try {
    return await http.get(
      http.matchesEndpoint + "/standings/" + divisionID,
      callback && {
        onDownloadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function saveMatch(id, match, callback) {
  const user = getCurrentUser();
  match.orgID = user.orgID;
  match.orgName = user.orgName;
  if (id === "new") {
    try {
      return await http.post(
        http.matchesEndpoint,
        match,
        callback && {
          onDownloadProgress: function (progress) {
            callbackProgress(progress, callback);
          },
        }
      );
    } catch (ex) {
      return ex.response;
    }
  }
  try {
    return await http.put(
      http.matchesEndpoint + "/" + id,
      match,
      callback && {
        onDownloadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function submitMatchComment(match, callback) {
  try {
    return await http.put(
      http.matchesEndpoint + "/submitcomment/" + match._id,
      match,
      callback && {
        onUploadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function acceptMatch(completedMatch, callback) {
  try {
    return await http.put(
      http.matchesEndpoint + "/acceptmatch/" + completedMatch._id,
      completedMatch,
      callback && {
        onUploadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function restartMatch(completedMatch, accepted, callback) {
  try {
    return await http.put(
      http.matchesEndpoint +
        "/restartmatch/" +
        accepted +
        "/" +
        completedMatch._id,
      completedMatch,
      callback && {
        onUploadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function createAndSaveSchedule(
  scheduleType,
  divisionID,
  refereeID,
  seeding,
  standings,
  numberOfTeams,
  groups,
  scheduleDates,
  createSecondaryBracket,
  selectedFields,
  callback
) {
  try {
    return await http.post(
      http.matchesEndpoint + "/createschedule",
      {
        scheduleType,
        divisionID,
        refereeID,
        seeding,
        standings,
        numberOfTeams,
        groups,
        scheduleDates,
        createSecondaryBracket,
        selectedFields,
      },
      callback && {
        onUploadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function deleteMatch(id, callback, sendNotifications) {
  const endpoint = sendNotifications ? "/" + id + "/notify" : "/" + id;
  try {
    return await http.delete(
      http.matchesEndpoint + endpoint,
      callback && {
        onDownloadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function deleteMultipleMatches(matchIDs, callback) {
  try {
    return await http.post(
      http.matchesEndpoint + "/multi",
      { matchIDs },
      callback && {
        onDownloadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function bulkWriteMatches(matches, callback) {
  try {
    return await http.put(
      http.matchesEndpoint + "/bulk/bulkwrite",
      { matches },
      callback && {
        onUploadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function emailSchedule(divisionIDs, callback) {
  try {
    return await http.post(
      http.matchesEndpoint + "/emailschedule",
      { divisionIDs },
      callback && {
        onDownloadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function submitScorecard(match, callback) {
  try {
    return await http.put(
      http.matchesEndpoint + "/referee/submitmatch/byauth",
      match,
      callback && {
        onDownloadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function getMatchesByLastUpdate(
  divisionID,
  status,
  limit,
  callback
) {
  try {
    return await http.get(
      http.matchesEndpoint +
        "/lastUpdated/" +
        divisionID +
        "/" +
        status +
        "/" +
        limit,
      callback && {
        onDownloadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function getNextMatch() {
  try {
    return await http.get(http.matchesEndpoint + "/checkin/nextmatch");
  } catch (ex) {
    return ex.response;
  }
}

export async function toggleCheckIn(matchID, notPlaying) {
  try {
    return await http.put(
      http.matchesEndpoint +
        "/checkin/" +
        matchID +
        (notPlaying ? "?notplaying=true" : "")
    );
  } catch (ex) {
    return ex.response;
  }
}
