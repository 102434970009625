import React from "react";
import Joi from "joi-browser";
import toast from "../../utils/toast";

import Form from "../common/form/form";
import PasswordRequirements from "./passwordRequirements";
import MiniHeader from "../common/pageComponents/miniHeader";
import CustomPrompt from "../common/customs/customPrompt";
import {
  requestLoginCode,
  requestPasswordReset,
} from "../../services/userService";
import HeaderContext from "../../context/headerContext";

class CombinedRegistrationForm extends Form {
  static contextType = HeaderContext;
  state = {
    data: { firstName: "", lastName: "", email: "", password: "" },
    errors: {},
    loginOpen: false,
    registerOpen: false,
    resetOpen: false,
    codeOpen: false,
  };

  schema = {
    firstName: Joi.string()
      .optional()
      .allow("")
      .min(1)
      .max(50)
      .label("First Name"),
    lastName: Joi.string()
      .min(1)
      .max(50)
      .label("Last Name")
      .when("firstName", {
        is: Joi.string(),
        then: Joi.required(),
        otherwise: Joi.optional().allow(""),
      }),
    email: Joi.string().required().email().label("Email"),
    password: Joi.string().required().min(8).label("Password"),
  };

  componentDidUpdate(prevProps) {
    let update = false;
    if (this.props.data) {
      for (let prop in this.props.data) {
        if (this.props.data[prop] !== prevProps.data[prop]) update = true;
      }
    }
    if (update)
      this.setState({ data: { ...this.state.data, ...this.props.data } });
  }

  openForm = (form) => {
    this.setState({ loginOpen: false, registerOpen: false });
    this.setState({ [form]: true });
  };

  indicateProgress = (progress, location) => {
    let { progress: currentProgress } = this.context;
    currentProgress[location.bar] =
      ((progress.loaded / progress.total) * 100) / currentProgress.length;
    this.context.setProgress(currentProgress);
  };

  handleAltLoginRequest = async (email, type) => {
    let trimmed;
    try {
      trimmed = String(email.trim().toLowerCase());
    } catch (error) {
      trimmed = "";
    }
    const ex = Joi.validate({ email: trimmed }, { email: this.schema.email });
    if (ex.error) return toast.error(ex.error.details[0].message);
    this.context.setLoading(true);
    this.context.setProgress([1]);
    if (type === "reset") {
      const response = await requestPasswordReset(trimmed, {
        callback: this.indicateProgress,
        bar: 0,
      });
      toast.dark(response.data);
    } else if (type === "code") {
      const response = await requestLoginCode(trimmed, {
        callback: this.indicateProgress,
        bar: 0,
      });
      if (response.status === 200) {
        window.location = `/loginwithcode?email=${response.data}`;
      }
    }
    this.context.setLoading(false);
  };

  doSubmit = () => {
    const formType =
      this.props.only || (this.state.loginOpen ? "login" : "register");
    this.props.onSubmit(this.state.data, formType);
  };

  renderFormSection = (formType) => {
    let inputs = [
      this.renderInput(
        "email",
        "Email",
        "autofocus",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "username"
      ),
      this.renderInput(
        "password",
        "Password",
        "",
        "password",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "current-password"
      ),
      this.renderValidatedButton(
        this.props.token
          ? "Change Password"
          : formType === "login"
          ? "Login"
          : "Register"
      ),
    ];
    if (formType === "register")
      inputs.splice(
        1,
        0,
        this.renderInput("firstName", "First Name"),
        this.renderInput("lastName", "Last Name"),
        <PasswordRequirements />
      );
    return inputs.map((i, idx) => <div key={idx}>{i}</div>);
  };

  renderForgotPassword = () => {
    return !this.state.token ? (
      <div>
        <br />
        <div className="row">
          <div className="col">
            <button
              className="btn-href-link"
              onClick={() => this.setState({ resetOpen: true })}
            >
              Forgot Password
            </button>
          </div>
          {this.props.only === "login" ? (
            <div className="col">
              <button
                className="btn-href-link"
                onClick={() => this.setState({ codeOpen: true })}
              >
                Login with Code
              </button>
            </div>
          ) : null}
        </div>
        <CustomPrompt
          dialog="Enter your email to receive a password reset link"
          callback={(email) => this.handleAltLoginRequest(email, "reset")}
          isOpen={this.state.resetOpen}
          close={() => {
            this.setState({ resetOpen: false });
          }}
        />
        <CustomPrompt
          dialog="Enter your email to receive a login code"
          callback={(email) => this.handleAltLoginRequest(email, "code")}
          isOpen={this.state.codeOpen}
          close={() => {
            this.setState({ codeOpen: false });
          }}
        />
      </div>
    ) : null;
  };

  render() {
    const { loginOpen, registerOpen } = this.state;
    const { only } = this.props;

    return (
      <div>
        {only ? (
          <div>
            <form onSubmit={this.handleSubmit}>
              {this.renderFormSection(only)}
            </form>
            {this.renderForgotPassword()}
          </div>
        ) : (
          <React.Fragment>
            <MiniHeader onClick={() => this.openForm("loginOpen")}>
              I already have an Ultimate Scoreboard account
            </MiniHeader>
            {loginOpen && (
              <div className="form-divided-section">
                <form onSubmit={this.handleSubmit}>
                  {this.renderFormSection("login")}
                </form>
                {this.renderForgotPassword()}
              </div>
            )}
            <br />
            <MiniHeader onClick={() => this.openForm("registerOpen")}>
              I don't have an account
            </MiniHeader>
            {registerOpen && (
              <div className="form-divided-section">
                <form onSubmit={this.handleSubmit}>
                  {this.renderFormSection("register")}
                </form>
              </div>
            )}
            <br />
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default CombinedRegistrationForm;
