import http from "./httpService";
import { getCurrentUser } from "./userService";
import { callbackProgress } from "../utils/progress";

export async function getPlayers(callback) {
  const user = getCurrentUser();
  try {
    return await http.get(
      http.playersEndpoint + "/orgs/" + user.orgID,
      callback && {
        onDownloadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function getPlayersByTeam(teamID, callback) {
  try {
    return await http.get(
      http.playersEndpoint + "/teams/" + teamID,
      callback && {
        onDownloadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function getPlayersForMatch(homeTeamID, awayTeamID, callback) {
  try {
    return await http.get(
      http.playersEndpoint + "/match/" + homeTeamID + "/" + awayTeamID,
      callback && {
        onDownloadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function getPlayer(id, callback) {
  try {
    return await http.get(
      http.playersEndpoint + "/" + id,
      callback && {
        onDownloadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function getLeaderboard(divisionID, callback) {
  try {
    return await http.get(
      http.playersEndpoint + "/leaderboard/" + divisionID,
      callback && {
        onDownloadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function savePlayer(id, player, email, callback) {
  if (id === "new") {
    try {
      return await http.post(
        http.playersEndpoint,
        { player, email },
        callback && {
          onUploadProgress: function (progress) {
            callbackProgress(progress, callback);
          },
        }
      );
    } catch (ex) {
      return ex.response;
    }
  } else {
    try {
      return await http.put(
        http.playersEndpoint + "/" + id,
        player,
        callback && {
          onUploadProgress: function (progress) {
            callbackProgress(progress, callback);
          },
        }
      );
    } catch (ex) {
      return ex.response;
    }
  }
}

export async function deletePlayer(id, callback) {
  try {
    return await http.delete(
      http.playersEndpoint + "/" + id,
      callback && {
        onDownloadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function resetSuspensions(divisionIDs, callback) {
  try {
    return await http.put(
      http.playersEndpoint + "/suspensions/reset/",
      { divisionIDs },
      callback && {
        onUploadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function bulkUpdateRosterNumbers(players, callback) {
  try {
    return await http.put(
      http.playersEndpoint + "/bulk/rosters",
      { players },
      callback && {
        onUploadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function incrementPlayerSuspension(
  playerID,
  incrementer,
  callback
) {
  try {
    return await http.put(
      http.playersEndpoint + "/suspensions/single/" + playerID,
      { incrementer },
      callback && {
        onUploadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function recruitFreeAgent(playerID, callback) {
  try {
    return await http.put(
      http.playersEndpoint + "/freeagent/add/" + playerID,
      {},
      callback && {
        onUploadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function moveToFreeAgents(playerID, callback) {
  try {
    return await http.put(
      http.playersEndpoint + "/freeagent/remove/" + playerID,
      {},
      callback && {
        onUploadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function getMyHistory(callback) {
  try {
    return await http.get(
      http.playersEndpoint + "/myhistory/all",
      callback && {
        onUploadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}
