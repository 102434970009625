import React, { useEffect, useState, useContext } from "react";
import useWindowDimensions from "../../../utils/useWindowDimensions";

import OrgContext from "../../../context/orgContext";
import allowables from "../../../utils/allowables";

const MainTitle = ({
  orientation,
  setWidth,
  disallowCustom,
  minWidthForPortrait,
  overrideImage,
  maxWidth,
  overrideStyle = {},
}) => {
  const org = useContext(OrgContext);

  const [image, setImage] = useState("/logos/stackedLogo.png");
  const { width } = useWindowDimensions();

  function getFallbackImage(event) {
    event.target.src = allowables.fallbackImage(3);
  }

  useEffect(() => {
    if (overrideImage) setImage(overrideImage);
  }, [overrideImage]);

  useEffect(() => {
    if (!overrideImage)
      setImage(
        org && org.bannerLogo && !disallowCustom
          ? `https://storage.googleapis.com/ultimatescoreboard-leaguelogos/${org.bannerLogo}.png`
          : orientation === "portrait" || width <= (minWidthForPortrait || 1000)
          ? "/logos/stackedLogo.png"
          : "/logos/spreadLogo.png"
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, orientation, overrideImage]);

  return (
    <img
      onError={getFallbackImage}
      alt={"Ultimate Scoreboard"}
      className="logo"
      src={image}
      width={setWidth ? setWidth : width * 0.7}
      height="auto"
      style={{
        padding: 10,
        borderRadius: 15,
        maxWidth,
        ...overrideStyle,
      }}
    />
  );
};

export default MainTitle;
