import React from "react";
import Modal from "react-modal";
import FullLoading from "./fullLoading";

const style = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    height: "auto",
    width: "auto",
    backgroundColor: "#f3e9fc",
    border: `1px solid #831fe0`,
    zIndex: 999,
  },
};

const LoadingModal = ({ loading, progress }) => {
  return (
    <Modal
      id="loading-modal"
      isOpen={loading}
      ariaHideApp={false}
      style={style}
    >
      <FullLoading progress={progress} setWidth={250} orientation="portrait" />
    </Modal>
  );
};

export default LoadingModal;
