import React, { Component } from "react";

import Fields from "./fields";
import FieldForm from "./fieldForm";
import HeaderContext from "../../context/headerContext";
import { getCurrentUser } from "../../services/userService";

class DivisionRedirect extends Component {
  static contextType = HeaderContext;
  state = {
    loading: true,
    comp: null,
    fieldID: "",
    source: "edit",
  };

  componentDidMount() {
    this.setup();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.search !== this.props.location.search) this.setup();
  }

  setup = () => {
    let comp = Fields;
    const query = this.props.location.search.slice(1).split("&");
    let fieldID = query.find((q) => q.split("=")[0] === "q");
    fieldID = fieldID && fieldID.split("=")[1];
    if (fieldID) {
      this.context.setLoading(true);
      comp = FieldForm;
    }
    let source =
      query.find((q) => q === "info") ||
      !getCurrentUser().role.includes("admin")
        ? "info"
        : "edit";

    this.setState({ loading: false, fieldID, source, comp });
  };

  render() {
    return this.state.loading || !this.state.comp ? null : (
      <this.state.comp
        {...this.props}
        fieldID={this.state.fieldID}
        source={this.state.source}
      />
    );
  }
}

export default DivisionRedirect;
