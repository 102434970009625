import React from "react";
import ProgressBar from "react-bootstrap/ProgressBar";

const ProgressIndicator = ({ progress }) => {
  return (
    <ProgressBar>
      {progress.map((pb, i) => (
        <ProgressBar key={i} now={progress[i]} variant="info" />
      ))}
    </ProgressBar>
  );
};

export default ProgressIndicator;
