import React, { Component } from "react";
import Table from "../common/table/table";
import { CLink } from "../common/customs/customLinks";
import { getCurrentUser } from "../../services/userService";
import { makeTableButton, combineButtons } from "../../utils/buttons";
import allowables from "../../utils/allowables";

class SeasonsTable extends Component {
  columns = [
    {
      path: "name",
      label: "Name",
      content: (s) => <CLink path={`/seasons?info&q=${s._id}`}>{s.name}</CLink>,
    },
    {
      path: "registrationEndDate",
      label: "Registration End Date",
      content: (d) =>
        d.registrationEndDate
          ? allowables.dateTime(d.registrationEndDate, true)?.date +
            ", " +
            allowables.dateTime(d.registrationEndDate, true)?.time
          : null,
    },
    {
      path: "startDate",
      label: "Start Date",
      content: (d) => allowables.dateTime(d.startDate)?.date,
    },
    {
      path: "endDate",
      label: "End Date",
      content: (d) => allowables.dateTime(d.endDate)?.date,
    },
    {
      path: "location",
      label: "Location",
    },
    {
      path: "active",
      label: "Active",
      content: (s) => (s.active ? "Yes" : "No"),
    },
  ];

  constructor(props) {
    super(props);
    const user = getCurrentUser();
    let buttons = [];
    if (user && user.role?.includes("admin")) {
      buttons.push(makeTableButton("delete", props.onDelete));
      buttons.push(makeTableButton("edit", "seasons"));
      this.columns.push(combineButtons(buttons));
    }
  }

  render() {
    const { seasonsByPage, onSort, sortColumn } = this.props;
    return (
      <Table
        columns={this.columns}
        data={seasonsByPage}
        sortColumn={sortColumn}
        onSort={onSort}
        keyProperty={"_id"}
        tableClass="table-custom"
        headerClass="thead-custom"
        bodyClass="tbody-custom"
        highlightOnHover={true}
      />
    );
  }
}

export default SeasonsTable;
