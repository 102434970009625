import React from "react";

const CommentsSubmit = ({
  toggleModal,
  refereeComments,
  onAddComment,
  onSubmit,
  match,
}) => {
  return (
    <div>
      <br />
      <textarea
        className="custom-text-area"
        style={{ width: "100%" }}
        rows="4"
        name="refereeComments"
        placeholder="Enter your comments about this scorecard. Your comments will be submitted along with the scorecard and are only visible to admins"
        onChange={(e) => onAddComment(e.target.value)}
        value={refereeComments}
      />
      <br />
      <br />
      <button className="btn btn-block btn-lg btn-info" onClick={onSubmit}>
        Submit Scorecard
      </button>
      <br />
      <br />
      <button
        className="btn btn-dark btn-block"
        onClick={() => toggleModal("cancelMatchOpen")}
      >
        {match.matchComplete === 1 ? "Discard Changes" : "Cancel Scorecard"}
      </button>
    </div>
  );
};

export default CommentsSubmit;
