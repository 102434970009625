import React, { useState, useContext } from "react";

import MiniHeader from "../common/pageComponents/miniHeader";
import {
  saGetEmails,
  saGetUserInfo,
  saGetUserInfoByEmail,
} from "../../services/superAdminService";
import HeaderContext from "../../context/headerContext";
import toast from "../../utils/toast";
import NonFormInput from "../common/form/nonFormInput";
import allowables from "../../utils/allowables";
import UserInfoModal from "./userInfoModal";
import Checkbox from "../common/form/checkBox";

const SuperAdminHelpers = () => {
  const { setLoading } = useContext(HeaderContext);
  const [emails, setEmails] = useState([]);
  const [userInfo, setUserInfo] = useState(null);

  const [data, setData] = useState({
    roles: "",
    sports: "",
    email: "",
    includeWithSettingNone: false,
  });

  const handleSetData = (key, value) => {
    let temp = { ...data };
    temp[key] = value;
    setData(temp);
  };

  const getEmails = async () => {
    setLoading(true);
    const res = await saGetEmails(
      data.roles,
      data.sports,
      data.includeWithSettingNone
    );
    if (res.status === 200) {
      setEmails(res.data);
    } else toast.error(res.data);
    setLoading(false);
  };

  const copyToClipboard = (type, prop, join = ";") => {
    const list = type.map((u) => u[prop]).join(join);
    navigator.clipboard.writeText(list);
    toast.success(`${prop}s copied to clipboard`);
  };

  const getUserInfo = async (user, byEmail) => {
    setLoading(true);
    const res = byEmail
      ? await saGetUserInfoByEmail(user.email)
      : await saGetUserInfo(user._id, true);
    if (res.status === 200) {
      setUserInfo(res.data);
    } else toast.error(res.data);
    setLoading(false);
  };

  return (
    <div>
      <MiniHeader>Mailing List Builder</MiniHeader>
      <div className="form-divided-section">
        <p>
          Create a mailing list narrowed by role and sport.
          <br />
          <br />
          Enter comma separated roles and sports, case sensitive to match DB
          entry (all lower case for roles, title case for sports), no spaces
          between commas. Enter "any" to retrieve all.
          <br />
          <br />
          <ul>
            <li>
              Double check the returned addresses before using them in an email.
            </li>
            <li>
              Do not send unprompted email to users who have opted out of
              Ultimate Scoreboard promotional emails.
            </li>
          </ul>
        </p>
        <div>
          <Checkbox
            onChange={(value) => handleSetData("includeWithSettingNone", value)}
            value={data.includeWithSettingNone}
            label="Include Users who have opted out of Ultimate Scoreboard emails"
          />
          <NonFormInput
            name="roles"
            type="text"
            label="Roles"
            value={data.roles}
            onChange={(event) => handleSetData("roles", event.target.value)}
          />
          <NonFormInput
            name="sports"
            type="text"
            label="Sports"
            value={data.sports}
            onChange={(event) => handleSetData("sports", event.target.value)}
          />
        </div>
        <div className="row">
          <div className="col">
            <button className="btn btn-md btn-info" onClick={getEmails}>
              Get Addresses
            </button>
          </div>
          <div className="col">
            {emails.length ? (
              <button
                className="btn btn-secondary btn-md"
                onClick={() => copyToClipboard(emails, "email", ";")}
              >
                Copy Mailing List
              </button>
            ) : null}
          </div>
          <div className="col">
            <button className="btn btn-dark" onClick={() => setEmails([])}>
              Clear
            </button>
          </div>
        </div>
        <p>
          <b>{emails.length}</b> emails retrieved.
          {emails.length ? " Click a name to get more user info." : ""}
        </p>
        <ul>
          {emails.map((e, idx) => (
            <li key={idx}>
              <div className="clickable" onClick={() => getUserInfo(e)}>
                <b>{allowables.splitName(e.name)}</b>: {e.email}{" "}
                <small>
                  {e.sport} - <i>{e.role}</i>
                </small>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <UserInfoModal userInfo={userInfo} setUserInfo={setUserInfo} />
      <MiniHeader>User Info & Email Templates</MiniHeader>
      <div className="form-divided-section">
        <p>
          This will pull user info by email. If the user does not exist it will
          retrieve the info by pending player email.
        </p>
        <NonFormInput
          name="email"
          type="text"
          label="Email"
          value={data.email}
          onChange={(event) => handleSetData("email", event.target.value)}
        />
        <button
          className="btn btn-info btn-md"
          onClick={() => getUserInfo({ email: data.email }, true)}
        >
          Get
        </button>
      </div>
    </div>
  );
};

export default SuperAdminHelpers;
