import React, { Component } from "react";
import { getLeaderboard } from "../../services/playerService";
import SearchBox from "../common/dataSorting/searchBox";
import DiamondLeaderboardTooltip from "./diamond/diamondLeaderboardTooltip";
import ExcelDownload from "../common/pageComponents/excelDownload";
import { soccerLeaderboardDownload } from "../../utils/soccerFunctions";
import { footballLeaderboardDownload } from "../../utils/footballFunctions";
import { diamondLeaderboardDownload } from "../../utils/diamondFunctions";
import HeaderContext from "../../context/headerContext";
import SportLeaderboard from "./sportLeaderboard";
import WarningHeader from "../common/pageComponents/warningHeader";

class Leaderboards extends Component {
  static contextType = HeaderContext;
  state = {
    matches: 0,
    leaderboard: [],
    players: 0,
    searchQuery: "",
    sortColumn: { path: "goals", order: "desc" },
    selectedTable: "batting",
  };

  indicateProgress = (progress, location) => {
    let { progress: currentProgress } = this.context;
    currentProgress[location.bar] =
      ((progress.loaded / progress.total) * 100) / currentProgress.length;
    this.context.setProgress(currentProgress);
  };

  componentDidMount() {
    this.getData();
  }
  componentDidUpdate(prevProps) {
    if (this.props.divisionID !== prevProps.divisionID) {
      this.getData();
    }
  }

  getData = async () => {
    this.context.setLoading(true);
    this.context.setProgress([1]);
    if (!this.props.divisionID) return this.context.setLoading(false);
    const response = await getLeaderboard(this.props.divisionID, {
      callback: this.indicateProgress,
      bar: 0,
    });
    if (response.status === 200) {
      this.setState({
        players: response.data.players,
        leaderboard: response.data.leaderboard || [],
        matches: response.data.matches,
      });
    }
    this.context.setLoading(false);
  };

  handleChange = async (event) => {
    this.context.setLoading(true);
    this.context.setProgress([1]);
    const { value: divisionID } = event.target;
    if (divisionID === "") return this.context.setLoading(false);
    const response = await getLeaderboard(divisionID, {
      callback: this.indicateProgress,
      bar: 0,
    });
    if (response.status === 200) {
      this.props.onDivisionChange(divisionID);
      this.setState({
        players: response.data.players,
        leaderboard: response.data.leaderboard || [],
        matches: response.data.matches,
      });
    }
    this.context.setLoading(false);
  };

  handleSearch = (query) => {
    this.setState({ searchQuery: query });
  };

  handleSelectTable = (selectedTable) => {
    this.setState({ selectedTable });
  };

  render() {
    const { matches, players, searchQuery, leaderboard } = this.state;
    const { divisionID } = this.props;

    const sport = this.props.org?.sport.toLowerCase();
    const excelData = sport.includes("soccer")
      ? soccerLeaderboardDownload(leaderboard)
      : sport.includes("football")
      ? footballLeaderboardDownload(leaderboard)
      : sport.includes("diamond")
      ? diamondLeaderboardDownload(leaderboard)
      : {};

    return (
      <div>
        <div className="row">
          {sport.includes("diamond") && (
            <div className="col-2">
              <DiamondLeaderboardTooltip
                selectedTable={this.state.selectedTable}
              />
            </div>
          )}
        </div>
        {divisionID && matches === 0 && (
          <WarningHeader>
            There have been no matches completed in this division.
          </WarningHeader>
        )}
        {divisionID && matches !== 0 && players === 0 && (
          <WarningHeader>
            There are no players added to teams in this division. Individual
            statistics are not being tracked.
          </WarningHeader>
        )}
        {divisionID && sport.includes("simple") && (
          <WarningHeader>
            Leaderboards are not tracked for leagues using the simple scoring
            option
          </WarningHeader>
        )}
        {leaderboard.length !== 0 && players.length !== 1 && (
          <React.Fragment>
            <div className="row">
              <div className="col">
                <SearchBox
                  value={searchQuery}
                  onChange={this.handleSearch}
                  placeholder="Search player or team name..."
                />
              </div>
              <div className="col-2">
                <ExcelDownload
                  data={excelData}
                  columns={Object.keys(excelData[0])}
                  dataName="leaderboard"
                  tooltipDirection="top-left"
                />
              </div>
            </div>

            <SportLeaderboard
              sport={sport.toLowerCase()}
              leaderboard={leaderboard}
              searchQuery={searchQuery}
              onSelectTable={this.handleSelectTable}
            />
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default Leaderboards;
