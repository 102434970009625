import React, { useState, useEffect } from "react";
import useWindowDimensions from "../../../utils/useWindowDimensions";

const MultiImageSlider = ({
  images,
  transitionTime,
  altText,
  ratio = 2 / 3,
}) => {
  const { width } = useWindowDimensions();
  const [curImageIndex, setCurImageIndex] = useState(
    Math.floor(Math.random() * images?.length || 0)
  );

  useEffect(() => {
    const int = setInterval(() => {
      setCurImageIndex((cur) => {
        return (cur + 1) % images.length;
      });
      // setCurImageIndex(Math.floor(Math.random() * images.length));
    }, transitionTime);

    return () => {
      clearInterval(int);
    };
  }, [images, transitionTime]);

  const widthPctmargin = width > 1000 ? 0.95 : 0.8;
  return (
    <div
      className="image-slider"
      style={{
        width: width * widthPctmargin,
        height: width * widthPctmargin * ratio,
        maxWidth: 1000,
        maxHeight: 800 * ratio,
      }}
    >
      {images.map((img, idx) => (
        <img
          key={idx}
          src={img}
          alt={`${altText}-${idx}`}
          className={"slider-image" + (idx === curImageIndex ? " active" : "")}
        />
      ))}
    </div>
  );
};

export default MultiImageSlider;
