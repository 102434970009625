import React from "react";
import ProfileStatus from "./profileStatus";

const CodeOfConductStatus = ({ org, user }) => {
  if (
    !org ||
    !user ||
    !user.playerID ||
    !user.role ||
    !user.role.includes("player") ||
    !org.codeOfConduct ||
    !org.codeOfConduct.length
  )
    return null;

  const checkCocAcceptance = () => {
    return (
      org.codeOfConduct &&
      org.codeOfConduct.length > 0 &&
      user.codeOfConduct &&
      user.codeOfConduct.accepted
    );
  };

  return (
    <ProfileStatus
      isComplete={checkCocAcceptance()}
      completeText="Code of conduct accepted"
      incompleteText="Read and accept the code of conduct"
      link="/codeofconduct"
    />
  );
};

export default CodeOfConductStatus;
