import React, { Component } from "react";
import {
  getCurrentUser,
  removeUserFromOrg,
  refreshUser,
  getUsersForOrg,
} from "../../services/userService";
import OrgForm from "./orgForm";
import {
  purgeOrg,
  sendAccountAuth,
  getPendingAccountInfo,
  removePendingAccount,
} from "../../services/orgService";
import { getCustomer, getSubscription } from "../../services/paymentService";
import toast from "../../utils/toast";
import OrgTable from "./orgTable";
import Joi from "joi-browser";
import OrgOptions from "./orgOptions";
import OrgPendingAccounts from "./orgPendingaccounts";
import OrgUserLinks from "./orgUserLinks";
import CustomConfirm from "../common/customs/customConfirm";
import CustomPrompt from "../common/customs/customPrompt";
import QuickLinks from "../common/pageComponents/quickLinks";
import PageBottom from "../common/pageComponents/pageBottom";
import allowables from "../../utils/allowables";
import NonFormInput from "../common/form/nonFormInput";
import HeaderContext from "./../../context/headerContext";
import { navigateTo } from "./../common/customs/customLinks";
import SideBySideView from "./../common/pageComponents/sideBySideView";
import ActiveSubscription from "../payment/subscriptions/activeSubscription";

class Organization extends Component {
  static contextType = HeaderContext;
  state = {
    org: { sport: "" },
    disabled: "disabled",
    copiedReferee: false,
    optionsOpen: false,
    deleteOpen: false,
    logoOpen: false,
    pendingAccounts: [],
    users: [],
    customer: null,
    subscription: null,
    suspensionsOpen: false,
    removePendingOpen: false,
    pendingDialog: "",
    selectedAccount: null,
    purgeOpen: false,
    purgeTwoOpen: false,
    removeUserOpen: false,
    removeUserDialog: "",
    selectedUser: null,
    enteredLeagueName: "",
    usersLoaded: false,
  };

  async componentDidMount() {
    this.context.setLoading(true);
    this.context.setProgress([1, 1]);
    await refreshUser({ callback: this.indicateProgress, bar: 0 });
    const user = getCurrentUser();
    await this.context.refreshOrg();
    if (this.props.org) {
      // get info from Stripe
      const subscriptionRes = await getSubscription();
      const customerRes = await getCustomer();
      const pendingAccounts = await getPendingAccountInfo(user.orgID, {
        callback: this.indicateProgress,
        bar: 1,
      });
      this.setState({
        org: this.props.org,
        subscription:
          subscriptionRes?.status === 200 ? subscriptionRes.data : null,
        customer: customerRes?.status === 200 ? customerRes.data : null,
        pendingAccounts: pendingAccounts?.data,
      });
    }
    this.context.setLoading(false);
  }

  loadUsers = async () => {
    this.context.setLoading(true);
    this.context.setProgress([1]);
    const user = getCurrentUser();
    const usersResponse = await getUsersForOrg({
      callback: this.indicateProgress,
      bar: 0,
    });
    let users = [];
    if (usersResponse.status === 200) {
      usersResponse.data.forEach((u) => {
        if (u._id !== user._id) {
          let newUser = { ...u };
          newUser.role = allowables.translateRole(u.role, u.sport);
          newUser.name = allowables.splitName(u.name);
          newUser.vaccineDate = newUser.vaccine ? newUser.vaccine.date : "";
          newUser.vaccineBrand = newUser.vaccine ? newUser.vaccine.name : "";
          newUser.vaccineReviewed =
            newUser.vaccine && newUser.vaccine.reviewed
              ? newUser.vaccine.reviewed
              : false;
          delete newUser.vaccine;
          newUser.codeOfConductAccepted = newUser.codeOfConduct
            ? newUser.codeOfConduct.accepted
            : false;
          newUser.codeOfConductAcceptedDate = newUser.codeOfConduct
            ? newUser.codeOfConduct.date
            : "";
          newUser.codeOfConductLocation = newUser.codeOfConduct
            ? newUser.codeOfConduct.location
            : "";
          delete newUser.codeOfConduct;
          newUser.imageUrl = newUser.profileID.hasAvatar
            ? !newUser.profileID.hasAvatar.toLowerCase().includes("blocked")
              ? `https://storage.googleapis.com/ultimatescoreboard-userimages/${newUser.profileID.hasAvatar}.png`
              : "blocked"
            : "";
          users.push(newUser);
        }
      });
      this.setState({ users, usersLoaded: true });
    } else toast.error(usersResponse.data);
    this.context.setLoading(false);
  };

  indicateProgress = (progress, location) => {
    let { progress: currentProgress } = this.context;
    currentProgress[location.bar] =
      ((progress.loaded / progress.total) * 100) / currentProgress.length;
    this.context.setProgress(currentProgress);
  };

  toggleModal = (id) => {
    this.setState({ [id]: this.state[id] ? false : true });
  };

  openOptions = () => {
    if (this.state.optionsOpen)
      this.setState({
        optionsOpen: false,
        deleteOpen: false,
        settingsOpen: false,
        otherOpen: false,
      });
    else this.setState({ optionsOpen: true });
  };

  openDelete = () => {
    if (this.state.deleteOpen) this.setState({ deleteOpen: false });
    else {
      this.setState({ deleteOpen: true });
    }
    this.setState({
      otherOpen: false,
      settingsOpen: false,
      logoOpen: false,
      bannerOpen: false,
      teamSettingsOpen: false,
    });
  };

  openLogo = () => {
    if (this.state.logoOpen) this.setState({ logoOpen: false });
    else {
      this.setState({ logoOpen: true });
    }
    this.setState({
      deleteOpen: false,
      settingsOpen: false,
      otherOpen: false,
      bannerOpen: false,
    });
  };

  openBanner = () => {
    if (this.state.bannerOpen) this.setState({ bannerOpen: false });
    else {
      this.setState({ bannerOpen: true });
    }
    this.setState({
      deleteOpen: false,
      settingsOpen: false,
      otherOpen: false,
      logoOpen: false,
    });
  };

  setEmailPrompt = (link, regType) => {
    const regTypeText =
      regType === "referee"
        ? allowables.refOrUmp(this.props.org?.sport).toLowerCase()
        : regType;
    this.setState({
      emailOpen: true,
      link,
      regType,
      emailDialog: `Enter the ${regTypeText}'s email`,
    });
  };

  sendRegistrationEmail = async (email) => {
    const { link, regType } = this.state;
    email = email.toLowerCase();
    const ex = Joi.validate(
      { email },
      { email: Joi.string().required().email().label("Email") }
    );
    if (ex.error) return toast.error(ex.error.details[0].message);
    this.context.setLoading(true);
    this.context.setProgress([1]);

    const response = await sendAccountAuth(email, link, regType, {
      callback: this.indicateProgress,
      bar: 0,
    });
    if (response.status === 200) {
      toast.success("Invitation sent");
      await this.context.refreshOrg();
      await this.componentDidMount();
    } else toast.error(response.data);
    this.context.setLoading(false);
  };

  setPendingAccount = (account) => {
    this.setState({
      removePendingOpen: true,
      pendingDialog: `Revoke pending ${account.regType} account for ${account.email}?`,
      selectedAccount: account,
    });
  };

  handleRemovePendingAccount = async () => {
    const { selectedAccount: account } = this.state;
    this.context.setLoading(true);
    this.context.setProgress([1]);
    const response = await removePendingAccount(
      { ...account, orgID: this.props.org?._id },
      {
        callback: this.indicateProgress,
        bar: 0,
      }
    );
    if (response.status === 200) {
      toast.success(response.data);
      await this.context.refreshOrg();
      await this.componentDidMount();
    } else toast.error(response.data);
    this.context.setLoading(false);
  };

  handleDelete = async () => {
    const user = getCurrentUser();
    this.context.setLoading(true);
    this.context.setProgress([1, 1]);
    const response = await purgeOrg(user, {
      callback: this.indicateProgress,
      bar: 0,
    });
    if (response.status === 200) {
      await refreshUser({ callback: this.indicateProgress, bar: 1 });
      toast.success("League successfully deleted.");
      return (window.location = "/league");
    } else toast.error(response.data);
    this.setState({ deleteOpen: false });
    this.context.setLoading(false);
  };

  setRemoveUserDialog = (user) => {
    const dialog = `Remove ${
      user.role.includes("Referee")
        ? allowables.refOrUmp(this.props.org?.sport).toLowerCase()
        : user.role.toLowerCase()
    } ${user.name
      .split("%20%")
      .join(" ")} from your league?%They will no longer be able to view${
      user.role.toLowerCase().includes("admin") ? " or edit" : ""
    } any of your private data. ${
      user.role.toLowerCase().includes("referee") ||
      user.role.toLowerCase().includes("admin")
        ? "%Any unplayed matches assigned to them will need to be reassigned."
        : user.role.toLowerCase().includes("player")
        ? "%They will remain as a player on their team's roster until the player is deleted."
        : ""
    }${
      user.role.toLowerCase().includes("captain") && user.teamName
        ? `%You should assign a new user to be captain of ${user.teamName}.`
        : ""
    }%Are you sure?`;
    this.setState({
      removeUserDialog: dialog,
      removeUserOpen: true,
      selectedUser: user,
    });
  };

  handleRemoveUser = async () => {
    this.context.setLoading(true);
    this.context.setProgress([1]);
    const user = this.state.selectedUser;
    const response = await removeUserFromOrg(user, {
      callback: this.indicateProgress,
      bar: 0,
    });
    if (response.status === 200) return (window.location = "/league");
    else toast.error(response.data);
    this.context.setLoading(false);
  };

  render() {
    const user = getCurrentUser();
    const {
      org,
      pendingAccounts,
      removePendingOpen,
      pendingDialog,
      purgeOpen,
      purgeTwoOpen,
      removeUserOpen,
      removeUserDialog,
      enteredLeagueName,
      emailOpen,
      emailDialog,
    } = this.state;

    const widthToSwitch = 625;

    const qrLinkPlayer = user.orgID
      ? "/signup?id=" +
        user.orgID +
        "&role=player&orgName=" +
        user.orgName.split(" ").join("%20") +
        "&sport=" +
        this.props.org?.sport.split(" ").join("%20")
      : "";
    const qrLinkAdmin = qrLinkPlayer.replace("player", "admin");
    const qrLinkReferee = qrLinkPlayer.replace("player", "referee");

    let Components = user?.role?.includes("owner")
      ? [
          <div>
            <div>
              <div
                className="clickable"
                onClick={() =>
                  navigateTo("/subscriptions", this.props.history, this.context)
                }
              >
                <ActiveSubscription
                  currentTier={this.state.subscription}
                  buttons={false}
                  defaultCard={this.state.customer?.defaultCard}
                />
              </div>
              <br />
              <OrgOptions
                org={this.props.org}
                openOptions={this.openOptions}
                optionsOpen={this.state.optionsOpen}
                openLogo={this.openLogo}
                logoOpen={this.state.logoOpen}
                bannerOpen={this.state.bannerOpen}
                openBanner={this.openBanner}
                openDelete={this.openDelete}
                deleteOpen={this.state.deleteOpen}
                handleDelete={() => this.toggleModal("purgeOpen")}
                navToSettings={() =>
                  this.props.history.push("/league?q=options")
                }
              />
            </div>
          </div>,
          <QuickLinks
            fullBoard={true}
            singleFile={true}
            org={this.props.org}
          />,
          <OrgUserLinks
            user={user}
            sendRegistrationEmail={this.setEmailPrompt}
            qrLinks={{
              player: qrLinkPlayer,
              referee: qrLinkReferee,
              admin: qrLinkAdmin,
            }}
          />,
        ]
      : [];

    if (pendingAccounts.length > 0)
      Components.splice(
        2,
        0,
        <OrgPendingAccounts
          user={user}
          pendingAccounts={pendingAccounts}
          onRemovePendingAccount={this.setPendingAccount}
        />
      );

    return !user.orgID ? (
      <OrgForm />
    ) : (
      <React.Fragment>
        <SideBySideView
          minWidth={widthToSwitch}
          separateRows={true}
          Components={Components}
        />
        {user.orgID && user.role.includes("admin") && (
          <React.Fragment>
            <hr />
            <OrgTable
              loadUsers={this.loadUsers}
              onDelete={this.setRemoveUserDialog}
              users={this.state.users}
              history={this.props.history}
              location={this.props.location}
              sport={this.props.org?.sport}
              loaded={this.state.usersLoaded}
            />
          </React.Fragment>
        )}
        <PageBottom />
        <CustomConfirm
          dialog={pendingDialog}
          isOpen={removePendingOpen}
          close={this.toggleModal}
          id="removePendingOpen"
          callback={this.handleRemovePendingAccount}
          yesNo={true}
          focused={true}
        />
        <CustomConfirm
          dialog={`Are you sure you want to purge this league?%You will be asked to reconfirm before data is deleted.`}
          isOpen={purgeOpen}
          close={this.toggleModal}
          id="purgeOpen"
          callback={() => this.toggleModal("purgeTwoOpen")}
          yesNo={true}
          focused={true}
          split="%"
        />
        <CustomConfirm
          dialog={`All divisions, teams, players and matches will be deleted. This cannot be undone.%
            Please type the league name to confirm: ${org?.name}`}
          isOpen={purgeTwoOpen}
          close={this.toggleModal}
          id="purgeTwoOpen"
          callback={this.handleDelete}
          focused={true}
          split="%"
          holdSubmitButton={{
            enteredValue: enteredLeagueName,
            mustMatchValue: this.props.org?.name,
          }}
        >
          <NonFormInput
            name="leagueName"
            type="text"
            label="League Name"
            value={enteredLeagueName}
            onChange={(event) =>
              this.setState({ enteredLeagueName: event.target.value })
            }
          />
        </CustomConfirm>
        <CustomConfirm
          dialog={removeUserDialog}
          isOpen={removeUserOpen}
          close={this.toggleModal}
          id="removeUserOpen"
          callback={this.handleRemoveUser}
          yesNo={true}
          focused={true}
          split="%"
        />
        <CustomPrompt
          dialog={emailDialog}
          isOpen={emailOpen}
          close={this.toggleModal}
          id="emailOpen"
          callback={this.sendRegistrationEmail}
        />
      </React.Fragment>
    );
  }
}

export default Organization;
