import React from "react";
import Form from "../common/form/form";
import WarningHeader from "../common/pageComponents/warningHeader";
import Joi from "joi-browser";
import { getCurrentUser } from "../../services/userService";
import allowables from "../../utils/allowables";
import MiniHeader from "../common/pageComponents/miniHeader";
import toast from "../../utils/toast";
import { sendContactUsEmail } from "../../services/emailService";
import HeaderContext from "../../context/headerContext";

class ContactUsForm extends Form {
  static contextType = HeaderContext;

  state = {
    data: {
      name: "",
      email: "",
      subject: "",
      body: "",
    },
    autofocus: "name",
    baseState: { name: "", email: "", subject: "", body: "" },
    errors: {},
  };

  componentDidMount() {
    const user = getCurrentUser();
    if (user) {
      const data = {
        name: allowables.splitName(user.name),
        email: user.email,
        subject: "",
        body: "",
      };
      this.setState({ data, autofocus: "subject" });
    }
  }

  schema = {
    name: Joi.string().required().max(99).label("Name"),
    email: Joi.string().email().required().max(999).label("Email"),
    subject: Joi.string().required().min(1).max(999).label("Subject"),
    body: Joi.string().required().min(10).max(2000).label("Question"),
  };

  handleReset = () => {
    this.context.setLoading(true);
    this.setState({
      data: this.state.baseState,
    });
    // resetting data will cause all validation errors to show
    // reset the errors afterwards to clear them
    setTimeout(() => {
      this.setState({ errors: {} });
      this.context.setLoading(false);
    }, 10);
  };

  doSubmit = async () => {
    this.context.setLoading(true);
    const res = await sendContactUsEmail(this.state.data);
    if (res.status === 200) {
      toast.success(res.data);
      this.handleReset();
      if (this.props.successCallback) this.props.successCallback();
    } else {
      toast.error(res.data);
      this.props.errorCallback();
    }
    this.setState({ apiError: res.data });
    this.context.setLoading(false);
  };

  render() {
    return (
      <div className="centered-small-input-area">
        <MiniHeader>Contact Ultimate Scoreboard</MiniHeader>
        <div className="form-divided-section">
          <form onSubmit={this.handleSubmit}>
            {this.renderInput(
              "name",
              "Name",
              this.state.autofocus === "name" ? "autofocus" : ""
            )}
            {this.renderInput("email", "Email")}
            {this.renderInput(
              "subject",
              "Subject",
              this.state.autofocus === "subject" ? "autofocus" : ""
            )}
            {this.renderTextArea(
              "body",
              "",
              "",
              "5",
              null,
              null,
              "Enter your question..."
            )}
            <WarningHeader>{this.state.apiError}</WarningHeader>
            <div className="row">
              <div className="col text-left">
                {this.renderResetButton("Clear Form")}
              </div>
              <div className="col text-right">
                {this.renderValidatedButton("Submit")}
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default ContactUsForm;
