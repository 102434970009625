import React from "react";
import { SiMicrosoftexcel } from "react-icons/si";
import allowables from "../../../utils/allowables";
import TooltipButton from "../tooltip/tooltipButton";

const ExcelDownload = ({ data, columns, dataName, tooltipDirection }) => {
  const downloadToExcel = () => {
    let csvRows = [columns.join(",")];

    data.forEach((row) => {
      let csvRow = [];
      columns.forEach((col) => {
        if (col.toLowerCase().includes("name"))
          csvRow.push(allowables.splitName(String(row[col]).replace(",", ";")));
        else csvRow.push(String(row[col]).replace(",", ";"));
      });
      csvRows.push(csvRow.join(","));
    });

    // download the data as csv
    const blob = new Blob([csvRows.join("\n")], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("href", url);
    a.setAttribute("download", `${dataName}.csv`);
    a.click();
  };

  return (
    <div style={{ paddingTop: 20 }}>
      <TooltipButton
        buttonClass="btn btn-success btn-sm"
        tooltipText={`Download ${dataName} to Excel`}
        tooltipDirection={tooltipDirection || "top-right"}
        onClick={downloadToExcel}
        testId={"excel-download"}
      >
        <SiMicrosoftexcel />
      </TooltipButton>
    </div>
  );
};

export default ExcelDownload;
