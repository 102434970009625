import React, { useContext } from "react";
import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";
import OrgContext from "../../../context/orgContext";

const DiamondInningSegment = ({ outs }) => {
  const org = useContext(OrgContext);
  const outsPerInning = org?.misc?.diamondSportOptions?.outsPerInning || 3;

  const calcInning = () => {
    let batting = "away";
    if (
      outs.away % outsPerInning === 0 &&
      (outs.home % outsPerInning !== 0 || outs.home !== outs.away)
    )
      batting = "home";
    if (outs.home > outs.away) batting = "error";
    const totalOuts = outs.home + outs.away;
    const thisInning =
      (totalOuts - (totalOuts % (outsPerInning * 2))) / (outsPerInning * 2) + 1;
    return { batting, thisInning };
  };
  return (
    <div className="row">
      <div className="col text-right">
        <h6 className="text-center">
          <b>Total Outs</b>
        </h6>
        <h5 className="text-center" aria-label="away-team-outs">
          <b>{outs.away}</b>
        </h5>
      </div>
      <div className="col text-center">
        <h5>
          <b>Inning</b>
        </h5>
        <div className="row">
          <div className="col">
            {calcInning().batting === "away" && <AiFillCaretLeft size={30} />}
          </div>
          <div className="col">
            <h4>
              <b>{calcInning().thisInning}</b>
            </h4>
          </div>
          <div className="col">
            {calcInning().batting === "home" && <AiFillCaretRight size={30} />}
          </div>
        </div>
      </div>
      <div className="col text-left">
        <h6 className="text-center">
          <b>Total Outs</b>
        </h6>
        <h5 className="text-center" aria-label="home-team-outs">
          <b>{outs.home}</b>
        </h5>
      </div>
    </div>
  );
};

export default DiamondInningSegment;
