import React, { useState } from "react";
import orderBy from "lodash/orderBy";

import MiniHeader from "../common/pageComponents/miniHeader";
import SearchBox from "../common/dataSorting/searchBox";
import MatchesCardView from "../matches/matchesCardView";
import SideBySideView from "../common/pageComponents/sideBySideView";
import ModalPicker from "../common/dataSorting/modalPicker";
import IconRender from "../common/icons/iconRender";

const typeMap = {
  regular: "Regular Season",
  group: "Group",
  playoff: "Playoff",
  seeding: "Seeding",
  friendly: "Friendly",
};

const SpectateMatches = ({
  matches,
  onSelectMatch,
  status,
  onSelectStatus,
  selectedType,
  onSelectType,
  onHeaderClick,
}) => {
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState({ path: "dateTime", order: "asc" });

  const getMatches = () => {
    let filteredMatches = [...matches];

    filteredMatches = filteredMatches.filter((m) => {
      let include = search
        ? m.homeTeamName.toLowerCase().includes(search.toLowerCase()) ||
          m.awayTeamName.toLowerCase().includes(search.toLowerCase())
        : true;
      if (typeMap[selectedType] && include) {
        include = typeMap[selectedType] === m.type;
      }
      if (status !== "all" && include) {
        if (status === "upcoming")
          include = !m.matchComplete && !m.matchAccepted;
        else if (status === "accepted") include = m.matchAccepted;
        else if (status === "complete") include = m.matchComplete;
      }
      return include;
    });

    filteredMatches = orderBy(filteredMatches, sort.path, sort.order);

    return filteredMatches;
  };

  const sortMatches = (path) => {
    let order = sort.order;
    if (path.slice(0, 4) === sort.path.slice(0, 4))
      order = order === "asc" ? "desc" : "asc";
    else order = "asc";
    setSort({ path, order });
  };

  const sortOptions = [
    { _id: "dateTime", name: "Date & Time" },
    { _id: "homeTeamName", name: "Home Team Name" },
    { _id: "awayTeamName", name: "Away Team Name" },
    { _id: "fieldName", name: "Field Name" },
    { _id: "type", name: "Match Type" },
  ];

  return (
    <div>
      <MiniHeader onClick={onHeaderClick}>Scorecards</MiniHeader>
      <div className="form-divided-section fill">
        {matches.length > 0 ? (
          <div>
            <SideBySideView
              minWidth={300}
              Components={[
                <ModalPicker
                  buttonLabel="Type"
                  label="Match Type"
                  options={[
                    { _id: "all", name: "All" },
                    { _id: "regular", name: "Regular" },
                    { _id: "group", name: "Group" },
                    { _id: "playoff", name: "Playoff" },
                    { _id: "seeding", name: "Seeding" },
                    { _id: "friendly", name: "Friendly" },
                  ]}
                  onSelect={(type) => onSelectType(type._id)}
                  selectedValue={selectedType}
                />,
                <ModalPicker
                  buttonLabel="Status"
                  label="Match Status"
                  options={[
                    { _id: "all", name: "All" },
                    { _id: "upcoming", name: "Upcoming" },
                    { _id: "complete", name: "Complete" },
                    { _id: "accepted", name: "Accepted" },
                  ]}
                  selectedValue={status}
                  onSelect={(status) => onSelectStatus(status._id)}
                />,
              ]}
            />
            <ModalPicker
              buttonLabel="Sort"
              label="Sort Matches"
              options={sortOptions}
              selectedValue={sort.path}
              selectedValueLabel={
                <div>
                  {sortOptions.find((o) => o._id === sort.path)?.name}&nbsp;
                  <IconRender
                    name={sort.order === "asc" ? "chevronup" : "chevrondown"}
                  />
                </div>
              }
              onSelect={(path) => sortMatches(path._id)}
            />
            <SearchBox
              value={search}
              onChange={setSearch}
              placeholder="Search by team name..."
            />
            <MatchesCardView matches={getMatches()} onClick={onSelectMatch} />
          </div>
        ) : (
          <p className="text-center cardview-card">
            There are no scorecards in this division
          </p>
        )}
      </div>
    </div>
  );
};

export default SpectateMatches;
