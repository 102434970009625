import React from "react";

import MiniHeader from "./miniHeader";
import PageBottom from "./pageBottom";

const CookieAcceptancePrompt = ({
  cookiePromptActive,
  cookiesAccepted,
  cookiesDeclined,
  onAcceptCookies,
  onDeclineCookies,
}) => {
  if (!cookiePromptActive || cookiesAccepted || cookiesDeclined) return null;

  return (
    <div
      style={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        marginLeft: "auto",
        marginRight: "auto",
        width: "85%",
        padding: 10,
        textAlign: "center",
        zIndex: 100,
      }}
    >
      <div>
        <MiniHeader>Please Accept Cookies</MiniHeader>
        <div className="form-divided-section">
          <p>
            We use cookies to enhance our site and analyze our traffic. For
            further information please see the Cookies section of our policies.
          </p>
          <div className="row">
            <div className="col-3">
              <button
                className="btn btn-block btn-dark btn-sm"
                onClick={onDeclineCookies}
              >
                Decline Cookies
              </button>
            </div>
            <div className="col">
              <button
                className="btn btn-block btn-info btn-sm"
                onClick={onAcceptCookies}
              >
                Accept Cookies
              </button>
            </div>
          </div>
          <PageBottom cookieOnly={true} />
        </div>
      </div>
    </div>
  );
};

export default CookieAcceptancePrompt;
