import React, { useState } from "react";
import { getCurrentUser } from "../../../services/userService";
import { CLink } from "../customs/customLinks";
import MobileAppLinks from "./mobileAppLinks";
import useWindowDimensions from "../../../utils/useWindowDimensions";
import MiniHeader from "./miniHeader";
import SideBySideView from "./sideBySideView";

const QuickLinks = ({ fullBoard, org, singleFile }) => {
  const [open, setOpen] = useState(true);
  const { width } = useWindowDimensions();
  const user = getCurrentUser();

  const isOwner = user && user.role.includes("owner");
  const canAdmin = user && user.role?.includes("admin");
  const canRef = user && (canAdmin || user.role?.includes("referee"));
  const player = user && user.role?.includes("player");
  const teamCaptain = user && user.role?.includes("captain") && user.teamID;

  let Components = [];
  if (canAdmin)
    Components.push(
      <div>
        {isOwner ? (
          <CLink path="/gettingstarted" color={true}>
            <h6>
              <i>Getting Started</i>
            </h6>
          </CLink>
        ) : null}
        <CLink path="/seasons?q=new">Create Season</CLink>
        <CLink path="/divisions?q=new">Create Division</CLink>
        <CLink path="/teams?q=new">Create Team</CLink>
        <CLink path="/players?q=new">Add Player</CLink>
        <CLink path="/fields?q=new">Add Field</CLink>
        <CLink path="/matches?q=new">Schedule Match</CLink>
      </div>
    );
  if (fullBoard && user)
    Components.push(
      <div>
        {canRef && (
          <React.Fragment>
            <CLink path="/matches?type=my%20matches">Referee Match</CLink>
          </React.Fragment>
        )}
        {teamCaptain && (
          <CLink path={"/teams?info&q=" + teamCaptain}>Manage My Team</CLink>
        )}
        {(canAdmin || player) && (
          <div>
            <CLink path="/matchreview">Played Matches</CLink>
            <CLink path="/reports?standings=">Tables & Brackets</CLink>
          </div>
        )}
        {player && org && org.spectatingAllowed && (
          <CLink path={`/${org.spectatingAllowed}`}>League Homepage</CLink>
        )}
        <CLink path="/help">Help and FAQs</CLink>
      </div>
    );

  return (
    <div className="row">
      <div className="col">
        <MiniHeader
          onClick={() => setOpen(open ? false : true)}
          iconName={open ? "chevronup" : "chevrondown"}
        >
          Quick Links
        </MiniHeader>
        {open && (
          <div className="form-divided-section">
            <SideBySideView
              Components={Components}
              minWidth={singleFile ? 9999 : 500}
              separateRows={true}
            />
          </div>
        )}
      </div>
      {!singleFile && (
        <div className="col">
          <MobileAppLinks standAlone={width > 500} />
        </div>
      )}
    </div>
  );
};

export default QuickLinks;
