import React from "react";
import MiniHeader from "../common/pageComponents/miniHeader";

const CodeOfConduct = ({ org }) => {
  return org?.codeOfConduct && org.codeOfConduct.length > 0 ? (
    <div>
      <MiniHeader>{org.name} Code of Conduct</MiniHeader>
      <div className="form-divided-section">
        {org.codeOfConduct.map((c, i) => (
          <div key={i}>
            <h6>
              <b>{c.header}</b>
            </h6>
            <p>{c.text}</p>
          </div>
        ))}
      </div>
    </div>
  ) : null;
};

export default CodeOfConduct;
