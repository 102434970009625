import React, { Component } from "react";
import toast from "../../utils/toast";

import {
  getSeasons,
  getDivisionsBySeason,
  getArchivedDivision,
} from "../../services/spectateService";
import HeaderContext from "../../context/headerContext";
import NonFormSelect from "../common/form/nonFormSelect";
import PageBottom from "../common/pageComponents/pageBottom";
import HorizontalButtons from "../common/dataSorting/horizontalButtons";
import StandingsTable from "../reports/standingsTable";
import PlayoffBracketCanvas from "../brackets/playoffBracketCanvas";
import SoccerLeaderboardTable from "../reports/soccer/soccerLeaderboardTable";
import FootballLeaderboardTable from "../reports/football/footballLeaderboardTable";
import DiamondLeaderboardTable from "../reports/diamond/diamondLeaderboardTable";
import SeasonDisplay from "../reports/seasonDisplay";
import { CLink } from "../common/customs/customLinks";

class ArchiveHome extends Component {
  static contextType = HeaderContext;
  state = {
    seasons: [],
    divisions: [],
    selectedSeason: "",
    selectedDivision: "",
    leaderboard: [],
    playoffMatches: [],
    standings: [],
    groupStandings: [],
    groups: [],
    selectedType: "standings",
    sport: "",
  };

  async componentDidMount() {
    this.context.setLoading(true);
    this.context.setProgress([1]);
    const query = this.props.location.search?.split("&");
    const selectedType = query.find((q) => q.includes("type"))?.split("=")[1];
    const selectedSeason = query
      .find((q) => q.includes("season"))
      ?.split("=")[1];
    if (selectedSeason && selectedSeason !== "undefined")
      await this.handleSelectSeason({ target: { value: selectedSeason } });
    const selectedDivision = query
      .find((q) => q.includes("division"))
      ?.split("=")[1];
    if (selectedDivision && selectedDivision !== "undefined")
      await this.handleSelectDivision({ target: { value: selectedDivision } });
    this.setState({ selectedType, selectedSeason, selectedDivision });
    const seasonsRes = await getSeasons(this.props.org._id, {
      callback: this.indicateProgress,
      bar: 0,
    });
    if (seasonsRes.status === 200) {
      this.setState({
        seasons: seasonsRes.data,
        sport: seasonsRes.data[0]?.sport.toLowerCase(),
      });
    } else toast.error(seasonsRes.data);
    this.context.setLoading(false);
  }

  indicateProgress = (progress, location) => {
    let { progress: currentProgress } = this.context;
    currentProgress[location.bar] =
      ((progress.loaded / progress.total) * 100) / currentProgress.length;
    this.context.setProgress(currentProgress);
  };

  pushHistoryString = (type, seasonID, divisionID) => {
    this.props.history.replace(
      `?type=${type}&season=${seasonID}&division=${divisionID}`
    );
  };

  handleSelectSeason = async (event) => {
    this.context.setLoading(true);
    this.context.setProgress([1]);
    const seasonID = event.target.value;
    const divisionsRes = await getDivisionsBySeason(seasonID, {
      callback: this.indicateProgress,
      bar: 0,
    });
    if (divisionsRes.status === 200) {
      this.setState({
        selectedSeason: seasonID,
        divisions: divisionsRes.data,
        selectedDivision: "",
      });
      this.pushHistoryString(
        this.state.selectedType,
        seasonID,
        this.state.selectedDivision
      );
    }
    this.context.setLoading(false);
  };

  handleSelectDivision = async (event) => {
    this.context.setLoading(true);
    this.context.setProgress([1]);
    const divisionID = event.target.value;
    const dataRes = await getArchivedDivision(
      divisionID,
      this.state.selectedSeason,
      {
        callback: this.indicateProgress,
        bar: 0,
      }
    );
    if (dataRes.status === 200) {
      this.pushHistoryString(
        this.state.selectedType,
        this.state.selectedSeason,
        divisionID
      );
      this.setState({ ...dataRes.data, selectedDivision: divisionID });
    }
    this.context.setLoading(false);
  };

  handleSelectType = (selectedType) => {
    this.setState({ selectedType });
    this.pushHistoryString(
      selectedType,
      this.state.selectedSeason,
      this.state.selectedDivision
    );
  };

  render() {
    const {
      seasons,
      selectedSeason,
      divisions,
      selectedDivision,
      selectedType,
      leaderboard,
      playoffMatches,
      standings,
      sport,
    } = this.state;

    const LeaderboardTable = sport
      ? sport.includes("soccer")
        ? SoccerLeaderboardTable
        : sport.includes("football")
        ? FootballLeaderboardTable
        : sport.includes("diamond")
        ? DiamondLeaderboardTable
        : null
      : null;

    return seasons.length === 0 ? (
      <div>
        <h5 className="text-center">
          There are no archived seasons for this league.
        </h5>
        <div className="text-center">
          <CLink path="/reports?standings=">
            Go to the Reports page to view current stats
          </CLink>
        </div>
      </div>
    ) : (
      <div>
        <div className="row">
          <div className="col">
            <NonFormSelect
              name="_id"
              label="Select Season:&nbsp;"
              options={seasons}
              onChange={this.handleSelectSeason}
              value={selectedSeason || "Make a selection..."}
            />
          </div>
          <div className="col">
            {selectedSeason && (
              <NonFormSelect
                name="_id"
                label="Select Division:&nbsp;"
                options={divisions}
                onChange={this.handleSelectDivision}
                value={selectedDivision || "Make a selection..."}
              />
            )}
          </div>
        </div>

        <HorizontalButtons
          buttons={["standings", "brackets", "leaderboards"]}
          selectedType={selectedType}
          onClick={this.handleSelectType}
        />
        <br />
        {!selectedDivision ? (
          <p>Please select a season and division</p>
        ) : (
          <div>
            <div className="row">
              <div className="col text-center">
                <SeasonDisplay
                  season={seasons.find((s) => s._id === selectedSeason)}
                />
              </div>
              <div className="col text-center">
                <SeasonDisplay
                  season={divisions.find((d) => d._id === selectedDivision)}
                  label="Division"
                />
              </div>
            </div>
            {selectedType === "standings" ? (
              <div>
                <StandingsTable
                  data={standings}
                  onSort={null}
                  sortColumn={this.sortColumn}
                  onChange={this.handleChange}
                  sport={sport}
                />
              </div>
            ) : selectedType === "brackets" ? (
              <PlayoffBracketCanvas matches={playoffMatches} spectate={true} />
            ) : selectedType === "leaderboards" && sport ? (
              <LeaderboardTable data={leaderboard} spectate={true} />
            ) : null}
          </div>
        )}

        <PageBottom />
      </div>
    );
  }
}

export default ArchiveHome;
