import React, { Component } from "react";
import ReactGA from "react-ga4";

import {
  registerUser,
  registerNewAdminAccount,
} from "../../services/userService";
import toast from "../../utils/toast";
import HeaderContext from "../../context/headerContext";
import NonFormSelect from "../common/form/nonFormSelect";
import WarningHeader from "../common/pageComponents/warningHeader";
import MiniHeader from "../common/pageComponents/miniHeader";
import CombinedRegistrationForm from "./combinedRegistrationForm";

class RegisterForm extends Component {
  static contextType = HeaderContext;
  state = {
    type: "",
    types: [
      { _id: "player", name: "Team Captain/Player" },
      { _id: "referee", name: "Referee/Umpire" },
      { _id: "owner", name: "League Owner/Organizer" },
    ],
    errors: {},
    roles: [{ id: "admin", name: "Admin" }],
    apiError: "",
    referralSource: "",
  };

  componentDidMount() {
    let referralSource = "";
    try {
      const query = this.props.location.search.slice(1).split("&");
      referralSource = query
        .find((q) => q.split("=")[0] === "ref")
        .split("=")[1];
    } catch (error) {
      //
    }
    this.setState({ referralSource });
  }

  indicateProgress = (progress, location) => {
    let { progress: currentProgress } = this.context;
    currentProgress[location.bar] =
      ((progress.loaded / progress.total) * 100) / currentProgress.length;
    this.context.setProgress(currentProgress);
  };

  handleSelectType = (event) => {
    this.setState({ type: event.target.value });
  };

  doSubmit = async (formData, formType) => {
    if (!this.state.type)
      return toast.error("Please make a selection for your registration type");
    if (this.state.type !== "owner")
      return "Only a single league owner can register directly. All other users or admins must request an invitation from an owner";
    this.context.setLoading(true);
    this.context.setProgress([1]);

    let data = { ...formData };

    data.email = data.email.toLowerCase().trim();

    if (formType === "register") {
      data.name = data.firstName + "%20%" + data.lastName;
      data.role = "admin";
      data.referralSource = this.state.referralSource;
      delete data.firstName;
      delete data.lastName;
      const response = await registerUser(data, {
        callback: this.indicateProgress,
        bar: 0,
      });
      if (response.status === 200) {
        ReactGA.event({
          category: "User",
          action: "register",
          label: "registered for new admin account from register page",
          nonInteraction: false,
        });
        return (window.location = "/league");
      } else {
        let apiError = "";
        if (response.data.length <= 5) {
          for (let message of response.data) apiError += message + " & ";
          apiError = apiError.substring(0, apiError.length - 3);
        } else apiError = response.data;
        this.setState({ apiError });
        toast.error(apiError);
      }
    } else if (formType === "login") {
      // register a new admin account onto the existing profile
      const res = await registerNewAdminAccount(
        {
          email: data.email,
          password: data.password,
        },
        {
          callback: this.indicateProgress,
          bar: 0,
        }
      );
      if (res.status === 200) {
        ReactGA.event({
          category: "User",
          action: "register",
          label: "registered for new admin account on an existing profile",
          nonInteraction: false,
        });
        // navigate to the create new org page
        return (window.location = "/league");
      } else {
        this.setState({ apiError: res.data });
        toast.error(res.data);
      }
    }
    this.context.setLoading(false);
  };

  render() {
    const { type } = this.state;

    return (
      <div className="centered-small-input-area">
        <MiniHeader>Register a New League</MiniHeader>
        <div className="form-divided-section">
          <NonFormSelect
            name="_id"
            label="I am a..."
            options={this.state.types}
            onChange={this.handleSelectType}
            value={type}
            overrideDefaultPlaceholder="..."
          />
          {type === "owner" ? (
            <CombinedRegistrationForm onSubmit={this.doSubmit} />
          ) : type ? (
            <div className="text-center">
              <WarningHeader>You must be a league owner</WarningHeader>
              <p>
                Only the league owner or organizer should register here.
                <br />
                {type === "player"
                  ? "Players and team captains should register using the link specific to their league."
                  : type === "referee"
                  ? "Referees, umpires, and other staff will receive an invitation from the league owner."
                  : ""}
              </p>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default RegisterForm;
