import React, { useState, useEffect, useContext } from "react";
import HeaderContext from "../../../context/headerContext";
import { getAllPayments } from "../../../services/paymentService";
import toast from "../../../utils/toast";
import Table from "../../common/table/table";
import orderBy from "lodash/orderBy";
import IconRender from "../../common/icons/iconRender";
import allowables from "../../../utils/allowables";
import MiniHeader from "../../common/pageComponents/miniHeader";
import SearchBox from "../../common/dataSorting/searchBox";
import Switch from "../../common/form/switch";
import WarningHeader from "../../common/pageComponents/warningHeader";
import { CLink } from "../../common/customs/customLinks";
import { getPiLink } from "../../../utils/paymentsUtil";

const OffCyclePaymentTable = () => {
  const { setLoading } = useContext(HeaderContext);

  const [payments, setPayments] = useState([]);
  const [search, setSearch] = useState("");
  const [sortColumn, setSortColumn] = useState({
    path: "datePaid",
    order: "desc",
  });
  const [showArchived, setShowArchived] = useState(false);

  const getData = async () => {
    setLoading(true);
    const res = await getAllPayments(showArchived);
    if (res.status === 200) {
      setPayments(res.data);
    } else toast.error(res.data);
    setLoading(false);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showArchived]);

  const getPageData = () => {
    let sortedPayments = orderBy(
      payments,
      [sortColumn.path],
      [sortColumn.order]
    );

    let filteredPayments = sortedPayments.filter((p) => {
      const lcItem = (p.name + p.email + p.divisionName).toLowerCase();
      return lcItem.includes(search.toLowerCase());
    });

    return filteredPayments;
  };

  const columns = [
    {
      path: "datePaid",
      label: "Date",
      content: (p) => allowables.shortDate(p.datePaid, true),
    },
    { path: "name", label: "Name" },
    {
      path: "email",
      label: "Email",
      content: (p) => <a href={`mailto:${p.email}`}>{p.email}</a>,
    },
    {
      path: "divisionName",
      label: "Division",
      content: (p) =>
        p.divisionID?._id ? (
          <CLink path={`/divisions?info&q=${p.divisionID._id}`}>
            {p.divisionName}
          </CLink>
        ) : (
          p.divisionName
        ),
    },
    {
      path: "amount",
      label: "Amount",
      content: (p) =>
        `${p.amount.toFixed(2)} ${p.orgID?.stripe?.currency?.toUpperCase()}`,
    },
    {
      path: "paymentIntent",
      label: "Link",
      content: (p) =>
        p.paymentIntent && p.orgID?.stripe?.accountID ? (
          <a
            href={getPiLink(p.paymentIntent, p.orgID)}
            target="_blank"
            rel="noopener noreferrer"
          >
            View In Stripe <IconRender name="externallink" />
          </a>
        ) : (
          ""
        ),
    },
  ];

  return (
    <div>
      <MiniHeader>Off Cycle Payments</MiniHeader>
      <div className="form-divided-section text-center">
        <p>
          Off Cycle Payments are flexible payments that can be created at any
          time. The amount is determined on a per-division basis, and while
          these payments are typically used to charge fees for substitutes to
          play in the league, they can be applied to any purpose as needed.
          <br />
          The Payment Link and QR Code can be found on each division page.
        </p>
        <button className="btn btn-block btn-info" onClick={getData}>
          Reload Payments
        </button>
      </div>

      <div className="row">
        <div className="col">
          <SearchBox
            value={search}
            onChange={setSearch}
            placeholder="Search by name, email, or division..."
          />
        </div>
        <div className="col-2">
          <Switch
            onChange={() => setShowArchived(!showArchived)}
            value={showArchived}
            label={"Show Archived"}
          />
        </div>
      </div>
      {payments.length ? (
        <Table
          columns={columns}
          data={getPageData()}
          sortColumn={sortColumn}
          onSort={setSortColumn}
          keyProperty={"_id"}
          tableClass="table-custom"
          headerClass="thead-custom"
          bodyClass="tbody-custom"
          highlightOnHover={true}
        />
      ) : (
        <WarningHeader>There are no payments to display</WarningHeader>
      )}
    </div>
  );
};

export default OffCyclePaymentTable;
