import React from "react";
import Spinner from "react-bootstrap/Spinner";
import MainTitle from "./mainTitle";

const Loading = ({ setWidth, orientation }) => {
  return (
    <div className="text-center">
      <MainTitle setWidth={setWidth} orientation={orientation} />
      <br />
      <Spinner animation="grow" className="bg-info" />
    </div>
  );
};

export default Loading;
