import React, { useState, useContext } from "react";

import MiniHeader from "../../common/pageComponents/miniHeader";
import TooltipButton from "../../common/tooltip/tooltipButton";
import Pricing from "./pricing";
import OrgContext from "../../../context/orgContext";

const Tier = ({
  tiers,
  onSelectTier,
  selectedTier,
  onClickSubscribe,
  nonAuthed,
  buttonDisabled,
}) => {
  const org = useContext(OrgContext);
  const [featuresOpen, setFeaturesOpen] = useState(true);

  return (
    <React.Fragment>
      <MiniHeader
        onClick={() => setFeaturesOpen(!featuresOpen)}
        iconName={featuresOpen ? "chevronup" : "chevrondown"}
      >
        Subscription Features
      </MiniHeader>
      {featuresOpen ? (
        <div className="form-divided-section">
          <Pricing drilledTiers={tiers} org={org} />
          <p className="text-center">
            <b>
              Need more teams, have a specific feature request, or need us to
              add a new sport? Contact our sales team at{" "}
              <a href="mailto:sales@ultimatescoreboard.com">
                sales@ultimatescoreboard.com
              </a>
              .
            </b>
          </p>
        </div>
      ) : null}
      {!nonAuthed && (
        <div>
          <MiniHeader>Select Your Subscription</MiniHeader>
          <div className="form-divided-section">
            <table className="table table-custom">
              <thead className="thead-custom">
                <tr>
                  <th>Subscription Cycle</th>
                  {tiers.map((t) => (
                    <th key={t.id}>{t.name}</th>
                  ))}
                </tr>
              </thead>
              <tbody className="tbody-custom">
                <tr>
                  <td>Monthly</td>
                  {tiers.map((t) => (
                    <td
                      key={t.id}
                      className={
                        "clickable" +
                        (selectedTier.id === t.id &&
                        selectedTier.cycle.includes("monthly")
                          ? " bg-info text-white"
                          : "")
                      }
                      onClick={() => onSelectTier(t, "monthly")}
                    >
                      ${(t.unit_amounts.monthly.unit_amount / 100).toFixed(2)}
                    </td>
                  ))}
                </tr>
                <tr>
                  <td>Yearly</td>
                  {tiers.map((t) => (
                    <td
                      key={t.id}
                      className={
                        "clickable" +
                        (selectedTier.id === t.id &&
                        selectedTier.cycle.includes("yearly")
                          ? " bg-info text-white"
                          : "")
                      }
                      onClick={() => onSelectTier(t, "yearly")}
                    >
                      ${(t.unit_amounts.yearly.unit_amount / 100).toFixed(2)}
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>

            <div className="text-right">
              <TooltipButton
                tooltipText={
                  buttonDisabled ? (
                    <div style={{ width: 200 }} className="text-center">
                      <p>
                        <b>You are already subscribed to this tier</b>
                      </p>
                      <p>
                        Please select a different tier to change your
                        subscription
                      </p>
                    </div>
                  ) : (
                    ""
                  )
                }
                tooltipDirection="top-left"
                onClick={onClickSubscribe}
                buttonClass="btn btn-sm btn-info"
                disabled={buttonDisabled}
              >
                Go to confirmation page
              </TooltipButton>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Tier;
