import React, { useState } from "react";

import TeamMessages from "./teamMessages";

const MessageCenter = (props) => {
  const [messageType] = useState("team");

  return messageType === "team" ? <TeamMessages {...props} /> : null;
};

export default MessageCenter;
