import React from "react";
import { TiUserDelete } from "react-icons/ti";
import allowables from "../../utils/allowables";
import MiniHeader from "../common/pageComponents/miniHeader";

const OrgPendingAccounts = ({
  user,
  pendingAccounts,
  onRemovePendingAccount,
}) => {
  return (
    <React.Fragment>
      {user.role.includes("owner") && pendingAccounts.length > 0 && (
        <React.Fragment>
          <MiniHeader>Pending Accounts</MiniHeader>
          <div className="form-divided-section">
            {pendingAccounts.map((a) => (
              <div
                className="row"
                key={a.email}
                style={{ borderBottom: "1px solid #f2f2f2" }}
              >
                <div className="col-2">
                  <button
                    onClick={() => onRemovePendingAccount(a)}
                    className="btn btn-dark btn-xsm"
                    data-testid="remove-pending-account"
                  >
                    <TiUserDelete />
                  </button>
                </div>
                <div className="col text-left">
                  {a.email} -{" "}
                  {a.regType.includes("referee")
                    ? allowables.refOrUmp(user.sport)
                    : allowables.capLetterOne(a.regType)}
                </div>
              </div>
            ))}
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default OrgPendingAccounts;
