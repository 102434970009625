import React from "react";

import allowables from "../../../utils/allowables";
import { getTierDescription, freeTier } from "../../../utils/paymentsUtil";
import MiniHeader from "../../common/pageComponents/miniHeader";
import WarningHeader from "../../common/pageComponents/warningHeader";

const ActiveSubscription = ({
  currentTier,
  onClickSubscribe,
  onRetryLastCharge,
  buttons,
  openInvoice,
  defaultCard,
}) => {
  if (!currentTier) currentTier = freeTier;

  const onlyTrialing = currentTier.status === "trialing" && !defaultCard;

  const headerWarning =
    currentTier.status === "past_due"
      ? "Your payment is past due. Update your payment card to continue access."
      : currentTier.status === "trialing"
      ? "You are on a free trial."
      : currentTier.cancel_at_period_end
      ? "Resubscribe to continue full access."
      : currentTier.id !== "free" && !defaultCard
      ? "Add a payment card to continue full access."
      : "";

  const nextBillDate =
    currentTier.status === "past_due"
      ? ""
      : (currentTier.cancel_at_period_end || !defaultCard
          ? "Subscription will end on "
          : "Subscription will next bill on ") +
        allowables.dateTime(currentTier.current_period_end * 1000, true).date;

  return (
    <div>
      <MiniHeader>Active Subscription Tier</MiniHeader>
      <div className="form-divided-section text-center">
        {headerWarning ? <WarningHeader>{headerWarning}</WarningHeader> : null}
        <div>
          <b>{getTierDescription(currentTier, true)}</b>
          {currentTier.id !== "free" ? nextBillDate : null}
        </div>
        {buttons && currentTier.id !== "free" ? (
          <div className="row" style={{ marginTop: 5 }}>
            <div className="col">
              {!onlyTrialing ? (
                <button
                  className="btn btn-sm btn-secondary btn-block"
                  onClick={() => openInvoice(true)}
                >
                  View Invoice
                </button>
              ) : null}
            </div>
            <div className="col">
              {!currentTier.cancel_at_period_end && (
                <button
                  className={`btn btn-sm btn-${
                    onlyTrialing ? "info" : "dark"
                  } btn-block`}
                  onClick={() => onClickSubscribe(null, true, onlyTrialing)}
                >
                  {onlyTrialing ? "Continue" : "Cancel"} Subscription
                </button>
              )}
            </div>
          </div>
        ) : null}
        {buttons && defaultCard && currentTier.status === "past_due" ? (
          <div className="pop-box">
            <p>
              Your payment was unsuccessful. Click the button below to retry the
              payment and reactivate your subscription.
            </p>
            <button
              className="btn btn-block btn-info"
              onClick={onRetryLastCharge}
            >
              Retry Payment
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ActiveSubscription;
