import React from "react";
import TableBody from "./tableBody";
import { FaSortDown, FaSortUp } from "react-icons/fa";

const TableMultiHeader = ({
  headerColumns,
  columns,
  tableData,
  sortColumn,
  onSort,
  keyProperty,
  headerClass,
  tableClass,
  backgroundKey,
  backgroundStyle,
  onSelect,
  highlightOnHover,
  initialSortOrder = "asc",
}) => {
  const raiseSort = (path) => {
    const newSortColumn = { ...sortColumn };
    if (newSortColumn.path === path)
      newSortColumn.order = newSortColumn.order === "asc" ? "desc" : "asc";
    else {
      newSortColumn.path = path;
      newSortColumn.order = path?.toLowerCase().includes("name")
        ? "asc"
        : initialSortOrder;
    }
    onSort(newSortColumn);
  };

  const renderSortIcon = (column) => {
    if (sortColumn) {
      if (column.path !== sortColumn.path) return null;
      if (sortColumn.order === "asc") return <FaSortUp />;
      return <FaSortDown />;
    }
  };

  return (
    <React.Fragment>
      <table className={"table " + tableClass}>
        <thead className={headerClass}>
          {headerColumns.map((c, i) => (
            <tr key={i}>
              {c.map((h, ii) => {
                const lastCol = i + 1 !== headerColumns.length;
                return !h.rowspan ? (
                  <th
                    key={ii}
                    colSpan={h.colspan || 1}
                    rowSpan={h.rowspan || 1}
                    className={"clickable text-center"}
                    onClick={!lastCol ? () => raiseSort(h.path) : () => {}}
                  >
                    {h.label} {!lastCol ? renderSortIcon(h) : ""}
                  </th>
                ) : (
                  <th key={ii}></th>
                );
              })}
            </tr>
          ))}
        </thead>
        <TableBody
          data={tableData}
          columns={columns}
          keyProperty={keyProperty}
          backgroundKey={backgroundKey}
          backgroundStyle={backgroundStyle}
          onSelect={onSelect}
          highlightOnHover={highlightOnHover}
        />
      </table>
    </React.Fragment>
  );
};

export default TableMultiHeader;
