import React, { useState } from "react";

import SearchBox from "../common/dataSorting/searchBox";
import MiniHeader from "../common/pageComponents/miniHeader";
import TeamsCardView from "../teams/teamsCardView";

const SpectateTeams = ({ teams, onHeaderClick }) => {
  const [query, setQuery] = useState("");

  const filterTeams = () => {
    if (query)
      return teams.filter((t) =>
        t.name.toLowerCase().includes(query.toLowerCase())
      );
    else return teams;
  };

  return (
    <React.Fragment>
      <MiniHeader onClick={onHeaderClick}>Teams</MiniHeader>
      <div className="form-divided-section fill">
        {teams.length > 0 ? (
          <div>
            <SearchBox
              placeholder="Search teams..."
              value={query}
              onChange={setQuery}
            />
            <TeamsCardView teams={filterTeams()} />
          </div>
        ) : (
          <p className="text-center cardview-card">
            There are no teams in this division
          </p>
        )}
      </div>
    </React.Fragment>
  );
};

export default SpectateTeams;
