import React from "react";

import useWindowDimensions from "./../../utils/useWindowDimensions";
import MatchesTable from "./matchesTable";
import MatchesCardView from "./matchesCardView";
import allowables from "../../utils/allowables";
import { getCurrentUser } from "./../../services/userService";

const MatchesTableCardSwitcher = ({
  org,
  cardView,
  matches,
  onClick,
  onDelete,
  onSort,
  sortColumn,
  location,
  onAddVideoUrl,
  onViewVideo,
  isReviewTable,
  onRefresh,
  onFieldClick,
}) => {
  const { width } = useWindowDimensions();
  const user = getCurrentUser();

  return (
    <React.Fragment>
      {cardView || width < 0 ? ( // this was updated to force always displaying the table view pending https://trello.com/c/fFnEYhH7/87-redo-card-components
        <MatchesCardView matches={matches} onClick={onClick} user={user} />
      ) : (
        <MatchesTable
          org={org}
          matchesByPage={matches}
          isReviewTable={isReviewTable}
          onDelete={onDelete}
          onSort={onSort}
          sortColumn={sortColumn}
          teams={allowables.teams(user.sport)}
          dummy={location.search.includes("dummymatches")}
          onAddVideoUrl={onAddVideoUrl}
          onViewVideo={onViewVideo}
          onRefresh={onRefresh}
          onFieldClick={onFieldClick}
        />
      )}
    </React.Fragment>
  );
};

export default MatchesTableCardSwitcher;
