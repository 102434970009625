import React, { Component } from "react";

import MatchReview from "./matchReview";
import CompletedMatchForm from "./completedMatchForm";
import HeaderContext from "../../context/headerContext";
import { getCurrentUser } from "../../services/userService";
import {
  getMatch,
  saveMatch,
  acceptMatch,
  restartMatch,
  submitMatchComment,
} from "../../services/matchService";
import { getPlayersForMatch } from "../../services/playerService";

class MatchReviewRedirect extends Component {
  static contextType = HeaderContext;
  state = {
    loading: true,
    comp: null,
    matchID: null,
    functions: {},
  };

  componentDidMount() {
    this.setup();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.search !== this.props.location.search) this.setup();
  }

  setup = () => {
    let comp = MatchReview;
    let functions = {};
    const query = this.props.location.search.slice(1).split("&");
    let matchID = query.find((q) => q.split("=")[0] === "q");
    if (matchID) {
      this.context.setLoading(true);
      matchID = matchID.split("=")[1];
      comp = CompletedMatchForm;
      functions = {
        getMatch,
        saveMatch,
        acceptMatch,
        restartMatch,
        submitMatchComment,
        getPlayersForMatch,
        getCurrentUser,
      };
    }
    this.setState({ comp, matchID, functions, loading: false });
  };

  render() {
    return this.state.loading || !this.state.comp ? null : (
      <this.state.comp
        {...this.props}
        matchID={this.state.matchID}
        {...this.state.functions}
      />
    );
  }
}

export default MatchReviewRedirect;
