import React from "react";

const OrgBannerLogo = ({ _id, name, width, fullSource }) => {
  return _id || fullSource ? (
    <img
      src={
        fullSource
          ? fullSource
          : "https://storage.googleapis.com/ultimatescoreboard-leaguelogos/" +
            _id +
            ".png"
      }
      alt={"bannerlogo"}
      width={width ? width : 350}
      height="auto"
      style={{ borderRadius: "5px" }}
    />
  ) : null;
};

export default OrgBannerLogo;
