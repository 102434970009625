import React, { useState, useEffect, useContext } from "react";

import BoxScore from "./boxScore";
import OrgContext from "../../../context/orgContext";
import { atBatName } from "../../../utils/diamondFunctions";

const DiamondReviewSegment = ({ match, teamOrder, liveEvents }) => {
  const org = useContext(OrgContext);
  const outsPerInning = org?.misc?.diamondSportOptions?.outsPerInning || 3;

  const events = liveEvents || match.chronoEventsNames;

  const [battingStats, setBattingStats] = useState({
    home: [],
    away: [],
  });
  const [pitchingStats, setPitchingStats] = useState({
    home: [],
    away: [],
  });

  useEffect(() => {
    setBattingStats({
      home: getBattingStats("home"),
      away: getBattingStats("away"),
    });
    setPitchingStats({
      home: getPitchingStats("home"),
      away: getPitchingStats("away"),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events]);

  const getTeamEvents = (team) => {
    return events.filter((e) => e.teamID === match[team + "TeamID"]);
  };

  const getBattingStats = (team) => {
    let teamEvents = getTeamEvents(team);
    let players = [];
    teamEvents.forEach((e) => {
      if (e.name === "stolen base") return;
      let hit = e.result === "hit" ? 1 : 0;
      const index = players.findIndex((p) => p._id === e._id);
      if (index >= 0)
        players.splice(index, 1, {
          _id: e._id,
          name: e.name,
          rbis: players[index].rbis + e.rbis,
          hits: players[index].hits + hit,
          atBats: players[index].atBats + (e.result === "walk" ? 0 : 1),
        });
      else
        players.push({
          _id: e._id,
          name: e.name,
          rbis: e.rbis,
          hits: hit,
          atBats: e.result === "walk" ? 0 : 1,
        });
    });
    return players.sort((a, b) => (a.rbis < b.rbis ? 1 : -1)).slice(0, 3);
  };

  const getOppTeamEvents = (team) => {
    return events.filter(
      (e) =>
        e.teamID === (team === "home" ? match.awayTeamID : match.homeTeamID)
    );
  };

  const getPitchingStats = (team) => {
    let oppTeamEvents = getOppTeamEvents(team);
    let players = [];
    oppTeamEvents.forEach((e) => {
      let out = e.result === "out" ? 1 : 0;
      let strikeout = e.inDepth === "strike out" ? 1 : 0;
      const index = players.findIndex((p) => p._id === e.pitcherID);
      if (index >= 0)
        players.splice(index, 1, {
          _id: e.pitcherID,
          name: e.pitcherName,
          outs: players[index].outs + out,
          runs: players[index].runs + e.rbis,
          strikeouts: players[index].strikeouts + strikeout,
        });
      else
        players.push({
          _id: e.pitcherID,
          name: e.pitcherName,
          outs: out,
          runs: e.rbis,
          strikeouts: strikeout,
        });
    });
    return players.sort((a, b) => (a.outs < b.outs ? 1 : -1)).slice(0, 2);
  };

  const comps = [
    {
      id: "batting",
      name: `${atBatName(org).ing}`,
      class: "bg-info border-main-theme",
      border: "border-main-theme-color-only",
    },
    {
      id: "pitching",
      name: "Pitching",
      class: "bg-secondary border-main-theme",
      border: "border-main-theme-color-only",
    },
  ];

  return (
    <div>
      <div className="d-flex justify-content-center">
        <BoxScore
          chronoEventsNames={events}
          teams={{
            away: { _id: match.awayTeamID, name: match.awayTeamName },
            home: { _id: match.homeTeamID, name: match.homeTeamName },
          }}
        />
      </div>
      {comps.map((c) => (
        <div key={c.id} style={{ marginBottom: 15 }}>
          <h5 className={"text-center " + c.class}>
            <b>{c.name}</b>
          </h5>
          <div className="row">
            {teamOrder.map((team, i) => {
              const players =
                c.id === "batting" ? battingStats[team] : pitchingStats[team];
              return (
                <div
                  key={team + i}
                  className={
                    "col text-" +
                    (i === 0 ? "right border-right " + c.border : "left")
                  }
                >
                  {players.map((p, pIdx) => {
                    const inningsPitched =
                      (p.outs - (p.outs % outsPerInning)) / outsPerInning +
                      (p.outs % outsPerInning) / 10;
                    return (
                      <div key={pIdx}>
                        {c.id === "batting" && (
                          <div>
                            <b>{p.name}</b>: {p.hits}-{p.atBats}, {p.rbis} RBI
                            {p.rbis !== 1 ? "s" : ""}
                          </div>
                        )}
                        {c.id === "pitching" && (
                          <div>
                            <b>{p.name}</b>: {inningsPitched} IP, {p.runs} Run
                            {p.runs !== 1 ? "s" : ""}, {p.strikeouts} K
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );
};

export default DiamondReviewSegment;
