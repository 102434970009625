import React, { useContext, useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";

import useSearchParams from "../../../hooks/useSearchParams";
import HeaderContext from "../../../context/headerContext";
import { getDivision } from "../../../services/divisionService";
import toast from "../../../utils/toast";
import MiniHeader from "../../common/pageComponents/miniHeader";
import WarningHeader from "../../common/pageComponents/warningHeader";
import OffCyclePaymentForm from "./offCyclePaymentForm";
import { getOrgbyID } from "../../../services/orgService";
import {
  getOffCyclePaymentCheckout,
  markOffCyclePaymentPaid,
} from "../../../services/paymentService";
import { stripeKey } from "../../../utils/paymentsUtil";
import OrgLogo from "../../common/pageComponents/orgLogo";
import QRCodeRender from "../../common/pageComponents/qrCode";
import allowables from "../../../utils/allowables";
import { renderPaymentInfoLine } from "../../../utils/textUtils";

const OffCyclePayment = ({ location, history }) => {
  const params = useSearchParams(location);
  const header = useContext(HeaderContext);
  const [division, setDivision] = useState(null);
  const [league, setLeague] = useState(null);
  const [payment, setPayment] = useState("");

  const getData = async () => {
    header.setLoading(true);
    const divisionsRes = await getDivision(params.divisionID);
    const leagueRes = await getOrgbyID(params.orgID);
    if (divisionsRes.status === 200) {
      setDivision(divisionsRes.data);
      if (leagueRes.status === 200) {
        setLeague(leagueRes.data);
      } else toast.error(leagueRes.data);
    } else toast.error(divisionsRes.data);
    header.setLoading(false);
  };

  const markPaid = async () => {
    header.setLoading(true);
    if (params.pid === "cancelled") return getData();

    const res = await markOffCyclePaymentPaid(
      params.pid,
      params.divisionID,
      params.orgID
    );
    if (res.status === 200) {
      history.replace(
        location.pathname +
          `?divisionID=${params.divisionID}&orgID=${params.orgID}`
      );
      toast.success("Payment Successful");
      setPayment(res.data);
    }
    header.setLoading(false);
  };

  useEffect(() => {
    if (params.divisionID) getData();
    if (params.pid) markPaid();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const handleGetCheckout = async (userInfo) => {
    header.setLoading(true);
    const checkoutRes = await getOffCyclePaymentCheckout(
      league._id,
      division._id,
      userInfo
    );
    if (checkoutRes.status === 200) {
      try {
        const stripePromise = await loadStripe(stripeKey(), {
          stripeAccount: checkoutRes.data.accountID,
        });
        return stripePromise.redirectToCheckout({
          sessionId: checkoutRes.data.sessionID,
        });
      } catch (error) {
        toast.error("Could not connect to Stripe. Please try again later");
      }
    } else toast.error(checkoutRes.data);
    header.setLoading(false);
  };

  return (
    <div>
      <div className="centered-small-input-area">
        {division && league ? (
          <div>
            <div className="text-center">
              <OrgLogo _id={league.logo} width={300} />
            </div>
            <MiniHeader>{league.name} Payment</MiniHeader>
            <div className="form-divided-section">
              <h5 className="text-center">
                <b>
                  {division.name}:{" "}
                  {division.payment?.offCyclePaymentName || "Off Cycle Payment"}
                </b>
              </h5>
              {division.payment?.offCyclePaymentAmount ? (
                <div>
                  {renderPaymentInfoLine(
                    "Payment Amount",
                    division.payment.offCyclePaymentAmount.toFixed(2) +
                      " " +
                      league.stripe?.currency?.toUpperCase(),
                    true
                  )}
                  {payment ? (
                    <div>
                      <WarningHeader overrideIconName="checkedbox">
                        Payment Complete
                      </WarningHeader>
                    </div>
                  ) : (
                    <OffCyclePaymentForm
                      onSubmit={handleGetCheckout}
                      navPath={location.pathname + location.search}
                    />
                  )}
                  <br />
                  <QRCodeRender
                    value={`${allowables.url}/offcyclepayment?orgID=${league._id}&divisionID=${division._id}`}
                  />
                </div>
              ) : (
                <WarningHeader>
                  Off cycle payments are not accepted for this division
                </WarningHeader>
              )}
            </div>
          </div>
        ) : (
          <WarningHeader>Division not found</WarningHeader>
        )}
      </div>
    </div>
  );
};

export default OffCyclePayment;
