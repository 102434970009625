import React from "react";
import ReactGA from "react-ga4";
import Form from "../common/form/form";
import Joi from "joi-browser";
import {
  getDivision,
  saveDivision,
  deleteDivision,
} from "../../services/divisionService";
import { getTeamsByDivision, deleteTeam } from "../../services/teamService";
import { getSeasons } from "../../services/seasonsService";
import toast from "../../utils/toast";
import TeamsTable from "../teams/teamsTable";
import { CLink, navigateBack, navigateTo } from "../common/customs/customLinks";
import CustomConfirm from "../common/customs/customConfirm";
import HeaderContext from "../../context/headerContext";
import WarningHeader from "../common/pageComponents/warningHeader";
import InfoComponent from "../common/info/infoComponent";
import { defaultTennisOptions } from "ultimatescoreboard-shared";
import allowables from "../../utils/allowables";
import SideBySideView from "../common/pageComponents/sideBySideView";
import { getCurrentUser } from "../../services/userService";
import QRCodeRender from "../common/pageComponents/qrCode";

class DivisionForm extends Form {
  static contextType = HeaderContext;
  state = {
    data: {
      name: "",
      maxPlayersPerTeam: "",
      offCyclePaymentAmount: 0,
      offCyclePaymentName: "",
      sets: defaultTennisOptions.sets,
      gamesPerSet: defaultTennisOptions.gamesPerSet,
      tiebreakPoints: defaultTennisOptions.tiebreakPoints,
      finalSetTiebreakPoints: defaultTennisOptions.finalSetTiebreakPoints,
      noAdScoring: defaultTennisOptions.noAdScoring,
      finalSetGamesPerSet: defaultTennisOptions.finalSetGamesPerSet,
    },
    errors: {},
    baseState: {
      name: "",
      maxPlayersPerTeam: "",
      offCyclePaymentAmount: 0,
      offCyclePaymentName: "",
      sets: defaultTennisOptions.sets,
      gamesPerSet: defaultTennisOptions.gamesPerSet,
      tiebreakPoints: defaultTennisOptions.tiebreakPoints,
      finalSetTiebreakPoints: defaultTennisOptions.finalSetTiebreakPoints,
      noAdScoring: defaultTennisOptions.noAdScoring,
      finalSetGamesPerSet: defaultTennisOptions.finalSetGamesPerSet,
    },
    source: "edit",
    teams: [],
    org: {},
    seasons: [],
    deleteOpen: false,
    deleteTeamOpen: false,
    deleteTeamDialog: "",
    rostersLocked: false,
    editingLocked: false,
  };

  schema = {
    name: Joi.string().required().min(3).max(50).label("Division Name"),
    seasonID: Joi.string().required().label("Season"),
    maxPlayersPerTeam: Joi.number()
      .integer()
      .optional()
      .allow("")
      .min(1)
      .max(99)
      .label("Maximum Players per Team"),
    offCyclePaymentAmount: Joi.number()
      .optional()
      .integer()
      .min(0)
      .allow("")
      .label("Off Cycle Payment Amount"),
    offCyclePaymentName: Joi.string()
      .optional()
      .min(3)
      .max(99)
      .allow("")
      .label("Off Cycle Payment Name"),
    sets: Joi.number()
      .optional()
      .integer()
      .min(1)
      .max(99)
      .label("Sets per Match"),
    gamesPerSet: Joi.number().optional().integer().min(1).max(99),
    tiebreakPoints: Joi.number().optional().integer().min(0).max(99),
    finalSetTiebreakPoints: Joi.number().optional().integer().min(0).max(99),
    noAdScoring: Joi.boolean().optional(),
    finalSetGamesPerSet: Joi.number().optional().integer().min(1).max(99),
  };

  async componentDidMount(forceEdit) {
    this.context.setLoading(true);
    this.context.setProgress([1, 1, 1]);
    if (this.props.org) this.setState({ org: this.props.org });
    const user = getCurrentUser();

    let { source, divisionID } = this.props;
    source = !user.role.includes("admin")
      ? "info"
      : forceEdit
      ? "edit"
      : source;
    const seasonsRes = await getSeasons(
      { callback: this.indicateProgress, bar: 2 },
      divisionID !== "new" && source.includes("info")
    );
    if (seasonsRes.status === 200) this.setState({ seasons: seasonsRes.data });
    if (divisionID === "new") {
      if (user.role.includes("admin")) return this.context.setLoading(false);
      else return this.props.history.replace("/not-found");
    }

    const division = await getDivision(divisionID, {
      callback: this.indicateProgress,
      bar: 0,
    });
    if (division.status !== 200)
      return this.props.history.replace("/not-found");

    if (source.includes("info")) {
      source = "info";
      const teamsRes = await getTeamsByDivision(divisionID, {
        callback: this.indicateProgress,
        bar: 1,
      });
      if (teamsRes.status === 200) this.setState({ teams: teamsRes.data });
      else toast.error(teamsRes.data);
    }

    this.setState({
      data: this.mapToViewModel(division.data),
      baseState: this.mapToViewModel(division.data),
      rostersLocked: division.data.rostersLocked,
      editingLocked: division.data.editingLocked,
      source,
    });
    this.context.setLoading(false);
  }

  indicateProgress = (progress, location) => {
    let { progress: currentProgress } = this.context;
    currentProgress[location.bar] =
      ((progress.loaded / progress.total) * 100) / currentProgress.length;
    this.context.setProgress(currentProgress);
  };

  navigate = (path) => {
    this.props.history.push({
      pathname: path.split("?")[0],
      search: path.split("?")[1],
      state: { from: this.props.history.location.pathname },
    });
    this.context.updateHeader();
  };

  mapToViewModel(division) {
    return {
      name: division.name || "",
      seasonID: division.seasonID || "",
      maxPlayersPerTeam: division.maxPlayersPerTeam || "",
      offCyclePaymentAmount: division.payment?.offCyclePaymentAmount || 0,
      offCyclePaymentName: division.payment?.offCyclePaymentName || "",
      sets:
        (division?.settings?.tennisOptions &&
          division.settings.tennisOptions.sets) ||
        defaultTennisOptions.sets,
      gamesPerSet:
        (division?.settings?.tennisOptions &&
          division.settings.tennisOptions.gamesPerSet) ||
        defaultTennisOptions.gamesPerSet,
      tiebreakPoints:
        (division?.settings?.tennisOptions &&
          division.settings.tennisOptions.tiebreakPoints) ??
        defaultTennisOptions.tiebreakPoints,
      finalSetTiebreakPoints:
        (division?.settings?.tennisOptions &&
          division.settings.tennisOptions.finalSetTiebreakPoints) ??
        defaultTennisOptions.finalSetTiebreakPoints,
      noAdScoring:
        (division?.settings?.tennisOptions &&
          division.settings.tennisOptions.noAdScoring) ||
        defaultTennisOptions.noAdScoring,
      finalSetGamesPerSet:
        (division?.settings?.tennisOptions &&
          division.settings.tennisOptions.finalSetGamesPerSet) ||
        defaultTennisOptions.finalSetGamesPerSet,
    };
  }

  getInfoData = () => {
    const season = this.state.seasons.find(
      (s) => s._id === this.state.baseState.seasonID
    );

    return {
      ...this.state.baseState,
      rostersLocked: this.state.rostersLocked,
      editingLocked: this.state.editingLocked,
      seasonName: season?.name,
      location: season?.location,
    };
  };

  handleSwitchToEditMode = () => {
    const editPath =
      this.props.history.location.pathname +
      this.props.history.location.search.replace("info&", "");
    navigateTo(editPath, this.props.history, this.context);
    this.componentDidMount(true);
  };

  toggleModal = (id) => {
    this.setState({ [id]: this.state[id] ? false : true });
  };

  handleDelete = async () => {
    this.context.setLoading(true);
    this.context.setProgress([1]);
    const response = await deleteDivision(this.props.divisionID, {
      callback: this.indicateProgress,
      bar: 0,
    });
    if (response.status === 200) {
      return navigateBack(this.props.history, this.context);
    } else toast.error(response.data);
    this.context.setLoading(false);
  };

  setDeleteDialog = (team) => {
    this.setState({
      deleteTeamDialog: `Are you sure you want to delete ${team.name}?`,
      deleteTeamOpen: true,
      selectedTeam: team,
    });
  };

  handleDeleteTeam = async () => {
    this.context.setLoading(true);
    this.context.setProgress([1, 1]);
    const { selectedTeam: team } = this.state;
    const response = await deleteTeam(team._id, {
      callback: this.indicateProgress,
      bar: 0,
    });
    if (response.status === 200) {
      ReactGA.event({
        category: "Team",
        action: "delete",
        label: "deleted team from teams table",
        nonInteraction: false,
      });
      const { data: teams } = await getTeamsByDivision(this.props.divisionID, {
        callback: this.indicateProgress,
        bar: 1,
      });
      toast.success(`${team.name} deleted successfully`);
      this.setState({ teams });
    } else toast.error(response.data);
    this.context.setLoading(false);
  };

  doSubmit = async () => {
    this.context.setLoading(true);
    this.context.setProgress([1]);
    const divisionID = this.props.divisionID;
    let data = { ...this.state.data };
    data.sport = this.state.org.sport;

    const payment = {
      offCyclePaymentAmount: Number(data.offCyclePaymentAmount),
      offCyclePaymentName: data.offCyclePaymentName || "",
    };
    data.payment = payment;

    const settings = {};
    if (allowables.translateSportName(this.props.org) === "Tennis") {
      settings.tennisOptions = {
        sets: Number(data.sets),
        gamesPerSet: Number(data.gamesPerSet),
        tiebreakPoints: Number(data.tiebreakPoints),
        finalSetTiebreakPoints: Number(data.finalSetTiebreakPoints),
        noAdScoring: data.noAdScoring || false,
        finalSetGamesPerSet: Number(data.finalSetGamesPerSet),
      };
    }
    data.settings = settings;

    delete data.offCyclePaymentAmount;
    delete data.offCyclePaymentName;
    delete data.sets;
    delete data.gamesPerSet;
    delete data.tiebreakPoints;
    delete data.finalSetTiebreakPoints;
    delete data.noAdScoring;
    delete data.finalSetGamesPerSet;

    const response = await saveDivision(divisionID, data, {
      callback: this.indicateProgress,
      bar: 0,
    });
    if (response.status === 200)
      return this.props.history.replace("/divisions");
    this.setState({ apiError: response.data });
    toast.error(response.data);
    if (this.state.apiError) {
      this.setState({ data: this.mapToViewModel(this.state.data) });
    }
    this.context.setLoading(false);
  };

  render() {
    const {
      source,
      teams,
      deleteOpen,
      seasons,
      deleteTeamOpen,
      deleteTeamDialog,
    } = this.state;
    const disabled = source === "info" ? "disabled" : "";
    const mappedSportName = allowables.translateSportName(this.props.org);
    const minWidth = 1000;
    const user = getCurrentUser();

    return (
      <div className="centered-small-input-area">
        {seasons.length === 0 ? (
          <div>
            <WarningHeader>
              You must create at least one season before adding a division
            </WarningHeader>
            <CLink
              path="/seasons?q=new"
              button={true}
              buttonStyle="btn-md btn-info btn-block"
            >
              Add New Season
            </CLink>
          </div>
        ) : (
          <div>
            {disabled ? (
              <InfoComponent
                data={this.getInfoData()}
                fields={[
                  {
                    name: "Season",
                    property: "seasonName",
                    link: `/seasons?info&q=${this.state.baseState.seasonID}`,
                  },
                  {
                    name: "Maximum Rostered Players",
                    property: "maxPlayersPerTeam",
                  },
                  { name: "Location", property: "location" },
                  {
                    name: "Editing Locked",
                    property: "editingLocked",
                    type: "checkbox",
                  },
                  {
                    name: "Rosters Locked",
                    property: "rostersLocked",
                    type: "checkbox",
                  },
                  {
                    name:
                      this.state.baseState.offCyclePaymentName ||
                      "Off Cycle Payment Amount",
                    property: "offCyclePaymentAmount",
                    type: "currency",
                    hide: this.state.baseState.offCyclePaymentAmount === 0,
                  },
                ]}
                handleSwitchToEditMode={
                  user.role.includes("admin")
                    ? this.handleSwitchToEditMode
                    : null
                }
                org={this.props.org}
              />
            ) : (
              this.renderWholeForm(
                "Division",
                "/divisions",
                {
                  listHeader: "Required Fields",
                  listItems: ["Division Name", "Season"],
                  direction: "right",
                  text: `Each division you create will track standings, brackets and leaderboards separately.
                    A team needs to be tied to a division at all times.`,
                },
                <div>
                  {this.renderInput(
                    "name",
                    "* Division Name",
                    "autofocus",
                    "text",
                    disabled,
                    "",
                    "",
                    {
                      header: "Division Name",
                      text: "This field is required. It will be used on team and standing screens to separate data.",
                      direction: "right",
                      className: "icon-mobile",
                    }
                  )}
                  {this.renderSelectByValueOption(
                    "seasonID",
                    `* Season`,
                    this.state.seasons,
                    "_id",
                    disabled,
                    {
                      header: "Season",
                      text: ``,
                      direction: "right",
                      className: "icon-mobile",
                    },
                    null,
                    {
                      onClick: () =>
                        navigateTo(
                          "/seasons?q=new",
                          this.props.history,
                          this.context
                        ),
                      tooltipText: "Add New Season",
                      icon: "add",
                      class: "btn-info",
                    }
                  )}
                  {this.renderInput(
                    "maxPlayersPerTeam",
                    "Maximum Players per Team",
                    "",
                    "number",
                    disabled,
                    "99",
                    "1",
                    {
                      header: "Maximum Players per Team",
                      text: `The maximum number of players that can be on a single team. If blank no maximum will be applied.`,
                      direction: "right",
                      className: "icon-mobile",
                    },
                    "1"
                  )}
                  <div className="row">
                    <div className="col">
                      {this.renderInput(
                        "offCyclePaymentAmount",
                        "Off Cycle Payment Amount",
                        "",
                        "number",
                        disabled,
                        "",
                        "0",
                        {
                          header: "Off Cycle Payment Amount",
                          text: `The amount that will be charged for a payment created using the QR code from your Division or Payments Page.
                          This can be used to charge any miscellaneous fees, such as fees to play as a substitute, or anything else.`,
                          direction: "right",
                          className: "icon-mobile",
                        },
                        "1"
                      )}
                    </div>
                    <div className="col-2 d-flex justify-content-center align-items-center">
                      <b>{this.props.org.stripe?.currency.toUpperCase()}</b>
                    </div>
                  </div>
                  {this.renderInput(
                    "offCyclePaymentName",
                    "Off Cycle Payment Name",
                    "",
                    "",
                    disabled,
                    "",
                    "",
                    {
                      header: "Off Cycle Payment Name",
                      text: `A name for your off cycle payment.
                      e.g. Sub Fee`,
                      direction: "right",
                      className: "icon-mobile",
                    }
                  )}
                  {["Tennis"].includes(mappedSportName) && (
                    <div>
                      <div>
                        <SideBySideView
                          doNotCenterOnStack={true}
                          minWidth={minWidth}
                          Components={[
                            this.renderInput(
                              "sets",
                              "Sets per Match",
                              "",
                              "number",
                              "",
                              99,
                              1,
                              {
                                header: "Sets per Match",
                                text: `Maximum possible number of sets to be played in each match.
                                    If an even number is entered the match will enter a match tiebreak if tied at end of all sets.`,
                                direction: "right",
                                className: "icon-mobile",
                              },
                              "1",
                              true
                            ),
                            this.renderInput(
                              "gamesPerSet",
                              "Games per Set",
                              "",
                              "number",
                              "",
                              99,
                              1,
                              {
                                header: "Games per Set",
                                text: `How many games to win a set.
                                    First to this number will be given the set, win by two games.
                                    If both players reach this number of games a tiebreak will be played.`,
                                direction: "right",
                                className: "icon-mobile",
                              },
                              "1",
                              true
                            ),
                          ]}
                        />
                        <SideBySideView
                          doNotCenterOnStack={true}
                          minWidth={minWidth}
                          Components={[
                            this.renderInput(
                              "tiebreakPoints",
                              "Tiebreak Points",
                              "",
                              "number",
                              "",
                              99,
                              0,
                              {
                                header: "Tiebreak Points",
                                text: `First to this number of points in a normal (non match/final set) tiebreak wins.
                                    Must win by two points. Enter 0 here to eliminate tiebreaks.`,
                                direction: "right",
                                className: "icon-mobile",
                              },
                              "1",
                              true
                            ),
                            this.renderInput(
                              "finalSetTiebreakPoints",
                              "Match Tiebreak Points",
                              "",
                              "number",
                              "",
                              99,
                              0,
                              {
                                header: "Match Tiebreak Points",
                                text: `First to this number of points in a match tiebreak (during or after final set) wins.
                                    Must win by two points. Enter 0 here to eliminate tiebreaks.`,
                                direction: "right",
                                className: "icon-mobile",
                              },
                              "1",
                              true
                            ),
                          ]}
                        />
                        <SideBySideView
                          doNotCenterOnStack={true}
                          minWidth={minWidth}
                          Components={[
                            this.renderInput(
                              "finalSetGamesPerSet",
                              "Final Set Games to Win Set",
                              "",
                              "number",
                              "",
                              99,
                              1,
                              {
                                header: "Final Set Games to Win Set",
                                text: `In the final set how many games must be won to win the set.`,
                                direction: "right",
                                className: "icon-mobile",
                              },
                              "1",
                              true
                            ),
                            this.renderCheckbox(
                              "noAdScoring",
                              "No Ad Scoring",
                              "",
                              {
                                header: "No Ad Scoring",
                                text: `With this option selected the winner of the deuce point wins the game.`,
                                direction: "right",
                                className: "icon-mobile",
                              },
                              true
                            ),
                          ]}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )
            )}
            {source === "info" && (
              <React.Fragment>
                <br />
                <h3>Registered Teams</h3>
                <CLink
                  path="/teams?q=new"
                  button={true}
                  buttonStyle="btn-md btn-info"
                >
                  Add New Team
                </CLink>
                {teams.length > 0 ? (
                  <TeamsTable
                    teamsByPage={teams}
                    onDelete={this.setDeleteDialog}
                    getCurrentUser={getCurrentUser}
                  />
                ) : (
                  <p>There are no teams registered for this division.</p>
                )}
              </React.Fragment>
            )}
            {this.state.baseState.offCyclePaymentAmount ? (
              <div>
                <QRCodeRender
                  value={`${allowables.url}/offcyclepayment?orgID=${this.props.org?._id}&divisionID=${this.props.divisionID}`}
                />
                <p className="text-center">
                  <a
                    href={`${allowables.url}/offcyclepayment?orgID=${this.props.org?._id}&divisionID=${this.props.divisionID}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Payment Link
                  </a>
                </p>
              </div>
            ) : null}
            <CustomConfirm
              dialog={`Are you sure you want to delete ${this.state.baseState.name}?`}
              isOpen={deleteOpen}
              callback={this.handleDelete}
              close={this.toggleModal}
              id={"deleteOpen"}
              focused={true}
            />
            <CustomConfirm
              dialog={deleteTeamDialog}
              isOpen={deleteTeamOpen}
              callback={this.handleDeleteTeam}
              close={this.toggleModal}
              id={"deleteTeamOpen"}
              focused={true}
            />
            <br />
            <br />
            <br />
            <br />
          </div>
        )}
      </div>
    );
  }
}

export default DivisionForm;
