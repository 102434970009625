import React, { useState, useEffect, useContext } from "react";
import toast from "../../../utils/toast";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { freeTier } from "../../../utils/paymentsUtil";

import {
  getCustomer,
  getSubscriptionPrices,
  updateDefaultPaymentMethod,
  removePaymentMethod,
  addSubscription,
  deleteCustomer,
  sendInvoice,
  getUpcomingInvoice,
  retryLastInvoice,
} from "../../../services/paymentService";
import CardForm from "./cardForm";
import Tier from "./tier";
import Customer from "./customer";
import ConfirmSubscriptionModal from "./confirmSubscriptionModal";
import InvoiceModal from "./invoiceModal";
import { estimateProration } from "../../../utils/paymentsUtil";
import CustomConfirm from "../../common/customs/customConfirm";
import PageBottom from "../../common/pageComponents/pageBottom";
import Placeholder from "./placeholder";
import HeaderContext from "../../../context/headerContext";
import SideBySideView from "../../common/pageComponents/sideBySideView";
import ActiveSubscription from "./activeSubscription";

const SubscriptionHome = () => {
  // update this flag to toggle subscriptions for whole site, also update flag in node backend in paymentsUtil file
  const subscriptionsActive = true;

  const { setLoading, setProgress } = useContext(HeaderContext);

  const stripe = useStripe();
  const elements = useElements();
  const [selectedTier, setSelectedTier] = useState(freeTier);
  const [currentTier, setCurrentTier] = useState(freeTier);
  const [tiers, setTiers] = useState([]);
  const [customer, setCustomer] = useState(null);
  const [invoice, setInvoice] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [finePrintOpen, setFinePrintOpen] = useState(false);
  const [invoiceOpen, setInvoiceOpen] = useState(false);
  const [cancelSub, setCancelSub] = useState(false);
  const [deleteCustomerOpen, setDeleteCustomerOpen] = useState(false);
  const [deleteCustomerTwoOpen, setDeleteCustomerTwoOpen] = useState(false);
  const [removePaymentOpen, setRemovePaymentOpen] = useState(false);
  const [cardOpen, setCardOpen] = useState(false);
  const [redirectToSubscription, setRedirectToSubscription] = useState(false);

  const getInfo = async (doNotResetTier) => {
    setLoading(true);
    setProgress([0, 0, 0]);
    const subscriptionRes = await getSubscriptionPrices({
      callback: (p) => setProgress(p),
      bar: 0,
    });
    const customerRes = await getCustomer({
      callback: (p) => setProgress(p),
      bar: 1,
    });
    const invoiceRes = await getUpcomingInvoice({
      callback: (p) => setProgress(p),
      bar: 2,
    });
    if (subscriptionRes.status === 200) setTiers(subscriptionRes.data);
    else toast.error(subscriptionRes.data);
    if (customerRes.status === 200) {
      const currentSub = customerRes.data.subscriptions.data[0]
        ? customerRes.data.subscriptions.data[0].items.data[0].metadata
        : null;
      const thisTier = currentSub
        ? {
            ...subscriptionRes.data.find((s) => s.id === currentSub.id),
            cycle: currentSub.cycle,
          }
        : null;
      if (!doNotResetTier) setSelectedTier(thisTier ? thisTier : freeTier);
      const sub = customerRes.data.subscriptions.data[0];

      setCurrentTier(
        thisTier
          ? {
              ...thisTier,
              cancel_at_period_end: sub.cancel_at_period_end,
              current_period_end: sub.current_period_end,
              status: sub.status,
            }
          : null
      );
      setCustomer(customerRes.data);
    } else toast.error(customerRes.data);
    if (invoiceRes.status === 200) {
      setInvoice(invoiceRes.data);
    } else {
      setInvoice(null);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (subscriptionsActive) getInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDeleteCustomer = async () => {
    // for now this is only available in development
    setLoading(true);
    const res = await deleteCustomer();
    if (res.status === 200) {
      toast.success(res.data);
      await getInfo();
    } else toast.error(res.data);
    setLoading(false);
  };

  const handleAddPaymentMethod = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    const cardElement = elements.getElement(CardElement);
    // create payment method
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });
    if (error) {
      return toast.error(error.message);
    }
    // set loading after grabbing cardElement and creating payment method to prevent component from unmounting
    setLoading(true);
    setCardOpen(false);
    // add payment method as default for customer
    const paymentRes = await updateDefaultPaymentMethod(paymentMethod.id);
    if (paymentRes.status === 200) {
      toast.success(paymentRes.data);
      await getInfo(redirectToSubscription);
      // must refresh info before opening confirmation modal
      // confirmation modal relies on a payment method being present
      if (redirectToSubscription) {
        setConfirmOpen(true);
        setRedirectToSubscription(false);
      }
    } else toast.error(paymentRes.data);
    setLoading(false);
  };

  const handleRemovePaymentMethod = async () => {
    setLoading(true);
    const paymentMethodID = customer.invoice_settings.default_payment_method;
    if (!paymentMethodID) return toast.error("No default payment mathod set");
    const res = await removePaymentMethod();
    if (res.status === 200) {
      toast.success(res.data);
      return getInfo();
    } else toast.error(res.data);
    setLoading(false);
  };

  const getCurrentTier = () => {
    return customer.subscriptions.data[0]
      ? customer.subscriptions.data[0].items.data[0].metadata
      : null;
  };

  const handleSelectTier = async (selectedTier, cycle) => {
    setSelectedTier({
      ...selectedTier,
      cycle: selectedTier.id === "free" ? "monthlyyearly" : cycle,
    });
  };

  const isSelectedSubscriptionInvalid = () => {
    return (
      selectedTier.id !== "free" &&
      currentTier &&
      currentTier.id === selectedTier.id &&
      currentTier.cycle === selectedTier.cycle &&
      !customer.subscriptions.data[0].cancel_at_period_end
    );
  };

  const toggleConfirm = (event, cancel, continueFreeTrial) => {
    if (continueFreeTrial) return setCardOpen(true);
    if (event) event.preventDefault();
    if (cancel) setCancelSub(true);
    else setCancelSub(false);
    const currentTier = getCurrentTier();
    if (!cancel && isSelectedSubscriptionInvalid())
      return toast.info("Your subscription to this tier is already active");

    if (selectedTier.id === "free" && !currentTier)
      return toast.error("Please select a paid tier");

    if (!customer.invoice_settings.default_payment_method && !cancel) {
      // if no payment method is set open the form to add one then redirect to the confirmation page
      setRedirectToSubscription(true);
      return setCardOpen(true);
    } else setRedirectToSubscription(false);

    setConfirmOpen(confirmOpen ? false : true);
  };

  const handleUpdateSubscription = async () => {
    setLoading(true);
    setConfirmOpen(false);
    // if cancel selected send free tier through
    const tier = cancelSub ? freeTier : selectedTier;
    if (!getCurrentTier() && tier.id === "free")
      toast.error("Please select a paid tier");
    else {
      const res = await addSubscription(tier || selectedTier);
      if (res.status === 200) {
        sendInvoice();
        getInfo();
        return toast.success(res.data);
      } else toast.error(res.data);
    }
    setLoading(false);
  };

  const handleRetryLastCharge = async () => {
    setLoading(true);
    const res = await retryLastInvoice();
    if (res.status === 200) {
      getInfo();
      return toast.success(res.data);
    } else toast.error(res.data);
    setLoading(false);
  };

  const openFinePrint = () => {
    setFinePrintOpen(finePrintOpen ? false : true);
  };

  return !subscriptionsActive ? (
    <Placeholder />
  ) : (
    <React.Fragment>
      <SideBySideView
        Components={[
          <Customer
            customer={customer}
            onRemovePaymentMethod={() => setRemovePaymentOpen(true)}
            openFinePrint={openFinePrint}
            onAddCustomerSuccess={getInfo}
            onDeleteCustomer={() => setDeleteCustomerOpen(true)}
            setCardOpen={setCardOpen}
          />,
          <ActiveSubscription
            currentTier={currentTier}
            buttons={true}
            onClickSubscribe={toggleConfirm}
            openInvoice={setInvoiceOpen}
            defaultCard={customer?.defaultCard}
            onRetryLastCharge={handleRetryLastCharge}
          />,
        ]}
      />
      {customer && (
        <React.Fragment>
          <CardForm
            onSubmit={handleAddPaymentMethod}
            disabled={!stripe}
            defaultCard={customer && customer.defaultCard}
            cardOpen={cardOpen}
            currentTier={currentTier}
            setCardOpen={setCardOpen}
          />
          <Tier
            onSelectTier={handleSelectTier}
            selectedTier={selectedTier}
            tiers={tiers}
            onClickSubscribe={toggleConfirm}
            openInvoice={setInvoiceOpen}
            buttonDisabled={isSelectedSubscriptionInvalid()}
          />
          <PageBottom />
          <ConfirmSubscriptionModal
            selectedTier={selectedTier}
            customer={customer}
            currentTier={currentTier}
            onUpdateSubscription={handleUpdateSubscription}
            confirmOpen={confirmOpen}
            closeConfirm={setConfirmOpen}
            cancel={cancelSub}
            amountToCharge={estimateProration(
              customer,
              currentTier,
              selectedTier,
              selectedTier.id === "free" || cancelSub
            )}
          />
          <InvoiceModal
            isOpen={invoiceOpen}
            closeModal={setInvoiceOpen}
            customer={customer}
            invoice={invoice}
          />
        </React.Fragment>
      )}
      <CustomConfirm
        dialog={
          "Deleting your customer account will immediately reset your subscription to the free tier and cannot be undone."
        }
        callback={() => setDeleteCustomerTwoOpen(true)}
        close={setDeleteCustomerOpen}
        isOpen={deleteCustomerOpen}
        focused={true}
      />
      <CustomConfirm
        dialog={"Are you sure you want to delete your customer account?"}
        callback={handleDeleteCustomer}
        close={setDeleteCustomerTwoOpen}
        isOpen={deleteCustomerTwoOpen}
        yesNo={true}
        focused={true}
      />
      <CustomConfirm
        dialog={
          "To continue any active subscription you will need to add a new payment method.%Remove this payment card from your account?"
        }
        callback={handleRemovePaymentMethod}
        close={setRemovePaymentOpen}
        isOpen={removePaymentOpen}
        yesNo={true}
        focused={true}
        split="%"
      />
    </React.Fragment>
  );
};

export default SubscriptionHome;
