import React from "react";

import TeamLogo from "./teamLogo";
import EventImage from "../../assignedMatch/eventImage";

const ImageHeader = ({ team, player, isNew, org }) => {
  if (
    org?.misc?.userImages !== "Yes" ||
    isNew ||
    (!team?.logo && !team?.adminLogo && !player)
  )
    return null;
  return (
    <div
      style={{
        height: 120,
        marginBottom: 0,
        marginTop: 5,
        marginLeft: 15,
        marginRight: 15,
      }}
    >
      <div style={{ float: "left" }}>
        <TeamLogo team={team} name={team?.name} size={100} />
      </div>
      <div style={{ float: "right" }}>
        <EventImage player={player} size={100} />
      </div>
    </div>
  );
};

export default ImageHeader;
