import React, { useState, useContext, useEffect } from "react";
import { CLink } from "../customs/customLinks";

import MobileAppLinks from "./mobileAppLinks";
import FinePrintModal from "../../contactPolicies/finePrintModal";
import SideBySideView from "./sideBySideView";
import HeaderContext from "../../../context/headerContext";

const PageBottom = ({ cookieOnly, leaguePage, extend }) => {
  const { toggleContactForm } = useContext(HeaderContext);

  const [toggleOpen, setToggleOpen] = useState(false);
  const [Components, setComponents] = useState([]);

  useEffect(() => {
    const Comps = [];

    if (!cookieOnly) {
      Comps.push(
        <MobileAppLinks />,
        <div className="clink text-center" onClick={toggleContactForm}>
          {leaguePage ? "Report Inappropriate Content" : "Contact Support"}
        </div>
      );
      if (!leaguePage)
        Comps.push(
          <div className="text-center">
            {!cookieOnly && <CLink path="/help">Help & FAQs</CLink>}
          </div>
        );
    }
    if (!leaguePage)
      Comps.push(
        <div
          onClick={() => setToggleOpen(true)}
          className="clickable clink text-center"
        >
          {"Ultimate Scoreboard Policies"}
        </div>
      );

    setComponents(Comps);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleModal = () => {
    setToggleOpen(toggleOpen ? false : true);
  };

  return (
    <div style={{ marginTop: 15 }}>
      <div>
        <div className="pop-box" style={{ padding: 15, color: "black" }}>
          <SideBySideView separateRows={true} Components={Components} />
        </div>
        <FinePrintModal
          finePrintOpen={toggleOpen}
          closeFinePrint={toggleModal}
        />
      </div>
      {extend ? <div style={{ height: 200 }} /> : null}
    </div>
  );
};

export default PageBottom;
