import http from "./httpService";
import { getCurrentUser } from "./userService";
import { callbackProgress } from "../utils/progress";

export async function getTeams(callback) {
  const user = getCurrentUser();
  try {
    return await http.get(
      http.teamsEndpoint + "/orgs/" + user.orgID,
      callback && {
        onDownloadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function getTeamsForMatch(homeTeamID, awayTeamID, callback) {
  try {
    return await http.get(
      http.teamsEndpoint + "/match/" + homeTeamID + "/" + awayTeamID,
      callback && {
        onDownloadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function getTeamsByDivision(divisionID, callback) {
  try {
    return await http.get(
      http.teamsEndpoint + "/divisions/" + divisionID,
      callback && {
        onDownloadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function getTeam(id, callback) {
  try {
    return await http.get(
      http.teamsEndpoint + "/" + id,
      callback && {
        onDownloadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function saveTeam(id, team) {
  if (id === "new") {
    try {
      return await http.post(http.teamsEndpoint, team);
    } catch (ex) {
      return ex.response;
    }
  } else {
    try {
      return await http.put(http.teamsEndpoint + "/" + id, team);
    } catch (ex) {
      return ex.response;
    }
  }
}

export async function deleteTeam(id, callback) {
  try {
    return await http.delete(
      http.teamsEndpoint + "/" + id,
      callback && {
        onDownloadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function purgeTeam(id, callback) {
  try {
    return await http.delete(
      http.teamsEndpoint + "/purge/" + id,
      callback && {
        onDownloadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function editMultipleTeams(
  teamIDs,
  divisionID,
  groupName,
  callback
) {
  try {
    return await http.put(
      http.teamsEndpoint + "/multi/edit",
      { teamIDs, divisionID, groupName },
      callback && {
        onDownloadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function cancelBid(callback) {
  try {
    return await http.put(
      http.teamsEndpoint + "/waiverwire/cancel",
      {},
      callback && {
        onUploadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}
