import React from "react";
import Form from "../common/form/form";
import Joi from "joi-browser";
import StandingsTable from "../reports/standingsTable";
import allowables from "../../utils/allowables";
import { navigateTo } from "../common/customs/customLinks";
import HeaderContext from "../../context/headerContext";
import WarningHeader from "../common/pageComponents/warningHeader";

class CombinedCreateForm extends Form {
  static contextType = HeaderContext;
  state = {
    data: {
      scheduleType: this.props.scheduleType,
      divisionID: this.props.divisionID,
      numberOfTeams: "",
      refereeID: "",
      homeAway: "No",
      scheduleDates: "No",
      selectedFields: [],
      startDate: "",
      timeBetweenMatches: "",
      timeBetweenRounds: "",
    },
    errors: {},
  };

  schema = {
    divisionID: Joi.string().required(),
    scheduleType: Joi.string().required(),
    seeding: Joi.string()
      .optional()
      .allow([
        "Custom Seeding",
        "Division Standings",
        "Random Seeding",
        "All Dummy Matches",
      ])
      .label("Seeding"),
    homeAway: Joi.string().optional().valid("Yes", "No").label("Home and Away"),
    numberOfTeams: Joi.number()
      .integer()
      .optional()
      .allow("")
      .min(2)
      .max(127)
      .label("Number of Teams"),
    refereeID: Joi.string().required().label("Referee"),
    scheduleDates: Joi.string().required().label("Schedule Matches"),
    selectedFields: Joi.array()
      .items(Joi.string())
      .required()
      .label("Selected Fields"),
    startDate: Joi.date().optional().allow("").label("Start Date & Time"),
    timeBetweenMatches: Joi.number()
      .integer()
      .optional()
      .allow("")
      .label("Time Between Matches"),
    timeBetweenRounds: Joi.number()
      .integer()
      .optional()
      .allow("")
      .label("Time Between Rounds"),
    createSecondaryBracket: Joi.string()
      .optional()
      .valid("Yes", "No")
      .label("Create Secondary Bracket"),
  };

  doSubmit = async () => {
    const { data } = this.state;
    data.startDate = allowables.formDate(data.startDate, false, true);
    this.props.onSubmit(data);
  };

  render() {
    const { seeding } = this.state.data;
    const { teams, standings, referees, scheduleType, teamsByGroup, groups } =
      this.props;
    const allSeeded = teams.every((t) => t.seed);
    let teamOptions = teams.sort((a, b) =>
      allSeeded ? (a.seed > b.seed ? 1 : -1) : a.name > b.name
    );
    let seedingError = "";
    if (seeding && teamOptions) {
      if (seeding === "Custom Seeding") {
        if (!teamOptions.every((t) => t.seed)) {
          seedingError =
            "Please set a seed for each team on the Teams tab to use custom seeding.";
        }
      }
    }

    return (
      <div className="form-divided-section">
        <form onSubmit={this.handleSubmit}>
          <div className="row">
            <div className="col">
              <div>
                {this.renderSelectByValueOption(
                  "refereeID",
                  "Assign Referee (this can be updated later)",
                  referees,
                  "_id",
                  "",
                  {
                    header: "Assign Referee",
                    text: `All the created matches will be assigned to this referee.
                    If you have more than one referee that will be taking charge of these matches it is advised to select yourself and then update each match after creation.`,
                    direction: "right",
                    className: "icon-raised",
                  }
                )}
              </div>
              {!scheduleType.includes("playoff") && (
                <React.Fragment>
                  {this.renderSelect(
                    "homeAway",
                    "Reverse Fixtures",
                    [
                      { _id: "n", name: "No" },
                      { _id: "y", name: "Yes" },
                    ],
                    "",
                    {
                      header: "Reverse Fixtures",
                      text: `If Yes is selected then each team will play all other teams twice, once in a home match and once in an away match`,
                      direction: "right",
                      className: "icon-raised",
                    }
                  )}
                  {scheduleType === "division" && (
                    <ul>
                      <h6>Teams</h6>
                      {teamOptions.map((t) => (
                        <li key={t._id}>{t.name}</li>
                      ))}
                    </ul>
                  )}
                  {scheduleType === "group" && (
                    <React.Fragment>
                      <h5>Groups</h5>
                      {groups.map((g) => (
                        <React.Fragment key={g}>
                          <ul>
                            <h6>{g}</h6>
                            {teamsByGroup[g].map((t) => (
                              <li key={t._id}>{t.name}</li>
                            ))}
                          </ul>
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
              {scheduleType.includes("playoff") && (
                <React.Fragment>
                  {this.renderSelect(
                    "createSecondaryBracket",
                    "Create Secondary Bracket",
                    [
                      { _id: "no", name: "No" },
                      { _id: "yes", name: "Yes" },
                    ],
                    "",
                    {
                      header: "Create Secondary Bracket",
                      text: `A secondary bracket will also be created for the loser of each match to have a chance to win their way back to the final.
                      These matches will be scheduled after each round of main bracket play.`,
                      direction: "right",
                      className: "icon-raised",
                    }
                  )}
                  {this.renderSelect(
                    "seeding",
                    "Seeding",
                    [
                      { _id: "custom", name: "Custom Seeding" },
                      { _id: "standings", name: "Division Standings" },
                      { _id: "random", name: "Random Seeding" },
                      { _id: "dummy", name: "All Dummy Matches" },
                    ],
                    "",
                    {
                      header: "Seeding",
                      text: `This will decide the order in which the bracket is set.
                      If selecting Custom seeding you will need to have already set a seed for each team on the Edit Team page.
                      For Division Standings seeding every team will need to have played at least one match.
                      For Divisions with a non standard number of teams (not a factorial of 2) there will be a preliminary round created.
                      Select All Dummy Matches if all teams are not ready to be entered for the first round of the bracket.
                      All rounds will be created using the dummy match flag and can be updated at a later time.
                      Brackets are created using a standard bracket seeding,
                      e.g. in the first round of an eight team tournament seed 1 will play 8, 2 will play 7, etc.`,
                      direction: "right",
                      className: "icon-raised",
                    }
                  )}
                  {seedingError && (
                    <WarningHeader>{seedingError}</WarningHeader>
                  )}
                  {this.state.data.seeding &&
                    this.state.data.seeding !== "Division Standings" &&
                    this.state.data.seeding !== "All Dummy Matches" && (
                      <ul>
                        <h6>Teams</h6>
                        {teamOptions.map((t) => (
                          <li key={t._id}>
                            Seed:{" "}
                            {this.state.data.seeding === "Random Seeding"
                              ? "Random"
                              : t.seed}{" "}
                            - {t.name}
                          </li>
                        ))}
                      </ul>
                    )}
                  {teamOptions.length < 2 && (
                    <p>
                      There must be at least two teams to create a playoff
                      bracket.
                    </p>
                  )}
                  {seeding === "Division Standings" &&
                    (standings.length > 0 ? (
                      <StandingsTable
                        data={standings}
                        sport={this.props.org?.sport}
                      />
                    ) : (
                      <p>
                        There have been no regular season matches completed for
                        this division. Please select another seeding method.
                      </p>
                    ))}
                  {seeding &&
                    this.state.data.seeding &&
                    this.state.data.seeding === "All Dummy Matches" &&
                    this.renderInput(
                      "numberOfTeams",
                      "Number of Teams",
                      "",
                      "number",
                      "",
                      "127",
                      "2",
                      {
                        header: "Number of Teams",
                        text: `You can create a bracket with as few as 2 or as many as 127 teams`,
                        direction: "right",
                        className: "icon-raised",
                      }
                    )}
                </React.Fragment>
              )}
            </div>

            <div className="col">
              {this.renderSelect(
                "scheduleDates",
                "Schedule Matches",
                [
                  { _id: "no", name: "No" },
                  { _id: "yes", name: "Yes" },
                ],
                "",
                {
                  header: "Schedule Matches",
                  text: `Ultimate Scoreboard can set a date and time for each of the matches you are about to schedule based on your choices below.
                  Selecting Yes here will make all fields below required.\n\n
                  Due to current alogrithm limitations for playoff brackets with more than one field
                  please double check the dates and times of the created secondary bracket for conflict.`,
                  direction: "right",
                  className: "icon-raised",
                }
              )}
              {this.state.data.scheduleDates === "Yes" && (
                <React.Fragment>
                  {this.renderSelectByValueOption(
                    "selectedFields",
                    "Select Fields",
                    this.props.fields,
                    "_id",
                    "",
                    {
                      header: "Select Fields",
                      text: `Select the fields to use for this schedule`,
                      direction: "right",
                      className: "icon-raised",
                    },
                    "",
                    {
                      onClick: () =>
                        navigateTo(
                          "/fields?q=new",
                          this.props.history,
                          this.context
                        ),
                      tooltipText: "Add New Field",
                      icon: "add",
                      class: "btn-info",
                    },
                    true
                  )}
                  {this.renderDateTimePicker(
                    "startDate",
                    "Start Date & Time",
                    "",
                    {
                      header: "Start Date & Time",
                      text: `The date and time at which the first match should be scheduled. All times are local, no adjusment is made for time zones.`,
                      direction: "right",
                      className: "icon-raised",
                    }
                  )}
                  {this.renderInput(
                    "timeBetweenMatches",
                    "Time Between Match Starts (in minutes)",
                    "",
                    "number",
                    "",
                    "",
                    "1",
                    {
                      header: "Time Between Match Starts",
                      text: `How much time must be left after a match starts before the next match can be played on the same field.
                        This is measured in total time in minutes from one match starting to the next match starting.`,
                      direction: "right",
                      className: "icon-raised",
                    }
                  )}
                  {this.renderInput(
                    "timeBetweenRounds",
                    "Time Between Rounds (in hours)",
                    "",
                    "number",
                    "",
                    "",
                    "0",
                    {
                      header: "Time Between Rounds",
                      text: `How much time should be left free between each round.
                        This is measured in hours from the start of one round to the start of the next.
                        Common choices are 24 if matches are played on consecutive days, or 168 if matches are played once a week.
                        Putting a number less than 24 will cause the next round to begin that many hours after the start of the last match in the previous round.
                        For example if your matches are 40 minutes in length and you put 2: if your last match in a round starts at 9am, the next round will start at 11am.
                        If you put 0 here the next round will start immediately after the last round finishes
                        (you may need to make some manual tweaks for the later rounds if you are using more than one field).
                        Rounds crossing over daylight savings time adjustments may start an hour earlier or later.`,
                      direction: "right",
                      className: "icon-raised",
                    }
                  )}
                </React.Fragment>
              )}
              {this.state.data.divisionID &&
                this.renderValidatedButton("Submit")}
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default CombinedCreateForm;
