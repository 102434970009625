import http from "./httpService";
import { getRefereeCode, setRefereeCode } from "./userService";

export async function validateRefereePasscode(username, passcode, name) {
  try {
    const res = await http.post(
      http.matchesEndpoint + "/referee/validatecode",
      {
        username,
        passcode,
      }
    );
    setRefereeCode({ username, passcode, name });
    return res;
  } catch (ex) {
    return ex.response;
  }
}

export async function getMatchesByCode() {
  const code = getRefereeCode();
  try {
    return await http.post(http.matchesEndpoint + "/referee/bycode", code);
  } catch (ex) {
    return ex.response;
  }
}

export async function getMatchByCode(matchID) {
  const code = getRefereeCode();
  try {
    return await http.post(
      http.matchesEndpoint + "/referee/bycode/" + matchID,
      code
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function getTeamsForMatchByCode(match) {
  const code = getRefereeCode();
  try {
    return await http.post(
      http.matchesEndpoint +
        "/referee/teamsbycode/" +
        match.homeTeamID +
        "/" +
        match.awayTeamID,
      code
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function getPlayersForMatchByCode(matchID) {
  const code = getRefereeCode();
  try {
    return await http.post(
      http.matchesEndpoint + "/referee/playersbycode/" + matchID,
      code
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function submitScorecard(scorecard) {
  const code = getRefereeCode();
  try {
    return await http.put(http.matchesEndpoint + "/referee/submitmatch", {
      refereeCode: code,
      refereeName: code.name,
      match: scorecard,
    });
  } catch (ex) {
    return ex.response;
  }
}
