import React from "react";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import { IconContext } from "react-icons";

const SingleTopic = ({ id, title, toggle, open, children }) => {
  return (
    <IconContext.Provider value={{ className: "icon-raised" }}>
      <div>
        <div
          onClick={() => toggle(open === id ? null : id)}
          className="clickable"
        >
          <h5>
            <b>
              {title}{" "}
              <small>{id === open ? <FaArrowUp /> : <FaArrowDown />}</small>
            </b>
          </h5>
        </div>
        {open === id && (
          <div>
            {children}
            <hr />
          </div>
        )}
      </div>
    </IconContext.Provider>
  );
};

export default SingleTopic;
