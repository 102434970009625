import http from "./httpService";
import { callbackProgress } from "../utils/progress";

export async function getFields(callback) {
  try {
    return await http.get(
      http.fieldEndpoint,
      callback && {
        onDownloadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function getField(id, callback) {
  try {
    return await http.get(
      http.fieldEndpoint + "/" + id,
      callback && {
        onDownloadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function deleteField(id, callback) {
  try {
    return await http.delete(
      http.fieldEndpoint + "/" + id,
      callback && {
        onDownloadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function saveField(id, field, callback) {
  try {
    if (id === "new")
      return await http.post(
        http.fieldEndpoint,
        field,
        callback && {
          onDownloadProgress: function (progress) {
            callbackProgress(progress, callback);
          },
        }
      );
    return await http.put(
      http.fieldEndpoint + "/" + id,
      field,
      callback && {
        onDownloadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}
