import React, { useEffect, useState } from "react";

import { saGetUsers } from "../../services/superAdminService";
import Loading from "../common/loading/loading";
import HorizontalButtons from "../common/dataSorting/horizontalButtons";
import SuperAdminUsersTable from "./superAdminUsersTable";
import toast from "../../utils/toast";
import orderBy from "lodash/orderBy";
import SearchBox from "../common/dataSorting/searchBox";
import allowables from "../../utils/allowables";
import { paginate } from "../../utils/paginate";
import Pagination from "../common/dataSorting/pagination";
import { GiCancel } from "react-icons/gi";

const SuperAdminUsersView = ({
  loading,
  setLoading,
  selectedLeague,
  onDeselectLeague,
}) => {
  const [users, setUsers] = useState([]);
  const [sortColumn, setSortColumn] = useState({ path: "name", order: "asc" });
  const [searchQuery, setSearchQuery] = useState("");
  const [queryLeague, setQueryLeague] = useState("");
  const [role, setRole] = useState("owner");
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 30;

  const getUsers = async () => {
    setLoading(true);
    const res = await saGetUsers(
      role,
      selectedLeague ? selectedLeague._id : null
    );
    if (res.status === 200) setUsers(res.data);
    else toast.error(res.data);

    setLoading(false);
  };

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role, selectedLeague]);

  const getData = () => {
    let filteredUsers = [...users];
    if (searchQuery)
      filteredUsers = filteredUsers.filter((u) =>
        allowables
          .splitName(u.name)
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      );
    if (queryLeague)
      filteredUsers = filteredUsers.filter(
        (u) =>
          u.orgName &&
          u.orgName.toLowerCase().includes(queryLeague.toLowerCase())
      );

    const sortedUsers = orderBy(
      filteredUsers,
      [sortColumn.path],
      [sortColumn.order]
    );

    const usersByPage = paginate(sortedUsers, currentPage, pageSize);

    return { usersByPage, filteredUsers };
  };

  return (
    <React.Fragment>
      <div className="row">
        <div className="col">
          <h4 className="text-center">
            <b>
              {selectedLeague ? selectedLeague.name : "Users with no League"}
            </b>
          </h4>
        </div>
        <div className="col-2">
          <button
            className="btn btn-sm btn-danger"
            onClick={() => {
              setRole("admin");
              onDeselectLeague();
            }}
          >
            <GiCancel />
          </button>
        </div>
      </div>
      <HorizontalButtons
        buttons={[
          "owner",
          "admin",
          "referee",
          "captain",
          "player",
          "player without user",
        ]}
        onClick={(tab) => {
          setRole(tab);
          setCurrentPage(1);
        }}
        selectedType={role}
      />
      <div className="row">
        <div className="col">
          <SearchBox
            value={searchQuery}
            onChange={setSearchQuery}
            placeholder="Filter by user name..."
          />
        </div>
        <div className="col">
          <SearchBox
            value={queryLeague}
            onChange={setQueryLeague}
            placeholder="Filter by league name..."
          />
        </div>
      </div>
      Showing {getData().usersByPage.length} of {getData().filteredUsers.length}{" "}
      filtered. There are {users.length} total users.
      {loading ? (
        <Loading />
      ) : (
        <React.Fragment>
          <SuperAdminUsersTable
            users={getData().usersByPage}
            sortColumn={sortColumn}
            onSort={(sortCol) => setSortColumn(sortCol)}
          />
          <Pagination
            itemsCount={getData().filteredUsers.length}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={(page) => setCurrentPage(page)}
            onNextPage={() => setCurrentPage(currentPage + 1)}
            onPreviousPage={() => setCurrentPage(currentPage - 1)}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default SuperAdminUsersView;
