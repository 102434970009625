import React, { useState } from "react";
import ReactPlayer from "react-player";
import Loading from "../common/loading/loading";
import useWindowDimensions from "../../utils/useWindowDimensions";
import WarningHeader from "../common/pageComponents/warningHeader";

const VideoPlayer = ({ url, overrideHeight }) => {
  const { width } = useWindowDimensions();
  const [loading, setLoading] = useState(true);

  if (!url) return null;
  if (!ReactPlayer.canPlay(url))
    return <WarningHeader>Invalid Video URL</WarningHeader>;

  const setReady = () => {
    setLoading(false);
  };

  return url ? (
    <div className="text-center">
      {loading && <Loading />}
      <ReactPlayer
        url={url}
        controls={true}
        onReady={setReady}
        width="100%"
        height={
          overrideHeight ||
          (width < 500
            ? (width * 0.9 * 9) / 16
            : (((width * 0.9) / 2) * 9) / 16)
        }
      />
    </div>
  ) : null;
};

export default VideoPlayer;
