import React from "react";
import DatePicker from "react-datepicker";

import { IconContext } from "react-icons";
import {
  FaCaretLeft,
  FaCaretRight,
  FaBackward,
  FaForward,
} from "react-icons/fa";
import { MdToday } from "react-icons/md";
import IconRender from "../icons/iconRender";
import FormLabel from "./formLabel";

const DateTimePicker = ({
  name,
  label,
  paidFeature,
  value,
  onChange,
  tooltip,
  disabled,
  multi,
  _id,
  noTime,
}) => {
  const dateFormat = "yyyy-MM-dd" + (noTime ? "" : " h:mm aa");

  const handleChange = (value) => {
    if (multi) onChange(value, _id);
    else onChange(value);
  };

  const dayClassName = (date) => {
    let className = "";
    let selectedDate = value ? new Date(value) : new Date();
    if (
      selectedDate.getDate() === date.getDate() &&
      selectedDate.getMonth() === date.getMonth() &&
      selectedDate.getYear() === date.getYear()
    )
      className = "bg-info text-white";
    return className;
  };

  const timeClassName = (time) => {
    let className = "";
    let selectedDate = value ? new Date(value) : null;
    if (
      selectedDate &&
      selectedDate.getHours() === time.getHours() &&
      selectedDate.getMinutes() === time.getMinutes()
    )
      className += "bg-info text-white";
    return className;
  };

  const renderCustomHeader = ({
    date,
    changeMonth,
    changeYear,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }) => {
    const resetToToday = () => {
      let selectedDate = new Date();
      changeMonth(selectedDate.getMonth());
      changeYear(selectedDate.getFullYear());
    };
    const updateYear = (inc) => {
      changeYear(date.getFullYear() + inc);
    };
    return (
      <div>
        <h6 className="bg-info text-white" style={{ padding: "5px" }}>
          <b>Select Date</b>
        </h6>
        <IconContext.Provider
          value={{ className: "icon-mobile icon-md icon-padding" }}
        >
          <div className="row">
            <div className="col">
              <div
                className={prevMonthButtonDisabled ? "" : " clickable"}
                onClick={prevMonthButtonDisabled ? () => {} : decreaseMonth}
              >
                <FaCaretLeft />
              </div>
              <div
                className={prevMonthButtonDisabled ? "" : " clickable"}
                onClick={
                  prevMonthButtonDisabled ? () => {} : () => updateYear(-1)
                }
              >
                <FaBackward />
              </div>
            </div>
            <div className="col">
              <h5>
                <b>
                  {date.toLocaleString("default", {
                    month: "long",
                  })}
                  <br />
                  {date.toLocaleString("default", {
                    year: "numeric",
                  })}
                </b>
              </h5>
            </div>
            <div className="col">
              <div
                className={nextMonthButtonDisabled ? "" : " clickable"}
                onClick={nextMonthButtonDisabled ? () => {} : increaseMonth}
              >
                <FaCaretRight />
              </div>
              <div
                className={nextMonthButtonDisabled ? "" : " clickable"}
                onClick={
                  nextMonthButtonDisabled ? () => {} : () => updateYear(1)
                }
              >
                <FaForward />
              </div>
            </div>
          </div>
        </IconContext.Provider>
        <IconContext.Provider value={{ className: "icon-info icon-sm" }}>
          <div className="row">
            <div className="col text-center"></div>
            <div className="col text-center">
              <MdToday onClick={resetToToday} className="clickable" />
            </div>
            <div
              className="col text-center clickable"
              onClick={() => {
                onChange("");
              }}
            >
              {value && <IconRender name="clear" className="icon-mobile" />}
            </div>
          </div>
        </IconContext.Provider>
      </div>
    );
  };

  const timeProps = noTime
    ? {}
    : {
        timeIntervals: 5,
        timeInputLabel: "Select",
        timeClassName,
        showTimeSelect: true,
      };

  return (
    <div className="form-group">
      <FormLabel
        infoTooltip={tooltip}
        paidFeature={paidFeature}
        label={label}
        htmlFor={name}
      />
      <DatePicker
        id={name}
        name={name}
        selected={value ? new Date(value) : null}
        onChange={handleChange}
        renderCustomHeader={renderCustomHeader}
        dateFormat={dateFormat}
        withPortal
        fixedHeight
        disabled={disabled}
        className="form-control custom-form"
        dayClassName={dayClassName}
        {...timeProps}
      />
    </div>
  );
};

export default DateTimePicker;
