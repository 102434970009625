import React, { useEffect, useState } from "react";
import Standings from "./standings";
import Leaderboards from "./leaderboard";
import Brackets from "./brackets";
import { getDivisions } from "../../services/divisionService";
import QuickLinks from "../common/pageComponents/quickLinks";
import allowables from "../../utils/allowables";
import ModalPicker from "../common/dataSorting/modalPicker";
import QRCodeRender from "../common/pageComponents/qrCode";
import MiniHeader from "../common/pageComponents/miniHeader";
import useSearchParams from "../../hooks/useSearchParams";

const Reports = ({ org, location, history }) => {
  const params = useSearchParams(location);

  const [openComponent, setOpenComponent] = useState("standings");
  const [selectedDivision, setSelectedDivision] = useState(null);
  const [divisions, setDivisions] = useState([]);

  const qrLink =
    org &&
    org.spectatingAllowed &&
    org.spectatingAllowed.toLowerCase() !== "blocked"
      ? `${allowables.url}/deeplink?pathname=${org.spectatingAllowed}&division=`
      : null;

  const mount = async () => {
    const { data } = await getDivisions();
    setDivisions(data);
    return data;
  };
  useEffect(() => {
    mount();
  }, []);

  const getData = async () => {
    let tempDivisions = divisions;
    if (!divisions.length) {
      tempDivisions = await mount();
      setDivisions(tempDivisions);
    }
    const curOpen =
      typeof params.brackets === "string"
        ? "brackets"
        : typeof params.leaderboards === "string"
        ? "leaderboards"
        : "standings";

    setOpenComponent(curOpen);
    setSelectedDivision(
      tempDivisions.find((d) => d._id === params[curOpen]) || tempDivisions[0]
    );
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const handleReportChange = (report) => {
    history.replace(`?${report._id}=${selectedDivision?._id}`);
    setOpenComponent(report._id);
  };

  const handleDivisionChange = (division) => {
    history.replace(`?${openComponent}=${division._id}`);
    setSelectedDivision(division);
  };

  return (
    <div>
      <MiniHeader>{selectedDivision?.seasonID?.name}</MiniHeader>
      <div className="form-divided-section">
        <div className="row">
          <div className="col">
            <ModalPicker
              buttonLabel="Report"
              label="Select Report"
              options={[
                { _id: "standings", name: "Standings" },
                { _id: "brackets", name: "Brackets" },
                { _id: "leaderboards", name: "Leaderboards" },
              ]}
              onSelect={handleReportChange}
              selectedValue={openComponent}
            />
            <ModalPicker
              buttonLabel="Division"
              label="Select Division"
              options={divisions}
              onSelect={handleDivisionChange}
              selectedValue={selectedDivision}
            />
          </div>
          <div className="col-3">
            {qrLink && selectedDivision ? (
              <QRCodeRender
                value={`${qrLink}${selectedDivision._id}&option=${openComponent}`}
                size={75}
              />
            ) : null}
          </div>
        </div>
      </div>
      {openComponent === "brackets" ? (
        <Brackets divisionID={selectedDivision?._id} />
      ) : openComponent === "leaderboards" ? (
        <Leaderboards divisionID={selectedDivision?._id} org={org} />
      ) : openComponent === "standings" ? (
        <Standings divisionID={selectedDivision?._id} org={org} />
      ) : null}
      <hr />
      <QuickLinks fullBoard={true} org={org ? org : null} />
    </div>
  );
};

export default Reports;
