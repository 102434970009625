import React, { useState } from "react";

import RefereeingInstructionImage from "../../instructions/refereeingInstructionImage";
import HorizontalButtons from "./../../common/dataSorting/horizontalButtons";

const FootballInstructions = () => {
  const [page, setPage] = useState("pre-match");

  return (
    <React.Fragment>
      <HorizontalButtons
        buttons={[
          "pre-match",
          "match page overview",
          "scoring",
          "extra points",
          "penalties",
          "undoing",
          "completing",
        ]}
        onClick={setPage}
        selectedType={page}
      />
      <br />
      {page === "pre-match" && (
        <React.Fragment>
          <p>
            Check that you have selected the correct match by verifying the
            teams, date and time. Click Start Match when ready.
            <br />
          </p>
          <RefereeingInstructionImage location="football/preMatch.jpg" />
        </React.Fragment>
      )}
      {page === "match page overview" && (
        <React.Fragment>
          <p>
            First up is the approvals section, you will mostly only use this
            after the match has finished. Here you will indicate team captain
            approvals, leave your comments for the League admins, and submit
            your final scorecard to the database.
            <br />
          </p>
          <RefereeingInstructionImage location="football/approvalsSegment.jpg" />
          <hr />
          <p>
            On the header segment you will find the team names and the current
            score. There is a timer available if you need it. In this segment
            you will also find the Undo button for making changes to match
            events.
            <br />
          </p>
          <RefereeingInstructionImage location="football/headerSegment.jpg" />
          <hr />
          <p>
            The team segment is where you will spend the majorty of the match.
            Here you can find the buttons for adding events, split into scoring
            and penalties.
            <br />
          </p>
          <RefereeingInstructionImage location="football/teamSegment.jpg" />
        </React.Fragment>
      )}
      {page === "scoring" && (
        <React.Fragment>
          <p>
            When a team scores points you will need to add them to the
            scorecard. First click the Score button for the appropriate team.
            This will open up the first menu. Choose the type of score that you
            are entering. Each type will increment the total score by a certain
            amount. Touchdowns are worth 6 points, Field Goals 3 points,
            Safeties 2 points. The Extra Point option can be entered for
            whatever value you need.
            <br />
          </p>
          <RefereeingInstructionImage location="football/score1.jpg" />
          <hr />
          <p>
            We will go through the scenario when a touchdown is scored. First
            click the Touchdown option. This will take you to a screen where you
            will select Touchdown Type. For defensive touchdowns select Run. We
            will select Pass for this example.
            <br />
          </p>
          <RefereeingInstructionImage location="football/scoreTD1.jpg" />
          <hr />
          <p>
            Now you will be on the player select screen. For a passing score you
            must select two players, the passer and the receiver. First select
            the passer, then you can select the receiver.
            <br />
          </p>
          <RefereeingInstructionImage location="football/scoreTD2.jpg" />
          <br />
          <br />
          <RefereeingInstructionImage location="football/scoreTD3.jpg" />
          <hr />
          <p>
            When you have selected both players you will be asked to confirm
            your selections.
            <br />
          </p>
          <RefereeingInstructionImage location="football/scoreTDConfirm.jpg" />
          <hr />
          <p>
            Once confirmed the score will be incremented and the players will
            show under their team segment.
            <br />
          </p>
          <RefereeingInstructionImage location="football/scoreTDFinal.jpg" />
        </React.Fragment>
      )}
      {page === "extra points" && (
        <React.Fragment>
          <p>
            For extra points there are three different options for type, and the
            number of points can be manually selected. To increment or decrement
            the points click the + or - buttons.
            <br />
          </p>
          <RefereeingInstructionImage location="football/extraPoint1.jpg" />
          <hr />
          <p>
            Selecting the type will lock in the points amount for this play.
            After you have selected the type select the player responsible for
            the points. In this case we have chosen a kicking extra point worth
            one point. The confirmation screen will display your selections.
            <br />
          </p>
          <RefereeingInstructionImage location="football/extraPoint2.jpg" />
          <hr />
          <p>
            If you need to change the points amount click Back to Type Select to
            start over. Below is an example of a two point extra point play
            confirmation screen.
            <br />
          </p>
          <RefereeingInstructionImage location="football/extraPoint3.jpg" />
          <hr />
          <p>
            After selection the score will increment and the team segment will
            update with the new player information.
            <br />
          </p>
          <RefereeingInstructionImage location="football/extraPointFinal.jpg" />
        </React.Fragment>
      )}
      {page === "penalties" && (
        <React.Fragment>
          <p>
            Click the Penalty button for the corresponding team to start. This
            will bring you to the penalty screen. Here you can select the player
            against whom the penalty was called, and the number of yards.
            <br />
          </p>
          <RefereeingInstructionImage location="football/penalty1.jpg" />
          <hr />
          <p>
            Once selected you will be asked to confirm the player and number of
            yards.
            <br />
          </p>
          <RefereeingInstructionImage location="football/penalty2.jpg" />
          <hr />
          <p>
            The penalty will now show on the team section.
            <br />
          </p>
          <RefereeingInstructionImage location="football/penaltyFinal.jpg" />
        </React.Fragment>
      )}
      {page === "undoing" && (
        <React.Fragment>
          <p>
            If you make any mistakes when entering a score or penalty then head
            to the Undo button. Clicking it will open up a chronological list of
            all the events that you have created so far. Simply click the event
            you wish to remove and confirm to remove it from the scorecard.
            <br />
          </p>
          <RefereeingInstructionImage location="football/undo1.jpg" />
          <br />
          <br />
          <RefereeingInstructionImage location="football/undo2.jpg" />
          <hr />
          <p>
            The event will now be deleted from the scorecard and any points
            earned during that event will be taken off. If you were removing an
            event in order to make a correction now is the time to go through
            the event process again.
          </p>
        </React.Fragment>
      )}
      {page === "completing" && (
        <React.Fragment>
          <p>
            When the game is over you should show the scorecard on your phone or
            tablet to the captain of each team. This is only to give them an
            overview of the final score. Every player will have the ability to
            view the full scorecard on their own account once you have submitted
            it. If they agree with the score then click the red approval button
            for their team. The button will turn green to indicate that the
            captain has accepted the result. If the captain does not accept the
            result then leave the button unclicked and red.
          </p>
          <p>
            Captain approvals is an optional feature, your League may not have
            it enabled. If the buttons do not appear on your page you can skip
            this step as your League does not require it.
            <br />
          </p>
          <RefereeingInstructionImage location="football/finishPre.jpg" />
          <br />
          <br />
          <RefereeingInstructionImage location="football/finishApproved.jpg" />
          <hr />
          <p>
            If you have any comments to make on how the match went you will
            enter them before clicking Finish Match. It is especially important
            to submit your comments if one or more teams does not approve of the
            scorecard, as the reviewing admin will be able to see your comments
            while reviewing the match, this can help clear up a lot of
            confusion.
            <br />
          </p>
          <RefereeingInstructionImage location="football/finishComments.jpg" />
          <hr />
          <p>
            After confirming with each team captain and adding your comments it
            is time to submit this scorecard. Click the Finish Match button to
            send the completed scorecard to the database. Once you confirm this
            you cannot get the scorecard back so please make sure that all the
            information is correct.
            <br />
          </p>
          <RefereeingInstructionImage location="football/finishConfirm.jpg" />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default FootballInstructions;
