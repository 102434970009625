import React from "react";
import Form from "../../common/form/form";
import Joi from "joi-browser";
import { getCurrentUser } from "../../../services/userService";
import allowables from "../../../utils/allowables";
import WarningHeader from "../../common/pageComponents/warningHeader";
import BasicModal from "../../common/pageComponents/basicModal";
import { popupStyle } from "../../../utils/styleUtil";
import LoginForm from "../../loginLogoutRegister/loginForm";

class OffCyclePaymentForm extends Form {
  state = {
    data: { name: "", email: "" },
    errors: {},
    loginOpen: false,
  };

  componentDidMount() {
    const user = getCurrentUser();
    if (user)
      this.setState({
        data: {
          name: allowables.splitName(user.name),
          email: user.email,
        },
      });
  }

  schema = {
    name: Joi.string().required().min(3).max(50).label("Name"),
    email: Joi.string().email().required().label("Email"),
  };

  doSubmit = () => {
    this.props.onSubmit(this.state.data);
  };

  render() {
    const user = getCurrentUser();

    return (
      <div>
        {!user ? (
          <div>
            <WarningHeader>
              Have an account? Log in first to keep all your payments in one
              place
            </WarningHeader>
            <div className="text-center">
              <button
                onClick={() => this.setState({ loginOpen: true })}
                className="btn btn-small btn-info"
              >
                Log In
              </button>
            </div>
          </div>
        ) : null}
        <form onSubmit={this.handleSubmit}>
          {this.renderInput("name", "Name")}
          {this.renderInput("email", "Email")}
          {this.renderValidatedButton("Go to Checkout")}
        </form>
        <BasicModal
          isOpen={!!this.state.loginOpen}
          onClose={() => {
            this.setState({ loginOpen: false });
          }}
          style={{
            content: {
              ...popupStyle.content,
              maxWidth: "90%",
              maxHeight: "90%",
            },
          }}
        >
          <LoginForm onSuccessPath={this.props.navPath} />
        </BasicModal>
      </div>
    );
  }
}

export default OffCyclePaymentForm;
