import React from "react";

import allowables from "../../../utils/allowables";
import { getSubscriptionAmount } from "../../../utils/paymentsUtil";

const Invoice = ({ customer, invoice }) => {
  return (
    <React.Fragment>
      {invoice ? (
        <React.Fragment>
          <div className="row">
            <div className="col text-left">
              <h4>{invoice.customer_name}</h4>
            </div>
            <div className="col text-right">
              <h5>{allowables.dateTime(invoice.created * 1000, true).date}</h5>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col">{invoice.lines.data[0].description}</div>
          </div>
          <br />
          <hr />
          <div className="row">
            <div className="col text-left">
              <h5>Invoice Total</h5>
            </div>
            <div className="col text-right">
              <h5>{getSubscriptionAmount(invoice.total)}</h5>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col text-left">Account Credit Balance</div>
            <div className="col text-right">
              {getSubscriptionAmount(customer.balance)}
            </div>
          </div>
          <hr />
          <div className="row">
            {customer.defaultCard ? (
              <React.Fragment>
                <div className="col text-left">
                  Amount be charged to card ending{" "}
                  {customer.defaultCard.card.last4} on{" "}
                  {allowables.dateTime(invoice.created * 1000, true).date}
                </div>
                <div className="col text-right">
                  <h4>
                    {getSubscriptionAmount(
                      invoice.total + customer.balance < 0
                        ? 0
                        : invoice.total + customer.balance
                    )}
                  </h4>
                </div>
              </React.Fragment>
            ) : (
              <div className="col">
                You do not have a payment card on file. Your subscription will
                end on {allowables.dateTime(invoice.created * 1000, true).date}
              </div>
            )}
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <h6 className="text-center">There is no upcoming invoice</h6>
          <hr />
          <div className="row">
            <div className="col text-left">Account Credit Balance</div>
            <div className="col text-right">
              {getSubscriptionAmount(customer.balance)}
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default Invoice;
