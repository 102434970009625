import React, { useContext } from "react";
import InfoTooltip from "./../../common/tooltip/infoTooltip";
import allowables from "../../../utils/allowables";
import OrgContext from "../../../context/orgContext";
import { atBatName } from "../../../utils/diamondFunctions";

const DiamondLeaderboardTooltip = ({ selectedTable }) => {
  const org = useContext(OrgContext);
  const header = allowables.capLetterOne(selectedTable) + " Table";
  const items =
    selectedTable === "batting"
      ? {
          listItems: [
            `${atBatName(org).abbr} - ${atBatName(org).action}`,
            "H - Hits",
            "RBI - Runs Batted In",
            "BB - Walks (All walks are included)",
            "K - Strikeouts",
            `AVG - ${atBatName(org).ing} Average`,
            "OBP - On Base Percentage",
            "SLG - Slugging Percentage",
            "OPS - On Base Plus Slugging Percentage",
          ],
          text: `Clicking the Show Above Average ${
            atBatName(org).abbr
          } button will filter the leaderboard for only players who have more than the rounded down average of ${atBatName(
            org
          ).action.toLowerCase()}s.
            This can be useful if you have awards based on any of the ${
              atBatName(org).ing
            } Average statistics.`,
        }
      : {
          listItems: [
            "IP - Innings Pitched",
            `H - Hits Against`,
            "R - Runs Against",
            "BB - Walks (All walks are included)",
            "K - Strikeouts",
            "HR - Home Runs Against",
            "ERA - Earned Run Average",
            "WHIP - Walks Plus Hits per Inning",
          ],
          text: `All runs scored are considered to be earned runs for the sake of this table.
            Clicking the Show Above Average IP button will filter the leaderboard for only players who have more than the rounded down average of Innings Pitched.
            This can be useful if you have awards based on ERA.`,
        };

  return (
    <InfoTooltip
      header={header}
      direction="bottom"
      className="icon-info icon-md"
      listHeader="Fields"
      listItems={items.listItems}
      text={items.text}
    />
  );
};

export default DiamondLeaderboardTooltip;
