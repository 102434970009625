import React from "react";

import allowables from "../../../utils/allowables";

const OrgLogo = ({ _id, name, width, fullSource }) => {
  const getInitials = (name) => {
    const split = name.split(" ");
    return (
      split[0].charAt(0).toUpperCase() +
      (split[1] ? split[1].charAt(0).toUpperCase() : "")
    );
  };

  function getFallbackImage(event) {
    event.target.src = allowables.fallbackImage(3);
  }

  return _id || fullSource ? (
    <img
      onError={getFallbackImage}
      src={
        fullSource
          ? fullSource
          : "https://storage.googleapis.com/ultimatescoreboard-leaguelogos/" +
            _id +
            ".png"
      }
      alt={name ? getInitials(name) : "logo"}
      width={width ? width : 120}
      height="auto"
      style={{ borderRadius: "5px", maxWidth: width, minWidth: 120 }}
    />
  ) : null;
};

export default OrgLogo;
