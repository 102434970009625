import React, { useEffect, useState } from "react";

import { getCurrentUser } from "../../services/userService";
import { makeTableButton, combineButtons } from "../../utils/buttons";
import Table from "../common/table/table";
import IconRender from "../common/icons/iconRender";

const FieldsTable = ({
  fields,
  sortColumn,
  onSort,
  onDelete,
  onFieldClick,
}) => {
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    let cols = [
      {
        path: "name",
        label: "Field Name",
        content: (f) =>
          f.latitude && f.longitude ? (
            <div
              className={f.latitude && f.longitude ? "svg-link" : ""}
              onClick={() => onFieldClick(f)}
            >
              <IconRender name="map" className="icon-raised" />
              &nbsp;
              {f.name}
            </div>
          ) : (
            f.name
          ),
      },
      {
        path: "address",
        label: "Location",
        content: (f) => (
          <div>
            <IconRender name="search" className="icon-raised" />
            &nbsp;
            <a
              href={`https://www.google.com/search?q=${f.address?.replace(
                " ",
                "+"
              )}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {f.address}
            </a>
          </div>
        ),
      },
      {
        path: "webAddress",
        label: "Website",
        content: (f) => (
          <a href={f.webAddress} target="_blank" rel="noopener noreferrer">
            {f.webAddress}
          </a>
        ),
      },
    ];

    const user = getCurrentUser();
    let buttons = [];
    if (user && user.role?.includes("admin")) {
      if (onDelete) buttons.push(makeTableButton("delete", onDelete));
      buttons.push(makeTableButton("edit", "fields"));
      cols.push(combineButtons(buttons));
    }
    setColumns(cols);
  }, [onDelete, onFieldClick]);

  return (
    <Table
      columns={columns}
      data={fields}
      sortColumn={sortColumn}
      onSort={onSort}
      keyProperty={"_id"}
      tableClass="table-custom"
      headerClass="thead-custom"
      bodyClass="tbody-custom"
      highlightOnHover={true}
    />
  );
};

export default FieldsTable;
