import React from "react";
import Form from "../common/form/form";
import Joi from "joi-browser";
import toast from "../../utils/toast";
import MiniHeader from "../common/pageComponents/miniHeader";

import { loginWithCode, getUserAccounts } from "../../services/userService";
import HeaderContext from "../../context/headerContext";
import AccountSelector from "./accountSelector";

class CodeEntryForm extends Form {
  static contextType = HeaderContext;
  state = {
    data: {
      code: "",
    },
    email: "",
    errors: {},
    accountSelectorOpen: false,
    userAccounts: [],
  };

  componentDidMount() {
    toast.success("Check your email for your login code");
    if (this.props.location && this.props.location.search) {
      const queryParams = this.props.location.search.split("&");
      let email = queryParams.find((q) => q.includes("email"));
      email = email && email.split("=")[1] ? email.split("=")[1] : "";
      this.setState({ email });
    }
  }

  schema = {
    code: Joi.string().required().max(50).min(3).label("Code"),
  };

  doSubmit = async () => {
    this.context.setLoading(true);
    const response = await loginWithCode(
      this.state.email,
      this.state.data.code
    );
    if (response.status === 200) {
      const accounts = await getUserAccounts();
      if (
        accounts &&
        accounts.status === 200 &&
        (accounts.data?.length || 0) > 1
      ) {
        this.setState({
          accountSelectorOpen: true,
          userAccounts: accounts.data,
        });
        return this.context.setLoading(false);
      }
      const { state } = this.props.location;
      let location = state ? state.from.pathname : "/profile";
      return (window.location = location);
    } else toast.error(response.data);
    this.context.setLoading(false);
  };

  render() {
    return (
      <div className="centered-small-input-area">
        <MiniHeader>Enter Login Code</MiniHeader>
        <div className="form-divided-section">
          <form onSubmit={this.handleSubmit}>
            {this.renderInput("code", "Code", true, "text")}
            {this.renderValidatedButton("Login")}
          </form>
        </div>
        <AccountSelector
          isOpen={this.state.accountSelectorOpen}
          setIsOpen={() => this.setState({ accountSelectorOpen: false })}
          userAccounts={this.state.userAccounts}
        />
      </div>
    );
  }
}

export default CodeEntryForm;
