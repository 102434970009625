import React from "react";

import useWindowDimensions from "./../../utils/useWindowDimensions";
import TeamsTable from "./teamsTable";
import TeamsCardView from "./teamsCardView";

const TeamsTableCardSwitcher = ({
  cardView,
  teams,
  onClick,
  onDelete,
  onSort,
  sortColumn,
  onPurge,
  thisID,
  getCurrentUser,
}) => {
  const { width } = useWindowDimensions();

  return (
    <div>
      {cardView || width < 0 ? ( // this was updated to force always displaying the table view pending https://trello.com/c/fFnEYhH7/87-redo-card-components
        <TeamsCardView teams={teams} onClick={onClick} />
      ) : (
        <TeamsTable
          teamsByPage={teams}
          onDelete={onDelete}
          onSort={onSort}
          sortColumn={sortColumn}
          onPurge={onPurge}
          thisID={thisID}
          getCurrentUser={getCurrentUser}
        />
      )}
    </div>
  );
};

export default TeamsTableCardSwitcher;
