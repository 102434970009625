import React from "react";
import Joi from "joi-browser";

import Form from "../common/form/form";

class ProfileForm extends Form {
  state = {
    data: { ...this.props.data },
    baseState: {
      name: "",
      email: "",
    },
    errors: {},
  };

  schema = {
    firstName: Joi.string().min(1).max(50).required().label("First Name"),
    lastName: Joi.string().min(1).max(50).required().label("Last Name"),
  };

  doSubmit = () => {
    this.props.onSubmit(this.state.data);
  };

  handleReset = (event) => {
    if (event) event.preventDefault();
    this.setState({ data: this.props.data });
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        {this.renderInput(
          "firstName",
          "First Name",
          null,
          null,
          this.props.isLocked
        )}
        {this.renderInput(
          "lastName",
          "Last Name",
          null,
          null,
          this.props.isLocked
        )}
        <div className="row">
          <div className="col">
            {!this.props.isLocked && this.renderValidatedButton("Save")}
          </div>
          <div className="col">
            {!this.props.isLocked && this.renderResetButton("Reset")}
          </div>
        </div>
      </form>
    );
  }
}

export default ProfileForm;
