import React, { Component } from "react";

import Players from "./players";
import PlayerForm from "./playerForm";
import { getCurrentUser } from "../../services/userService";
import HeaderContext from "./../../context/headerContext";
import { sendLink } from "../../services/emailService";
import { getTeams } from "../../services/teamService";
import { getDivision } from "../../services/divisionService";
import {
  getPlayer,
  savePlayer,
  incrementPlayerSuspension,
} from "../../services/playerService";
import { manageImage } from "../../services/ftpService";

class PlayerRedirect extends Component {
  static contextType = HeaderContext;
  state = {
    loading: true,
    comp: null,
    playerID: "",
    source: "edit",
  };

  componentDidMount() {
    this.setup();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.search !== this.props.location.search) this.setup();
  }

  setup = () => {
    let comp = Players;
    const query = this.props.location.search.slice(1).split("&");
    let playerID = query.find((q) => q.split("=")[0] === "q");
    if (playerID) {
      this.context.setLoading(true);
      comp = PlayerForm;
    }
    playerID = playerID && playerID.split("=")[1];
    let source =
      query.find((q) => q === "info") ||
      (!getCurrentUser().role.includes("admin") &&
        !getCurrentUser().role.includes("captain"))
        ? "info"
        : "edit";

    this.setState({ loading: false, playerID, source, comp });
  };

  render() {
    return this.state.loading || !this.state.comp ? null : (
      <this.state.comp
        {...this.props}
        playerID={this.state.playerID}
        source={this.state.source}
        getCurrentUser={getCurrentUser}
        sendLink={sendLink}
        getTeams={getTeams}
        getDivision={getDivision}
        getPlayer={getPlayer}
        savePlayer={savePlayer}
        incrementPlayerSuspension={incrementPlayerSuspension}
        manageImage={manageImage}
      />
    );
  }
}

export default PlayerRedirect;
