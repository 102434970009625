import React from "react";

const FootballReviewSegment = ({ match, teamOrder, liveEvents }) => {
  const events = liveEvents || match.chronoEventsNames;

  const comps = [
    {
      id: "pass",
      name: "Passing",
      class: "bg-info border-main-theme",
      border: "border-main-theme-color-only",
    },
    {
      id: "pass-recp",
      name: "Receiving",
      class: "bg-info border-main-theme",
      border: "border-main-theme-color-only",
    },
    {
      id: "rush",
      name: "Rushing",
      class: "bg-secondary",
      border: "border-main-theme-color-only",
    },
    {
      id: "kick",
      name: "Kicking",
      class: "bg-dark text-white border-main-theme",
      border: "border-main-theme-color-only",
    },
    {
      id: "Defense",
      name: "Defense",
      class: "bg-light border-main-theme",
      border: "border-main-theme-color-only",
    },
    {
      id: "penalty",
      name: "Penalties",
      class: "soccer-yellow-card-bg border-main-theme",
      border: "border-soccer-yellow-card",
    },
  ];

  const getCounts = (teamID, id) => {
    const filteredEvents = events.filter(
      (e) =>
        (id === "Defense"
          ? e.eventType === id && e.eventStyle !== "safety"
          : e.eventStyle.slice(0, 4) === id.slice(0, 4)) && e.teamID === teamID
    );

    const playerField =
      id === "pass-recp"
        ? { _id: "player2ID", name: "player2Name" }
        : { _id: "_id", name: "name" };
    let counts = [];
    filteredEvents.forEach((e) => {
      const touchdowns = e.eventType === "Touchdown" ? 1 : 0;
      const extraPoints = e.eventType === "Extra Point" ? 1 : 0;
      const fieldGoals = e.eventType === "Field Goal" ? 1 : 0;
      const interceptions = e.eventStyle === "interception" ? 1 : 0;
      const sacks = e.eventStyle === "sack" ? 1 : 0;
      const fumblesRecovered = e.eventStyle === "fumble" ? 1 : 0;
      const puntReturns = e.eventStyle === "puntReturn" ? 1 : 0;
      const index = counts.findIndex((c) => c._id === e[playerField._id]);
      if (index >= 0) {
        counts.splice(index, 1, {
          _id: e[playerField._id],
          name: e[playerField.name],
          points: counts[index].points + e.points,
          touchdowns: counts[index].touchdowns + touchdowns,
          extraPoints: counts[index].extraPoints + extraPoints,
          fieldGoals: counts[index].fieldGoals + fieldGoals,
          interceptions: counts[index].interceptions + interceptions,
          sacks: counts[index].sacks + sacks,
          fumblesRecovered: counts[index].fumblesRecovered + fumblesRecovered,
          puntReturns: counts[index].puntReturns + puntReturns,
        });
      } else {
        counts.push({
          _id: e[playerField._id],
          name: e[playerField.name],
          points: e.points,
          touchdowns,
          extraPoints,
          fieldGoals,
          interceptions,
          sacks,
          fumblesRecovered,
          puntReturns,
        });
      }
    });

    return counts;
  };

  return (
    <div>
      {comps.map((c) => {
        if (
          getCounts(match.homeTeamID, c.id).length === 0 &&
          getCounts(match.awayTeamID, c.id).length === 0
        )
          return null;
        return (
          <div key={c.id} style={{ marginBottom: 15 }}>
            <h5 className={"text-center " + c.class}>
              <b>{c.name}</b>
            </h5>
            <div className="row">
              {teamOrder.map((team, i) => {
                const counts = getCounts(match[team + "TeamID"], c.id);
                return (
                  <div
                    key={team + c.id}
                    className={
                      "col text-" +
                      (i === 0 ? "right border-right " + c.border : "left")
                    }
                  >
                    {counts.map((p, idx) => {
                      return (
                        <div key={idx}>
                          <b>{p.name}</b> -{" "}
                          {p.touchdowns
                            ? `${p.touchdowns} TD${
                                p.touchdowns === 1 ? "" : "s"
                              }, `
                            : ""}
                          {p.fieldGoals
                            ? `${p.fieldGoals} FG${
                                p.fieldGoals === 1 ? "" : "s"
                              }, `
                            : ""}
                          {p.extraPoints
                            ? `${p.extraPoints} XP${
                                p.extraPoints === 1 ? "" : "s"
                              }, `
                            : ""}
                          {p.interceptions
                            ? `${p.interceptions} Int${
                                p.interceptions === 1 ? "" : "s"
                              }, `
                            : ""}
                          {p.sacks ? `${p.sacks} Sk, ` : ""}
                          {p.fumblesRecovered
                            ? `${p.fumblesRecovered} FR, `
                            : ""}
                          {p.puntReturns ? `${p.puntReturns} Ret, ` : ""}
                          {c.name === "Defense"
                            ? p.points / 6 > 0
                              ? `${p.points / 6} TD${
                                  p.points / 6 === 1 ? "" : "s"
                                }`
                              : ""
                            : `${p.points} ${
                                c.id === "penalty" ? "Yard" : "Pt"
                              }${p.points === 1 ? "" : "s"}`}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default FootballReviewSegment;
