import React, { useEffect, useState, useContext } from "react";
import HeaderContext from "../../../context/headerContext";
import { freeTier } from "../../../utils/paymentsUtil";
import { getSubscriptionPrices } from "../../../services/paymentService";
import Table from "../../common/table/table";
import IconRender from "../../common/icons/iconRender";
import PageBottom from "../../common/pageComponents/pageBottom";
import allowables from "../../../utils/allowables";
import MiniHeader from "../../common/pageComponents/miniHeader";

const data = [
  {
    free: true,
    name: "Accept League Dues",
    tooltip: (
      <p>
        <b>Collect your league dues directly on Ultimate Scoreboard</b>
        <br />
        <br />
        Set your price and away you go. You can choose to collect a bulk payment
        from Team Captains, or individual payments from players. Either way you
        can track payment status right here, and funds are deposited to your
        bank account daily.
        <br />
        <br />
        If collecting league dues from players, you can require every player to
        pay those dues before they are able to complete their registration. No
        more spending time chasing down payments.
        <br />
        <br />
        Create and distribute discount codes to encourage early bird signup or
        player loyalty.
      </p>
    ),
  },
  {
    free: true,
    name: "Virtual Scorecards",
    tooltip: (
      <p>
        <b>Virtual scorecards with simple approval process</b>
        <br />
        <br />
        Completely cut out any data entry with our virtual scorecards.
        Scorekeepers submit their results directly on the app for admin review
        and acceptance.
      </p>
    ),
  },
  {
    free: true,
    name: "Automatically Tracked League Stats",
    tooltip: (
      <p>
        <b>Standings, brackets, and leaderboards</b>
        <br />
        <br />
        All division standings, playoff brackets, and individual leaderboards
        are tracked from the results submitted on the virtual scorecards. No
        need for manual calculations or data entry.
      </p>
    ),
  },
  {
    free: true,
    name: "Schedule Creator",
    tooltip: (
      <p>
        <b>Automatically create full league, group, or playoff schedules</b>
        <br />
        <br />
        No matter if you're playing a single day tournament, or a weekly league,
        the scheduler can handle your needs.
      </p>
    ),
  },
  {
    free: true,
    name: "Direct Registration Page",
    tooltip: (
      <p>
        <b>
          Allow Free Agents and Team Captains to register directly for your
          league.
        </b>
        <br />
        <br />
        Free Agents and Team Captains can sign up directly without an invitation
        using this page. We provide a handy QR code and url link to use in your
        marketing materials.
        <br />
        <br />
        Team Captains can invite and register their whole team. Place Free
        Agents on teams at your leisure, or activate our Waiver Wire system to
        allow Team Captains to recruit and move their own Free Agents.
      </p>
    ),
  },
  //   {
  //     free: true,
  //     name: "External Links",
  //     tooltip: (
  //       <p>
  //         <b>Link to your external sites from Ultimate Scoreboard.</b>
  //         <br />
  //         <br />
  //         These links will be easily accessible to all your users from their
  //         Profile drop down, or on your league webpage.
  //       </p>
  //     ),
  //   },
  //   {
  //     free: true,
  //     name: "Vaccine Tracking",
  //     tooltip: (
  //       <p>
  //         <b>Collect user vaccination information.</b>
  //         <br />
  //         <br />
  //         Review and accept the information before your league starts to ensure a
  //         safe tournement.
  //       </p>
  //     ),
  //   },
  //   {
  //     free: true,
  //     name: "Custom Flags",
  //     tooltip: (
  //       <p>
  //         <b>Create your own named flags to track user status.</b>
  //         <br />
  //         <br />
  //         Use these flags to check people in at the fields, distribute swag and
  //         prizes, or anything else you can think of.
  //       </p>
  //     ),
  //   },
  {
    basic: true,
    name: "Scorecard Approval",
    tooltip: (
      <p>
        <b>Track team approval of scorecards.</b>
        <br />
        <br />
        Team Captains can notify admins of any disputes with the final scorecard
        prior to submission.
        <br />
        <br />
        Scorekeepers and Captains can leave their comments on the scorecard for
        admin review. No more on field arguments or discussions.
      </p>
    ),
  },
  {
    basic: true,
    name: "League Webpage",
    tooltip: (
      <p>
        <b>
          Create a simple single page website hosted by Ultimate Scoreboard.
        </b>
        <br />
        <br />
        This website can contain images, embedded video, links to your
        merchandise pages, whatever you need. No coding required.
        <br />
        <br />
        Along with this page you can make your stats public for all fans to be
        able to watch the action.
      </p>
    ),
  },
  {
    basic: true,
    name: "Mailing Lists",
    tooltip: (
      <p>
        <b>Create mailing and distribution lists with just a couple clicks</b>
        <br />
        <br />
        Filter your data down to form super targeted mailing lists.
        <br />
        <br />
        Send payment reminders to only unpaid players, let all Team Captains
        know about a rule update, email your admins important internal
        information, and so much more.
      </p>
    ),
  },
  {
    basic: true,
    name: "User ID and Waivers",
    tooltip: (
      <p>
        <b>Profile Pictures and Code of Conduct</b>
        <br />
        <br />
        Allow your users to upload their profile picture for easier
        identification on the field.
        <br />
        <br />
        Create a custom code of conduct or waiver for players to sign prior to
        playing
        <br />
        <br />
        Track adherance to both of these criteria with simple, searchable lists.
      </p>
    ),
  },
  {
    basic: true,
    name: "Referee/Umpire Login",
    tooltip: (
      <p>
        <b>
          Create a single login credential to distribute to all your
          referees/umpires.
        </b>
        <br />
        <br />
        This allows for maximum flexibility when scheduling matches. Any
        referee/umpire can take charge of any available scorecard.
      </p>
    ),
  },
  {
    ultimate: true,
    name: "Live Scorecards",
    tooltip: (
      <p>
        <b>
          Allow all users to access the live score and receive updates as the
          officials make them.
        </b>
        <br />
        <br />
        Set up a central screen at your tournament, or display our handy QR code
        and users can access the live score directly on their own devices.
      </p>
    ),
  },
  {
    ultimate: true,
    name: "Custom Advertising",
    tooltip: (
      <p>
        <b>Place your own ads directly on our mobile app.</b>
        <br />
        <br />
        If you have sponsorship from local businesses, or just want to
        cross-promote your other activities, you can display your ads directly
        in our mobile app, putting them in front of a relevant and engaged
        audience.
      </p>
    ),
  },
  {
    ultimate: true,
    name: "Team Management Tools",
    tooltip: (
      <p>
        <b>Give your players the tools they deserve</b>
        <br />
        <br />
        <b>Tactics: </b>Captains can use our custom formation and tactic editor
        to create their own gameplan to share with their team.
        <br />
        <br />
        <b>Messaging: </b>The team messaging platform allows players to message
        amongst their team, keeping everything in one place.
        <br />
        <br />
        <b>Check Ins: </b>All players can check in or out for their matches
        letting captains plan ahead.
      </p>
    ),
  },
  {
    ultimate: true,
    name: "Automatic Suspension Tracking",
    tooltip: (
      <p>
        <b>Automatically track and suspend players when they receive cards.</b>
        <br />
        <br />
        Set up your suspension rules and don't worry about tracking cards any
        more. Violating players will show as suspended on the scorecard for the
        scorekeeper to see.
      </p>
    ),
  },
  {
    ultimate: true,
    name: "Waiver Wire",
    tooltip: (
      <div>
        <p>
          <b>
            The waiver wire system cuts out the admin work of moving players
            from team to team.
          </b>
        </p>
        <ul>
          <li>
            Players can remove themselves from their team so they can be picked
            up by other teams.
          </li>
          <li>
            Captains can recruit Free Agents and players on the waiver wire,
            settled on a schedule you decide.
          </li>
        </ul>
      </div>
    ),
  },
  //   {
  //     ultimate: true,
  //     name: "Local Map",
  //     tooltip: (
  //       <p>
  //         <b>Display an interactive map of the local area on our mobile app.</b>
  //         <br />
  //         Set your coordinates for an interactive map of the local area to
  //         display, complete with location information. Couple this with our custom
  //         advertising to get your local businesses on your side.
  //       </p>
  //     ),
  //   },
];

const translateAmount = (price) => {
  return `$${price?.unit_amount / 100}`;
};

const getNumberOfTeams = (level) => {
  const desc = level.description.split(" ");
  return Number(desc.find((t) => !isNaN(Number(t))));
};

const Pricing = ({ drilledTiers, org }) => {
  const { setLoading } = useContext(HeaderContext);
  const [features, setFeatures] = useState([]);

  const columns = [
    {
      path: "info",
      label: "",
      content: (d) =>
        d.tooltip ? <IconRender name="info" className="icon-raised" /> : null,
      tooltip: { direction: "right", overrideStyle: { minWidth: 200 } },
    },
    {
      path: "feature",
      label: "",
      content: (d) => d.name,
    },
    {
      path: "free",
      label: "Intro",
      content: (d) => (
        <div className="text-center">
          {d.custom ? (
            <b>{d.free}</b>
          ) : d.free ? (
            allowables.ballIcon(org?.sport, "icon-raised", "bigcheck")
          ) : null}
        </div>
      ),
    },
    {
      path: "basic",
      label: "Basic",
      content: (d) => (
        <div className="text-center">
          {d.custom ? (
            <b>{d.basic}</b>
          ) : d.free || d.basic ? (
            allowables.ballIcon(org?.sport, "icon-raised", "bigcheck")
          ) : null}
        </div>
      ),
    },
    {
      path: "ultimate",
      label: "Ultimate",
      content: (d) => (
        <div className="text-center">
          {d.custom ? (
            <b>{d.ultimate}</b>
          ) : d.free || d.basic || d.ultimate ? (
            allowables.ballIcon(org?.sport, "icon-raised", "bigcheck")
          ) : null}
        </div>
      ),
    },
  ];

  const getData = async () => {
    if (drilledTiers) {
      mapProductInfo(drilledTiers);
    } else {
      setLoading(true);
      const subscriptionRes = await getSubscriptionPrices();
      if (subscriptionRes.status === 200) {
        mapProductInfo(subscriptionRes.data);
      }
      setLoading(false);
    }
  };

  const mapProductInfo = (tiers) => {
    const basic = tiers.find((p) => p.name === "Basic");
    const ultimate = tiers.find((p) => p.name === "Ultimate");
    const price = {
      name: "Subscription",
      custom: true,
      free: <div className="pop-box">Free</div>,
      basic: (
        <div className="pop-box" style={{ fontWeight: "normal" }}>
          <b>{translateAmount(basic?.unit_amounts?.monthly)}</b>
          <small>/month</small>
          <hr className="custom" />
          <b>{translateAmount(basic?.unit_amounts?.yearly)}</b>
          <small>/year</small>
        </div>
      ),
      ultimate: (
        <div className="pop-box" style={{ fontWeight: "normal" }}>
          <b>{translateAmount(ultimate?.unit_amounts?.monthly)}</b>
          <small>/month</small>
          <hr className="custom" />
          <b>{translateAmount(ultimate?.unit_amounts?.yearly)}</b>
          <small>/year</small>
        </div>
      ),
      tooltip: (
        <p>
          <b>Pay only for what you need</b>
          <br />
          <br />
          Hosting a short tournament once a year? Pay monthly only while
          registration is ongoing and save the rest of the time.
          <br />
          <br />
          Running year-round leagues? Pay for the year and see the biggest
          savings.
        </p>
      ),
    };
    const teams = {
      name: "Teams",
      custom: true,
      free: <div className="pop-box">{getNumberOfTeams(freeTier)}</div>,
      basic: <div className="pop-box">{getNumberOfTeams(basic)}</div>,
      ultimate: <div className="pop-box">{getNumberOfTeams(ultimate)}</div>,
      tooltip: (
        <p>
          <b>Register a maximum number of teams</b>
          <br />
          <br />
          You can divide your teams into as many divisions and groups as you
          need. Host unlimited players, matches, playoff brackets, etc.
        </p>
      ),
    };

    let feats = [price, teams, ...data];
    setFeatures(feats);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={!drilledTiers ? "centered-small-input-area" : ""}>
      {!drilledTiers ? (
        <MiniHeader borderRadiusOverride={15} marginBottom={10} marginTop={-10}>
          What you get with your Subscription
        </MiniHeader>
      ) : null}
      <Table
        keyProperty="name"
        columns={columns}
        data={features}
        tableClass="table-custom"
        headerClass="thead-custom text-center"
        bodyClass="tbody-custom"
        highlightOnHover={true}
      />
      {!drilledTiers ? <PageBottom extend={true} /> : null}
    </div>
  );
};

export default Pricing;
