import React, { useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import toast from "../../../utils/toast";

import HeaderContext from "./../../../context/headerContext";
import { postPrepaymentSuccess } from "../../../services/userService";

const PrepaymentSuccess = () => {
  const { setLoading } = useContext(HeaderContext);
  const history = useHistory();

  const postPayment = async () => {
    const successID = history.location.search.split("?")[1];
    const res = await postPrepaymentSuccess(successID);
    if (res.status === 200) {
      toast.success("Registered");
      window.location = "/profile";
    } else toast.error(res.data);
  };

  useEffect(() => {
    setLoading(true);
    postPayment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div></div>;
};

export default PrepaymentSuccess;
