import React from "react";

const MatchesDivisionSelect = ({
  divisions,
  selectedDivisions,
  onSelectDivision,
}) => {
  return (
    <React.Fragment>
      <h5 className="text-center">
        <b>Select Divisions</b>
      </h5>
      {divisions.map((d) => {
        const isSelected = selectedDivisions.includes(d._id);
        return (
          <React.Fragment key={d._id}>
            <div
              className={
                "row clickable custom-select-item" +
                (isSelected ? "-selected" : "")
              }
              onClick={() => onSelectDivision(d)}
            >
              <div className="col text-center">{d.name}</div>
            </div>
            <div style={{ height: 5 }} />
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

export default MatchesDivisionSelect;
