import React from "react";

const FinalTotal = ({ amounts, card, revert }) => {
  return (
    <React.Fragment>
      <div className="row">
        <div className="col">New Subscription Charges:</div>
        <div className="col">
          <h6>{amounts.newCharges}</h6>
        </div>
      </div>

      <div className="row">
        <div className="col">Prorated Credit for Unused Time:</div>
        <div className="col">
          <h6>{amounts.creditAmount}</h6>
        </div>
      </div>

      <div className="row">
        <div className="col">Current Account Credit Balance:</div>
        <div className="col">
          <h6>{amounts.customerBalance}</h6>
        </div>
      </div>

      {amounts.remainingCredit && (
        <div className="row">
          <div className="col">Unused Credit to be Applied to Account:</div>
          <div className="col">
            <h6>{amounts.remainingCredit.string}</h6>
          </div>
        </div>
      )}

      <hr />
      {card ? (
        <div className="row">
          <div className="col">
            <b>Payment to be charged today to card ending {card.card.last4}:</b>
          </div>
          <div className="col">
            <h5>{amounts.amountToBill}*</h5>
          </div>
        </div>
      ) : null}
      <p style={{ fontSize: "12px" }}>
        *Charges are calculated based on the time at which you selected your
        tier. For the most accurate information please complete your
        subscription within five minutes of selecting a tier.
      </p>
    </React.Fragment>
  );
};

export default FinalTotal;
