import { useState, useEffect } from "react";

import useSearchParams from "./useSearchParams";

const usePagination = (
  location,
  history,
  defaultLimit,
  loadData,
  extraSearchParams = ""
) => {
  const params = useSearchParams(location);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentLimit, setCurrentLimit] = useState(defaultLimit);

  useEffect(() => {
    let page, limit;
    try {
      page = Number(params.page || 1);
      limit = Number(params.limit || defaultLimit);
    } catch (error) {
      page = 1;
      limit = defaultLimit;
    }
    setCurrentLimit(limit);
    setCurrentPage(page);
    loadData(page, limit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const replaceHistory = (page, limit) => {
    history.replace(`?page=${page}&limit=${limit}&${extraSearchParams}`);
  };
  const handlePageChange = (page) => {
    replaceHistory(page, currentLimit);
  };
  const handleLimitChange = (limit) => {
    replaceHistory(1, limit);
  };

  return { currentPage, currentLimit, handlePageChange, handleLimitChange };
};

export default usePagination;
