import React, { useState, useContext } from "react";
import Avatar from "../common/pageComponents/avatarEditor";
import toast from "../../utils/toast";
import CustomConfirm from "../common/customs/customConfirm";
import { uploadTeamLogo, removeTeamLogo } from "../../services/ftpService";
import OrgContext from "../../context/orgContext";

const TeamLogoEditor = ({ team }) => {
  const [removeOpen, setRemoveOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);

  const org = useContext(OrgContext);
  if (org && org.misc && org.misc.userImages === "No") return null;

  const removeLogo = async () => {
    const res = await removeTeamLogo();
    if (res.status === 200) window.location.reload();
    else toast.error(res.data);
  };

  return (
    <div>
      <button
        className="btn btn-sm btn-secondary"
        onClick={() => setEditOpen(!editOpen)}
      >
        Upload Team Logo
      </button>
      {editOpen ? (
        <div>
          {team.logo ? (
            <div>
              <br />
              <button
                className="btn btn-sm btn-dark"
                onClick={() => setRemoveOpen(true)}
              >
                Remove Logo
              </button>
            </div>
          ) : null}

          <div>
            <br />
            <Avatar
              width={150}
              height={150}
              uploadFunction={uploadTeamLogo}
              saveTitle={"Save Logo"}
              minZoom={25}
              maxZoom={300}
            />
          </div>
        </div>
      ) : null}
      <CustomConfirm
        dialog="Are you sure you want to remove your logo?"
        callback={removeLogo}
        isOpen={removeOpen}
        close={setRemoveOpen}
      />
    </div>
  );
};

export default TeamLogoEditor;
