import React, { Component } from "react";

import Teams from "./teams";
import TeamForm from "./teamForm";
import HeaderContext from "../../context/headerContext";
import { getCurrentUser } from "../../services/userService";
import {
  getPlayersByTeam,
  deletePlayer,
  moveToFreeAgents,
} from "../../services/playerService";
import {
  getTeam,
  saveTeam,
  deleteTeam,
  cancelBid,
  getTeams,
  purgeTeam,
  editMultipleTeams,
} from "../../services/teamService";
import { getDivisions } from "../../services/divisionService";
import { sendLink } from "../../services/emailService";
import { manageImage } from "../../services/ftpService";

class TeamRedirect extends Component {
  static contextType = HeaderContext;
  state = {
    loading: true,
    comp: null,
    teamID: "",
    source: "edit",
  };

  componentDidMount() {
    this.setup();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location.search) this.setup();
  }

  setup = () => {
    let comp = Teams;
    const query = this.props.location.search.slice(1).split("&");
    let teamID = query.find((q) => q.split("=")[0] === "q");
    teamID = teamID && teamID.split("=")[1];
    if (teamID) {
      this.context.setLoading(true);
      comp = TeamForm;
    }
    let source =
      query.find((q) => q === "info") ||
      (!getCurrentUser().role.includes("admin") &&
        !getCurrentUser().role.includes("captain"))
        ? "info"
        : "edit";

    this.setState({ loading: false, teamID, source, comp });
  };

  render() {
    return this.state.loading || !this.state.comp ? null : (
      <this.state.comp
        {...this.props}
        teamID={this.state.teamID}
        source={this.state.source}
        getPlayersByTeam={getPlayersByTeam}
        deletePlayer={deletePlayer}
        moveToFreeAgents={moveToFreeAgents}
        getTeam={getTeam}
        saveTeam={saveTeam}
        deleteTeam={deleteTeam}
        cancelBid={cancelBid}
        getCurrentUser={getCurrentUser}
        getDivisions={getDivisions}
        sendLink={sendLink}
        manageImage={manageImage}
        getTeams={getTeams}
        purgeTeam={purgeTeam}
        editMultipleTeams={editMultipleTeams}
      />
    );
  }
}

export default TeamRedirect;
