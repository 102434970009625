import React from "react";

import allowables from "../../utils/allowables";
import Table from "../common/table/table";
import EventImage from "./../assignedMatch/eventImage";

const SuperAdminUsersTable = ({ users, sortColumn, onSort }) => {
  const columns = [
    {
      path: "avatar",
      label: "",
      content: (u) => <EventImage player={u} />,
    },
    {
      path: "name",
      label: "Name",
      content: (u) => allowables.splitName(u.name),
    },
    { path: "teamName", label: "Team" },
    { path: "sport", label: "Sport" },
    {
      path: "lastActive",
      label: "Last Active",
      content: (u) => allowables.dateTime(u.lastActive).date,
    },
    { path: "role", label: "Full Role" },
    {
      path: "email",
      label: "Email",
      content: (u) => <a href={`mailto:${u.email}`}>{u.email}</a>,
    },
    {
      path: "expoPushToken",
      label: "Push",
      content: (u) => (u.expoPushToken ? "Y" : "N"),
    },
  ];
  return (
    <Table
      columns={columns}
      data={users}
      sortColumn={sortColumn}
      onSort={onSort}
      keyProperty={"_id"}
      headerClass="thead-light"
    />
  );
};

export default SuperAdminUsersTable;
