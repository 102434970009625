import React from "react";
import Modal from "react-modal";
import IconRender from "../icons/iconRender";

const BasicModal = ({
  id,
  isOpen,
  header,
  onClose,
  alsoRunOnClose,
  children,
  style,
  noSticky,
  shouldCloseOnOverlayClick = true,
  hideCloseButton = false,
}) => {
  return (
    <Modal
      id={id}
      isOpen={isOpen}
      closeOnDocumentClick
      onRequestClose={() => {
        onClose(id ? id : false);
        if (alsoRunOnClose) alsoRunOnClose();
      }}
      ariaHideApp={false}
      style={style}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
    >
      <div
        className={"text-right" + (!noSticky ? " sticky-top" : "")}
        id="close"
      >
        {!hideCloseButton && (
          <button
            className="btn btn-sm btn-dark light-box"
            onClick={() => {
              onClose(id ? id : false);
              if (alsoRunOnClose) alsoRunOnClose();
            }}
          >
            <IconRender name="close" />
          </button>
        )}
        {header}
      </div>
      {children}
    </Modal>
  );
};

export default BasicModal;
