import allowables from "./allowables";
import { GiSoccerBall } from "react-icons/gi";
import { FaSquare } from "react-icons/fa";

export function soccerLeaderboardDownload(leaderboard) {
  return leaderboard.map((p) => {
    return {
      name: p.name,
      teamName: p.teamName,
      goals: p.events.Goals,
      yellowCards: p.events.YellowCards,
      redCards: p.events.RedCards,
      gamesPlayed: p.events.Games,
    };
  });
}

export function checkYellowCards(events, event) {
  const thisPlayerEvents = events.filter((e) => e._id === event._id);
  if (
    thisPlayerEvents.find((e) => e.eventType.includes("Red")) ||
    thisPlayerEvents.filter((e) => e.eventType.includes("Yellow")).length > 1
  )
    return "alreadyOff";
  if (
    thisPlayerEvents.find((e) => e.eventType.includes("Yellow")) &&
    event.eventType.includes("Yellow")
  )
    return "secondYellow";
  return "ok";
}

export function mapSoccerEvents(match, events) {
  let mappedEvents = [];
  events.forEach((event) => {
    let newEvent = { ...event };
    const team = match[event.eventType.slice(0, 8) + "Name"];
    let type = event.eventType.includes("Goal")
      ? "Goal"
      : event.eventType.includes("Red")
      ? "Red Card"
      : "Yellow Card";
    newEvent.text = createSoccerEventText(
      { teamName: team, name: newEvent.name },
      type,
      newEvent.assistName
    );
    newEvent.icon =
      type === "Goal"
        ? { icon: GiSoccerBall, color: "info-text" }
        : {
            icon: FaSquare,
            color: type.includes("Red")
              ? "soccer-red-card-text"
              : "soccer-yellow-card-text",
          };
    mappedEvents.push(newEvent);
  });
  return mappedEvents.reverse();
}

export function createSoccerEventText(player, eventType, assistName) {
  const eventHeader = eventType.includes("Goal")
    ? "Goal"
    : eventType.includes("Red")
    ? "Red Card"
    : "Yellow Card";
  const text = `${player.teamName} ${eventHeader}: ${allowables.splitName(
    player.name
  )}`;
  return (
    text + (assistName ? `%s%Assist: ${allowables.splitName(assistName)}` : "")
  );
}
