import React from "react";

import BasicModal from "../../common/pageComponents/basicModal";
import { nameButtonText } from "../../../utils/matchFunctions";
import { popupStyle } from "../../../utils/styleUtil";

const DiamondPitcherPopup = ({
  players,
  toggleModal,
  id,
  isOpen,
  onSelect,
  team,
  teamName,
}) => {
  return (
    <BasicModal
      id={id}
      isOpen={isOpen}
      onClose={toggleModal}
      style={popupStyle}
    >
      <br />
      <h3 className="text-center">Select Pitcher for {teamName}</h3>
      <hr />
      <button
        className="btn btn-sm btn-block btn-light"
        onClick={() => {
          onSelect({ _id: "unk", name: "Unknown Player" }, team);
          toggleModal(id);
        }}
      >
        Unknown/Unregistered Player
      </button>
      <br />
      {players.map((p) => {
        if (p.shirtNumber && p.shirtNumber === -1) return null;
        return (
          <button
            key={p._id}
            className="btn btn-md btn-block btn-light"
            onClick={() => {
              onSelect(p, team);
              toggleModal(id);
            }}
          >
            {nameButtonText(p)}
          </button>
        );
      })}
    </BasicModal>
  );
};

export default DiamondPitcherPopup;
