import React, { useState } from "react";

import BasicModal from "../pageComponents/basicModal";
import { popupStyle } from "../../../utils/styleUtil";
import allowables from "../../../utils/allowables";

const ModalPicker = ({
  label,
  buttonLabel,
  options,
  onSelect,
  selectedValue,
  selectedValueLabel,
  externalOpenState = [],
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const checkIsOpen = () => {
    return externalOpenState.length ? externalOpenState[0] : isOpen;
  };

  const handleSetIsOpen = (bool) => {
    return externalOpenState.length
      ? externalOpenState[1](bool)
      : setIsOpen(bool);
  };

  return (
    <div>
      <div
        className="modal-picker-button"
        onClick={() => handleSetIsOpen(true)}
      >
        {buttonLabel || label}:&nbsp;
        <b>
          {selectedValueLabel ||
            allowables.capLetterOne(
              selectedValue?.name || selectedValue || "select"
            )}
        </b>
      </div>
      <BasicModal
        isOpen={checkIsOpen()}
        onClose={handleSetIsOpen}
        style={{
          content: {
            ...popupStyle.content,
            maxWidth: "60%",
            maxHeight: "70%",
          },
        }}
        hideCloseButton
      >
        <div className="modal-picker-header sticky-top">
          <b>{label}</b>
        </div>
        {options.map((o) => {
          const isSelected = (selectedValue?._id || selectedValue) === o._id;
          return (
            <div
              key={o._id}
              className={
                "modal-picker-item clickable" + (isSelected ? " selected" : "")
              }
              onClick={() => {
                onSelect(o);
                handleSetIsOpen(false);
              }}
            >
              {o.name}
            </div>
          );
        })}
      </BasicModal>
    </div>
  );
};

export default ModalPicker;
