import React from "react";

import Table from "../common/table/table";
import { BsViewList } from "react-icons/bs";
import { FiUsers } from "react-icons/fi";

const SuperAdminLeaguesTable = ({
  leagues,
  sortColumn,
  onSort,
  setSelectedLeague,
  onSelectLeague,
}) => {
  const columns = [
    {
      path: "name",
      label: "Name",
    },
    { path: "userCount", label: "Users" },
    { path: "divisionCount", label: "Divisions" },
    { path: "teamCount", label: "Teams" },
    { path: "playerCount", label: "Players" },
    {
      path: "matchCount.unplayed",
      label: "Unplayed Matches",
      content: (o) => o.matchCount.unplayed,
    },
    {
      path: "matchCount.completed",
      label: "Completed Matches",
      content: (o) => o.matchCount.completed,
    },
    {
      path: "matchCount.accepted",
      label: "Accepted Matches",
      content: (o) => o.matchCount.accepted,
    },
    {
      path: "select",
      content: (o) => (
        <button
          onClick={() => onSelectLeague(o)}
          className="btn btn-md btn-info"
        >
          <FiUsers />
        </button>
      ),
    },
    {
      path: "view",
      content: (o) => (
        <button
          onClick={() => setSelectedLeague(o)}
          className="btn btn-md btn-secondary"
        >
          <BsViewList />
        </button>
      ),
    },
  ];
  return (
    <Table
      columns={columns}
      data={leagues}
      sortColumn={sortColumn}
      onSort={onSort}
      keyProperty={"_id"}
      headerClass="thead-light"
    />
  );
};

export default SuperAdminLeaguesTable;
