export const popupStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-49%",
    transform: "translate(-50%, -50%)",
    height: "auto",
    width: "auto",
    backgroundColor: "#f3e9fc",
    border: "2px solid #831fe0",
    zIndex: 101,
    maxHeight: "95%",
    maxWidth: "95%",
  },
};
