import React from "react";

const VaccineStatus = ({ user, table }) => {
  // status codes: 1: accepted, 2: under review, 3: not submitted
  const status =
    user && user.vaccine && user.vaccine.reviewed
      ? 1
      : user && user.vaccine && user.vaccine.name && user.vaccine.date
      ? 2
      : 3;
  return (
    <React.Fragment>
      <div
        className={
          "text-center text-white " +
          (status === 1 ? "bg-info" : status === 2 ? "bg-secondary" : "bg-dark")
        }
        style={{ margin: "5px", padding: "5px" }}
      >
        {!table ? (
          <b>
            Your vaccine information{" "}
            {status === 1
              ? "has been accepted"
              : status === 2
              ? "is under review"
              : "has not been submitted"}
          </b>
        ) : (
          <div>
            {status === 1
              ? "Accepted"
              : status === 2
              ? "Under Review"
              : "Not Submitted"}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default VaccineStatus;
