import React from "react";
import { defaultTennisOptions } from "ultimatescoreboard-shared";

const TennisScoringInfo = ({ match }) => {
  const scoring =
    match?.divisionID?.settings?.tennisOptions || defaultTennisOptions;

  const renderInfo = (value) => {
    return <span className="scorecard-info">{value}</span>;
  };
  return (
    <div className="text-center">
      <p>
        Best of {renderInfo(scoring.sets)} set{scoring.sets > 1 ? "s" : ""}.
      </p>
      <p>
        {renderInfo(
          scoring.noAdScoring ? "No Ad Scoring" : "Advantage Scoring"
        )}
      </p>
      <p>
        {renderInfo("Regular Set:")} first to {renderInfo(scoring.gamesPerSet)}{" "}
        games, win by 2.{" "}
        {scoring.tiebreakPoints ? (
          <span>
            Tiebreak played at {scoring.gamesPerSet} games all, first to{" "}
            {renderInfo(scoring.tiebreakPoints)} points.
          </span>
        ) : (
          <span>No tiebreaks in final set.</span>
        )}
      </p>
      <p>
        {renderInfo("Final Set:")} first to{" "}
        {renderInfo(scoring.finalSetGamesPerSet)} games, win by 2.{" "}
        {scoring.finalSetTiebreakPoints ? (
          <span>
            Tiebreak played at {scoring.finalSetGamesPerSet} games all, first to{" "}
            {renderInfo(scoring.finalSetTiebreakPoints)} points.
          </span>
        ) : (
          <span>No tiebreaks in final set.</span>
        )}
      </p>
    </div>
  );
};

export default TennisScoringInfo;
