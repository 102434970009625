import React from "react";
import { defaultTennisOptions, oppTeam } from "ultimatescoreboard-shared";
import { mapTennisPoints } from "../../../utils/textUtils";
import TeamLogo from "../../common/pageComponents/teamLogo";

const TennisMatchHeader = ({
  match,
  score,
  teamOrder,
  settings = defaultTennisOptions,
}) => {
  if (!score) return null;

  const mapSetScore = (setScore, team, isComplete) => {
    if (score.isFinished && !isComplete) return null;
    const isWinner = isComplete && setScore[team] > setScore[oppTeam[team]];
    const tbScore = setScore[team + "Tiebreak"];
    return (
      <span
        style={{ fontWeight: isWinner ? "bold" : "" }}
        className={
          "box-score-body" + (isWinner ? " box-score-inning total" : "")
        }
      >
        {setScore[team]}
        {tbScore >= 0 ? <sup>{tbScore}</sup> : null}
      </span>
    );
  };

  return (
    <div className="pop-box scrolling-table-container">
      <table className="tennis-score-table">
        <tbody>
          {teamOrder.map((team, idx) => {
            const border = idx === 0 ? " tennis-border" : "";
            return (
              <tr className="tennis-score-row" key={team}>
                <td style={{ width: 50 }}>
                  <TeamLogo team={match[team + "TeamLogo"]} size={50} />
                </td>
                <td className={"tennis-score-team" + border}>
                  {match[team + "TeamName"]}
                </td>
                {[...score.trackedSetScore, score.curSetScore].map(
                  (tss, psi) => (
                    <td
                      key={"set" + team + psi}
                      className={"tennis-score-set" + border}
                      aria-label={team + "-set-" + psi}
                    >
                      {mapSetScore(
                        tss,
                        team,
                        psi < score.trackedSetScore.length
                      )}
                    </td>
                  )
                )}

                <td
                  className={border}
                  style={{
                    visibility: score.isFinished ? "hidden" : "",
                    width: 50,
                  }}
                >
                  <span
                    className="tennis-score-set points pop-box"
                    aria-label={team + "-points"}
                  >
                    {mapTennisPoints(score, score.curScoreIndex[team])}
                  </span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div
        className={`${
          score?.isFinished || score?.inTiebreak ? "pop-box" : ""
        } tennis-notice`}
      >
        {score?.isFinished
          ? "Final"
          : score?.inTiebreak
          ? `${
              score?.inFinalSet
                ? settings?.finalSetTiebreakPoints
                : settings?.tiebreakPoints
            } Point Tiebreak`
          : ""}
      </div>
    </div>
  );
};

export default TennisMatchHeader;
