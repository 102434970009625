import React from "react";

import EventImage from "../assignedMatch/eventImage";
import allowables from "../../utils/allowables";
import IconRender from "../common/icons/iconRender";

const PlayersCardView = ({ players, onSelectPlayer }) => {
  return (
    <div className="cardview">
      {players.map((p, idx) => {
        return (
          <div key={idx}>
            <div
              className="cardview-card clickable"
              style={{ display: "flex" }}
              onClick={() => onSelectPlayer(p)}
            >
              <div
                style={{
                  flex: "25%",
                  alignSelf: "center",
                  marginLeft: 5,
                  marginRight: 5,
                }}
              >
                <EventImage player={p} />
              </div>
              <div style={{ flex: "75%" }}>
                <div className="cardview-text">
                  &nbsp;<b>{allowables.splitName(p.name)}</b>
                </div>
                <div className="cardview-text">
                  <IconRender name="team" className="icon-raised" />
                  &nbsp;{p.teamName}
                </div>
                <div className="cardview-text">
                  <IconRender name="switch" className="icon-raised" />
                  &nbsp;{p.shirtNumber}
                </div>
                <div className="cardview-text row">
                  <div className="col">
                    {p.runningYellows > 0 && (
                      <div
                        className="soccer-yellow-card-bg"
                        style={{
                          borderRadius: 5,
                          width: "100%",
                          height: 25,
                          marginLeft: -5,
                          paddingLeft: 5,
                        }}
                      >
                        <IconRender name="square" />
                        &nbsp;{p.runningYellows}
                      </div>
                    )}
                  </div>
                  <div className="col">
                    {p.suspendedFlag > 0 && (
                      <div
                        className="soccer-red-card-bg"
                        style={{
                          borderRadius: 5,
                          width: "100%",
                          height: 25,
                          marginLeft: -5,
                          paddingLeft: 5,
                        }}
                      >
                        <IconRender name="ban" />
                        &nbsp;{p.suspendedFlag}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="card-separator" />
          </div>
        );
      })}
    </div>
  );
};

export default PlayersCardView;
