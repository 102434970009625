import React, { useState } from "react";
import { RiDeleteBin4Line } from "react-icons/ri";

import {
  uploadBannerImage,
  uploadWebpageImage,
} from "../../services/ftpService";
import WebpageImage from "./webpageImage";

import Avatar from "./../common/pageComponents/avatarEditor";
import MiniHeader from "../common/pageComponents/miniHeader";

const ImagePickers = ({ org, onDeleteImage }) => {
  const [bannerOpen, setBannerOpen] = useState(false);
  const [imageOpen, setImageOpen] = useState(false);
  return (
    <div>
      <MiniHeader onClick={() => setBannerOpen(!bannerOpen)}>
        Banner Image
      </MiniHeader>
      {bannerOpen && (
        <div className="form-divided-section">
          The banner image will display towards the top of the page just below
          your Header/Slogan. The aspect ratio is 8:1
          <br />
          {org.webpage && org.webpage.bannerImage && (
            <div>
              <WebpageImage
                location={org.webpage.bannerImage}
                alt={org.name}
                width={400}
                height={50}
                extraClass="banner-crop"
              />
              &nbsp;
              <button
                className="btn btn-sm btn-dark"
                onClick={() => onDeleteImage(org.webpage.bannerImage, "banner")}
              >
                <RiDeleteBin4Line />
              </button>
              <br />
            </div>
          )}
          <br />
          <Avatar
            width={800}
            height={100}
            uploadFunction={uploadBannerImage}
            saveTitle={"Save Banner Image"}
            minZoom={25}
            maxZoom={300}
          />
        </div>
      )}
      <br />
      <MiniHeader onClick={() => setImageOpen(!imageOpen)}>
        Other Images
      </MiniHeader>
      {imageOpen && (
        <div className="form-divided-section">
          Images you upload here will display around your page. You may upload
          up to 4 images. The aspect ratio is 1:1
          <br />
          <div className="row">
            {org.webpage?.images &&
              org.webpage?.images.map((e, i) => {
                return (
                  <div className="col text-center" key={i}>
                    <WebpageImage
                      location={e}
                      alt={org.name}
                      width={75}
                      height={75}
                    />
                    <div style={{ height: 5 }} />
                    <button
                      className="btn btn-sm btn-dark"
                      onClick={() => onDeleteImage(e, "images")}
                    >
                      <RiDeleteBin4Line />
                    </button>
                  </div>
                );
              })}
          </div>
          <br />
          {org.webpage?.images && org.webpage.images.length >= 4 ? (
            <p>
              You have already uploaded the maximum number of images. Please
              delete one of your current images before uploading another
            </p>
          ) : (
            <Avatar
              width={250}
              height={250}
              uploadFunction={uploadWebpageImage}
              saveTitle={"Save Image"}
              minZoom={25}
              maxZoom={300}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ImagePickers;
