import React from "react";
import { CLink } from "../common/customs/customLinks";

const faqs = [
  {
    question: "How do I collect payments?",
    keyword: "payments",
    tag: "Admin",
    answer: (
      <div>
        Ultimate Scoreboard provides League owners the ability to easily collect
        and track their payments. On your League page you can set up if you
        collect your payments from each individual player, or if you require one
        lump sum payment from the team captain. Here you will also set the
        amount. When you first register your League you can select your currency
        but after that it is set. Please contact support if you need to update
        this.
        <br />
        <br />
        Payments are only collected in whole amounts, no partial payments can be
        made. If you have selected to collect bulk payment from a team only the
        team captain will be able to make that payment.
        <br />
        <br />
        The first thing to do would be to visit your Payments page and set up
        your connected Stripe account. This is very simple and should take no
        more than a few minutes.
        <br />
        <CLink path="/payments" color={true}>
          Set up Payments
        </CLink>
        <br />
        Once your Stripe account and amounts are set up you are ready to accept
        payments from your players. Players who have yet to pay their League
        dues will have a highlighted notification on their Profile page that
        they have a payment due. You also have the option to require payment
        before a user account will be created for the player.
        <br />
        <br />
        On your Payments page you can track who has paid and is yet to pay, mark
        off payments that you received outside our site, or start a refund
        process.
      </div>
    ),
  },
  {
    question:
      "I'm having a problem with players signing up but not paying their league dues, how can I fix this?",
    keyword: "payments",
    tag: "Admin",
    answer: (
      <div>
        Collecting league dues from players always involves at least a little
        chasing, but we make it easy for you.
        <br />
        <br />
        From your league settings page you can update your options to require
        that players pay their league dues as the first step in signing up. If
        they do not complete their payment then their user account will not be
        created. If you are worried about a lot of incomplete signups you can
        track incomplete payments on your Stripe page, or we can provide more
        detailed data upon request.
        <br />
        <br />
        If you don't want to force prepayment then the Create Mailing List
        button on your Payments hub allows you to easily send reminder notices
        to unpaid players.
      </div>
    ),
  },
  {
    question: "Why is my playoff bracket displaying incorrectly?",
    keyword: "playoff bracket",
    tag: "Admin",
    answer: (
      <div>
        If your playoff bracket is displaying in a lopsided manner or matches
        are not being displayed in the correct place then you should double
        check that each match has the correct round and match number. Rounds for
        the main bracket should start at 1 and increment by 1 with each
        different round, and matches will be displayed in each round in
        ascending order based on match number. See the Playoff Brackets help
        section for more information on custom brackets.
        <br />
        <br />
        If your matches display in the correct order but the rounds are not
        lining up correctly then it is possible that your screen is too small
        for optimal full bracket display. On smaller screens and with brackets
        of 64 or more teams the bracket may get compressed. You can try
        displaying on a larger screen or zooming out on your browser (CTRL +
        mouse wheel down). If that does not work then try displaying the bracket
        round by round.
        <br />
        Due to the uneven nature of a secondary bracket those will always need
        to be displayed round by round.
        <br />
        <br />
        Playoff brackets can be extremely complicated to create and manage, so
        it is suggested that you use our bracket creator tool. Even if you do
        not have all of your teams registered yet you can create a whole bracket
        with dummy matches and fill in the teams later.
      </div>
    ),
  },
  {
    question: "How do I fill in the teams for my playoff bracket?",
    keyword: "playoff bracket",
    tag: "Admin",
    answer: (
      <div>
        An incomplete match can be edited directly from the Brackets page by
        clicking on the match number/date & time in between the two teams. This
        will allow you to set the teams from the available teams in that
        division. If you do not know which team will be filling the spot yet you
        can choose Select for Home/Away Team and this will allow you to input a
        placeholder name for that team (such as winner of seeding match).
        <br />
        <br />
        If you used our bracket creator to make your bracket then you will only
        need to fill in the first round once you know the seeds (if you created
        the bracket with all dummy teams). All other matches will be
        auto-populated when you accept the result from the previous round.
      </div>
    ),
  },
  {
    question:
      "Why do I not see all of my registered players on my code of conduct page?",
    keyword: "code of conduct",
    tag: "Admin",
    answer: (
      <div>
        There is one scenario where a registered player does not show up on your
        code of conduct list. If you have invited a team captain and they have
        signed up but not yet created their team they are just a user and not
        attached to a player. Only your registered players will show up on the
        code of conduct screen, therefore the team captain is able to accept the
        code of conduct without it being visible to an admin.
        <br />
        Once the team captain creates their team they will have a player created
        for them and at that point their code of conduct status will become
        visible to admins.
        <br />
        <br />
        If the scenario above does not describe your situation then please
        contact support.
      </div>
    ),
  },
  {
    question: "What happens if I lose internet access during a match?",
    keyword: "match",
    tag: "Referee/Umpire",
    answer: (
      <div>
        We understand that being out on the field you may not always have the
        best reception on your device. So when you are using the scorecard
        internet access is only needed at the beginning and at the end of each
        match.
        <br />
        <br />
        When you first navigate to the match page from your assigned matches and
        see the pre match screen you will need internet access as all the data
        is pulled from our servers. But from there you can referee the whole
        match without needing the internet, the only issue you may find is that
        player images not loading depending on when you lost connection. All
        events can be created as normal and are stored locally on your device
        until you are ready to submit the scorecard.
        <br />
        <br />
        You will need internet access to submit your scorecard. If you try to
        submit without having a connection you will be alerted and stay at the
        scorecard screen. On the mobile app you will already be aware of no
        internet connection with a red bar showing at the top of your screen.
        You are still able to attempt to submit and keep your data intact if
        connection fails. If you have a spotty connection you can keep trying to
        submit as many times as you need to get through.
      </div>
    ),
  },
  {
    question: "Do I really need all my referees to have an account?",
    keyword: "match",
    tag: "Referee/Umpire",
    answer: (
      <div>
        While you may prefer to have all of your referees/umpires sign up for an
        account it is not necessary.
        <br />
        <br />
        The league owner can add a username and passcode combo to the league
        which will allow any referee to pull up any incomplete scorecard and
        take charge of the match.
        <br />
        <br />
        This feature is currently only available when completing a scorecard on
        the mobile app.
      </div>
    ),
  },
  {
    question: "How do I accept the code of conduct?",
    keyword: "registration",
    tag: "Player",
    answer: (
      <div>
        First navigate to the Code of Conduct page from your Profile page or
        using the navigation bar.
        <br />
        <br />
        Next you must check the box (or move the slider in the mobile app) to
        indicate that you have read the text. Once you have done that you must
        type your name to act as your signature. On a web browser click the{" "}
        <b>Accept</b> button, on the mobile app an input box will appear.
        <br />
        <br />
        Your name must be typed exactly as it appears on the screen (except
        capitalizations). If your name does not appear correctly please first
        correct your name on your profile page.
        <br />
        <br />
        Only after typing your name correctly will the <b>Accept</b> or{" "}
        <b>OK</b> button appear. Click this button to accept the code of
        conduct. At this time your approximate location will be gathered and
        stored via your IP address.
        <br />
        <br />
        If any changes (even just updating punctuation) are made to the code of
        conduct after you have accepted it then your acceptance will
        automatically be revoked and you will have an opportunity to review the
        new code before accepting.
      </div>
    ),
  },
  {
    question: "How do I submit my vaccine information?",
    keyword: "vaccine",
    tag: "All Users",
    answer: (
      <div>
        Each league is able to require submission of covid-19 vaccine
        information from every participant, admins, referees/umpires and players
        alike.
        <br />
        <br />
        Navigate to the Vaccine page from your Profile page or using the
        navigation bar.
        <br />
        First fill out the vaccine name or brand and the latest date on which
        you received the vaccination, then click the <b>Save</b> or{" "}
        <b>Submit</b> button. Once submitted you are required to send an email
        with an attachment of a photo or scan of your vaccine card for review by
        the league owner. On the mobile app you will be prompted to open your
        email client directly after tapping the <b>Save</b> button. On web you
        will have to click the link that appears after submitting your info.
        <br />
        <br />
        Send the email with your attachment and your part is done. Your status
        will be "pending review" until the league owner reviews and accepts your
        information. If they require anything further from you they will reach
        out.
      </div>
    ),
  },
  {
    question: "How do I create my team?",
    keyword: "registration",
    tag: "Captain",
    answer: (
      <div>
        Click the <b>Create my Team</b> button on your profile page, this will
        take you to the team creation form.
        <br />
        <br />
        You will need to name your team, this name must be unique within your
        league. You will also select which division you want to participate in.
        This is only provided as a suggestion to the league admins, they retain
        the final decision on team placement.
        <br />
        <br />
        Next you will select a team abbreviation to show on the playoff
        brackets, up to 6 characters. Then select your team jersey colors and
        click Save.
        <br />
        <br />
        You will be able to edit all of this information until team editing is
        locked, usually just prior to the match schedule being created. Please
        check with your league admins for information on this deadline.
      </div>
    ),
  },
  {
    question: "How do I register my players?",
    keyword: "registration",
    tag: "Captain",
    answer: (
      <div>
        Once you have created your team you will need to add your players. Click
        the <b>Manage my Team</b> button on your profile page to be taken to
        your team information page.
        <br />
        <br />
        Scroll to the bottom of the page and click the <b>
          Add New Player
        </b>{" "}
        button.
        <br />
        Enter the first and last name of your player. This combination must be
        unique to your league to avoid confusion. If two players share the same
        name please use a unique identifier, e.g. include their middle name, or
        add a nickname.
        <br />
        <br />
        There is a team name drop down but as team captain you can only register
        players to your own team.
        <br />
        <br />
        Enter the shirt number (for soccer and football) or roster position
        (batting or kicking order for softball, baseball or kickball).
        <br />
        <br />
        If your league is using the code of conduct, vaccine, or image features
        or takes payment from individual players then every player will need to
        register their own account to complete those requirements. Enter an
        email address to send a registration link to that player. If you do not
        have an email address ready for that player you can leave this blank and
        send the email later from your team information page.
        <br />
        <br />
        Click Save and you will be returned to the main players page. From here
        you can view the player you just created and add more players to your
        team.
      </div>
    ),
  },
  {
    question: "How do I pay my league dues?",
    keyword: "registration",
    tag: "Player",
    answer: (
      <div>
        If you owe league dues you will have a yellow highlight on your profile
        page. Click this button to be taken to your payments home screen (you
        can also navigate there by clicking your name and then Payments from the
        navigation bar).
        <br />
        <br />
        Here you will be able to see how much you owe. Click the{" "}
        <b>Pay League Dues</b> button to make your payment. This will open up a
        Stripe checkout page and from here you can enter all of your information
        to make the payment.
        <br />
        <br />
        When the payment goes through your account will be updated as paid. Do
        not use your browser navigation buttons during this process as this can
        interrupt your payment information being communicated to our servers.
      </div>
    ),
  },
  {
    question: "What do I need to do to complete my registration?",
    keyword: "registration",
    tag: "Player",
    answer: (
      <div>
        The steps taken to complete your registration will vary from league to
        league. Please visit your profile page to see which steps you still have
        to complete.
        <br />
        <br />
        Your registration steps still pending will be highlighted in yellow on
        the right hand side of your profile page. These could include:
        <ul>
          <li>Paying league dues</li>
          <li>Accepting the code of conduct</li>
          <li>Submitting vaccination information</li>
          <li>Adding profile image</li>
        </ul>
        For more information on how to complete each step please see the
        individual topics in the FAQs.
      </div>
    ),
  },
  {
    question: "How do I upload my profile image?",
    keyword: "registration",
    tag: "Player",
    answer: (
      <div>
        Most leagues will require the uploading of a profile image so that
        referees/umpires have an easier time identifying who is who on the
        field. As your profile image is used for identification purposes please
        upload a clear picture of only your face, without sunglasses or hats.
        You should think of this image like it is a driver's license or passport
        photo.
        <br />
        <br />
        Your profile image will transfer across all of your Ultimate Scoreboard
        accounts under the same email address.
        <br />
        <br />
        First navigate to your Profile page. Click the <b>
          Add Profile Image
        </b>{" "}
        button, then the <b>Choose New File</b> button.
        <br />
        Select a file from your computer, use the built in controls to zoom,
        rotate, or move it around to center the image, then click Save.
        <br />
        <br />
        If you need to take a new photo then using the mobile app is the best
        way to do this. You can download the app for iOS or Android using the
        links at the bottom of this page.
      </div>
    ),
  },
  {
    question: "Why can I not recruit a player from Free Agents?",
    keyword: "registration",
    tag: "Captain",
    answer: (
      <div>
        If you league has chosen not to turn the waiver wire system on then team
        captains will be unable to recruit players from the Free Agents. To add
        a Free Agent to your team please contact your league admin and make the
        request.
        <br />
        <br />
      </div>
    ),
  },
  {
    question: "How does the waiver wire work?",
    keyword: "registration",
    tag: "Captain",
    answer: (
      <div>
        If your league has chosen to turn the waiver wire on then as team
        captain you will be responsible for player recruitment to and from the
        Free Agents team.
        <br />
        <br />
        If a player signs up as a free agent they are immediately available to
        be recruited by any team captain. Go to the Players screen, filter by
        Free Agents and click the black add player button next to a player who
        has "Open" listed as when their Waiver Wire expires
        <br />
        <br />
        If a player is sent to the Free Agents by their captain or chooses to
        leave their team then they will be placed on the waiver wire. While on
        the waiver wire a player cannot be directly recruited. Instead teams
        must place bids for that player. Once the waiver wire for that player
        comes to an end all of the bids will be evaluated and the team with the
        highest waiver priority which is not at the maximum roster size will
        recruit that player.
        <br />
        <br />
        To see your team's priority go to your team page, it will be listed
        there. To place a bid for a player on the waiver wire follow the same
        instructions as recruiting a free agent. Instead of being placed on your
        team your bid will be put in for that player. You can see you current
        bid on your Team page. It can also be canceled from there if necessary.
        You can only have a bid out for one player at a time. Bids made by other
        teams are secret, you can only know your own bid.
        <br />
        <br />
        Once the waiver wire for a player expires the player will be placed on
        their new team and all the bids will be reset. Teams who were not
        successful in their bid will have their original priority restored and
        moved up one place. The team which won the waiver will be moved to the
        lowest priority. If no team made a bid for the player then they will
        become an open free agent and can be freely recruited by any team.
      </div>
    ),
  },
];

export default faqs;
