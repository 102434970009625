import React from "react";

import BasicModal from "../common/pageComponents/basicModal";
import allowables from "../../utils/allowables";
import OrgLogo from "./../common/pageComponents/orgLogo";
import OrgBannerLogo from "./../common/pageComponents/orgBannerLogo";
import MiniHeader from "../common/pageComponents/miniHeader";

const SuperAdminLeaguePopup = ({ isOpen, onClose, league }) => {
  if (!league) return null;

  const items = [
    {
      label: "Contact Info",
      rows: [
        { label: "Owner Name", text: allowables.splitName(league.ownerName) },
        {
          label: "Owner Email",
          text: <a href={`mailto:${league.ownerEmail}`}>{league.ownerEmail}</a>,
        },
        {
          label: "Customer Service Email",
          dontDisplay: !league.misc?.customerServiceEmail,
          text: (
            <a href={`mailto:${league.misc?.customerServiceEmail}`}>
              {league.misc?.customerServiceEmail}
            </a>
          ),
        },
        {
          label: "Customer Phone Number",
          dontDisplay: !league.misc?.customerServicePhoneNumber,
          text: (
            <a href={`tel:${league.misc?.customerServicePhoneNumber}`}>
              {league.misc?.customerServicePhoneNumber}
            </a>
          ),
        },
        {
          label: "Vaccination Email",
          dontDisplay: !league.misc?.vaccinationEmail,
          text: (
            <a href={`mailto:${league.misc?.vaccinationEmail}`}>
              {league.misc?.vaccinationEmail}
            </a>
          ),
        },
        {
          label: "Website Extension",
          text: league.spectatingAllowed ? (
            <a
              href={`${allowables.url}/${league.spectatingAllowed}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {league.spectatingAllowed}
            </a>
          ) : (
            "No spectating"
          ),
        },
        {
          label: "League Website",
          dontDisplay: !league.misc?.leagueWebsiteAddress,
          text: league.misc?.leagueWebsiteAddress,
        },
        {
          label: "External Links",
          text: league.misc?.externalLinks.map((el) => (
            <>
              <br />
              <a href={el.url}>
                {el.text} - {el.url}
              </a>
            </>
          )),
        },
        {
          label: "Referral Source",
          text: league.referralSource,
        },
      ],
    },
    {
      label: "Payment Info",
      rows: [
        {
          label: "Collected From",
          text: league.stripe ? league.stripe.whoPays : "No Stripe link",
        },
        {
          label: "Payment before Signup",
          text: league.misc?.requirePaymentBeforeSignup,
        },
        {
          label: "Amount",
          text: league.stripe ? league.stripe.amount : "No Stripe link",
        },
        {
          label: "Refund Policy",
          text: league.misc?.refundPolicy || "Not set",
        },
      ],
    },
    {
      label: "Main Features",
      rows: [
        { label: "Sport", text: league.sport },
        {
          label: "Live Scorecards",
          text: league.misc?.liveScorecards ? "On" : "Off",
        },
        {
          label: "Free Agent Registration",
          text: league.misc?.activeRegistrationLinks?.freeAgent ? "Yes" : "No",
        },
        {
          label: "Team Captain Direct Registration",
          text: league.misc?.activeRegistrationLinks?.teamCaptain
            ? "Yes"
            : "No",
        },
        {
          label: "User Images",
          text: league.misc?.userImages || "No",
        },
        {
          label: "Waiver Wire",
          text: league.misc?.waiverWireOn
            ? `On: ${league.misc?.waiverWireLength} day waivers`
            : "Off",
        },
        {
          label: "Custom Ads",
          text: league.customAds && league.customAds.enabled ? "Yes" : "No",
        },
        {
          label: "Ad Units",
          dontDisplay: !league.customAds,
          text:
            league.customAds &&
            league.customAds.enabled &&
            Object.keys(league.customAds.adUnits).map((a) => (
              <div>
                {a}: {league.customAds.adUnits[a].length}
              </div>
            )),
        },
      ],
    },

    {
      label: "Other Misc Settings",
      rows: [
        { label: "Approvals", text: league.captainApproval },
        {
          label: "Soccer Suspensions: Running Yellows",
          dontDisplay: league.sport !== "Soccer",
          text: league.yellowsInTournament,
        },
        {
          label: "Soccer Suspensions: Two Yellows in One Game",
          dontDisplay: league.sport !== "Soccer",
          text: league.twoYellowsInGame,
        },
        {
          label: "Soccer Suspensions: Straight Red Card",
          dontDisplay: league.sport !== "Soccer",
          text: league.redCard,
        },
        {
          label: "Soccer Suspensions: Accumulated Yellows for Suspension",
          dontDisplay: league.sport !== "Soccer",
          text: league.accumulateYellows,
        },
        {
          label: "Soccer: Track Assists",
          dontDisplay: league.sport !== "Soccer",
          text: league.misc?.soccerSportOptions?.trackAssists ? "Yes" : "No",
        },
        {
          label: "Diamond Sports: Sport Name",
          dontDisplay: !league.sport.includes("Diamond"),
          text: league.misc?.diamondSportOptions?.sportName,
        },
        {
          label: "Diamond Sports: Outs Per Inning",
          dontDisplay: !league.sport.includes("Diamond"),
          text: league.misc?.diamondSportOptions?.outsPerInning,
        },
        {
          label: "Diamond Sports: Innings per Game",
          dontDisplay: !league.sport.includes("Diamond"),
          text: league.misc?.diamondSportOptions?.inningsPerGame,
        },
        {
          label: "Diamond Sports: Hide Foul Balls",
          dontDisplay: !league.sport.includes("Diamond"),
          text: league.misc?.diamondSportOptions?.hideFoulBalls,
        },
        { label: "Vaccines", text: league.requireVaccine ? "Yes" : "No" },
        {
          label: "Code of Conduct",
          text: league.codeOfConduct.length > 0 ? "Yes" : "No",
        },
        {
          label: "Webpage Is Setup",
          text: league.webpage ? "Yes" : "No",
        },
        {
          label: "Region",
          text: league.misc?.region?.name || "No",
        },
        {
          label: "Region Longitude",
          dontDisplay: !league.misc?.region,
          text: league.misc?.region?.longitude,
        },
        {
          label: "Region Latitude",
          dontDisplay: !league.misc?.region,
          text: league.misc?.region?.latitude,
        },
        {
          label: "Ranking Type",
          text: league.misc?.rankingType,
        },
        {
          label: "Elo K Factor",
          text: league.misc?.eloKFactor,
        },
        {
          label: "Default Scorecard Approval",
          text: league.misc?.defaultApproval,
        },
        {
          label: "Comment Hint",
          text: league.misc?.commentHint,
        },
        {
          label: "Custom Flag 1",
          dontDisplay: !league.misc?.customFlag1,
          text: league.misc?.customFlag1,
        },
        {
          label: "Custom Flag 2",
          dontDisplay: !league.misc?.customFlag2,
          text: league.misc?.customFlag2,
        },
        {
          label: "Pending Accounts",
          text: ["admin", "referee", "captain"].map((t) => (
            <div>
              {allowables.capLetterOne(t)}:{" "}
              {league.pendingAccounts.filter((a) => a.regType === t).length}
            </div>
          )),
        },
      ],
    },
  ];

  return (
    <BasicModal
      isOpen={isOpen}
      onClose={onClose}
      header={
        <div>
          <div className="text-center">
            <OrgBannerLogo _id={league.bannerLogo} name={league.name} />
          </div>
          <div className="row">
            <div className="col-4">
              <OrgLogo _id={league.logo} name={league.name} />
            </div>
            <div className="col">
              <h3 className="text-left">{league.name}</h3>
            </div>
          </div>
        </div>
      }
    >
      <div>
        {items.map((i) => {
          return (
            <div key={i.label}>
              <MiniHeader>{i.label}</MiniHeader>
              <div className="form-divided-section">
                {i.rows.map((r) => {
                  return r.dontDisplay ? null : (
                    <p key={r.label}>
                      <small>{r.label}</small>: {r.text}
                    </p>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </BasicModal>
  );
};

export default SuperAdminLeaguePopup;
