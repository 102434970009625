import React, { useState, useContext } from "react";
import MiniHeader from "../common/pageComponents/miniHeader";
import { useEffect } from "react";
import {
  getDiscounts,
  deleteDiscount,
  applyDiscount,
} from "../../services/discountService";
import { getPlayers } from "../../services/playerService";
import { getTeams } from "../../services/teamService";
import toast from "../../utils/toast";
import DiscountForm from "./discountForm";
import IconRender from "../common/icons/iconRender";
import CustomConfirm from "../common/customs/customConfirm";
import HeaderContext from "../../context/headerContext";
import ApplyDiscount from "./applyDiscount";
import TooltipButton from "../common/tooltip/tooltipButton";
import allowables from "../../utils/allowables";

const Discounts = ({ org }) => {
  const { setLoading } = useContext(HeaderContext);
  const [discounts, setDiscounts] = useState([]);
  const [discountFormOpen, setDiscountFormOpen] = useState(false);
  const [selectedDiscount, setSelectedDiscount] = useState(null);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [applyDiscountOpen, setApplyDiscountOpen] = useState(false);
  const [paymentItems, setPaymentItems] = useState([]);

  const getData = async (discountID) => {
    setLoading(true);
    const res = await getDiscounts();
    if (res.status === 200) {
      if (discountID) {
        setSelectedDiscount(res.data.find((d) => d._id === discountID));
      }
      setDiscounts(res.data);
      const paymentItemsRes = await (org?.stripe?.whoPays === "Player"
        ? getPlayers
        : getTeams)();
      if (paymentItemsRes.status === 200) {
        setPaymentItems(paymentItemsRes.data);
      } else toast.error(paymentItemsRes.data);
    } else toast.error(res.data);
    setLoading(false);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const confirmDeleteDiscount = async () => {
    setLoading(true);
    const res = await deleteDiscount(selectedDiscount._id);
    if (res.status === 200) {
      setDeleteOpen(false);
      toast.success(res.data);
      return getData();
    } else toast.error(res.data);
    setLoading(false);
  };

  const handleApplyDiscount = async (item) => {
    setLoading(true);
    const res = await applyDiscount(selectedDiscount._id, [item._id]);
    if (res.status === 200) {
      const appliedText = item.applied ? "removed from" : "applied to";
      toast.dismissAll();
      toast.success(
        `${selectedDiscount.name} ${appliedText} ${allowables.splitName(
          item.name
        )}`
      );
      return getData(selectedDiscount._id);
    } else toast.error(res.data);
    setLoading(false);
  };

  return (
    <div className="centered-small-input-area">
      <DiscountForm
        isOpen={discountFormOpen}
        setIsOpen={setDiscountFormOpen}
        discount={selectedDiscount}
        onSuccess={getData}
      />
      <ApplyDiscount
        isOpen={applyDiscountOpen}
        setIsOpen={setApplyDiscountOpen}
        discount={selectedDiscount}
        paymentItems={paymentItems}
        org={org}
        onApplyDiscount={handleApplyDiscount}
      />
      <div className="pop-box text-center">
        Create discount codes for your players to use on their league dues
        payment. You can also set discounts to apply to specific users only.
        <button
          className="btn btn-block btn-info"
          onClick={() => {
            setSelectedDiscount({ _id: "new" });
            setDiscountFormOpen(true);
          }}
        >
          Add New Discount
        </button>
      </div>
      <MiniHeader>Current Discounts</MiniHeader>
      <div className="form-divided-section text-center">
        {discounts.length ? (
          discounts.map((discount, idx) => (
            <div className="cardview-card" key={idx}>
              <div className="row">
                <div className="col-2">
                  <br />
                  <TooltipButton
                    tooltipText={`Apply ${discount.name} Discounts`}
                    tooltipDirection="right"
                    buttonClass="btn btn-info btn-sm"
                    onClick={() => {
                      setSelectedDiscount(discount);
                      setApplyDiscountOpen(true);
                    }}
                  >
                    <IconRender name="discount" />
                  </TooltipButton>
                </div>
                <div className="col">
                  <h6>
                    <b>{discount.name}</b>
                  </h6>
                  <small>Code:</small> <b>{discount.code}</b>
                  <br />
                  {discount.discountInfo.amount ? (
                    <div>
                      <small>Amount:</small>{" "}
                      <b>{discount.discountInfo.amount}</b>
                    </div>
                  ) : (
                    <div>
                      <small>Percentage:</small>{" "}
                      <b>{discount.discountInfo.percentage}</b>
                    </div>
                  )}
                  <small>Active: </small>
                  <IconRender
                    name={discount.active ? "checkedbox" : "uncheckedbox"}
                    className="icon-info"
                  />
                </div>
                <div className="col-3">
                  <TooltipButton
                    tooltipText={`Edit ${discount.name}`}
                    tooltipDirection="left"
                    buttonClass="btn btn-secondary btn-sm"
                    onClick={() => {
                      setSelectedDiscount(discount);
                      setDiscountFormOpen(true);
                    }}
                  >
                    <IconRender name="edit" />
                  </TooltipButton>
                  <br />
                  <TooltipButton
                    tooltipText={`Delete ${discount.name}`}
                    tooltipDirection="left"
                    buttonClass="btn btn-dark btn-sm"
                    onClick={() => {
                      setSelectedDiscount(discount);
                      setDeleteOpen(true);
                    }}
                  >
                    <IconRender name="delete" />
                  </TooltipButton>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>You do not have any discounts</p>
        )}
      </div>
      <CustomConfirm
        dialog={"Delete this discount code?"}
        callback={confirmDeleteDiscount}
        isOpen={deleteOpen}
        close={setDeleteOpen}
        yesNo={true}
      />
    </div>
  );
};

export default Discounts;
