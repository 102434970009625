import React from "react";
import Joi from "joi-browser";
import Form from "../common/form/form";
import BasicModal from "../common/pageComponents/basicModal";
import { popupStyle } from "../../utils/styleUtil";
import MiniHeader from "../common/pageComponents/miniHeader";
import TooltipButton from "../common/tooltip/tooltipButton";
import IconRender from "../common/icons/iconRender";
import FormationCreator, {
  fields,
  players as defaultPlayers,
  flipFormation,
} from "formation-creator";
import allowables from "../../utils/allowables";
import PlayerEditor from "./playerEditor";

const formations = {
  5: ["1-2-1", "2-2"],
  7: ["1-2-1-2", "2-1-2-1", "3-1-2"],
  11: ["4-4-2", "4-3-3", "5-4-1"],
};

class TacticEditor extends Form {
  state = {
    id: "",
    data: { name: "", description: "", group: "", ranking: "" },
    errors: {},
    baseState: {},
    drawMode: null,
    playerEditorOpen: false,
  };

  schema = {
    name: Joi.string().min(3).max(99).required().label("Title"),
    description: Joi.string()
      .min(3)
      .max(99)
      .optional()
      .allow("")
      .label("Description"),
    group: Joi.string().min(3).max(50).optional().allow("").label("Group Name"),
    ranking: Joi.number()
      .integer()
      .optional()
      .min(-1)
      .max(99)
      .allow("")
      .label("Order"),
  };

  componentDidUpdate(prevProps) {
    if (!this.props.createOpen) return;
    if (prevProps.tactic !== this.props.tactic) {
      this.setState({
        id: this.props.tactic._id,
        data: this.mapToViewModel(this.props.tactic),
        baseState: this.mapToViewModel(this.props.tactic),
        errors: {},
      });
    }
  }

  mapToViewModel(tactic) {
    return {
      name: tactic?.name || "",
      description: tactic?.description || "",
      group: tactic?.group || "",
      ranking: tactic?.ranking || "",
    };
  }

  showingDefenders = () => {
    return this.props.players.find((p) => p.defender);
  };

  handleSelection = (piece, selection, final) => {
    let curTactic = { ...this.props.tactic };
    curTactic[piece] = selection;
    this.props.setTactic(curTactic);
    this.props.setCurrentPage((currentPage) => currentPage + 1);

    if (final) {
      this.props.setField({
        ...fields.soccer[this.props.tactic.playersPerTeam],
        id: "temp",
      });
      let temp = [];
      defaultPlayers.soccer[this.props.tactic.playersPerTeam][
        selection
      ].players.forEach((p) => {
        p.backgroundColor = this.props.team.teamColor1;
        p.color = allowables.getTextColor(this.props.team.teamColor1);
        temp.push(p);
      });
      this.props.setPlayers(temp);
    }
  };

  setTactic = (tactic) => {
    this.setState({
      id: tactic._id,
      data: this.mapToViewModel(tactic),
      baseState: this.mapToViewModel(tactic),
      errors: {},
    });
  };

  doSubmit = () => {
    this.props.onSave(this.state.id, this.state.data, this.setTactic);
  };

  render() {
    const {
      team,
      tactic,
      createOpen,
      setCreateOpen,
      currentPage,
      isNew,
      field,
      players,
      setPlayers,
      lines,
      setLines,
      selectedPlayer,
      setSelectedPlayer,
      onRestart,
    } = this.props;

    const { drawMode, playerEditorOpen, id } = this.state;

    return (
      <div>
        <BasicModal
          isOpen={createOpen}
          onClose={setCreateOpen}
          style={{
            content: {
              ...popupStyle.content,
              minWidth: "90%",
              minHeight: "70%",
            },
          }}
        >
          {isNew ? (
            <div>
              <MiniHeader>Create New Tactic</MiniHeader>
              <div className="form-divided-section text-center">
                <div style={{ display: currentPage === 1 ? "" : "none" }}>
                  <b>Select Players per Team</b>
                  {["5", "7", "11"].map((ppt) => (
                    <button
                      key={ppt}
                      className={
                        "btn btn-block btn-md btn-" +
                        (tactic.playersPerTeam
                          ? tactic.playersPerTeam === ppt
                            ? "info"
                            : "dark"
                          : "secondary")
                      }
                      onClick={() =>
                        this.handleSelection("playersPerTeam", ppt)
                      }
                    >
                      {ppt}
                    </button>
                  ))}
                </div>
                <div style={{ display: currentPage === 2 ? "" : "none" }}>
                  <b>Select Base Formation</b>
                  {formations[tactic.playersPerTeam || "11"].map(
                    (formation) => (
                      <button
                        key={formation}
                        className={
                          "btn btn-block btn-md btn-" +
                          (tactic.formation
                            ? tactic.formation === formation
                              ? "info"
                              : "dark"
                            : "secondary")
                        }
                        onClick={() =>
                          this.handleSelection("formation", formation, true)
                        }
                      >
                        {formation}
                      </button>
                    )
                  )}
                </div>
                <div style={{ display: currentPage === 3 ? "" : "none" }}>
                  <div className="row">
                    <div className="col">
                      <div>
                        <b>{tactic.playersPerTeam}</b> Players per Team
                      </div>
                      <div>
                        <b>{tactic.formation}</b> Base Formation
                      </div>
                    </div>
                    <div className="col-2">
                      <TooltipButton
                        tooltipText="Start Over"
                        tooltipDirection="left"
                        onClick={onRestart}
                        buttonClass="btn btn-md btn-info"
                      >
                        <IconRender name="reset" />
                      </TooltipButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <MiniHeader>
                <small>Edit Tactic:</small> {tactic.name}
              </MiniHeader>
              <br />
            </div>
          )}
          <div
            className="text-center"
            style={{ display: currentPage === 3 ? "" : "none" }}
          >
            <div className="row">
              <div className="col">
                <TooltipButton
                  tooltipText={`In ${
                    drawMode ? "Line" : "Move"
                  } Mode, click to switch`}
                  tooltipDirection="top"
                  onClick={() =>
                    this.setState({
                      drawMode: drawMode
                        ? null
                        : {
                            lineColor: "black",
                            withMarker: true,
                          },
                    })
                  }
                  buttonClass={`btn btn-${drawMode ? "dark" : "info"}`}
                >
                  <IconRender name={drawMode ? "arrowup" : "drag"} />
                </TooltipButton>
              </div>
              <div className="col">
                <TooltipButton
                  tooltipText={`Show ${
                    players[0]?.numberFirst ? "Names" : "Numbers"
                  }`}
                  tooltipDirection="top"
                  onClick={() => {
                    let temp = [];
                    players.forEach((player) => {
                      let p = { ...player };
                      p.numberFirst = !p.numberFirst;
                      temp.push(p);
                    });
                    setPlayers(temp);
                  }}
                  buttonClass={`btn btn-md btn-${
                    players[0]?.numberFirst ? "info" : "light"
                  }`}
                >
                  <IconRender
                    name={players[0]?.numberFirst ? "letter" : "number"}
                  />
                </TooltipButton>
              </div>
              <div className="col">
                <TooltipButton
                  tooltipText={`${
                    this.showingDefenders() ? "Hide" : "Show"
                  } Defenders`}
                  tooltipDirection="top"
                  onClick={() => {
                    if (this.showingDefenders()) {
                      let temp = players.filter((p) => !p.defender);
                      setPlayers(temp);
                    } else {
                      let temp = [...players];
                      flipFormation(temp).forEach((def) => {
                        def.backgroundColor = allowables.getTextColor(
                          team.teamColor1
                        );
                        def.color = team.teamColor1;
                        def.defender = true;
                        temp.push(def);
                      });

                      setPlayers(temp);
                    }
                  }}
                  buttonClass={`btn btn-md btn-${
                    this.showingDefenders() ? "info" : "light"
                  }`}
                >
                  <IconRender name="jersey" />
                </TooltipButton>
              </div>
              <div className="col">
                {selectedPlayer ? (
                  <TooltipButton
                    tooltipText={`Edit ${
                      selectedPlayer.defender ? "Defense: " : ""
                    }${selectedPlayer.number} - ${selectedPlayer.name}`}
                    tooltipDirection="top"
                    onClick={() => this.setState({ playerEditorOpen: true })}
                    buttonClass="btn btn-md btn-secondary"
                  >
                    <IconRender name="edit" />
                  </TooltipButton>
                ) : null}
              </div>
              <div className="col">
                {lines.length > 0 ? (
                  <TooltipButton
                    tooltipText="Remove last line"
                    tooltipDirection="top"
                    onClick={() => {
                      let temp = [...lines];
                      temp.pop();
                      setLines(temp);
                    }}
                    buttonClass="btn btn-md btn-dark"
                  >
                    <IconRender name="undo" />
                  </TooltipButton>
                ) : null}
              </div>
            </div>
            <div style={{ margin: 10 }}>
              {selectedPlayer ? (
                <span>
                  {selectedPlayer.defender ? "Defense: " : ""}
                  <b>
                    {selectedPlayer.number} - {selectedPlayer.name}
                  </b>
                </span>
              ) : (
                "Select a Player to Edit"
              )}
            </div>
            <FormationCreator
              field={{ ...field, id: id || "temp" }}
              players={players}
              setPlayers={setPlayers}
              lines={lines}
              setLines={setLines}
              drawMode={drawMode}
              onDrop={(updatedPlayers) => {
                setSelectedPlayer(updatedPlayers[updatedPlayers.length - 1]);
              }}
            />
            <br />
            {this.renderWholeForm(
              "",
              null,
              null,
              <div>
                {this.renderInput("name", "Title")}
                {this.renderTextArea("description", "Description", "", "2")}
                {this.renderInput(
                  "group",
                  "Group Name",
                  null,
                  null,
                  null,
                  null,
                  null,
                  {
                    header: "Group Name",
                    text: `Tactics under the same group name will be shown horizontally.
                    Tactics without a group will be shown together.
                    Use the Order field to rearrange tactics within a group.`,
                    direction: "right",
                    className: "icon-mobile",
                  }
                )}
                {this.renderInput(
                  "ranking",
                  "Order",
                  null,
                  "number",
                  null,
                  null,
                  null,
                  {
                    header: "Order",
                    text: `The order in which the tactic will show within its group`,
                    direction: "right",
                    className: "icon-mobile",
                  },
                  "1"
                )}
              </div>,
              false,
              { noHeader: true, formClass: "", inputDivClass: "text-left" }
            )}
          </div>
          <PlayerEditor
            visible={playerEditorOpen}
            setVisible={(bool) => this.setState({ playerEditorOpen: bool })}
            player={selectedPlayer}
            team={team}
            onSave={(player) => {
              let temp = [...players];
              let thisPlayer = temp[temp.length - 1];
              thisPlayer.color = allowables.getTextColor(
                player.backgroundColor
              );
              Object.assign(thisPlayer, player);
              temp.splice(temp.length - 1, 1, thisPlayer);
              setPlayers(temp);
              this.setState({ playerEditorOpen: false });
            }}
          />
        </BasicModal>
      </div>
    );
  }
}

export default TacticEditor;
