import React from "react";

import VideoPlayer from "../media/videoPlayer";
import LeagueContactInfo from "./leagueContactInfo";
import WebpageImage from "./webpageImage";
import allowables from "../../utils/allowables";
import SideBySideView from "../common/pageComponents/sideBySideView";
import MiniHeader from "../common/pageComponents/miniHeader";
import IconRender from "../common/icons/iconRender";
import WebpageDivider from "./webpageDivider";

const LeagueWebpage = (props) => {
  const webpage = (props.pageOrg && props.pageOrg.webpage) || {};
  const text = webpage && webpage.text ? webpage.text.split("%20%") : [];
  const highlightColor = webpage.themeTextColor || "black";
  const bgColor = webpage?.logoBgColor || "white";
  const textColor = allowables.getTextColor(webpage?.themeTextColor) || "white";
  const bgTextColor = allowables.getTextColor(webpage?.logoBgColor) || "black";
  const style = { backgroundColor: highlightColor, color: textColor };

  return webpage ? (
    <div>
      <MiniHeader overrideStyle={style}>{webpage.header}</MiniHeader>
      <div
        className="form-divided-section"
        style={{
          borderColor: highlightColor,
          backgroundColor: bgColor,
          color: bgTextColor,
        }}
      >
        {webpage.bannerImage && (
          <div>
            <WebpageImage
              location={webpage.bannerImage}
              alt={props.pageOrg.name}
              width={"100%"}
              extraClass="banner-crop"
            />
            <WebpageDivider color={highlightColor} />
          </div>
        )}
        {webpage.showFreeAgentLink && (
          <div>
            <WebpageDivider color={highlightColor} />
            <button
              className="btn btn-lg btn-block"
              style={{
                backgroundColor: highlightColor,
                color: textColor,
              }}
              onClick={() =>
                window.open(
                  `${allowables.url}/freeagent?id=${props.pageOrg._id}`,
                  "_blank",
                  "noopener noreferrer"
                )
              }
            >
              {allowables.ballIcon(
                allowables.translateSportName(props.pageOrg)
              )}
              &nbsp;<b>Register</b>
              &nbsp;
              {allowables.ballIcon(
                allowables.translateSportName(props.pageOrg)
              )}
            </button>
            <WebpageDivider color={highlightColor} />
          </div>
        )}
        {props.pageOrg?.misc?.externalLinks?.length > 0 && (
          <div>
            <SideBySideView
              Components={props.pageOrg.misc.externalLinks.map((link, i) => (
                <div className="col text-center" key={i}>
                  <button
                    onClick={() =>
                      window.open(link.url, "_blank", "noopener noreferrer")
                    }
                    className="btn btn-md btn-block"
                    style={{
                      backgroundColor: highlightColor,
                      color: textColor,
                    }}
                  >
                    {link.text}&nbsp;
                    <IconRender name="externallink" />
                  </button>
                </div>
              ))}
            />
            <WebpageDivider color={highlightColor} />
          </div>
        )}
        {webpage.videoUrl && (
          <div style={{ width: "100%" }}>
            <VideoPlayer url={webpage.videoUrl} />
            <WebpageDivider color={highlightColor} />
          </div>
        )}
        <SideBySideView
          minWidth={400}
          separateRows={true}
          customDivider={<WebpageDivider color={highlightColor} />}
          colClassNames={["col", "col-4"]}
          doNotCenterOnStack
          Components={[
            <div style={{ marginLeft: 20, marginRight: 20 }}>
              {text.map((t, i) =>
                t ? <div key={i}>{t}</div> : <div key={i}>&nbsp;</div>
              )}
            </div>,
            <div>
              {webpage.images &&
                webpage.images.map((image, idx) => (
                  <div key={idx}>
                    <WebpageImage
                      location={image}
                      alt={"image" + idx}
                      width={"100%"}
                    />
                    {idx < webpage.images.length - 1 && (
                      <WebpageDivider color={highlightColor} />
                    )}
                  </div>
                ))}
            </div>,
          ]}
        />
      </div>
      <LeagueContactInfo
        org={props.pageOrg}
        bgColor={bgColor}
        textColor={textColor}
        bgTextColor={bgTextColor}
        highlightColor={highlightColor}
      />
    </div>
  ) : null;
};

export default LeagueWebpage;
