import http from "./httpService";

export async function uploadAvatar(avatar) {
  try {
    return await http.post(http.ftpEndpoint + "/avatar", { avatar });
  } catch (ex) {
    return ex.response;
  }
}

export async function deleteAvatar(user) {
  try {
    return await http.delete(http.ftpEndpoint + "/avatar");
  } catch (ex) {
    return ex.response;
  }
}

export async function uploadOrgLogo(logo) {
  try {
    return await http.post(http.ftpEndpoint + "/logo", { logo });
  } catch (ex) {
    return ex.response;
  }
}

export async function removeOrgLogo() {
  try {
    return await http.delete(http.ftpEndpoint + "/logo");
  } catch (ex) {
    return ex.response;
  }
}

export async function uploadTeamLogo(logo) {
  try {
    return await http.post(http.ftpEndpoint + "/team/logo", { logo });
  } catch (ex) {
    return ex.response;
  }
}

export async function removeTeamLogo() {
  try {
    return await http.delete(http.ftpEndpoint + "/team/logo");
  } catch (ex) {
    return ex.response;
  }
}

export async function uploadBannerImage(image) {
  try {
    return await http.post(http.ftpEndpoint + "/webpage/image", {
      image,
      location: "banner",
    });
  } catch (ex) {
    return ex.response;
  }
}

export async function uploadWebpageImage(image) {
  try {
    return await http.post(http.ftpEndpoint + "/webpage/image", {
      image,
      location: "images",
    });
  } catch (ex) {
    return ex.response;
  }
}

export async function removeWebpageImage(imageName, location) {
  try {
    return await http.delete(
      http.ftpEndpoint + "/webpage/image/" + imageName + "/" + location
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function manageImage(type, _id, image = null) {
  try {
    return http.put(http.ftpEndpoint + "/" + type + "/" + _id, {
      image,
    });
  } catch (ex) {
    return ex.response;
  }
}
