import React from "react";

import TeamLogo from "../common/pageComponents/teamLogo";
import allowables from "../../utils/allowables";
import IconRender from "../common/icons/iconRender";

const TeamsCardView = ({ teams, onClick }) => {
  return (
    <div
      className={"cardview" + (onClick ? " clickable" : "")}
      onClick={onClick}
    >
      {teams.map((t, idx) => {
        const backgroundColor = t.teamColor1;
        const color = allowables.getTextColor(backgroundColor);
        return (
          <div key={idx}>
            <div
              className="cardview-card"
              style={{ backgroundColor, color, borderColor: backgroundColor }}
            >
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    flex: "25%",
                    alignSelf: "center",
                    marginLeft: 5,
                    marginRight: 5,
                  }}
                >
                  <TeamLogo _id={t.logo} name={t.name} size={55} />
                </div>
                <div style={{ flex: "75%" }}>
                  <div className="cardview-text">
                    &nbsp;<b>{t.name}</b>
                  </div>
                  <div className="cardview-text">
                    <IconRender name="manageusers" />
                    &nbsp;{allowables.splitName(t.teamCaptainID?.name)}
                  </div>
                  <div className="cardview-text row">
                    <div className="col-4">
                      <IconRender name="rank" />
                      &nbsp;{t.seed}
                    </div>
                    <div className="col">
                      <div
                        className="color-square"
                        style={{
                          backgroundColor: t.teamColor2,
                          color: allowables.getTextColor(t.teamColor2),
                          height: 25,
                          width: 50,
                        }}
                      >
                        <IconRender name="color" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-separator" />
          </div>
        );
      })}
    </div>
  );
};

export default TeamsCardView;
