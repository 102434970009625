import React, { useContext, useEffect, useState } from "react";
import toast from "../../utils/toast";

import HeaderContext from "../../context/headerContext";
import MiniHeader from "../common/pageComponents/miniHeader";
import useSearchParams from "../../hooks/useSearchParams";
import {
  acceptOwnerInvitation,
  acceptPlayerInvitation,
} from "../../services/invitationService";
import { getPrepaymentCheckout } from "../../services/paymentService";
import { loadStripe } from "@stripe/stripe-js";
import { stripeKey } from "../../utils/paymentsUtil";
import allowables from "../../utils/allowables";
import CombinedRegistrationForm from "./combinedRegistrationForm";

const InvitationRegistrationPage = ({ location }) => {
  const [data, setData] = useState({});
  const [regType, setRegType] = useState("");
  const { setLoading } = useContext(HeaderContext);
  const params = useSearchParams(location);

  useEffect(() => {
    setData({
      email: params.email || "",
      firstName: (params.firstName || params.playerFirstName || "")
        .split("%20")
        .join(" "),
      lastName: (params.lastName || params.playerLastName || "")
        .split("%20")
        .join(" "),
    });
    setRegType(location.pathname.includes("signupteam") ? "player" : "invite");
  }, [params, location]);

  const handleSubmit = async (data, formType) => {
    setLoading(true);
    if (regType === "invite") {
      const res = await acceptOwnerInvitation(params.id, formType, {
        ...data,
        code: params.code,
      });
      if (res.status === 200) {
        window.location = params.role === "player" ? "teams?q=new" : "profile";
      } else toast.error(res.data);
    } else {
      const res = await acceptPlayerInvitation(params.playerID, formType, data);
      if (res.status === 200) {
        return (window.location = "profile");
      } else if (res.status === 402) {
        toast.info(res.data.text);
        const checkoutRes = await getPrepaymentCheckout(
          res.data.orgID,
          { ...data, ...res.data.userData },
          location.pathname + location.search
        );
        if (checkoutRes.status === 200) {
          const stripePromise = await loadStripe(stripeKey(), {
            stripeAccount: checkoutRes.data.accountID,
          });
          return setTimeout(() => {
            stripePromise.redirectToCheckout({
              sessionId: checkoutRes.data.sessionID,
            });
          }, 1000);
        } else toast.error(checkoutRes.data);
      } else toast.error(res.data);
    }
    setLoading(false);
  };

  const getHeaderText = () => {
    return regType === "invite"
      ? `Register as ${allowables.capLetterOne(
          params.role
        )} for ${params.orgName?.split("%20").join(" ")}`
      : `Register for ${params.name?.split("%20").join(" ")} in ${params.orgName
          ?.split("%20")
          .join(" ")}`;
  };

  return (
    <div className="centered-small-input-area">
      <MiniHeader>{getHeaderText()}</MiniHeader>
      <div className="form-divided-section">
        <CombinedRegistrationForm onSubmit={handleSubmit} data={data} />
      </div>
    </div>
  );
};

export default InvitationRegistrationPage;
