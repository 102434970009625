import React from "react";

const NonFormInput = ({ name, onChange, type, label, value, boldLabel }) => {
  const labelText = boldLabel ? <b>{label}</b> : label;
  return (
    <div className="row non-form-input">
      <div className="col-3 text-right">
        <label htmlFor={name}>{labelText}</label>
      </div>
      <div className="col text-left">
        <input
          id={name}
          name={name}
          type={type}
          className="form-control custom-form"
          onChange={onChange}
          value={value}
        />
      </div>
    </div>
  );
};

export default NonFormInput;
