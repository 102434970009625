import React from "react";
import allowables from "./allowables";
import EventImage from "../components/assignedMatch/eventImage";

export function nameButtonText(p, batter, suspended) {
  return (
    <div className="row">
      <div className="col-4">
        <EventImage player={p} />
      </div>
      <div className="col text-left" aria-label="player-event-name">
        &nbsp;
        {p.shirtNumber || p.shirtNumber === 0 ? p.shirtNumber + " - " : ""}
        {allowables.splitName(p.name)}
        {batter
          ? `: ${
              !p.matchEvents ? "0" : `${p.matchEvents.atBats}`
            } plate appearance${
              !p.matchEvents || p.matchEvents.atBats !== 1 ? "s" : ""
            }`
          : ""}
        {suspended ? <b> (suspended)</b> : ""}
      </div>
    </div>
  );
}

export function mapUndoEventsFootball(chronoEventsNames, alreadyParsed) {
  let undoEvents = [];
  chronoEventsNames.forEach((e) => {
    let thisEvent = alreadyParsed ? { ...e } : { ...JSON.parse(e) };
    thisEvent.labelText = `${allowables.convertTime(thisEvent.eventID)} ~ ${
      thisEvent.teamName
    } - ${
      thisEvent.eventStyle === "passer"
        ? "Passing "
        : thisEvent.eventStyle === "rusher"
        ? "Rushing "
        : thisEvent.eventStyle === "kicker"
        ? "Kicking "
        : ""
    }${
      thisEvent.eventType === "Penalty"
        ? thisEvent.points + " Yard "
        : thisEvent.eventType === "Extra Point"
        ? thisEvent.points + "x "
        : ""
    }${thisEvent.eventType}: ${thisEvent.name}`;
    thisEvent.label = <div>{thisEvent.labelText}</div>;
    thisEvent.color = thisEvent.eventType === "Penalty" ? "warning" : "light";
    if (!undoEvents.map((e) => e.eventID).includes(thisEvent.eventID)) {
      undoEvents.push(thisEvent);
    } else {
      const eventIndex = undoEvents.findIndex(
        (e) => e.eventID === thisEvent.eventID
      );
      let newEvent = { ...undoEvents[eventIndex] };
      newEvent.eventStyle2 = thisEvent.eventStyle;
      newEvent.name2 = thisEvent.name;
      newEvent.labelText =
        undoEvents[eventIndex].labelText + ` to ${thisEvent.name}`;
      newEvent.label = <div>{newEvent.labelText}</div>;
      undoEvents.splice(eventIndex, 1, newEvent);
    }
  });
  return undoEvents;
}

export function mapUndoEventsSoccer(
  chronoEventsNames,
  originalMatch,
  alreadyParsed,
  players
) {
  let undoEvents = [];
  chronoEventsNames.forEach((e, i) => {
    let event = alreadyParsed ? { ...e } : { ...JSON.parse(e) };
    event.teamName = event.eventType.includes("homeTeam")
      ? originalMatch.homeTeamName
      : originalMatch.awayTeamName;
    event.buttonName = event.eventType.includes("Goals")
      ? "Goal"
      : event.eventType.includes("Yellow")
      ? "Yellow Card"
      : "Red Card";
    event.color = event.eventType.includes("Goals")
      ? "success-text"
      : event.eventType.includes("Yellow")
      ? "soccer-yellow-card-text"
      : "soccer-red-card-text";
    event.childID = i;
    event.eventID = allowables.convertTime(event.eventID);
    if (!["unk", "og"].includes(event._id)) {
      if (players) event.player = players.find((p) => p._id === event._id);
      else event.player = { _id: e._id, name: e.name };
    } else
      event.player =
        event._id === "unk"
          ? { _id: "unk", name: "Unknown Player" }
          : { _id: "og", name: "Own Goal" };
    undoEvents.push(event);
  });
  return undoEvents;
}

export function preMatchSetup(match, players, org) {
  let homeTeamPlayers = players.filter((p) => p.teamID === match.homeTeamID);
  let awayTeamPlayers = players.filter((p) => p.teamID === match.awayTeamID);
  let homeTeamApproved = false;
  let awayTeamApproved = false;
  let captainApproval = false;
  if (org.captainApproval === "Yes") {
    captainApproval = true;
    if (match.matchComplete === 1) {
      homeTeamApproved = match.homeTeamProtest ? false : true;
      awayTeamApproved = match.awayTeamProtest ? false : true;
    }
  }
  return {
    captainApproval,
    homeTeamApproved,
    awayTeamApproved,
    originalMatch: match,
    homeTeamPlayers,
    awayTeamPlayers,
  };
}

export function prepareMatchForSubmission(match, state, originalMatch) {
  if (match.fieldName === 0) match.fieldName = "";
  if (match.groupName === 0) match.groupName = "";
  if (match.dateTime === 0) match.dateTime = "";
  match.fieldName = String(match.fieldName);
  match.matchComplete = 1;
  ["home", "away"].forEach((t) => {
    match[t + "TeamProtest"] = state[t + "TeamApproved"] ? false : true;
    if (match[t + "TeamAbbreviation"] === 0) match[t + "TeamAbbreviation"] = "";
    if (match[t + "TeamProtestString"] === 0)
      match[t + "TeamProtestString"] = "";
  });
  if (match.refereeComments === 0) match.refereeComments = "";
  match.chronoEventsNames = JSON.parse(match.chronoEventsNames).map((e) =>
    JSON.parse(e)
  );
}
