import React from "react";
import Form from "../common/form/form";
import Joi from "joi-browser";

import { getCurrentUser, logout } from "../../services/userService";
import { validateRefereePasscode } from "../../services/refereeScorecardService";
import WarningHeader from "../common/pageComponents/warningHeader";
import HeaderContext from "../../context/headerContext";
import toast from "../../utils/toast";
import MiniHeader from "../common/pageComponents/miniHeader";
import { navigateTo } from "../common/customs/customLinks";

class RefereeLoginForm extends Form {
  static contextType = HeaderContext;
  state = {
    data: { name: "", username: "", passcode: "" },
    baseState: {},
    errors: {},
  };

  schema = {
    name: Joi.string().min(3).max(50).required().label("Name"),
    username: Joi.string().required().max(50).label("Username"),
    passcode: Joi.string().required().max(50).label("Passcode"),
  };

  async doSubmit() {
    this.context.setLoading(true);
    const res = await validateRefereePasscode(
      this.state.data.username,
      this.state.data.passcode,
      this.state.data.name
    );

    if (res.status === 200) {
      const location = "/referees?page=scorecards";
      if (getCurrentUser()) {
        logout();
        return (window.location = location);
      }
      navigateTo(location, this.props.history, this.context);
    } else {
      toast.error(res.data);
    }
    this.context.setLoading(false);
  }

  render() {
    const user = getCurrentUser();
    return (
      <div className="centered-small-input-area">
        <MiniHeader>Referee/Umpire Login</MiniHeader>
        <div className="form-divided-section">
          {user ? (
            <WarningHeader>
              Using a login code will log you out of your account.
            </WarningHeader>
          ) : null}
          <form onSubmit={this.handleSubmit}>
            {this.renderInput("name", "Name", "autofocus")}
            {this.renderInput("username", "Username")}
            {this.renderInput(
              "passcode",
              "Passcode",
              "",
              "password",
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              true
            )}
            {this.renderValidatedButton("Get Scorecards")}
          </form>
        </div>
      </div>
    );
  }
}

export default RefereeLoginForm;
