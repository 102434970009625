import http from "./httpService";
import { callbackProgress } from "../utils/progress";

export async function getOrgByHomepage(name, callback) {
  try {
    return await http.get(
      http.spectateEndpoint + "/homepage/" + name,
      callback && {
        onDownloadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function getDivisions(orgID, callback) {
  try {
    return await http.get(
      http.spectateEndpoint + "/divisions/" + orgID,
      callback && {
        onDownloadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function getDivisionInfo(endpoint, divisionID, callback) {
  try {
    return await http.get(
      http.spectateEndpoint + "/" + endpoint + "/" + divisionID,
      callback && {
        onDownloadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function getMatchNoAuth(matchID, callback) {
  try {
    return await http.get(
      http.spectateEndpoint + "/match/" + matchID,
      callback && {
        onDownloadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function getCombinedStats(divisionID, callback) {
  try {
    return await http.get(
      http.spectateEndpoint + "/combinedstats/" + divisionID,
      callback && {
        onDownloadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function getSeasons(orgID, callback) {
  try {
    return await http.get(
      http.spectateEndpoint + "/seasons/" + orgID,
      callback && {
        onDownloadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function getDivisionsBySeason(seasonID, callback) {
  try {
    return await http.get(
      http.spectateEndpoint + "/seasons/divisions/" + seasonID,
      callback && {
        onDownloadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function getArchivedDivision(divisionID, seasonID, callback) {
  try {
    return await http.get(
      http.spectateEndpoint + "/seasons/stats/" + divisionID + "/" + seasonID,
      callback && {
        onDownloadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}
