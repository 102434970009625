import React from "react";

import FormLabel from "./formLabel";
import WarningHeader from "../pageComponents/warningHeader";

const ConditionalSelect = ({
  name,
  label,
  tooltip,
  options,
  error,
  condition,
  extraOptions,
  paidFeature,
  ...rest
}) => {
  const newOptions = options[condition];
  let optionsHTML;
  if (newOptions)
    optionsHTML = newOptions.map((option) => (
      <option key={option._id} value={option.name}>
        {option.name}
      </option>
    ));
  let extraOptionsHTML;
  if (extraOptions)
    extraOptionsHTML = extraOptions.map((o) => (
      <option key={o._id} value={o.name}>
        {o.name}
      </option>
    ));

  return (
    <div className="form-group">
      <FormLabel
        tooltip={tooltip}
        paidFeature={paidFeature}
        label={label}
        htmlFor={name}
      />
      <select name={name} {...rest} className="form-control custom-form">
        <option key="default">Make a selection...</option>
        {optionsHTML}
        {extraOptionsHTML}
      </select>
      {error && <WarningHeader>{error}</WarningHeader>}
    </div>
  );
};

export default ConditionalSelect;
