import http from "./httpService";
import { callbackProgress } from "../utils/progress";

export async function getSeasons(callback, includeInactive) {
  try {
    return await http.get(
      http.seasonsEndpoint + (includeInactive ? "/all/includeinactive" : ""),
      callback && {
        onDownloadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function getSeason(seasonID, callback) {
  try {
    return await http.get(
      http.seasonsEndpoint + "/" + seasonID,
      callback && {
        onDownloadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function deleteSeason(seasonID, callback) {
  try {
    return await http.delete(
      http.seasonsEndpoint + "/" + seasonID,
      callback && {
        onDownloadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function saveSeason(seasonID, season, callback) {
  try {
    if (seasonID === "new")
      return await http.post(
        http.seasonsEndpoint,
        season,
        callback && {
          onUploadProgress: function (progress) {
            callbackProgress(progress, callback);
          },
        }
      );
    else
      return await http.put(
        http.seasonsEndpoint + "/" + seasonID,
        season,
        callback && {
          onUploadProgress: function (progress) {
            callbackProgress(progress, callback);
          },
        }
      );
  } catch (ex) {
    return ex.response;
  }
}

export async function moveDataToNewSeason(seasonID, options, callback) {
  try {
    return await http.post(
      http.seasonsEndpoint + "/newseason/" + seasonID,
      options,
      callback && {
        onUploadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function archiveSeason(seasonID, callback) {
  try {
    return await http.put(
      http.seasonsEndpoint + "/archive/" + seasonID,
      {},
      callback && {
        onUploadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}
