import React, { useState, useEffect, useContext } from "react";
import FieldsButtonLineup from "./fieldsButtonLineup";
import { deleteField, getFields } from "../../services/fieldService";
import HeaderContext from "../../context/headerContext";
import toast from "../../utils/toast";
import FieldsTable from "./fieldsTable";
import CustomConfirm from "../common/customs/customConfirm";
import MapModal from "../common/pageComponents/mapModal";
import orderBy from "lodash/orderBy";
import SearchBox from "../common/dataSorting/searchBox";

const Fields = () => {
  const { setLoading, setProgress } = useContext(HeaderContext);

  const [fields, setFields] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortColumn, setSortColumn] = useState({ path: "name", order: "desc" });
  const [dialog, setDialog] = useState("");
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [selectedField, setSelectedField] = useState(null);
  const [mapOpen, setMapOpen] = useState(false);

  const getData = async () => {
    setLoading(true);
    setProgress([0]);
    const fieldsRes = await getFields({
      callback: (p) => setProgress(p),
      bar: 0,
    });
    if (fieldsRes.status === 200) {
      setFields(fieldsRes.data);
    } else toast.error(fieldsRes.data);
    setLoading(false);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDelete = async () => {
    setLoading(true);
    setProgress([0]);
    const fieldsRes = await deleteField(selectedField._id, {
      callback: (p) => setProgress(p),
      bar: 0,
    });
    if (fieldsRes.status === 200) {
      toast.success("Field Deleted");
      return getData();
    } else toast.error(fieldsRes.data);
    setLoading(false);
  };

  const getPageData = () => {
    let filteredFields = [...fields];

    if (searchQuery)
      filteredFields = filteredFields.filter(
        (s) =>
          s.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          s.address.toLowerCase().includes(searchQuery.toLowerCase())
      );

    const sortedFields = orderBy(
      filteredFields,
      [sortColumn.path],
      [sortColumn.order]
    );

    return sortedFields;
  };

  return (
    <div>
      <div className="row">
        <div className="col text-center">
          <p className="header-p">Showing all {fields.length} fields.</p>
        </div>
        <div className="col text-center">
          <FieldsButtonLineup />
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col">
          <SearchBox
            value={searchQuery}
            onChange={(q) => setSearchQuery(q)}
            placeholder="Search by field name or address..."
          />
        </div>
      </div>
      <FieldsTable
        fields={getPageData()}
        onDelete={(field) => {
          setSelectedField(field);
          setDialog(`Are you sure you want to delete ${field.name}`);
          setConfirmOpen(true);
        }}
        onFieldClick={(field) => {
          if (field.longitude && field.latitude) {
            setSelectedField(field);
            setMapOpen(true);
          }
        }}
        sortColumn={sortColumn}
        onSort={setSortColumn}
      />
      <CustomConfirm
        dialog={dialog}
        isOpen={confirmOpen}
        callback={handleDelete}
        close={() => setConfirmOpen(false)}
        focused={true}
      />
      <MapModal
        mapOpen={mapOpen}
        setMapOpen={setMapOpen}
        mapCenter={selectedField}
        pins={fields}
      />
    </div>
  );
};

export default Fields;
