import React, { Component } from "react";
import get from "lodash/get";
import Tooltip from "../tooltip/tooltip";

class TableBody extends Component {
  renderCell = (item, column) => {
    if (column.content) {
      if (column.tooltip && item.tooltip)
        return (
          <Tooltip
            overrideStyle={column.tooltip.overrideStyle}
            customClass={column.tooltip.customClass}
            content={item.tooltip}
            direction={column.tooltip.direction}
          >
            {column.content(item)}
          </Tooltip>
        );
      return column.content(item);
    }
    return get(item, column.path);
  };

  createKey = (item, column, keyProperty) => {
    return item[keyProperty] + (column.path || column.key);
  };

  getBackgroundColor(item, backgroundKey, backgroundStyle, isSelected) {
    if (
      (backgroundKey === "_id" &&
        (item._id === this.props.thisID || this.props.thisID === "all")) ||
      isSelected
    )
      return backgroundStyle;
    else if (item[backgroundKey] > 0) return backgroundStyle;
  }

  render() {
    const {
      data,
      columns,
      keyProperty,
      backgroundKey,
      backgroundStyle,
      onSelect,
      selectedRows,
      highlightAlternate,
      highlightOnHover,
    } = this.props;

    return (
      <tbody className={this.props.bodyClass || ""}>
        {data.map((item, idx) => {
          const isSelected = selectedRows
            ? selectedRows.find((r) => r === item[keyProperty])
            : null;
          return (
            <tr
              className={
                this.getBackgroundColor(
                  item,
                  backgroundKey,
                  backgroundStyle,
                  isSelected
                ) +
                (onSelect ? " clickable" : "") +
                (highlightAlternate && idx % 2 === 1 ? " trow-alt" : "") +
                (highlightOnHover ? " trow-highlight" : "")
              }
              key={item[keyProperty]}
              onClick={onSelect ? () => onSelect(item, true) : () => {}}
            >
              {columns.map((column) => (
                <td key={this.createKey(item, column, keyProperty)}>
                  {this.renderCell(item, column)}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    );
  }
}

export default TableBody;
