import React from "react";
import { getCurrentUser } from "../../services/userService";
import MiniHeader from "../common/pageComponents/miniHeader";
import allowables from "../../utils/allowables";
import WarningHeader from "../common/pageComponents/warningHeader";

const CheckinInfo = ({ match, teamOrder, onCheckin }) => {
  const user = getCurrentUser();
  if (!user) return null;

  if (
    !match ||
    !match.checkedIn ||
    !user.role.includes("player") ||
    (match.homeTeamID !== user.teamID && match.awayTeamID !== user.teamID)
  )
    return null;

  const response = match.checkedIn.find((p) => p.playerID === user.playerID);

  const getResponses = (team) => {
    let responses = {
      in: [],
      out: [],
    };
    match.checkedIn.forEach((p) => {
      if (p.teamID === match[team + "TeamID"]) {
        if (p.playing) responses.in.push(p);
        else responses.out.push(p);
      }
    });
    return responses;
  };

  return (
    <div>
      <div className="pop-box text-center">
        {response ? (
          <i>
            You will {response.playing ? "" : <b>NOT</b>} be attending this
            match
          </i>
        ) : (
          <WarningHeader>
            <b>You have not responded for this match</b>
          </WarningHeader>
        )}
        <div className="row">
          <div className="col-4">
            {!response || response.playing ? (
              <button
                className={`btn btn-sm btn-dark`}
                onClick={() => onCheckin(true)}
              >
                I can't make it
              </button>
            ) : null}
          </div>
          <div className="col">
            {!response || !response.playing ? (
              <button
                className={`btn btn-sm btn-info btn-block`}
                onClick={() => onCheckin(false)}
              >
                I will be there
              </button>
            ) : null}
          </div>
        </div>
      </div>
      {response ? (
        <div>
          <MiniHeader>Responses</MiniHeader>
          <div className="form-divided-section text-center">
            <div className="row">
              {teamOrder.map((o) => {
                const players = getResponses(o);
                return (
                  <div className="col" key={o}>
                    <h6>
                      <b>{match[o + "TeamName"]}</b>
                    </h6>
                    {players.in.map((p, idx) => (
                      <div key={idx}>{allowables.splitName(p.name)}</div>
                    ))}
                    {players.out.length ? <hr className="custom" /> : null}
                    {players.out.map((p, idx) => (
                      <div key={idx}>
                        {allowables.splitName(p.name)}:{" "}
                        <small>
                          <b>OUT</b>
                        </small>
                      </div>
                    ))}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default CheckinInfo;
