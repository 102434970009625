import React from "react";
import FootballReviewSegment from "../assignedMatch/football/footballReviewSegment";
import DiamondReviewSegment from "../assignedMatch/diamond/diamondReviewSegment";
import SoccerReviewSegment from "../assignedMatch/soccer/soccerReviewSegment";

const ReviewInfoSegment = ({ data, teams, liveEvents, alreadyPopped }) => {
  const sport = data
    ? data.sport.toLowerCase().includes("diamond")
      ? "diamond"
      : data.sport.toLowerCase().includes("simple")
      ? "simple"
      : data.sport.toLowerCase()
    : "";

  if (sport === "simple") return null;

  if (
    (liveEvents && liveEvents.length === 0) ||
    (!liveEvents && data.chronoEventsNames.length === 0)
  )
    return (
      <div style={{ margin: 10 }}>
        <h5 className="text-center">
          <b>No events were added to the scorecard</b>
        </h5>
      </div>
    );

  return data ? (
    <div className={alreadyPopped ? "" : "pop-box"}>
      {sport.includes("soccer") ? (
        <SoccerReviewSegment
          match={data}
          teamOrder={teams}
          liveEvents={liveEvents}
        />
      ) : sport.includes("football") ? (
        <FootballReviewSegment
          match={data}
          teamOrder={teams}
          liveEvents={liveEvents}
        />
      ) : sport.includes("diamond") ? (
        <DiamondReviewSegment
          match={data}
          teamOrder={teams}
          liveEvents={liveEvents}
        />
      ) : null}
    </div>
  ) : null;
};

export default ReviewInfoSegment;
