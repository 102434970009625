import React, { useContext, useEffect, useState } from "react";
import useSearchParams from "../../hooks/useSearchParams";
import {
  getMatchByCode,
  getMatchesByCode,
  getPlayersForMatchByCode,
  getTeamsForMatchByCode,
  submitScorecard,
  validateRefereePasscode,
} from "../../services/refereeScorecardService";
import RefereeLoginForm from "./refereeLogin";
import RefereeScorecards from "./refereeScorecards";
import HeaderContext from "../../context/headerContext";
import { getRefereeCode } from "../../services/userService";
import RefereesOrgHeader from "./refereesOrgHeader";
import CombinedMatch from "../assignedMatch/combinedMatch";
import {
  removeEventFromLiveScorecard,
  removeLiveScorecard,
  sendEventToLiveScorecard,
  startLiveScorecard,
  syncLiveScorecard,
} from "../../services/liveScoreService";
import toast from "../../utils/toast";

const RefereesRedirect = (props) => {
  const header = useContext(HeaderContext);
  const [compProps, setCompProps] = useState({});
  const [thisComp, setThisComp] = useState("login");
  const [matchID, setMatchID] = useState("");

  const params = useSearchParams(props.location);

  const loadData = async () => {
    header.setLoading(true);

    let comp = "login";
    let passedProps = {};
    const code = getRefereeCode();
    if (code) {
      const orgRes = await validateRefereePasscode(
        code.username,
        code.passcode,
        code.name
      );

      if (orgRes.status === 200) {
        header.updateHeader(orgRes.data.logo);
        passedProps.org = orgRes.data;
        comp = params?.page;
        if (comp === "scorecards") {
          const matchesRes = await getMatchesByCode();
          if (matchesRes.status === 200) {
            passedProps.matches = matchesRes.data;
          } else toast.error(matchesRes.data);
        }
        setMatchID(params.q);
      }
    }

    setThisComp(comp);
    setCompProps(passedProps);
    header.setLoading(false);
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  return (
    <div>
      <RefereesOrgHeader org={compProps.org} page={thisComp} />
      {thisComp === "login" ? (
        <RefereeLoginForm {...props} />
      ) : thisComp === "scorecards" ? (
        <RefereeScorecards {...compProps} {...props} />
      ) : thisComp === "scorecard" ? (
        <CombinedMatch
          matchID={matchID}
          byCode={true}
          getMatch={getMatchByCode}
          getPlayersForMatch={getPlayersForMatchByCode}
          getTeamsForMatch={getTeamsForMatchByCode}
          startLiveScorecard={startLiveScorecard}
          syncLiveScorecard={syncLiveScorecard}
          removeLiveScorecard={removeLiveScorecard}
          sendEventToLiveScorecard={sendEventToLiveScorecard}
          removeEventFromLiveScorecard={removeEventFromLiveScorecard}
          submitScorecard={submitScorecard}
          {...compProps}
          {...props}
        />
      ) : null}
    </div>
  );
};

export default RefereesRedirect;
