import React, { Component } from "react";
import ReactGA from "react-ga4";
import toast from "../../utils/toast";

import { navigateBack, navigateTo } from "../common/customs/customLinks";
import HeaderContext from "../../context/headerContext";
import PreMatchSegment from "./preMatchSegment";
import HorizontalTabs from "../common/dataSorting/horizontalTabs";
import { FaStar, FaClipboardList, FaChevronCircleRight } from "react-icons/fa";
import { VscPreview } from "react-icons/vsc";
import LiveScorecard from "./liveScorecard";
import SoccerTeamSegment from "./soccer/soccerTeamSegment";
import FootballTeamSegment from "./football/footballTeamSegment";
import DiamondTeamSegment from "./diamond/diamondTeamSegment";
import SimpleTeamSegment from "./simple/simpleTeamSegment";
import allowables from "../../utils/allowables";
import MatchHeader from "./matchHeader";
import EventsTab from "./eventsTab";
import CommentsSubmit from "./commentsSubmit";
import { checkYellowCards } from "./../../utils/soccerFunctions";
import { createSoccerEvent } from "ultimatescoreboard-shared";
import { createFootballEvent } from "../../utils/footballFunctions";
import { createDiamondEvent } from "../../utils/diamondFunctions";
import CustomConfirm from "./../common/customs/customConfirm";
import CustomAlert from "./../common/customs/customAlert";
import ReviewInfoSegment from "./../matchReview/reviewInfoSegment";
import Approvals from "./approvals";
import RefereeingInstructionsModal from "../instructions/refereeingInstructionsModal";
import NotFound from "./../notFound";
import QRCodeRender from "../common/pageComponents/qrCode";
import EventEditModal from "./eventEditModal";
import { createTennisEvent, mapTennisScore } from "ultimatescoreboard-shared";
import SimpleScoringInfo from "./simple/simpleScoringInfo";

class CombinedMatch extends Component {
  static contextType = HeaderContext;
  state = {
    originalMatch: {
      homeTeamName: "",
      awayTeamName: "",
      sport: "",
    },
    players: { home: [], away: [] },
    teams: { home: null, away: null },
    goals: { home: 0, away: 0 },
    outs: { home: 0, away: 0 },
    penalties: { home: 0, away: 0 },
    pitchers: { home: null, away: null },
    TeamSegment: SoccerTeamSegment,
    sport: "soccer",
    matchStarted: false,
    startedMatchID: "",
    chronoEventsNames: [],
    refereeComments: "",
    approvals: { home: false, away: false },
    approvalRequired: false,
    cancelMatchOpen: false,
    submitMatchOpen: false,
    submitDialog: "",
    selectedTab: "game",
    alertDialog: "",
    alertOpen: false,
    instructionsOpen: false,
    dummyMatch: true,
    liveScorecardOutOfSync: true,
    editingEvent: null,
    editingEventOpen: false,
    complexScore: null,
  };

  async componentDidMount() {
    this.context.setLoading(true);
    this.context.setProgress([1, 1, 1]);
    const { matchID } = this.props;

    // fetch match from db
    const matchRes = await this.props.getMatch(matchID, {
      callback: this.indicateProgress,
      bar: 0,
    });

    if (matchRes?.status === 200) {
      const match = matchRes.data;
      if (!this.props.byCode) {
        // check that user is not player and match belongs to this referee, if match is in edit mode allow all admins
        const user = this.props.getCurrentUser();
        if (
          (match.matchComplete === 0 && match.refereeID !== user._id) ||
          (match.matchComplete === 1 && !user.role.includes("admin")) ||
          user.role.includes("player")
        ) {
          toast.error("That match is not assigned to you");
          return navigateBack(this.props.history, this.context);
        }
      }
      if (
        allowables.dummyTeam._id !== match.homeTeamID &&
        allowables.dummyTeam._id !== match.awayTeamID
      ) {
        this.setState({ dummyMatch: false });
      } else return this.context.setLoading(false);

      // fetch players from db
      const playersRes = await (this.props.byCode
        ? this.props.getPlayersForMatch(matchID)
        : this.props.getPlayersForMatch(match.homeTeamID, match.awayTeamID, {
            callback: this.indicateProgress,
            bar: 1,
          }));
      const teamsRes = await (this.props.byCode
        ? this.props.getTeamsForMatch(match)
        : this.props.getTeamsForMatch(match.homeTeamID, match.awayTeamID, {
            callback: this.indicateProgress,
            bar: 2,
          }));

      if (playersRes.status === 200) {
        // fetch teams from db (to get team colors)
        if (teamsRes.status === 200) {
          // select segments from sport
          const sport = match.sport.toLowerCase();
          const TeamSegment = sport.includes("soccer")
            ? SoccerTeamSegment
            : sport.includes("football")
            ? FootballTeamSegment
            : sport.includes("diamond")
            ? DiamondTeamSegment
            : sport.includes("simple")
            ? SimpleTeamSegment
            : null;
          // set players, teams, match, approvals
          const allPlayers = playersRes.data;
          const approvals = this.props.org.captainApproval !== "Yes";

          this.setState({
            originalMatch: match,
            players: {
              home: allPlayers.filter((p) => p.teamID === match.homeTeamID),
              away: allPlayers.filter((p) => p.teamID === match.awayTeamID),
            },
            teams: {
              home: teamsRes.data.find((t) => t._id === match.homeTeamID),
              away: teamsRes.data.find((t) => t._id === match.awayTeamID),
            },
            approvals: { home: approvals, away: approvals },
            approvalRequired: !approvals,
            TeamSegment,
            sport,
          });
          this.retrieveData(sport, match);
        } else toast.error(teamsRes.data);
      } else toast.error(playersRes.data);
    } else toast.error(matchRes?.data || "Could not gather match data");
    this.context.setLoading(false);
  }

  indicateProgress = (progress, location) => {
    let { progress: currentProgress } = this.context;
    currentProgress[location.bar] =
      ((progress.loaded / progress.total) * 100) / currentProgress.length;
    this.context.setProgress(currentProgress);
  };

  retrieveData = (sport, match) => {
    const matchStarted = localStorage.getItem("matchStarted");
    const startedMatchID = localStorage.getItem("_id");
    const homeTeamGoals = localStorage.getItem("homeTeamGoals");
    const awayTeamGoals = localStorage.getItem("awayTeamGoals");
    const homeTeamPKs = localStorage.getItem("homeTeamPKs");
    const awayTeamPKs = localStorage.getItem("awayTeamPKs");
    const refereeComments = localStorage.getItem("refereeComments");
    if (sport.includes("diamond")) {
      const homeTeamOuts = localStorage.getItem("homeTeamOuts");
      const awayTeamOuts = localStorage.getItem("awayTeamOuts");
      this.setState({
        outs: {
          home: homeTeamOuts ? Number(homeTeamOuts) : 0,
          away: awayTeamOuts ? Number(awayTeamOuts) : 0,
        },
      });
    }
    let chronoEventsNames = localStorage.getItem("chronoEventsNames");
    if (chronoEventsNames) {
      chronoEventsNames = JSON.parse(chronoEventsNames).map((e) =>
        JSON.parse(e)
      );
    }
    let goals = {
      home: homeTeamGoals ? Number(homeTeamGoals) : 0,
      away: awayTeamGoals ? Number(awayTeamGoals) : 0,
    };
    const penalties = {
      home: homeTeamPKs ? Number(homeTeamPKs) : 0,
      away: awayTeamPKs ? Number(awayTeamPKs) : 0,
    };

    goals = this.mapComplexScores(chronoEventsNames, goals, match);

    this.setState({
      startedMatchID: startedMatchID || "",
      matchStarted: matchStarted ? true : false,
      chronoEventsNames: chronoEventsNames || [],
      goals,
      penalties,
      refereeComments: refereeComments || "",
    });

    if (
      this.liveScorecardHasAction(match) &&
      matchStarted &&
      startedMatchID === this.props.matchID
    ) {
      this.handleSyncLiveScorecard(goals, penalties, chronoEventsNames || []);
    }
  };

  mapComplexScores = (events, goals, match) => {
    const sport = allowables.translateSportName(this.props.org);
    if (sport === "Tennis") {
      const complexScore = mapTennisScore(
        (match || this.state.originalMatch)?.divisionID?.settings
          ?.tennisOptions,
        events
      );
      this.setState({
        complexScore,
      });
      return { ...complexScore.matchScore };
    } else return goals;
  };

  liveScorecardHasAction = (match) => {
    return (
      this.props?.org?.misc?.liveScorecards &&
      (match?.matchComplete === 0 ||
        this.state.originalMatch?.matchComplete === 0)
    );
  };

  startMatch = async () => {
    // if live scorecards are enabled start the scorecard
    if (this.liveScorecardHasAction()) {
      this.context.setLoading(true);
      const liveRes = await this.props.startLiveScorecard(this.props.matchID);
      if (liveRes?.status === 200) {
        toast.success("Live scorecard started");
        this.setState({ liveScorecardOutOfSync: false });
      } else {
        if (liveRes) toast.error(liveRes.data);
        return this.context.setLoading(false);
      }
      this.context.setLoading(false);
    }

    const teamOrder = allowables.teams(this.state.sport);
    localStorage.setItem("matchStarted", "true");
    localStorage.setItem("_id", this.state.originalMatch._id);
    if (this.state.originalMatch.matchComplete === 1) {
      const match = this.state.originalMatch;
      let chronoEventsNames = [...match.chronoEventsNames].map((e) =>
        JSON.stringify(e)
      );
      localStorage.setItem(
        "chronoEventsNames",
        JSON.stringify(chronoEventsNames)
      );
      localStorage.setItem("refereeComments", match.refereeComments);

      if (match.sport.toLowerCase().includes("diamond")) {
        let outs = { home: 0, away: 0 };
        match.chronoEventsNames.forEach((event) => {
          if (event.result === "out") {
            const team = event.teamID === match.homeTeamID ? "home" : "away";
            outs[team] = outs[team] + 1;
          }
        });
        this.setState({ outs });
        localStorage.setItem("homeTeamOuts", String(outs.home));
        localStorage.setItem("awayTeamOuts", String(outs.away));
      }

      teamOrder.forEach((t) => {
        localStorage.setItem(t + "TeamGoals", String(match[t + "TeamGoals"]));
        localStorage.setItem(t + "TeamPKs", String(match[t + "TeamPKs"]));
      });

      this.mapComplexScores(match.chronoEventsNames);

      this.setState({
        goals: { home: match.homeTeamGoals, away: match.awayTeamGoals },
        penalties: { home: match.homeTeamPKs, away: match.awayTeamPKs },
        refereeComments: match.refereeComments,
        chronoEventsNames: match.chronoEventsNames,
      });
    } else {
      localStorage.setItem("chronoEventsNames", JSON.stringify([]));
      teamOrder.forEach((t) => {
        localStorage.setItem(t + "TeamGoals", "0");
        localStorage.setItem(t + "TeamPKs", "0");
      });
    }
    this.setState({
      matchStarted: true,
      startedMatchID: this.state.originalMatch._id,
    });
  };

  cancelMatch = async () => {
    if (this.liveScorecardHasAction()) {
      this.context.setLoading(true);
      const startedMatchID = localStorage.getItem("_id");
      const liveRes = await this.props.removeLiveScorecard(startedMatchID);
      if (liveRes?.status === 200) toast.info("Live scorecard cancelled");
      else {
        if (liveRes) toast.error(liveRes.data);
        return this.context.setLoading(false);
      }
      this.context.setLoading(false);
    }
    localStorage.clear();
    this.setState({ selectedTab: "game" });
    this.retrieveData(this.state.sport);
  };
  toggleModal = (id) => {
    this.setState({ [id]: !this.state[id] });
  };

  handleSelectTab = (tab) => {
    this.setState({ selectedTab: tab.name });
  };

  handleEvent = async (
    player,
    event,
    player2,
    team,
    originalEventIndex = -1,
    passedChronoEventsNames,
    passedGoals
  ) => {
    let newEvents;
    let goals = passedGoals || { ...this.state.goals };
    let chronoEventsNames = passedChronoEventsNames || [
      ...this.state.chronoEventsNames,
    ];

    const sport = allowables.translateSportName(this.props.org);

    if (this.state.sport.includes("soccer")) {
      // for soccer
      newEvents = createSoccerEvent(player, event, player2);
      if (event.toLowerCase().includes("goal"))
        goals[event.slice(0, 4)] = goals[event.slice(0, 4)] + 1;
      else {
        const check = checkYellowCards(chronoEventsNames, newEvents[0]);
        if (check === "alreadyOff")
          return this.setState({
            alertDialog: `${newEvents[0].name} has already been sent off.`,
            alertOpen: true,
          });
        else if (check === "secondYellow")
          this.setState({
            alertDialog: `${newEvents[0].name} has received their second yellow card and is now sent off.`,
            alertOpen: true,
          });
      }
    } else if (this.state.sport.includes("football")) {
      // for football
      newEvents = createFootballEvent(player, event, player2);
      if (newEvents[0].eventStyle !== "penalty")
        goals[team] = goals[team] + newEvents[0].points;
    } else if (this.state.sport.includes("diamond")) {
      // for diamond
      newEvents = createDiamondEvent(player, event, player2);
      goals[team] = goals[team] + event.rbis;
      if (event.result === "out") {
        let outs = { ...this.state.outs };
        outs[team] = outs[team] + 1;
        this.setState({ outs });
        localStorage.setItem(team + "TeamOuts", String(outs[team]));
      }
    } else if (this.state.sport.includes("simple")) {
      if (sport === "Tennis") {
        if (!event.undo) {
          newEvents = createTennisEvent(event.team, chronoEventsNames.length);
        } else newEvents = [];
      } else {
        goals[event.team] = goals[event.team] + event.increment;
      }
    }

    if (!this.state.sport.includes("simple") || sport === "Tennis") {
      if (originalEventIndex === -1) {
        chronoEventsNames.push(...newEvents);
      } else
        chronoEventsNames.splice(
          event.undo ? chronoEventsNames.length - 1 : originalEventIndex,
          event.undo ? 1 : 0,
          ...newEvents
        );
    }

    goals = this.mapComplexScores(chronoEventsNames, goals);

    this.setState({ chronoEventsNames, goals });
    let strungEvents = chronoEventsNames.map((e) => JSON.stringify(e));
    localStorage.setItem("chronoEventsNames", JSON.stringify(strungEvents));
    localStorage.setItem("homeTeamGoals", String(goals.home));
    localStorage.setItem("awayTeamGoals", String(goals.away));

    // handle sending live event
    // do this last, it should run in background and not interfere with execution
    // if this fails there is no need for a retry
    if (this.liveScorecardHasAction()) {
      const liveRes = await this.props.sendEventToLiveScorecard(
        this.props.matchID,
        {
          homeTeamScore: goals.home,
          homeTeamPKs: this.state.penalties.home,
          awayTeamScore: goals.away,
          awayTeamPKs: this.state.penalties.away,
          event: newEvents,
        }
      );
      if (liveRes?.status !== 200)
        this.setState({ liveScorecardOutOfSync: true });
    }
  };

  handleEdit = async (
    originalEvent,
    player,
    eventType,
    player2,
    team,
    newEventIndex
  ) => {
    const chronoEventsNames = [...this.state.chronoEventsNames];
    const goals = { ...this.state.goals };

    // easiest way to edit an event is to undo it and redo it
    const undo = await this.handleUndo(originalEvent, chronoEventsNames, goals);
    await this.handleEvent(
      player,
      eventType,
      player2,
      team,
      Number(newEventIndex),
      undo.chronoEventsNames,
      undo.goals
    );
  };

  spliceEventByID = (chronoEventsNames, event, pop = false) => {
    const index = pop
      ? chronoEventsNames.length - 1
      : chronoEventsNames.findIndex((e) => e.eventID === event.eventID);

    if (index < 0) return;
    chronoEventsNames.splice(index, 1);
  };

  // remove the event completely
  handleUndo = async (
    event,
    passedChronoEventsNames,
    passedGoals,
    pop = false
  ) => {
    let chronoEventsNames = passedChronoEventsNames || [
      ...this.state.chronoEventsNames,
    ];

    let thisEvent = { ...event };
    if (pop) {
      if (!chronoEventsNames.length) return;
      thisEvent.eventID = chronoEventsNames.length;
    }
    this.spliceEventByID(chronoEventsNames, thisEvent);

    let goals = passedGoals || { ...this.state.goals };
    if (this.state.sport.includes("soccer")) {
      if (thisEvent.eventType.toLowerCase().includes("goal"))
        goals[thisEvent.eventType.slice(0, 4)] =
          goals[thisEvent.eventType.slice(0, 4)] - 1;
    } else if (this.state.sport.includes("football")) {
      const team =
        this.state.originalMatch.homeTeamID === thisEvent.teamID
          ? "home"
          : "away";
      if (thisEvent.eventStyle !== "penalty")
        goals[team] = goals[team] - thisEvent.points;
    } else if (this.state.sport.includes("diamond")) {
      const team =
        this.state.originalMatch.homeTeamID === thisEvent.teamID
          ? "home"
          : "away";
      if (thisEvent.rbis > 0) goals[team] = goals[team] - thisEvent.rbis;
      if (thisEvent.result === "out") {
        let outs = { ...this.state.outs };
        outs[team] = outs[team] - 1;
        this.setState({ outs });
        localStorage.setItem(team + "TeamOuts", String(outs[team]));
      }
    }
    goals = this.mapComplexScores(chronoEventsNames, goals);

    if (!passedChronoEventsNames) {
      // in order to not make two state updates when editing an event
      // the events will be passed in by the edit function
      // state will be updated by the handleEvent function
      this.setState({ chronoEventsNames, goals });
    }
    let strungEvents = chronoEventsNames.map((e) => JSON.stringify(e));
    localStorage.setItem("chronoEventsNames", JSON.stringify(strungEvents));
    localStorage.setItem("homeTeamGoals", String(goals.home));
    localStorage.setItem("awayTeamGoals", String(goals.away));

    if (this.liveScorecardHasAction()) {
      const liveRes = await this.props.removeEventFromLiveScorecard(
        this.props.matchID,
        {
          homeTeamScore: goals.home,
          awayTeamScore: goals.away,
          event: thisEvent,
        }
      );
      if (liveRes?.status !== 200)
        this.setState({ liveScorecardOutOfSync: true });
    }

    return { chronoEventsNames, goals };
  };

  handleSyncLiveScorecard = async (goals, penalties, chronoEventsNames) => {
    this.context.setLoading(true);
    goals = goals || this.state.goals;
    penalties = penalties || this.state.penalties;
    chronoEventsNames = chronoEventsNames || this.state.chronoEventsNames;

    const liveRes = await this.props.syncLiveScorecard(this.props.matchID, {
      homeTeamScore: goals.home,
      homeTeamPKs: penalties.home,
      awayTeamScore: goals.away,
      awayTeamPKs: penalties.away,
      events: chronoEventsNames,
    });
    if (liveRes?.status === 200) {
      this.setState({ liveScorecardOutOfSync: false });
      toast.success("Live scorecard in sync");
    } else if (liveRes?.status === 405) {
      // 405 means that live scorecards are not enabled
      // the users org and actual org are out of sync, refresh the org
      // this will be done silently in the background
      await this.context.refreshOrg();
    } else if (liveRes) toast.error(liveRes.data);

    this.context.setLoading(false);
  };

  handleChangePitcher = (pitcher, team) => {
    let pitchers = { ...this.state.pitchers };
    pitchers[team] = pitcher;
    this.setState({ pitchers });
  };

  handleChangePenalties = async (team, value) => {
    if (value < 0) return;
    let penalties = { ...this.state.penalties };
    penalties[team] = value;
    this.setState({ penalties });
    localStorage.setItem(team + "TeamPKs", String(value));

    if (this.liveScorecardHasAction()) {
      const liveRes = await this.props.sendEventToLiveScorecard(
        this.props.matchID,
        {
          homeTeamScore: this.state.goals.home,
          awayTeamScore: this.state.goals.away,
          homeTeamPKs: penalties.home,
          awayTeamPKs: penalties.away,
        }
      );
      if (liveRes?.status !== 200)
        this.setState({ liveScorecardOutOfSync: true });
    }
  };

  handleAddComment = (comment) => {
    this.setState({ refereeComments: comment });
    localStorage.setItem("refereeComments", comment);
  };

  handleApproval = (team) => {
    let approvals = { ...this.state.approvals };
    approvals[team] = !approvals[team];
    this.setState({ approvals });
  };

  alertSubmit = () => {
    const { approvals, originalMatch: match, goals, penalties } = this.state;

    // check for penalties and a tie
    if (match.sport.toLowerCase() === "soccer") {
      if (
        goals.home === goals.away &&
        (penalties.home !== 0 || penalties.away !== 0) &&
        penalties.home === penalties.away
      )
        return this.setState({
          alertDialog:
            "Penalties cannot end in a tie. There must be a clear winner of the match.",
          alertOpen: true,
        });
    }

    const approvalText =
      "You are submitting this scorecard without approval from ";
    const approvalTextTwo =
      !approvals.home && !approvals.away
        ? `${match.homeTeamName} or ${match.awayTeamName}`
        : !approvals.home
        ? match.homeTeamName
        : !approvals.away
        ? match.awayTeamName
        : null;
    const approvalDialog = approvalTextTwo
      ? approvalText + approvalTextTwo + "%Are you sure?"
      : "Are you sure you want to submit this scorecard? This cannot be undone.";
    this.setState({ submitDialog: approvalDialog, submitMatchOpen: true });
  };
  handleSubmit = async () => {
    this.context.setLoading(true);
    this.context.setProgress([1]);
    let match = { ...this.state.originalMatch };
    ["home", "away"].forEach((t) => {
      match[t + "TeamProtest"] = !this.state.approvals[t];
      match[t + "TeamGoals"] = this.state.goals[t];
      match[t + "TeamPKs"] = this.state.penalties[t];
    });
    match.chronoEventsNames = this.state.chronoEventsNames;
    match.refereeComments = this.state.refereeComments;
    match.matchComplete = 1;

    const sport = allowables.translateSportName(this.props.org);
    if (sport === "Tennis") {
      match.miscScore = this.state.complexScore;
    }

    const response = await this.props.submitScorecard(match, {
      callback: this.indicateProgress,
      bar: 0,
    });
    if (!response)
      toast.error(
        "No internet connection. Connection is required for scorecard submission."
      );
    else if (response.status === 200) {
      localStorage.clear();
      return navigateTo(
        this.props.byCode
          ? "/referees?page=scorecards"
          : this.props.history.location.state?.from?.includes("matchreview")
          ? this.props.history.location.state?.from
          : "/matches",
        this.props.history,
        this.context
      );
    } else {
      ReactGA.event({
        category: "Assigned Match",
        action: "scorecard submission failed",
        label: response.data,
        nonInteraction: true,
      });
      toast.error(response.data);
    }
    this.context.setLoading(false);
  };

  render() {
    const {
      originalMatch,
      players,
      teams,
      goals,
      penalties,
      pitchers,
      outs,
      matchStarted,
      startedMatchID,
      approvals,
      approvalRequired,
      refereeComments,
      chronoEventsNames,
      cancelMatchOpen,
      selectedTab,
      TeamSegment,
      sport,
      submitMatchOpen,
      submitDialog,
      alertDialog,
      alertOpen,
      instructionsOpen,
      dummyMatch,
      complexScore,
    } = this.state;

    if (this.context.loading) return null;
    if (dummyMatch)
      return (
        <NotFound
          text="This match has one or more dummy teams assigned. To complete this scorecard please assign two real teams."
          history={this.props.history}
        />
      );

    let tabs = [
      { name: "game", icon: FaStar },
      { name: "events", icon: FaClipboardList },
      { name: "review", icon: VscPreview },
      { name: "submit", icon: FaChevronCircleRight },
    ];
    if (sport.includes("simple")) {
      if (allowables.translateSportName(this.props.org) === "Tennis") {
        tabs.splice(1, 1, { name: "scoring", icon: FaClipboardList });
      } else tabs.splice(1, 1);
    }

    const teamOrder = allowables.teams(sport);
    return (
      <div className="centered-small-input-area">
        {!matchStarted || startedMatchID !== originalMatch._id ? (
          <PreMatchSegment
            matchStarted={matchStarted}
            startedMatchID={startedMatchID}
            originalMatch={originalMatch}
            startMatch={this.startMatch}
            toggleModal={this.toggleModal}
            teamOrder={teamOrder}
            teams={teams}
            players={players}
            byCode={this.props.byCode}
          />
        ) : (
          <div>
            <LiveScorecard
              liveScorecards={this.liveScorecardHasAction()}
              outOfSync={this.state.liveScorecardOutOfSync}
              onSync={this.handleSyncLiveScorecard}
            />
            <HorizontalTabs
              tabs={tabs}
              selectedTab={selectedTab}
              onSelectTab={this.handleSelectTab}
            />
            <MatchHeader
              org={this.props.org}
              match={originalMatch}
              teamOrder={teamOrder}
              goals={goals}
              penalties={penalties}
              complexScore={complexScore}
              division={originalMatch?.divisionID?.settings}
            />
            <div className="pop-box">
              {selectedTab === "game" && TeamSegment ? (
                <TeamSegment
                  match={originalMatch}
                  players={players}
                  teamOrder={teamOrder}
                  onEvent={this.handleEvent}
                  chronoEventsNames={chronoEventsNames}
                  penalties={penalties}
                  onChangePenalties={this.handleChangePenalties}
                  onSubmit={this.alertSubmit}
                  pitchers={pitchers}
                  setPitchers={this.handleChangePitcher}
                  outs={outs}
                  org={this.props.org}
                  complexScore={complexScore}
                  onUndo={this.handleUndo}
                />
              ) : null}
              {selectedTab === "events" && (
                <EventsTab
                  chronoEventsNames={chronoEventsNames}
                  sport={sport}
                  match={originalMatch}
                  onUndo={this.handleUndo}
                  onEdit={(event) => {
                    this.setState({
                      editingEvent: event,
                      editingEventOpen: true,
                    });
                  }}
                />
              )}
              {selectedTab === "scoring" && (
                <SimpleScoringInfo org={this.props.org} match={originalMatch} />
              )}
              {selectedTab === "review" && (
                <div>
                  <Approvals
                    match={originalMatch}
                    approvalRequired={approvalRequired}
                    approvals={approvals}
                    teamOrder={teamOrder}
                    onApproval={this.handleApproval}
                  />
                  <ReviewInfoSegment
                    data={originalMatch}
                    teams={teamOrder}
                    liveEvents={chronoEventsNames}
                    alreadyPopped={true}
                  />
                </div>
              )}
              {selectedTab === "submit" && (
                <CommentsSubmit
                  toggleModal={this.toggleModal}
                  refereeComments={refereeComments}
                  onAddComment={this.handleAddComment}
                  onSubmit={this.alertSubmit}
                  match={originalMatch}
                />
              )}
            </div>
          </div>
        )}
        <QRCodeRender
          value={allowables.getLiveScorecardUrl(
            originalMatch._id,
            this.props.org
          )}
          margin={25}
        />
        <CustomConfirm
          dialog={
            "Are you sure you want to cancel the match in progress?%All input will be lost."
          }
          isOpen={cancelMatchOpen}
          id="cancelMatchOpen"
          callback={this.cancelMatch}
          close={this.toggleModal}
          split="%"
          yesNo={true}
        />
        <CustomConfirm
          dialog={submitDialog}
          isOpen={submitMatchOpen}
          id="submitMatchOpen"
          callback={this.handleSubmit}
          close={this.toggleModal}
          split="%"
          yesNo={true}
        />
        <CustomAlert
          dialog={alertDialog}
          isOpen={alertOpen}
          close={this.toggleModal}
          id={"alertOpen"}
        />
        <RefereeingInstructionsModal
          isOpen={instructionsOpen}
          onClose={() => this.setState({ instructionsOpen: !instructionsOpen })}
          sport={originalMatch.sport}
        />
        <EventEditModal
          originalEvent={this.state.editingEvent}
          onSave={this.handleEdit}
          id="editingEventOpen"
          isOpen={this.state.editingEventOpen}
          onClose={this.toggleModal}
          teams={this.state.teams}
          players={this.state.players}
          sport={this.state.originalMatch.sport.toLowerCase()}
          allEvents={chronoEventsNames}
        />
      </div>
    );
  }
}

export default CombinedMatch;
