import React from "react";
import WarningHeader from "../common/pageComponents/warningHeader";

const LiveScorecard = ({ liveScorecards, outOfSync, onSync }) => {
  if (!liveScorecards) return null;
  return (
    <div className="pop-box text-center">
      {outOfSync ? (
        <div>
          <WarningHeader>
            <b>Scorecard is out of sync</b>
          </WarningHeader>
          <button
            className="btn btn-info btn-sm btn-block"
            onClick={() => onSync()}
          >
            Sync Now
          </button>
        </div>
      ) : (
        <b>This scorecard is live and in sync</b>
      )}
    </div>
  );
};

export default LiveScorecard;
