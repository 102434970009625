import React from "react";
import StandingsCardView from "../reports/standingsCardView";
import MiniHeader from "../common/pageComponents/miniHeader";

const SpectateStandings = ({ standings, sport, onHeaderClick }) => {
  return (
    <div>
      <MiniHeader onClick={onHeaderClick}>Standings</MiniHeader>
      <div className="form-divided-section fill">
        {!standings ||
        (!standings.standings.length && !standings.groups.length) ? (
          <p className="text-center cardview-card">
            There are no standings available for this division
          </p>
        ) : (
          <StandingsCardView standings={standings} sport={sport} />
        )}
      </div>
    </div>
  );
};

export default SpectateStandings;
