import React from "react";
import { CLink } from "../common/customs/customLinks";

import Checkbox from "../common/form/checkBox";
import SideBySideView from "../common/pageComponents/sideBySideView";

const ReviewButtonsSegment = ({
  user,
  data,
  restartMatch,
  acceptMatch,
  removeComments,
  fullyApprove,
  autoPopulate,
  onApprovalChange,
  org,
}) => {
  if (!user.role.includes("admin") || !data.matchComplete) return null;

  const buttons = [
    <button onClick={restartMatch} className="btn btn-md btn-dark">
      Restart Scorecard
    </button>,
  ];

  if (!data.matchAccepted) {
    buttons.push(
      <CLink
        path={`/assignedmatches?q=${data._id}`}
        button={true}
        buttonStyle="btn-md btn-secondary"
      >
        Edit Scorecard
      </CLink>
    );
  }

  return (
    <div className="pop-box">
      <SideBySideView
        Components={buttons}
        colClassNames={["text-center", "text-center"]}
      />
      {user.role.includes("admin") && !data.matchAccepted && (
        <div>
          <div style={{ marginLeft: 50, marginBottom: 10 }}>
            {data.metadata?.bracketID && (
              <Checkbox
                id="autoPopulate"
                onChange={onApprovalChange}
                label={"Auto-Populate"}
                value={autoPopulate}
                tooltip={{
                  header: "Auto-Populate Playoff Bracket",
                  text: `This match is eligible for bracket autopopulation.
                  Upon accepting the match the teams will be placed into their respective next matches.`,
                  direction: "right",
                  className: "icon-raised",
                }}
              />
            )}
            {org && org.captainApproval === "Yes" && (
              <div>
                <Checkbox
                  id="removeComments"
                  onChange={onApprovalChange}
                  label={"Remove Comments"}
                  value={removeComments}
                  tooltip={{
                    header: "Remove Comments",
                    text: `Upon accepting captain comments will be removed. They would be unretriveable after this point.
                        If this box is not checked then comments will remain visible for this match and cannot be removed.
                        If both boxes are checked then the approval segment will not be visible when viewing the accepted match.`,
                    direction: "right",
                    className: "icon-raised",
                  }}
                />
                <Checkbox
                  id="fullyApprove"
                  onChange={onApprovalChange}
                  label={"Fully Approve"}
                  value={fullyApprove}
                  tooltip={{
                    header: "Fully Approve",
                    text: `Upon accepting match status will be set to approved for both teams.
                        If both boxes are checked then the approval segment will not be visible when viewing the accepted match.`,
                    direction: "right",
                    className: "icon-raised",
                  }}
                />
              </div>
            )}
          </div>
          <button
            onClick={acceptMatch}
            className="btn btn-lg btn-info btn-block"
          >
            Accept Scorecard
          </button>
        </div>
      )}
    </div>
  );
};

export default ReviewButtonsSegment;
