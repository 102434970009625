import React, { Component } from "react";

import Organization from "./organization";
import OrgForm from "./orgForm";
import HeaderContext from "../../context/headerContext";
import { getCurrentUser, refreshUser } from "../../services/userService";
import { saveOrg, updateOrg } from "../../services/orgService";
import { getCurrencies } from "../../services/paymentService";

class OrgRedirect extends Component {
  static contextType = HeaderContext;
  state = {
    loading: true,
    comp: null,
    source: "",
  };

  componentDidMount() {
    this.setup();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.search !== this.props.location.search) this.setup();
  }

  setup = () => {
    this.context.setLoading(true);
    const user = getCurrentUser();
    let comp = user.orgID ? Organization : OrgForm;
    let source;
    if (user.orgID) {
      const query = this.props.location.search.slice(1).split("&");
      let type = query.find((q) => q.split("=")[0] === "q");
      type = type && type.split("=")[1];
      if (type && type === "options") {
        comp = OrgForm;
        source = type;
      }
    }
    this.setState({ loading: false, source, comp });
  };

  render() {
    return this.state.loading || !this.state.comp ? null : (
      <this.state.comp
        {...this.props}
        source={this.state.source}
        getCurrentUser={getCurrentUser}
        refreshUser={refreshUser}
        saveOrg={saveOrg}
        updateOrg={updateOrg}
        getCurrencies={getCurrencies}
      />
    );
  }
}

export default OrgRedirect;
