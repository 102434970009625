import React, { Component } from "react";
import toast from "../../utils/toast";

import VaccineInfoUpload from "./vaccineInfoUpload";
import VaccineTable from "./vaccineTable";
import VaccineStatus from "./vaccineStatus";
import {
  getCurrentUser,
  getUsersForOrg,
  reviewVaccines,
} from "../../services/userService";
import CustomConfirm from "../common/customs/customConfirm";
import PageBottom from "../common/pageComponents/pageBottom";
import Checkbox from "../common/form/checkBox";
import HeaderContext from "../../context/headerContext";
import allowables from "../../utils/allowables";

class VaccineMain extends Component {
  static contextType = HeaderContext;
  state = {
    users: [],
    toAcceptList: [],
    confirmOpen: false,
    forceApprove: false,
  };

  componentDidMount() {
    this.refreshData();
  }

  refreshData = async () => {
    this.context.setLoading(true);
    this.context.setProgress([1]);
    const user = getCurrentUser();
    if (user.role.includes("admin")) {
      const usersRes = await getUsersForOrg({
        callback: this.indicateProgress,
        bar: 0,
      });
      if (usersRes.status === 200) {
        this.setState({ users: usersRes.data });
      } else toast.error(usersRes.data);
    }
    this.setState({ toAcceptList: [] });
    this.context.setLoading(false);
  };

  indicateProgress = (progress, location) => {
    let { progress: currentProgress } = this.context;
    currentProgress[location.bar] =
      ((progress.loaded / progress.total) * 100) / currentProgress.length;
    this.context.setProgress(currentProgress);
  };

  toggleModal = (id) => {
    this.setState({ [id]: this.state[id] ? false : true });
  };

  setConfirmDialog = () => {
    const dialog = `The following users will have their reviewed status updated:%${this.state.toAcceptList.map(
      (u) => ` ${allowables.splitName(u.name)}`
    )}`;
    this.setState({ confirmOpen: true, confirmDialog: dialog });
  };

  addUserToList = (user) => {
    let list = [...this.state.toAcceptList];
    if (list.find((u) => u._id === user._id))
      list.splice(list.map((u) => u._id).indexOf(user._id), 1);
    else list.push(user);
    this.setState({ toAcceptList: list });
  };

  deselectAll = () => {
    this.setState({ toAcceptList: [] });
  };

  selectAll = (users) => {
    this.setState({ toAcceptList: users });
  };

  submitChanges = async () => {
    this.context.setLoading(true);
    this.context.setProgress([1]);
    const res = await reviewVaccines(
      this.state.toAcceptList,
      this.state.forceApprove,
      {
        callback: this.indicateProgress,
        bar: 0,
      }
    );
    if (res.status === 200) return this.refreshData();
    else toast.error(res.data);
    this.context.setLoading(false);
  };

  render() {
    const user = getCurrentUser();
    const { users, toAcceptList, confirmOpen, confirmDialog, forceApprove } =
      this.state;
    return this.props.org.requireVaccine === "Yes" ? (
      <React.Fragment>
        <h3 className="text-center">
          Your League requires uploading of vaccination information
        </h3>
        <br />
        <VaccineStatus user={user} />
        <VaccineInfoUpload org={this.props.org} />
        <hr style={{ height: "2px", backgroundColor: "black" }} />
        {user.role.includes("admin") && (
          <React.Fragment>
            <h4
              className="text-center bg-dark text-white"
              style={{ borderRadius: "5px" }}
            >
              Review User Vaccine Information
            </h4>
            <VaccineTable
              users={users}
              addUserToList={this.addUserToList}
              userList={toAcceptList.map((u) => u._id)}
              submitChanges={() => this.setConfirmDialog()}
              deselectAll={this.deselectAll}
              selectAll={this.selectAll}
            />
          </React.Fragment>
        )}
        <CustomConfirm
          dialog={confirmDialog}
          isOpen={confirmOpen}
          callback={this.submitChanges}
          close={this.toggleModal}
          id="confirmOpen"
          split="%"
        >
          <Checkbox
            onChange={() => this.setState({ forceApprove: !forceApprove })}
            value={forceApprove}
            label="Force Approve Not Submitted Users"
          />
        </CustomConfirm>
        <br />
        <PageBottom />
      </React.Fragment>
    ) : (
      <React.Fragment>
        <h4>
          Your League does not require uploading of vaccine information. No
          further action is needed
        </h4>
      </React.Fragment>
    );
  }
}

export default VaccineMain;
