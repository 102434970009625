import React from "react";

const StepProgressBar = ({ steps, currentStep, onSelectStep }) => {
  return (
    <div className="step-progress-bar" data-testid="step-progress-bar">
      {steps.map((step, index) => (
        <div key={index} className="step-container">
          <div
            className={`clickable step ${
              currentStep === index + 1
                ? "active"
                : currentStep > index
                ? "complete"
                : ""
            }`}
            onClick={() => onSelectStep(index + 1)}
            data-testid={`step-${index}`}
          >
            <span className="step-number">{index + 1}</span>
          </div>
          <span
            className={`step-label ${
              currentStep === index + 1 ? "active" : ""
            }`}
          >
            {step.name}
          </span>
          {index < steps.length - 1 && (
            <div
              className={`step-line ${currentStep > index + 1 ? "active" : ""}`}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default StepProgressBar;
