import React, { Component } from "react";
import toast from "../../../utils/toast";
import orderBy from "lodash/orderBy";

import {
  getAccountLink,
  getAccountInfo,
  markAsPaid,
  giveRefund,
  getArchivedLeaguePayments,
} from "../../../services/paymentService";
import { getPlayers } from "../../../services/playerService";
import { getTeams } from "../../../services/teamService";
import CustomConfirm from "./../../common/customs/customConfirm";
import NotSetUp from "./notSetUp";
import OwnerPriceInfo from "./ownerPriceInfo";
import PaymentTrackingTable from "./paymentTrackingTable";
import PageBottom from "../../common/pageComponents/pageBottom";
import NotCharging from "./notCharging";
import HeaderContext from "../../../context/headerContext";
import allowables from "../../../utils/allowables";
import IconRender from "../../common/icons/iconRender";
import MiniHeader from "../../common/pageComponents/miniHeader";
import CheckboxGroup from "../../common/dataSorting/checkboxGroup";
import WarningHeader from "../../common/pageComponents/warningHeader";
import { isPaid, paymentIntentRequirements } from "../../../utils/paymentsUtil";
import NonFormInput from "../../common/form/nonFormInput";
import Switch from "../../common/form/switch";

class OwnerPaymentHome extends Component {
  static contextType = HeaderContext;
  state = {
    accountSetUp: false,
    accountInfo: null,
    retryOpen: false,
    trackedData: [],
    sortColumn: { path: "payment.amountPaid", order: "asc" },
    searchQuery: "",
    selectedType: "all",
    refundOpen: false,
    markAsPaidOpen: false,
    selectedData: null,
    error: "",
    showPaid: true,
    showUnpaid: true,
    inputPaymentIntent: "",
    piError: false,
    showArchived: false,
  };

  componentDidMount() {
    this.reloadPage();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.showArchived !== this.state.showArchived) this.reloadPage();
  }

  indicateProgress = (progress, location) => {
    let { progress: currentProgress } = this.context;
    currentProgress[location.bar] =
      ((progress.loaded / progress.total) * 100) / currentProgress.length;
    this.context.setProgress(currentProgress);
  };

  reloadPage = async () => {
    this.context.setLoading(true);
    const param = this.props.history.location.search;
    if (param && param.includes("reauth")) {
      this.context.setLoading(false);
      return this.setState({ retryOpen: true });
    }
    const accountRes = await getAccountInfo();
    let dataRes;
    if (this.state.showArchived) dataRes = await getArchivedLeaguePayments();
    else if (this.props.org.stripe.whoPays === "Team")
      dataRes = await getTeams();
    else if (this.props.org.stripe.whoPays === "Player")
      dataRes = await getPlayers();
    if (accountRes.status === 200) {
      if (dataRes && dataRes.status === 200) {
        this.setState({
          accountSetUp: accountRes.data.details_submitted,
          trackedData: this.state.showArchived
            ? dataRes.data.map((d) => ({
                ...d,
                payment: { ...d, amountPaid: d.amount },
              }))
            : dataRes.data,
          accountInfo: accountRes.data,
        });
      } else {
        toast.error(dataRes.data);
        this.setState({ error: dataRes.data });
      }
    } else {
      toast.error(accountRes.data);
      this.setState({ error: accountRes.data });
    }
    this.context.setLoading(false);
  };

  toggleModal = (id) => {
    this.setState({ [id]: this.state[id] ? false : true });
  };

  redirect = async () => {
    this.context.setLoading(true);
    const linkRes = await getAccountLink();
    if (linkRes.status === 200) {
      return window.open(linkRes.data.url, "_self");
    } else toast.error(linkRes.data);
    this.context.setLoading(false);
  };

  handleSort = (sortColumn) => {
    if (sortColumn.path) {
      this.setState({ sortColumn });
    }
  };

  handleSearch = (query) => {
    this.setState({
      searchQuery: query,
    });
  };

  handleSelectType = (type) => {
    this.setState({ [type]: !this.state[type] });
  };

  setRefund = (data) => {
    this.setState({ selectedData: data, refundOpen: true });
  };

  handleRefund = async () => {
    const { selectedData: data } = this.state;
    this.context.setLoading(true);
    const paymentRes = await giveRefund(data);
    if (paymentRes.status === 200) {
      toast.success(paymentRes.data);
      return this.reloadPage();
    } else toast.error(paymentRes.data);
    this.context.setLoading(false);
  };

  setMarkPaid = (data) => {
    this.setState({ selectedData: data, markAsPaidOpen: true });
  };

  handleMarkPaid = async () => {
    const { selectedData: data, inputPaymentIntent, piError } = this.state;
    if (piError) return toast.error(paymentIntentRequirements.text);

    this.context.setLoading(true);
    const paymentRes = await markAsPaid(data, inputPaymentIntent);
    if (paymentRes.status === 200) {
      toast.success(paymentRes.data);
      this.setState({ inputPaymentIntent: "", piError: false });
      return this.componentDidMount();
    } else toast.error(paymentRes.data);
    this.context.setLoading(false);
  };

  getPageData = () => {
    const { trackedData, sortColumn, searchQuery, showPaid, showUnpaid } =
      this.state;
    let filteredData = trackedData.filter((d) => {
      let paid = isPaid(d.payment);

      return showUnpaid && showPaid
        ? true
        : showUnpaid
        ? !paid
        : showPaid
        ? paid
        : false;
    });
    if (searchQuery)
      filteredData = filteredData.filter((d) =>
        d.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
    let sortedData = filteredData;
    if (!sortColumn.path.includes(".")) {
      sortedData = orderBy(filteredData, [sortColumn.path], [sortColumn.order]);
    } else
      sortedData = filteredData.sort((a, b) => {
        const split = sortColumn.path.split(".");
        let x = 0;
        if (
          a[split[0]] &&
          b[split[0]] &&
          a[split[0]][split[1]] > b[split[0]][split[1]]
        )
          x = 1;
        else if (a[split[0]] && !b[split[0]]) x = 1;
        else x = -1;
        return sortColumn.order === "asc" ? x : x * -1;
      });

    return sortedData;
  };

  render() {
    const {
      retryOpen,
      accountSetUp,
      sortColumn,
      selectedType,
      error,
      refundOpen,
      markAsPaidOpen,
      selectedData,
      searchQuery,
      accountInfo,
      inputPaymentIntent,
      piError,
    } = this.state;
    const data = this.getPageData();

    return (
      <div>
        {accountSetUp ? (
          this.props.org.stripe.amount > 0 ? (
            <React.Fragment>
              <OwnerPriceInfo org={this.props.org} />
              <div className="row">
                <div className="col">
                  <MiniHeader>Filter Data</MiniHeader>
                  <div className="form-divided-section">
                    {!this.state.showArchived ? (
                      <CheckboxGroup
                        header="Filter Data"
                        onChange={this.handleSelectType}
                        // containerClassName="form-divided-section"
                        colCount={window.innerWidth > 500 ? 2 : 1}
                        checkboxes={[
                          {
                            id: "showPaid",
                            value: this.state.showPaid,
                            label: "Paid",
                          },
                          {
                            id: "showUnpaid",
                            value: this.state.showUnpaid,
                            label: "Unpaid",
                          },
                        ]}
                      />
                    ) : null}
                    <div className="text-center">
                      <Switch
                        onChange={() =>
                          this.setState({
                            showPaid: true,
                            showArchived: !this.state.showArchived,
                          })
                        }
                        value={this.state.showArchived}
                        label={"Show Archived"}
                      />
                    </div>
                  </div>
                </div>
                <div className="col text-center">
                  <MiniHeader>Manage Data</MiniHeader>
                  <div className="form-divided-section">
                    <p>
                      <a
                        href="https://www.stripe.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Manage your connected Stripe account{" "}
                        <IconRender name="externallink" />
                      </a>
                    </p>
                    <button
                      className="btn btn-block btn-info btn-md"
                      onClick={this.reloadPage}
                    >
                      Reload Payment Data
                    </button>
                  </div>
                </div>
              </div>
              <PaymentTrackingTable
                org={this.props.org}
                data={data}
                selectedType={selectedType}
                onSort={this.handleSort}
                sortColumn={sortColumn}
                onSearch={this.handleSearch}
                searchQuery={searchQuery}
                onRefund={this.setRefund}
                onMarkPaid={this.setMarkPaid}
                isArchive={this.state.showArchived}
              />
            </React.Fragment>
          ) : (
            <NotCharging
              whoPays={this.props.org.stripe.whoPays}
              currency={this.props.org.stripe.currency}
            />
          )
        ) : (
          <NotSetUp
            redirect={this.redirect}
            error={error}
            onAddCustomerSuccess={this.reloadPage}
          />
        )}

        {accountSetUp &&
        accountInfo &&
        (accountInfo.requirements?.currently_due?.length ||
          accountInfo.future_requirements?.currently_due?.length) ? (
          <div className="pop-box text-center">
            <WarningHeader>You have account requirements due</WarningHeader>
            <p>
              Go to your{" "}
              <a
                href="https://dashboard.stripe.com/account/status"
                target="_blank"
                rel="noopener noreferrer"
              >
                Stripe dashboard
              </a>{" "}
              to complete these requirements.
              <br />
              <br />
              Once you have completed these requirements please refresh the
              page.
            </p>
          </div>
        ) : null}

        <PageBottom />
        <CustomConfirm
          dialog="Account set up was not completed.%Try again?"
          yesNo={true}
          callback={this.redirect}
          isOpen={retryOpen}
          id="retryOpen"
          close={this.toggleModal}
          split="%"
        />
        <CustomConfirm
          dialog={`Marking a ${
            this.props.org.stripe.whoPays
          } as unpaid will set their paid balance to zero.
          %If a refund is due you will have to provide that separately, either directly with the player or using your connected Stripe account.
          %You are marking ${
            selectedData && allowables.splitName(selectedData.name)
          } as unpaid.
          %Are you sure?`}
          yesNo={true}
          callback={this.handleRefund}
          isOpen={refundOpen}
          id="refundOpen"
          close={this.toggleModal}
          split="%"
        />
        <CustomConfirm
          dialog={`You are marking ${
            selectedData && allowables.splitName(selectedData.name)
          } as paid.`}
          yesNo={true}
          callback={this.handleMarkPaid}
          isOpen={markAsPaidOpen}
          id="markAsPaidOpen"
          close={this.toggleModal}
          split="%"
          holdSubmitButton={{
            enteredValue: !!inputPaymentIntent && piError ? "error" : "ok",
            mustMatchValue: "ok",
          }}
        >
          <p className="text-center">
            If this payment was made via Stripe you can add the payment intent
            code here to link the payment. {paymentIntentRequirements.text}
            <br />
            Leave this blank if not linking the payment.
          </p>
          <NonFormInput
            name="paymentIntent"
            type="text"
            label={`Stripe Payment Intent`}
            value={inputPaymentIntent}
            onChange={(event) => {
              let value = event.target.value;
              let validPi = paymentIntentRequirements.regex.test(value);

              this.setState({
                inputPaymentIntent: event.target.value,
                piError: !validPi,
              });
            }}
            boldLabel={true}
          />
          <WarningHeader>
            {piError ? paymentIntentRequirements.text : ""}
          </WarningHeader>
        </CustomConfirm>
      </div>
    );
  }
}

export default OwnerPaymentHome;
