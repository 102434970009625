import React from "react";
import { CardElement } from "@stripe/react-stripe-js";
import MiniHeader from "../../common/pageComponents/miniHeader";
import BasicModal from "../../common/pageComponents/basicModal";
import { popupStyle } from "../../../utils/styleUtil";
import useWindowDimensions from "../../../utils/useWindowDimensions";

const CardForm = ({
  onSubmit,
  defaultCard,
  currentTier,
  cardOpen,
  setCardOpen,
}) => {
  const { width } = useWindowDimensions();

  return (
    <BasicModal
      isOpen={cardOpen}
      onClose={setCardOpen}
      style={{
        content: { ...popupStyle.content, width: Math.min(width - 20, 500) },
      }}
    >
      <MiniHeader>
        {(defaultCard ? "Replace" : "Add") + " Default Payment Method"}
      </MiniHeader>
      <div className="form-divided-section">
        <form onSubmit={onSubmit}>
          <CardElement
            options={{
              style: {
                base: {
                  fontSize: "16px",
                  color: "#424770",
                  "::placeholder": {
                    color: "#aab7c4",
                  },
                },
                invalid: {
                  color: "#9e2146",
                },
              },
            }}
          />
          <br />
          <div className="row">
            <div className=" col text-right">
              <p className="text-center">
                <small>
                  {!defaultCard && currentTier?.status !== "trialing"
                    ? "You will be asked to confirm your subscription before your card is charged"
                    : "Your subscription will be charged to the new card starting with the next billing cycle"}
                </small>
              </p>
              <button className="btn btn-sm btn-info btn-block" type="submit">
                {(defaultCard ? "Replace" : "Add") + " Payment Method"}
              </button>
              <br />
              <p>
                <a
                  href="https://www.stripe.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/Powered by Stripe - blurple.png"
                    alt="stripe"
                    style={{ width: 132, height: 30 }}
                  />
                </a>
              </p>
            </div>
          </div>
        </form>
      </div>
    </BasicModal>
  );
};

export default CardForm;
