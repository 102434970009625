import React from "react";
import allowables from "../../../utils/allowables";
import InfoTooltip from "../tooltip/infoTooltip";

const NonFormSelect = ({
  name,
  label,
  options,
  onChange,
  value,
  noDefaultOption,
  overrideDefaultPlaceholder,
  splitNames,
  override_id,
  tooltip,
  testId,
}) => {
  return (
    <div className="nfs">
      <div className="row">
        {tooltip ? (
          <div className="col-xs">
            <InfoTooltip {...tooltip} className="icon-raised" />
          </div>
        ) : null}
        {label ? (
          <div className="col">
            <label htmlFor={name} className="nfs-label">
              {label}
            </label>
          </div>
        ) : null}
        <div className="col">
          <select
            id={name}
            name={name}
            onChange={onChange}
            value={value}
            className="custom-form nfs-select"
            data-testid={testId}
          >
            {!noDefaultOption && (
              <option key="default">
                {overrideDefaultPlaceholder || "Make a selection..."}
              </option>
            )}
            {options.map((option, i) => (
              <option
                key={i}
                value={override_id ? override_id(option) : option._id}
                className="nfs-option"
                aria-label="nonform-option"
              >
                {splitNames ? allowables.splitName(option.name) : option.name}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default NonFormSelect;
