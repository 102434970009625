import React, { useContext } from "react";
import { useHistory } from "react-router-dom";

import SecondaryHeader from "../common/pageComponents/secondaryHeader";
import allowables from "../../utils/allowables";
import TeamLogo from "./../common/pageComponents/teamLogo";
import { navigateTo } from "./../common/customs/customLinks";
import HeaderContext from "../../context/headerContext";
import useWindowDimensions from "../../utils/useWindowDimensions";
import TennisMatchHeader from "./simple/tennisMatchHeader";

const MatchHeader = ({
  match,
  teamOrder,
  goals,
  penalties,
  spectate,
  complexScore,
  org,
}) => {
  const { width } = useWindowDimensions();
  const header = useContext(HeaderContext);
  const history = useHistory();

  if (!match) return null;

  const renderName = (team) => {
    return (
      <div
        className={!spectate ? "clickable" : ""}
        onClick={
          spectate
            ? null
            : () =>
                navigateTo(
                  `/teams?info&q=${match[team + "TeamID"]}`,
                  history,
                  header
                )
        }
      >
        {width > 500
          ? match[team + "TeamName"]
          : match[team + "TeamAbbreviation"] ||
            match[team + "TeamName"].slice(0, 6).toUpperCase()}
      </div>
    );
  };

  if (allowables.translateSportName(org) === "Tennis")
    return (
      <TennisMatchHeader
        match={match}
        teamOrder={teamOrder}
        score={complexScore}
        settings={match.divisionID?.settings?.tennisOptions}
      />
    );

  return (
    <div className="text-center pop-box">
      <div className="row">
        {teamOrder.map((team) => {
          const bgColor = match[team + "TeamLogo"]?.teamColor1;

          return (
            <SecondaryHeader
              className="col"
              key={team}
              backgroundColor={bgColor}
              color={allowables.getTextColor(bgColor)}
            >
              {renderName(team)}
              <TeamLogo
                team={match[team + "TeamLogo"]}
                name={match[team + "TeamName"]}
                size={50}
              />
            </SecondaryHeader>
          );
        })}
      </div>
      <div className="row">
        {teamOrder.map((team, i) => (
          <div className="col" key={team}>
            <h1 className="text-center">
              {i === 1 &&
              (penalties.home || penalties.away) &&
              (penalties.home !== 0 || penalties.away !== 0) ? (
                <small aria-label="team-pks">({penalties[team]}) </small>
              ) : null}
              <b aria-label="team-score">{goals[team]}</b>
              {i === 0 &&
              (penalties.home || penalties.away) &&
              (penalties.home !== 0 || penalties.away !== 0) ? (
                <small aria-label="team-pks"> ({penalties[team]})</small>
              ) : null}
            </h1>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MatchHeader;
