import http from "./httpService";

export async function getDiscount(_id) {
  try {
    return await http.get(http.discountsEndpoint + "/" + _id);
  } catch (ex) {
    return ex.response;
  }
}

export async function getDiscountByCode(code) {
  try {
    return await http.get(http.discountsEndpoint + "/discountcode/" + code);
  } catch (ex) {
    return ex.response;
  }
}

export async function getDiscounts() {
  try {
    return await http.get(http.discountsEndpoint);
  } catch (ex) {
    return ex.response;
  }
}

export async function saveDiscount(_id, discount) {
  try {
    if (_id === "new") {
      return await http.post(http.discountsEndpoint, discount);
    } else return await http.put(http.discountsEndpoint + "/" + _id, discount);
  } catch (ex) {
    return ex.response;
  }
}

export async function deleteDiscount(_id) {
  try {
    return await http.delete(http.discountsEndpoint + "/" + _id);
  } catch (ex) {
    return ex.response;
  }
}

export async function applyDiscount(discountID, items = []) {
  try {
    return await http.put(
      http.discountsEndpoint + "/applydiscount/" + discountID,
      items
    );
  } catch (ex) {
    return ex.response;
  }
}
