import React from "react";

import { CLink } from "../components/common/customs/customLinks";
import { FaEllipsisH } from "react-icons/fa";
import { getCurrentUser } from "../services/userService";
import allowables from "../utils/allowables";

import Tooltip from "./../components/common/tooltip/tooltip";
import IconRender from "../components/common/icons/iconRender";

export function makeTableButton(
  buttonType,
  onFunction,
  tooltipDirection,
  property,
  overrideText
) {
  if (buttonType !== "edit")
    return {
      key: buttonType + property,
      content: (x) => (
        <button
          onClick={() => onFunction(x)}
          className={`btn btn-${
            buttonType === "delete" || buttonType === "remove"
              ? "dark"
              : buttonType === "add" || buttonType === "recruit"
              ? "info"
              : "danger"
          } btn-sm`}
          data-testid={`${buttonType}-button`}
        >
          <Tooltip
            content={
              <span>
                {buttonType === "delete" ? (
                  <b>Delete </b>
                ) : buttonType === "remove" ? (
                  <b>Remove </b>
                ) : buttonType === "recruit" ? (
                  <b>Recruit </b>
                ) : buttonType === "add" ? (
                  <b>Add </b>
                ) : (
                  <b>Purge </b>
                )}
                {overrideText ||
                  `${
                    x.name
                      ? allowables.splitName(x.name)
                      : x.homeTeamName + " vs " + x.awayTeamName
                  }`}
              </span>
            }
            direction={tooltipDirection ? tooltipDirection : "top-left"}
          >
            <IconRender name={buttonType} />
          </Tooltip>
        </button>
      ),
    };
  else
    return {
      key: buttonType + property,
      content: (x) => (
        <CLink
          path={`/${onFunction}?q=${property ? x[property + "ID"] : x._id}`}
          buttonStyle={`btn-sm btn-` + (property ? "info" : "secondary")}
          button={true}
          data-testid={`${buttonType}-button`}
        >
          <Tooltip
            content={
              <span>
                <b>Edit </b>{" "}
                {`${
                  property
                    ? x[property + "Name"]
                    : x.name
                    ? allowables.splitName(x.name)
                    : x.homeTeamName + " vs " + x.awayTeamName
                }`}
              </span>
            }
            direction={tooltipDirection ? tooltipDirection : "top-left"}
          >
            <IconRender name="edit" />
          </Tooltip>
        </CLink>
      ),
    };
}

export function combineButtons(buttons) {
  return {
    key: "combinedButtons",
    content: (x) => (
      <div className="btn btn-sm btn-light">
        <Tooltip
          content={buttons.map((b) => (
            <span key={b.key} style={{ marginRight: 5, marginLeft: 5 }}>
              {b.content(x)}
            </span>
          ))}
          customClass="tooltip-buttons"
          clickable={true}
          testId="combined-button"
        >
          <FaEllipsisH />
        </Tooltip>
      </div>
    ),
  };
}

export function makeCustomTableButton(
  buttonText,
  tooltipHeader,
  buttonColor,
  buttonKey,
  onFunction,
  tooltipDirection,
  belongComp,
  tooltipText,
  buttonLabel
) {
  const user = getCurrentUser();
  return {
    key: buttonKey,
    content: (x) =>
      typeof onFunction === "string" &&
      (!belongComp ||
        (belongComp && x[belongComp] && x[belongComp]._id === user._id)) ? (
        <CLink
          path={`/${onFunction}?q=${x._id}`}
          buttonStyle={`btn-${buttonColor(x)}`}
          button={true}
          aria-label={buttonLabel}
        >
          <Tooltip
            content={
              <span>
                <b>{tooltipHeader(x)}</b>
                {tooltipText(x)}
              </span>
            }
            direction={tooltipDirection ? tooltipDirection : "top-right"}
          >
            {buttonText(x)}
          </Tooltip>
        </CLink>
      ) : !belongComp ? (
        <button
          className={`btn btn-${buttonColor}`}
          onClick={() => onFunction(x)}
          aria-label={buttonLabel}
        >
          <Tooltip
            content={
              <span>
                <b>{tooltipHeader}</b>
                <div>{tooltipText(x)}</div>
              </span>
            }
            direction={tooltipDirection ? tooltipDirection : "right"}
          >
            {buttonText}
          </Tooltip>
        </button>
      ) : null,
  };
}
