import React from "react";
import SideBySideView from "../common/pageComponents/sideBySideView";
import TooltipButton from "../common/tooltip/tooltipButton";
import IconRender from "../common/icons/iconRender";
import { getCurrentUser } from "../../services/userService";

const FieldsButtonLineup = () => {
  const user = getCurrentUser();

  return (
    user.role.includes("admin") &&
    user.orgID && (
      <SideBySideView
        Components={[
          <TooltipButton
            buttonClass="btn-md btn-info"
            tooltipText="Add New Field"
            navigatePath={"/fields?q=new"}
            tooltipDirection="bottom"
          >
            <IconRender name="add" className="icon-md" />
          </TooltipButton>,
        ]}
        groupClassName="button-group-box"
        separateRows
        minWidth={200}
      />
    )
  );
};

export default FieldsButtonLineup;
