import React, { useState, useEffect } from "react";

import MultiEdit from "../common/pageComponents/multiEdit";
import allowables from "../../utils/allowables";
import MassInput from "./../common/form/massInput";
import TooltipButton from "../common/tooltip/tooltipButton";
import IconRender from "../common/icons/iconRender";
import SideBySideView from "../common/pageComponents/sideBySideView";

const PlayersButtonLineup = ({
  user,
  captainOrAdmin,
  filteredPlayers,
  onMultiEdit,
  search,
  sort,
}) => {
  const [shirtNumbers, setShirtNumbers] = useState([]);

  useEffect(() => {
    if (user.role.includes("captain")) {
      setShirtNumbers(filteredPlayers.filter((p) => p.teamID === user.teamID));
    } else setShirtNumbers(filteredPlayers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, sort]);

  const multiEditColumns = [
    { content: (p) => allowables.splitName(p.name), label: "Name" },
    { content: (p) => allowables.splitName(p.teamName), label: "Team" },
  ];

  const handleUpdate = (number, { _id }) => {
    number = number !== "" ? Number(number) : "";
    if (isNaN(number)) return;
    let players = [...shirtNumbers];
    const index = players.findIndex((p) => p._id === _id);
    let player = { ...players[index] };
    player.shirtNumber = number;
    players.splice(index, 1, player);
    setShirtNumbers(players);
  };

  const handleSubmit = (playerIDs) => {
    return onMultiEdit(shirtNumbers.filter((p) => playerIDs.includes(p._id)));
  };

  return captainOrAdmin && user.orgID ? (
    <SideBySideView
      Components={[
        <TooltipButton
          buttonClass="btn-md btn-info"
          tooltipText={"Add New Player"}
          tooltipDirection="bottom"
          navigatePath={"/players?q=new"}
        >
          <IconRender name="add" className="icon-md" />
        </TooltipButton>,
        <MultiEdit
          data={shirtNumbers}
          keyValue="_id"
          columnsToShow={multiEditColumns}
          editComponents={[
            {
              component: MassInput,
              props: {
                name: "shirtNumber",
                type: "number",
                value: "shirtNumber",
                onChange: handleUpdate,
                min: -1,
                max: 999,
                label: "Roster Number",
              },
            },
          ]}
          noHeaders={true}
          onSubmit={handleSubmit}
          title="Edit Roster Numbers"
          type="players"
          minRowHeight={30}
          buttonIcon={<IconRender name="settings" className="icon-md" />}
          buttonClass="btn-md btn-secondary"
          tooltipText="Edit Roster Numbers"
          tooltipDirection={"bottom"}
        />,
      ]}
      groupClassName="button-group-box"
      separateRows
      minWidth={200}
    />
  ) : null;
};
export default PlayersButtonLineup;
