import React from "react";
import InfoTooltip from "../tooltip/infoTooltip";
import IconRender from "../icons/iconRender";
import PaidFeature from "../tooltip/paidFeature";

const Checkbox = ({
  onChange,
  id,
  label,
  value,
  tooltip,
  disabled,
  paidFeature,
  isGroup,
}) => {
  const updateBox = () => {
    onChange(id ? id : value ? false : true);
  };

  return (
    <div className="row" style={{ marginTop: 15 }}>
      <div className="col-2">
        <div className="row">
          <div className="col-xs">
            {tooltip && <InfoTooltip {...tooltip} />}
          </div>
          <div className="col-xs">{paidFeature && <PaidFeature />}</div>
        </div>
      </div>
      <div className="checkbox-wrapper">
        <div style={{ position: "absolute" }}>
          <div style={{ position: "absolute", left: 0, top: -2 }}>
            <input
              type="checkbox"
              className="form-check-input"
              name={id}
              id={id}
              onChange={updateBox}
              checked={value}
              disabled={disabled}
            />
          </div>
          {value && (
            <div
              style={{ position: "absolute", left: -18, top: 0 }}
              onClick={updateBox}
            >
              <IconRender
                name="check"
                className={disabled ? "icon-check disabled" : "icon-check"}
              />
            </div>
          )}
        </div>
      </div>
      <div
        className="col text-left"
        style={{ paddingLeft: 20 }}
        onClick={isGroup ? updateBox : null}
      >
        <label htmlFor={id} className={disabled ? "" : "clickable"}>
          {label}
        </label>
      </div>
    </div>
  );
};

export default Checkbox;
