import React from "react";
import allowables from "../../../utils/allowables";
import FormLabel from "./formLabel";
import WarningHeader from "../pageComponents/warningHeader";

const SelectByValueOption = ({
  name,
  value,
  label,
  options,
  error,
  valueOption,
  tooltip,
  paidFeature,
  labelButton,
  multiSelect,
  onChange,
  ...rest
}) => {
  if (multiSelect)
    return (
      <div className="form-group">
        <FormLabel
          tooltip={tooltip}
          paidFeature={paidFeature}
          label={label}
          htmlFor={name}
          labelButton={labelButton}
        />
        <div className="form-multi-select" onChange={null}>
          {options.map((option, idx) => (
            <div
              key={idx}
              className={
                "form-multi-select-option" +
                (value.includes(option[valueOption]) ? " selected" : "")
              }
              onClick={() =>
                onChange({
                  currentTarget: { name, value: option[valueOption] },
                })
              }
            >
              {allowables.splitName(option.name)}
            </div>
          ))}
        </div>
      </div>
    );

  return (
    <div className="form-group">
      <FormLabel
        tooltip={tooltip}
        paidFeature={paidFeature}
        label={label}
        htmlFor={name}
        labelButton={labelButton}
      />
      <select
        name={name}
        id={name}
        value={value}
        {...rest}
        className="form-control custom-form"
        multiple={multiSelect}
        onChange={onChange}
      >
        {!multiSelect && <option key="default">Make a selection...</option>}
        {options.map((option) => (
          <option key={option._id} value={option[valueOption]}>
            {allowables.splitName(option.name)}
          </option>
        ))}
      </select>
      {error && <WarningHeader>{error}</WarningHeader>}
    </div>
  );
};

export default SelectByValueOption;
