import React from "react";

import SoccerLeaderboardTable from "./soccer/soccerLeaderboardTable";
import FootballLeaderboardTable from "./football/footballLeaderboardTable";
import DiamondLeaderboardTable from "./diamond/diamondLeaderboardTable";

const SportLeaderboard = ({
  leaderboard,
  sport,
  searchQuery,
  onSelectTable,
}) => {
  const LeaderboardTable = sport.includes("soccer")
    ? SoccerLeaderboardTable
    : sport.includes("football")
    ? FootballLeaderboardTable
    : sport.includes("diamond")
    ? DiamondLeaderboardTable
    : null;

  if (!LeaderboardTable) return null;
  return (
    <LeaderboardTable
      data={leaderboard}
      searchQuery={searchQuery}
      onSelectTable={onSelectTable}
    />
  );
};

export default SportLeaderboard;
