import axios from "axios";
import toast from "../utils/toast";
import { logout } from "./userService";

const apiUrl = process.env.REACT_APP_RENDER_API_URL;

if (process.env.NODE_ENV !== "test") {
  axios.defaults.baseURL = apiUrl;
  axios.interceptors.response.use(null, (error) => {
    const expectedError =
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500;

    if (!expectedError) {
      toast.error(
        "An unexpected error occured. Please check your internet connection.",
        true
      );
    }

    if (error.response && error.response.status === 409) {
      toast.error(error.response.data + " You will be logged out.");
      setTimeout(() => {
        logout();
        window.location = "/login";
      }, 4000);
    }

    return Promise.reject(error);
  });
}
function setJwt(jwt) {
  axios.defaults.headers.common["x-auth-token"] = jwt;
}

function resetConfig(jwt) {
  axios.defaults.baseURL = apiUrl;
  axios.defaults.headers.common["x-auth-token"] = jwt;
}

const exports = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  teamsEndpoint: "/v1/teams",
  divisionsEndpoint: "/v1/divisions",
  seasonsEndpoint: "/v1/seasons",
  usersEndpoint: "/v1/users",
  authEndpoint: "/v1/auth",
  orgEndpoint: "/v1/organizations",
  playersEndpoint: "/v1/players",
  matchesEndpoint: "/v1/matches",
  ftpEndpoint: "/v1/ftp",
  versionEndpoint: "/v1/version",
  emailsEndpoint: "/v1/emailing",
  spectateEndpoint: "/v1/spectate",
  paymentEndpoint: "/v1/payment",
  superAdminEndpoint: "/v1/superadmin",
  errorEndpoint: "/v1/error",
  profilesEndpoint: "/v1/profiles",
  liveScoreEndpoint: "/v1/livescores",
  mediaEndpoint: "/v1/media",
  fieldEndpoint: "/v1/fields",
  invitationEndpoint: "/v1/invitation",
  messageEndpoint: "/v1/messages",
  tacticsEndpoint: "/v1/tactics",
  discountsEndpoint: "/v1/discounts",
  setJwt,
  resetConfig,
};

export default exports;
