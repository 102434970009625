import React from "react";

import { CLink } from "../../common/customs/customLinks";
import MiniHeader from "../../common/pageComponents/miniHeader";

const NotCharging = ({ whoPays, currency }) => {
  return (
    <div>
      <MiniHeader>You are not charging {whoPays} dues</MiniHeader>
      <div className="form-divided-section text-center">
        <p>
          Your settings allow you to collect payments from {whoPays}, but the
          amount you are charging must be set higher than zero.
        </p>
        <p>
          Update the amount in your league settings to start accpeting and
          tracking {whoPays} payments.
        </p>
        <CLink path="/league?q=options" button={true}>
          Go To League Settings
        </CLink>
      </div>
    </div>
  );
};

export default NotCharging;
