import React from "react";

import BasicModal from "../common/pageComponents/basicModal";
import { popupStyle } from "../../utils/styleUtil";
import VideoPlayer from "./videoPlayer";
import IconRender from "../common/icons/iconRender";

const ModalVideoViewer = ({ isOpen, onClose, videoUrl, header }) => {
  if (!videoUrl) return null;
  return (
    <BasicModal
      isOpen={isOpen}
      onClose={onClose}
      style={popupStyle}
      header={
        <div className="text-left">
          <a href={videoUrl} target="_blank" rel="noopener noreferrer">
            Open Link <IconRender name="externallink" />
          </a>
          {header}
        </div>
      }
    >
      <VideoPlayer url={videoUrl} />
    </BasicModal>
  );
};

export default ModalVideoViewer;
