import React from "react";
import Tooltip from "./tooltip";
import { IconContext } from "react-icons";
import { RiFileInfoLine } from "react-icons/ri";

const InfoTooltip = ({
  header,
  listHeader,
  listItems,
  text,
  className,
  direction,
  link,
}) => {
  return (
    <IconContext.Provider value={{ className }}>
      <Tooltip
        content={
          <div style={{ width: 250 }}>
            <b>{header}</b>
            <br />
            {listItems && (
              <div>
                <br />
                {listHeader}
                {listItems.map((i) => (
                  <li key={i}>{i}</li>
                ))}
              </div>
            )}
            {text && (
              <div>
                <br />
                {text}
              </div>
            )}
            {link && (
              <div>
                <br />
                <a href={link} target="_blank" rel="noopener noreferrer">
                  {link}
                </a>
              </div>
            )}
          </div>
        }
        direction={direction ? direction : "top"}
      >
        <RiFileInfoLine style={{ cursor: "help" }} />
      </Tooltip>
    </IconContext.Provider>
  );
};

export default InfoTooltip;
