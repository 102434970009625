import http from "./httpService";
import { callbackProgress } from "../utils/progress";

export async function addVideoUrl(matchID, videoUrl, callback) {
  try {
    return await http.put(
      http.mediaEndpoint + "/addvideourl/" + matchID,
      { videoUrl },
      callback && {
        onDownloadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}
