import React from "react";
import Joi from "joi-browser";
import toast from "../../utils/toast";

import Loading from "../common/loading/loading";
import Form from "../common/form/form";
import { getCurrentUser, updateUserInfo } from "../../services/userService";
import allowables from "../../utils/allowables";

class VaccineUploadInfo extends Form {
  state = {
    data: {
      date: "",
      name: "",
    },
    errors: {},
    replace: false,
    showInfo: false,
    loading: false,
  };

  schema = {
    date: Joi.date().required().label(" Vaccine Date"),
    name: Joi.string().required().min(1).max(255).label("Vaccine Name"),
  };

  componentDidMount() {
    const user = getCurrentUser();
    let showInfo = true;
    if (user.role.includes("admin")) showInfo = false;
    this.setState({ data: this.mapToViewModel(), showInfo });
  }

  mapToViewModel = () => {
    const user = getCurrentUser();
    return {
      date:
        (user.vaccine && allowables.formDate(user.vaccine.date, true)) || "",
      name: (user.vaccine && user.vaccine.name) || "",
    };
  };

  checkUser = (checkReplace) => {
    const user = getCurrentUser();
    return (
      user &&
      user.vaccine &&
      user.vaccine.date &&
      user.vaccine.name &&
      (!this.state.replace || (checkReplace && this.state.replace))
    );
  };

  doSubmit = async () => {
    const { data } = this.state;
    if (!data.date || !data.name)
      return toast.error("Please fill in Date and Name fields");
    this.setState({ loading: true });
    let user = getCurrentUser();

    // if the user has a CoC object attached but it was wiped by the owner updated the terms then having the CoC attached when updating will cause an error, so it must be deleted
    delete user.codeOfConduct;
    user.vaccine = { date: data.date, name: data.name };
    const res = await updateUserInfo(user);
    if (res.status === 200) return (window.location = "/vaccine");
    else toast.error(res.data);
    this.setState({ loading: false });
  };

  render() {
    const user = getCurrentUser();
    const { loading, data } = this.state;
    return loading ? (
      <Loading />
    ) : (
      <React.Fragment>
        <div
          className="bg-info text-white clickable"
          onClick={() =>
            this.setState({ showInfo: this.state.showInfo ? false : true })
          }
          style={{ borderRadius: "5px" }}
        >
          <h4 className="text-center">My Vaccine Info</h4>
        </div>
        {this.state.showInfo && (
          <div className="row">
            <div className="col">
              {this.checkUser() ? (
                <React.Fragment>
                  <h5>
                    <small>Vaccine Name: </small>
                    {user.vaccine.name}
                  </h5>
                  <h5>
                    <small>Vaccine Date: </small>
                    {allowables.dateTime(user.vaccine.date).date}
                  </h5>
                  <button
                    className="btn btn-block btn-secondary"
                    onClick={() => this.setState({ replace: true })}
                  >
                    Replace Information
                  </button>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <form>
                    {this.renderInput(
                      "name",
                      "* Vaccine Name (Manufacturer Name)",
                      "autofocus"
                    )}
                    {this.renderDateTimePicker(
                      "date",
                      "* Vaccine Date (Most Recent Date Received",
                      "",
                      null,
                      true
                    )}
                  </form>
                  {this.checkUser(true) && (
                    <button
                      className="btn btn-block btn-secondary"
                      onClick={() => this.setState({ replace: false })}
                    >
                      Cancel
                    </button>
                  )}
                </React.Fragment>
              )}
            </div>
            <div className="col">
              Once you have submitted your vaccine info please email a copy of
              your vaccine card to the League owner using the link below. If you
              are submitting a medical or religious exemption please enter "
              <i>Exemption</i>" as the Vaccine Name along with today's date and
              provide your documentation in the email.
              <br />
              <br />
              {!this.checkUser() && (
                <button
                  className="btn btn-block btn-info"
                  onClick={this.doSubmit}
                >
                  Submit
                </button>
              )}
              <h5 className="text-center">
                {user &&
                user.vaccine &&
                user.vaccine.date &&
                user.vaccine.name ? (
                  <a
                    href={`mailto:${
                      this.props.org.misc?.vaccinationEmail ||
                      this.props.org.webpage?.contactEmail ||
                      this.props.org.ownerEmail
                    }?subject=${
                      user && allowables.splitName(user.name)
                    }%20Vaccine%20Card&body=Please%20see%20my%20vaccine%20card%20attached%0D%0A%0D%0AVaccine%20Name:%20${data.name
                      .split(" ")
                      .join("%20")
                      .split("&")
                      .join("and")}%0D%0AVaccine%20Date:%20${
                      data.date
                    }%0D%0A%0D%0A${user && user.email}%0D%0A%0D%0A`}
                  >
                    Email Photo of Vaccine Card to League Owner
                  </a>
                ) : (
                  "Fill in all info and click Submit for email link"
                )}
              </h5>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default VaccineUploadInfo;
