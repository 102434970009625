import React from "react";

const SoccerReviewSegment = ({ match, teamOrder, liveEvents }) => {
  const events = liveEvents || match.chronoEventsNames;

  if (events.length === 0) return null;

  const comps = [
    {
      id: "Goals",
      name: "Goals",
      class: "bg-info",
      border: "border-main-theme-color-only",
    },
    {
      id: "Assists",
      name: "Assists",
      class: "bg-light border-main-theme",
      border: "border-main-theme-color-only",
    },
    {
      id: "YellowCards",
      name: "Yellow Cards",
      class: "soccer-yellow-card-bg",
      border: "border-soccer-yellow-card",
    },
    {
      id: "RedCards",
      name: "Red Cards",
      class: "soccer-red-card-bg",
      border: "border-soccer-red-card",
    },
  ];
  const getCounts = (team, id) => {
    const property = team + "Team" + id;
    const filteredEvents = events.filter((e) =>
      id === "Assists"
        ? e.eventType === team + "TeamGoals" && e.assistID
        : e.eventType === property
    );
    let counts = [];
    filteredEvents.forEach((e) => {
      const playerID = id === "Assists" ? e.assistID : e._id;
      const playerName = id === "Assists" ? e.assistName : e.name;
      const index = counts.findIndex((c) => c._id === playerID);
      if (index >= 0) {
        counts.splice(index, 1, {
          _id: playerID,
          name: playerName,
          count: counts[index].count + 1,
        });
      } else counts.push({ _id: playerID, name: playerName, count: 1 });
    });
    return counts;
  };

  return (
    <div>
      {comps.map((c) => {
        if (
          getCounts("home", c.id).length === 0 &&
          getCounts("away", c.id).length === 0
        )
          return null;
        return (
          <div key={c.id}>
            <h5 className={"text-center " + c.class}>
              <b>{c.name}</b>
            </h5>
            <div className="row">
              {teamOrder.map((team, i) => {
                const counts = getCounts(team, c.id);
                return (
                  <div
                    key={team + c.id}
                    className={
                      "col text-" +
                      (i === 0 ? "right border-right " + c.border : "left")
                    }
                  >
                    {counts.map((p) => {
                      return (
                        <div className="row" key={c.id + p._id}>
                          <div className="col">
                            {c.id !== "RedCards" ? (
                              <div>
                                <b>{p.name}</b>&nbsp;&nbsp;&nbsp;{p.count}
                              </div>
                            ) : (
                              <div>
                                <b>{p.name}</b>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
            <br />
          </div>
        );
      })}
    </div>
  );
};

export default SoccerReviewSegment;
