import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { navigateBack, navigateTo } from "./common/customs/customLinks";
import HeaderContext from "../context/headerContext";
import MainTitle from "./common/loading/mainTitle";
import allowables from "../utils/allowables";

const NotFound = (props) => {
  const header = useContext(HeaderContext);
  const history = useHistory();

  useEffect(() => {
    header.setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="text-center centered-small-input-area">
      <MainTitle />
      <br />
      <h1>{props.accessDenied ? "Access Denied" : "Page Not Found"}</h1>
      <br />
      {props.text && (
        <div>
          <p>
            <b>{props.text}</b>
          </p>
          <br />
        </div>
      )}
      <p>
        If you believe{" "}
        {props.accessDenied
          ? "you should have access"
          : "this page should exist"}{" "}
        please contact{" "}
        <a href={`mailto:${allowables.supportEmail}`}>
          {allowables.supportEmail}
        </a>
      </p>
      <div>
        <button
          className="btn btn-secondary btn-md btn-block"
          onClick={() => navigateTo("/profile", history, header)}
        >
          Go To Profile Page
        </button>
        <br />
        <button
          className="btn btn-dark btn-md"
          onClick={() => navigateBack(history, header)}
        >
          Go Back
        </button>
      </div>
    </div>
  );
};

export default NotFound;
