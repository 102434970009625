import React, { useContext } from "react";
import Avatar from "../common/pageComponents/avatarEditor";
import { saveAvatar } from "../../services/userService";
import OrgContext from "../../context/orgContext";
import MiniHeader from "../common/pageComponents/miniHeader";
import allowables from "../../utils/allowables";

const ProfilePic = ({
  user,
  hasAvatar,
  openAvatar,
  avatarOpen,
  removeAvatar,
}) => {
  const org = useContext(OrgContext);
  if (org && org.misc && org.misc.userImages === "No") return null;
  const show = hasAvatar && !hasAvatar.toLowerCase().includes("blocked");

  function getFallbackImage(event) {
    event.target.src = allowables.fallbackImage();
  }

  return (
    <React.Fragment>
      <MiniHeader>Profile Image</MiniHeader>
      <div className="form-divided-section">
        {show && (
          <img
            onError={getFallbackImage}
            src={
              "https://storage.googleapis.com/ultimatescoreboard-userimages/" +
              hasAvatar +
              ".png"
            }
            alt={user.name}
            onClick={openAvatar}
            className="clickable"
            width={150}
            height={150}
            style={{ border: "3px solid #831fe0", borderRadius: "25px" }}
          />
        )}

        {hasAvatar && hasAvatar.toLowerCase().includes("blocked") && (
          <p>
            You have been blocked from uploading a profile image. Please contact
            your league admin for more information.
          </p>
        )}

        {!hasAvatar && (
          <button className="btn btn-secondary btn-sm" onClick={openAvatar}>
            Add Profile Image
          </button>
        )}
        {avatarOpen && (
          <React.Fragment>
            <br />
            <br />
            <p>
              Your profile image is used for identification purposes so please
              upload a clear face picture.
            </p>
            <Avatar
              uploadFunction={saveAvatar}
              height={150}
              width={150}
              saveTitle="Save Profile Image"
            />
          </React.Fragment>
        )}
        {show && (
          <div style={{ margin: "5px" }}>
            <button className="btn btn-dark btn-sm" onClick={removeAvatar}>
              Remove Profile Image
            </button>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default ProfilePic;
