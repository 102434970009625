import React from "react";
import InfoTooltip from "../common/tooltip/infoTooltip";
import OrgLogoEditor from "./orgLogoEditor";
import RefereeCodeForm from "./refereeCodeForm";

const OrgOptions = ({
  org,
  openOptions,
  optionsOpen,
  openLogo,
  logoOpen,
  openDelete,
  deleteOpen,
  handleDelete,
  navToSettings,
}) => {
  return (
    <div>
      <div>
        <button className="btn btn-light btn-sm" onClick={navToSettings}>
          Edit Leaguewide Settings
        </button>
        <br />
        <br />
        <button className="btn btn-light btn-sm" onClick={openOptions}>
          {optionsOpen ? "Close " : ""}Logo & Options
        </button>
        <br />
        <br />
        {optionsOpen && (
          <div>
            <RefereeCodeForm />

            <InfoTooltip
              header="Main Logo"
              className="icon-info"
              text={`Your Main Logo will display in the upper left corner of the navigation bar in place of your League Name.
                  For all users except yourself (the League owner) clicking it will take them to your Ultimate Scoreboard league and stats webpage.`}
              direction="right"
            />
            <button className="btn btn-info btn-sm" onClick={openLogo}>
              {logoOpen ? "Close Main Logo" : "Main Logo"}
            </button>
            {logoOpen && <OrgLogoEditor org={org} />}
            <br />
          </div>
        )}
        {optionsOpen && (
          <div>
            <br />
            <br />
            <br />
            <button className="btn btn-danger btn-sm" onClick={openDelete}>
              {deleteOpen ? "Close Delete" : "Delete League"}
            </button>
            <br />
            <br />

            {deleteOpen && (
              <button className="btn btn-dark btn-sm" onClick={handleDelete}>
                Are you sure? Purge League
              </button>
            )}
            <br />
            <br />
          </div>
        )}
      </div>
    </div>
  );
};

export default OrgOptions;
