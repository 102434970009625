import React from "react";

import MiniHeader from "../../common/pageComponents/miniHeader";
import WarningHeader from "../../common/pageComponents/warningHeader";
import NoSuchCustomer from "../subscriptions/noSuchCustomer";

const NotSetUp = ({ redirect, error, onAddCustomerSuccess }) => {
  if (
    error.toLowerCase().includes("customer account has been deleted") ||
    error.toLowerCase().includes("no such customer")
  )
    return <NoSuchCustomer onAddSuccessCallback={onAddCustomerSuccess} />;

  return (
    <React.Fragment>
      <MiniHeader>Set Up your Stripe Account</MiniHeader>
      <div className="form-divided-section">
        {error && <WarningHeader>{error}</WarningHeader>}
        <div className="text-center">
          <p>
            Please complete your account setup using Stripe to accept player
            payments
          </p>
        </div>
        <div className="text-center">
          <img
            onClick={redirect}
            src="/Connect with Stripe - blurple.png"
            alt="stripe"
            style={{ width: 200, height: 44 }}
            className="clickable"
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default NotSetUp;
