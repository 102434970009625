import React, { Component } from "react";

import Seasons from "./seasons";
import SeasonForm from "./seasonForm";
import HeaderContext from "../../context/headerContext";
import { getCurrentUser } from "../../services/userService";

class SeasonsRedirect extends Component {
  static contextType = HeaderContext;
  state = {
    loading: true,
    comp: null,
    seasonID: "",
    source: "edit",
  };

  componentDidMount() {
    this.setup();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.search !== this.props.location.search) this.setup();
  }

  setup = () => {
    let comp = Seasons;
    const query = this.props.location.search.slice(1).split("&");
    let seasonID = query.find((q) => q.split("=")[0] === "q");
    seasonID = seasonID && seasonID.split("=")[1];
    if (seasonID) {
      this.context.setLoading(true);
      comp = SeasonForm;
    }
    let source =
      query.find((q) => q === "info") ||
      !getCurrentUser().role.includes("admin")
        ? "info"
        : "edit";

    this.setState({ loading: false, seasonID, source, comp });
  };

  render() {
    return this.state.loading || !this.state.comp ? null : (
      <this.state.comp
        {...this.props}
        seasonID={this.state.seasonID}
        source={this.state.source}
      />
    );
  }
}

export default SeasonsRedirect;
