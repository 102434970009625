import React from "react";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import { IconContext } from "react-icons";

const SingleQuestion = ({ id, onClick, opened, children }) => {
  return (
    <React.Fragment>
      <IconContext.Provider value={{ className: "icon-raised" }}>
        <div
          onClick={() => onClick(opened === id ? null : id)}
          className="clickable"
        >
          <h6>
            {children}{" "}
            <small>{id === opened ? <FaArrowUp /> : <FaArrowDown />}</small>
          </h6>
        </div>
      </IconContext.Provider>
    </React.Fragment>
  );
};

export default SingleQuestion;
