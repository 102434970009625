import React from "react";
import { FaSortDown, FaSortUp } from "react-icons/fa";
import { IconContext } from "react-icons";

const VerticalCounter = ({
  title,
  className,
  updater,
  value,
  index,
  min,
  max,
}) => {
  const handleValueChange = (change, position) => {
    if (position !== undefined) {
      let current = [...value];
      if (current[position] === min && change === -1) return;
      if (current[position] === max && change === 1) return;
      current[position] = current[position] + change;
      updater(current);
    } else {
      if (value === min && change === -1) return;
      if (value === max && change === 1) return;
      updater(value + change);
    }
  };

  return (
    <IconContext.Provider
      value={className ? { className } : { className: "icon-info icon-lg" }}
    >
      <h6>{title}</h6>
      <div onClick={() => handleValueChange(1, index)} className="clickable">
        <FaSortUp />
      </div>
      <b>{index !== undefined ? value[index] : value}</b>
      <div onClick={() => handleValueChange(-1, index)} className="clickable">
        <FaSortDown />
      </div>
    </IconContext.Provider>
  );
};

export default VerticalCounter;
