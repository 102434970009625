import React, { useState } from "react";
import UserPlayerHistory from "./userPlayerHistory";
import MiniHeader from "../common/pageComponents/miniHeader";
import UserPaymentHistory from "./userPaymentHistory";
import PageBottom from "../common/pageComponents/pageBottom";

const UserHistory = () => {
  const [openItems, setOpenItems] = useState({});

  const items = [
    { id: "player", label: "Player History", Comp: UserPlayerHistory },
    {
      id: "dues",
      label: "League Dues",
      Comp: UserPaymentHistory,
    },
    { id: "offcycle", label: "Other Payments", Comp: UserPaymentHistory },
  ];

  return (
    <div>
      {items.map((item) => (
        <div key={item.id}>
          <MiniHeader
            onClick={() =>
              setOpenItems({ ...openItems, [item.id]: !openItems[item.id] })
            }
            iconName={openItems[item.id] ? "chevronup" : "chevrondown"}
          >
            {item.label}
          </MiniHeader>
          <item.Comp isOpen={openItems[item.id]} id={item.id} />
        </div>
      ))}
      <PageBottom />
    </div>
  );
};

export default UserHistory;
