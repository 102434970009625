import React from "react";
import FormationCreator from "formation-creator";
import TooltipButton from "../common/tooltip/tooltipButton";
import IconRender from "../common/icons/iconRender";

const SingleTactic = ({ idx, tactic, isLocked, onDelete, onEdit, user }) => {
  if (!tactic) return null;
  return (
    <div className="text-center">
      <FormationCreator
        field={{ ...tactic.field, id: idx }}
        players={tactic.players}
        lines={tactic.lines}
        isLocked={isLocked}
      />
      {user.role.includes("captain") ? (
        <div className="row">
          <div className="col">
            <TooltipButton
              tooltipText="Delete Tactic"
              tooltipDirection="top"
              onClick={() => onDelete(tactic)}
              buttonClass="btn btn-md btn-dark"
            >
              <IconRender name="delete" />
            </TooltipButton>
          </div>
          <div className="col">
            <TooltipButton
              tooltipText="Edit Tactic"
              tooltipDirection="top"
              onClick={() => onEdit(tactic)}
              buttonClass="btn btn-md btn-secondary"
            >
              <IconRender name="edit" />
            </TooltipButton>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default SingleTactic;
