import React from "react";

const SecondaryHeader = ({ children, backgroundColor, color, className }) => {
  return (
    <div className={className}>
      <div
        className="dark-bg"
        style={{
          paddingTop: 4,
          paddingBottom: 1,
          paddingLeft: 2,
          paddingRight: 2,
          backgroundColor,
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h3 className={!color ? "text-white" : ""} style={{ color }}>
          <b>{children}</b>
        </h3>
      </div>
    </div>
  );
};

export default SecondaryHeader;
