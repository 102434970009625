import React from "react";

const MassInput = ({ value, name, type, onChange, _id, min, max }) => {
  const handleChange = (event) => {
    onChange(event.target.value, _id);
  };
  return (
    <div className="row non-form-input">
      <div className="col text-left">
        <input
          name={name}
          type={type}
          className="form-control custom-form"
          onChange={handleChange}
          value={value === 0 ? "0" : value || ""}
          min={min}
          max={max}
        />
      </div>
    </div>
  );
};

export default MassInput;
