import React from "react";

import useWindowDimensions from "./../../utils/useWindowDimensions";
import PlayersCardView from "./playersCardView";
import PlayersTable from "./playersTable";

const PlayersTableCardSwitcher = ({
  playersByPage,
  onClick,
  onDelete,
  onSort,
  sortColumn,
  thisID,
  freeAgents,
  onRecruit,
  onMoveToFreeAgents,
}) => {
  const { width } = useWindowDimensions();
  return (
    <div>
      {width < 0 ? ( // this was updated to force always displaying the table view pending https://trello.com/c/fFnEYhH7/87-redo-card-components
        <PlayersCardView players={playersByPage} onClick={onClick} />
      ) : (
        <PlayersTable
          playersByPage={playersByPage}
          onDelete={onDelete}
          onSort={onSort}
          sortColumn={sortColumn}
          thisID={thisID}
          freeAgents={freeAgents}
          onRecruit={onRecruit}
          onMoveToFreeAgents={onMoveToFreeAgents}
        />
      )}
    </div>
  );
};

export default PlayersTableCardSwitcher;
