import React from "react";
import allowables from "../../utils/allowables";

const WebpageImage = ({ width, height, location, alt, extraClass }) => {
  if (!location) return null;

  function getFallbackImage(event) {
    event.target.src = allowables.fallbackImage(height ? 8 : 1);
  }

  return (
    <img
      onError={(e) => getFallbackImage(e)}
      src={`https://storage.googleapis.com/ultimatescoreboard-webpageimages/${location}.png`}
      width={width}
      height={height || "auto"}
      alt={alt || "img"}
      className={"round-corners " + extraClass}
    />
  );
};

export default WebpageImage;
