import React, { useState, useContext } from "react";

import { saGetMessages, saGetMessage } from "../../services/superAdminService";
import toast from "../../utils/toast";
import HeaderContext from "../../context/headerContext";
import usePagination from "../../hooks/usePagination";
import Pagination from "../common/dataSorting/pagination";
import Table from "../common/table/table";
import WarningHeader from "../common/pageComponents/warningHeader";
import IconRender from "../common/icons/iconRender";
import BasicModal from "../common/pageComponents/basicModal";
import allowables from "../../utils/allowables";

const SuperAdminMessages = (props) => {
  const { setLoading } = useContext(HeaderContext);
  const [messages, setMessages] = useState([]);
  const [totalMessages, setTotalMessages] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [theseMessages, setTheseMessages] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState(null);

  const loadData = async (page, limit) => {
    setLoading(true);
    const res = await saGetMessages(limit, page - 1);
    if (res.status === 200) {
      setTotalMessages(res.data.count);
      setMessages(res.data.messages);
    } else toast.error(res.data);
    setLoading(false);
  };

  const { handlePageChange, currentLimit, currentPage } = usePagination(
    props.location,
    props.history,
    100,
    loadData
  );

  const getMessage = async (message) => {
    setLoading(true);
    const res = await saGetMessage(message.teamID._id);
    if (res.status === 200) {
      setSelectedMessage(message);
      setTheseMessages(res.data.messages);
    } else toast.error(res.data);
    setLoading(false);
  };

  const hasProfanityFilter = (message) => {
    return typeof message.orgID?.misc?.profanityFilter === "boolean"
      ? message.orgID?.misc?.profanityFilter
      : true;
  };

  const columns = [
    { path: "orgID.name", label: "League" },
    { path: "teamID.sport", label: "Sport" },
    { path: "teamID.name", label: "Team" },
    { path: "messageCount", label: "Messages" },
    {
      path: "profanityFilter",
      label: "Profanity Filter",
      content: (m) => (hasProfanityFilter(m) ? "Yes" : "No"),
    },
    { path: "blocked", label: "Blocked" },
  ];

  return (
    <div>
      <Table
        columns={columns}
        data={messages}
        keyProperty={"_id"}
        backgroundStyle="bg-info text-white"
        tableClass="table-custom"
        headerClass="thead-custom"
        bodyClass="tbody-custom"
        highlightOnHover={true}
        onSelect={(row) => {
          getMessage(row);
          setIsOpen(true);
        }}
      />
      <Pagination
        itemsCount={totalMessages}
        pageSize={currentLimit}
        currentPage={currentPage}
        onPageChange={handlePageChange}
        onPreviousPage={() => handlePageChange(currentPage - 1)}
        onNextPage={() => handlePageChange(currentPage + 1)}
      />
      <BasicModal isOpen={isOpen} onClose={setIsOpen}>
        <h4 className="text-center">{selectedMessage?.teamID.name}</h4>
        {theseMessages?.map((m, idx) => (
          <p key={idx} className={m.deleted ? "bg-secondary" : ""}>
            {m.isCaptain ? <IconRender name="copyright" /> : null}
            {m.deleted ? <IconRender name="delete" /> : null}
            <small>{allowables.shortDate(m.date)}</small>{" "}
            <b>{allowables.splitName(m.userID?.name)}</b>: {m.text}
            {hasProfanityFilter(theseMessages) && m.text !== m.originalText ? (
              <small className="bg-muted">
                <br />
                {m.originalText}
              </small>
            ) : null}
          </p>
        ))}
        {theseMessages.length === 0 ? (
          <WarningHeader>There are no messages</WarningHeader>
        ) : null}
      </BasicModal>
    </div>
  );
};

export default SuperAdminMessages;
