import React from "react";
import Form from "../common/form/form";
import BasicModal from "../common/pageComponents/basicModal";
import MiniHeader from "../common/pageComponents/miniHeader";

class CodeOfConductModal extends Form {
  state = { data: { codeOfConduct: [] }, errors: null };

  componentDidMount() {
    this.setState({ data: this.mapToViewModel() });
  }

  mapToViewModel = () => {
    return {
      codeOfConduct: this.props.org.codeOfConduct || [],
    };
  };

  render() {
    return (
      <BasicModal onClose={this.props.onClose} isOpen={this.props.isOpen}>
        <MiniHeader>Code of Conduct</MiniHeader>
        <div className="form-divided-section">
          <p>
            Input your code of conduct here to display to players on their
            Profile page. The player will be asked to accept this text before
            they play their first match. You are able to view a list of players
            that have accepted (and registered users who have yet to accept)
          </p>
          <p>
            Each section consists of a header and text and will display one
            after the other, separated by a line break. The header will display
            in bold, the text will display as a single paragraph.
          </p>
        </div>
        {this.renderMultiFieldInput(
          "Edit Code of Conduct",
          [
            {
              name: "header",
              type: "input",
              style: { fontWeight: "bold" },
            },
            { name: "text", type: "textarea" },
          ],
          "codeOfConduct"
        )}
        <br />
        <div className="pop-box">
          <div className="text-center">
            <b>
              Please note that making any changes to the code of conduct after
              users have already acknowledged and accepted will cause all
              acceptances to be removed.
            </b>
          </div>
          <br />
          <div className="row">
            <div className="col text-center">
              <button
                className="btn btn-md btn-dark"
                onClick={() =>
                  this.setState({
                    data: this.mapToViewModel(this.props.org),
                  })
                }
              >
                Reset Changes
              </button>
            </div>
            <div className="col text-center">
              <button
                className="btn btn-md btn-info"
                onClick={() =>
                  this.props.doSubmit(this.state.data.codeOfConduct)
                }
              >
                Submit Changes
              </button>
            </div>
          </div>
        </div>
      </BasicModal>
    );
  }
}

export default CodeOfConductModal;
