import React from "react";
import ProfileStatus from "./profileStatus";

const NextMatchStatus = ({ nextMatch, user }) => {
  if (!nextMatch) return null;

  const response = nextMatch.checkedIn?.find(
    (p) => p.playerID === user.playerID
  );

  return (
    <ProfileStatus
      isComplete={response}
      completeText={
        <span>
          You have responded for your next match{" "}
          <small>
            : <b>{response?.playing ? "IN" : "OUT"}</b>
          </small>
        </span>
      }
      incompleteText="Check in for your next match"
      extraText={
        <small>
          : <b>{response?.playing ? "IN" : "OUT"}</b>
        </small>
      }
      link={`/matches?q=${nextMatch._id}`}
    />
  );
};

export default NextMatchStatus;
