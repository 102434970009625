import React, { useState, useContext } from "react";

import SingleQuestion from "./singleQuestion";
import SingleAnswer from "./singleAnswer";
import faqs from "./faqs";
import SearchBox from "./../common/dataSorting/searchBox";
import HeaderContext from "../../context/headerContext";

const FaqMain = () => {
  const { toggleContactForm } = useContext(HeaderContext);

  const [opened, setOpened] = useState(null);
  const [query, setQuery] = useState("");

  const getTagColor = (tag) => {
    tag = tag.toLowerCase();
    return tag.includes("admin")
      ? "bg-success text-white"
      : tag.includes("player") || tag.includes("captain")
      ? "bg-info text-white"
      : tag.includes("all")
      ? "bg-light main-theme"
      : "bg-warning main-theme";
  };

  const getQuestions = () => {
    let allQuestions = [...faqs].sort((a, b) => (a.tag > b.tag ? 1 : -1));
    return allQuestions.filter((q) =>
      query
        ? q.question.toLowerCase().includes(query.toLowerCase()) ||
          q.keyword.toLowerCase().includes(query.toLowerCase())
        : true
    );
  };

  return (
    <React.Fragment>
      <h2 className="text-center">FAQs</h2>
      <div className="clink" onClick={toggleContactForm}>
        Have a question not answered here? Contact Support
      </div>
      <SearchBox
        value={query}
        onChange={setQuery}
        placeholder="Search by keyword..."
      />
      {getQuestions().map((q, i) => {
        return (
          <div key={i}>
            <SingleQuestion id={q.question} onClick={setOpened} opened={opened}>
              <b className={"faq-tag " + getTagColor(q.tag)}>{q.tag}:</b>{" "}
              {q.question}
            </SingleQuestion>
            <SingleAnswer opened={opened} id={q.question}>
              {q.answer}
              <hr />
            </SingleAnswer>
          </div>
        );
      })}
    </React.Fragment>
  );
};

export default FaqMain;
