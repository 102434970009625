import http from "./httpService";
import { setUser } from "./userService";

export async function acceptOwnerInvitation(orgID, formType, body) {
  try {
    const response = await http.post(
      http.invitationEndpoint + "/fromowner/" + orgID,
      {
        formType,
        ...body,
      }
    );
    setUser(response.headers["x-auth-token"]);
    return response;
  } catch (ex) {
    return ex.response;
  }
}

export async function acceptPlayerInvitation(playerID, formType, body) {
  try {
    const response = await http.post(
      http.invitationEndpoint + "/player/" + playerID,
      {
        formType,
        ...body,
      }
    );
    setUser(response.headers["x-auth-token"]);
    return response;
  } catch (ex) {
    return ex.response;
  }
}
