import React, { useContext } from "react";
import HorizontalButtons from "./../common/dataSorting/horizontalButtons";
import allowables from "../../utils/allowables";
import BasicModal from "../common/pageComponents/basicModal";
import MiniHeader from "../common/pageComponents/miniHeader";
import { popupStyle } from "../../utils/styleUtil";
import ImageHeader from "../common/pageComponents/imageHeader";
import OrgContext from "../../context/orgContext";

const SpectatePlayerModal = ({
  id,
  player,
  sport,
  popupOpen,
  closeModal,
  selectedTable,
  setSelectedTable,
}) => {
  const org = useContext(OrgContext);
  sport = sport.includes("diamond") ? "diamond" : sport;
  const commonRows = [
    { path: "teamName", label: "Team" },
    { path: "gamesPlayed", label: "Played" },
  ];

  const eventColumns = {
    soccer: {
      topRows: [...commonRows],
      rows: [
        { path: "Goals", label: "Goals" },
        { path: "Assists", label: "Assists" },
        { path: "YellowCards", label: "Yellow Cards" },
        { path: "RedCards", label: "Red Cards" },
        {
          path: "suspendedFlag",
          label: "Unserved Suspensions",
          notEvent: true,
        },
        {
          path: "runningYellows",
          label: "Accumulated Yellow Cards",
          notEvent: true,
        },
      ],
      headers: [{ path: "", label: "Count" }],
    },
    football: {
      topRows: [
        ...commonRows,
        { path: "points", label: "Total Points", event: true },
      ],
      rows: [
        { path: "passing", label: "Passing" },
        { path: "receiving", label: "Receiving" },
        { path: "rushing", label: "Rushing" },
        { path: "kicking", label: "Kicking" },
        { path: "penalty", label: "Yards" },
      ],
      headers: [
        { path: "Touchdown", label: "Touchdowns" },
        { path: "Field Goal", label: "Field Goals" },
        { path: "Extra Point", label: "Extra Points" },
        { path: "Penalty", label: "Penalty" },
      ],
    },
    diamond: {
      topRows: [...commonRows],
      batting: [
        { path: "atBats", label: "At Bats", fixed: 0 },
        { path: "hits", label: "Hits", fixed: 0 },
        { path: "rbis", label: "Runs Batted In", fixed: 0 },
        { path: "walks", label: "Walks", fixed: 0 },
        { path: "strikeouts", label: "Strikeouts", fixed: 0 },
        { path: "battingAverage", label: "Batting Average", fixed: 3 },
        { path: "onBasePct", label: "On Base Pct", fixed: 3 },
        { path: "slugging", label: "Slugging Pct", fixed: 3 },
        { path: "onBasePlus", label: "On Base plus Slugging Pct", fixed: 3 },
      ],
      pitching: [
        { path: "inningsPitched", label: "Innings Pitched", fixed: 1 },
        { path: "hits", label: "Hits", fixed: 0 },
        { path: "runs", label: "Runs", fixed: 0 },
        { path: "walks", label: "Walks (all)", fixed: 0 },
        { path: "strikeouts", label: "Strikeouts", fixed: 0 },
        { path: "era", label: "Earned Run Average (9 innings)", fixed: 2 },
        {
          path: "walksPlusHits",
          label: "Walks Plus Hits per Inning Pitched",
          fixed: 2,
        },
      ],
    },
  };

  const createTable = () => {
    const table = eventColumns[sport];
    return (
      <table className="table">
        <thead>
          <tr>
            <th scope="col"></th>
            {table.headers.map((h) => (
              <th className="text-center" scope="col" key={h.path}>
                {h.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {table.rows.map((r) => (
            <tr key={r.path}>
              <th scope="row">{r.label}</th>
              {table.headers.map((h) => (
                <td className="text-center" key={h.path}>
                  {!r.notEvent && r.path === "penalty" && h.path === "Penalty"
                    ? player.events && player.events.penaltyYards
                    : !r.notEvent
                    ? player.events &&
                      player.events[(h.path ? h.path + "-" : "") + r.path]
                    : player[r.path]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const createDiamondTable = () => {
    const table = eventColumns[sport];
    return (
      <React.Fragment>
        <HorizontalButtons
          buttons={["batting", "pitching"]}
          selectedType={selectedTable}
          onClick={setSelectedTable}
        />
        <br />
        {!player[selectedTable] && (
          <p className="text-center">
            Player is not on the {selectedTable} leaderboards
          </p>
        )}
        <React.Fragment>
          {player[selectedTable] &&
            table[selectedTable].map((s) => {
              return (
                <div className="row" key={s.path}>
                  <div className="col text-right">
                    <h6>{s.label}</h6>
                  </div>
                  <div className="col text-left">
                    {(player[selectedTable][s.path] || 0).toFixed(s.fixed)}
                  </div>
                </div>
              );
            })}
        </React.Fragment>
      </React.Fragment>
    );
  };

  return (
    <BasicModal
      id={id}
      isOpen={popupOpen}
      onClose={closeModal}
      style={{
        content: {
          ...popupStyle.content,
        },
      }}
    >
      {player && (
        <div>
          <ImageHeader player={player} team={player.teamLogo} org={org} />
          <MiniHeader>{allowables.splitName(player.name)}</MiniHeader>
          <div className="form-divided-section">
            {eventColumns[sport].topRows.map((r) => (
              <h4 key={r.path}>
                <small className="text-muted">{r.label}: </small>
                {r.event
                  ? player.events
                    ? player.events[r.path]
                    : 0
                  : player[r.path]}
              </h4>
            ))}

            {sport.includes("diamond") ? createDiamondTable() : createTable()}
          </div>
        </div>
      )}
    </BasicModal>
  );
};

export default SpectatePlayerModal;
