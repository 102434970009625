import React, { useEffect } from "react";
import IconRender from "../icons/iconRender";

const SearchBox = ({ value, onChange, placeholder }) => {
  const escFunction = (e) => {
    if (e.keyCode === 27) onChange("");
  };

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="row">
      <div className="col">
        <input
          type="text"
          name="query"
          className="form-control my-3 custom-form"
          placeholder={placeholder}
          value={value}
          onChange={(e) => onChange(e.currentTarget.value)}
        />
      </div>
      {value && (
        <div style={{ alignItems: "end" }}>
          <button className="clear-button" onClick={() => onChange("")}>
            <IconRender name="close" />
          </button>
        </div>
      )}
    </div>
  );
};

export default SearchBox;
