import http from "./httpService";

export async function getMessages(limit, offset) {
  try {
    return await http.get(
      http.messageEndpoint + `?limit=${limit}&offset=${offset}`
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function postMessage(message) {
  try {
    return await http.post(http.messageEndpoint, message);
  } catch (ex) {
    return ex.response;
  }
}

export async function lockMessaging() {
  try {
    return await http.put(http.messageEndpoint);
  } catch (ex) {
    return ex.response;
  }
}

export async function deleteMessage(messageID, recover) {
  try {
    return await http.delete(
      http.messageEndpoint + "/" + messageID + (recover ? "?recover=true" : "")
    );
  } catch (ex) {
    return ex.response;
  }
}
