import React, { useState } from "react";
import toast from "../../utils/toast";

import IconRender from "../common/icons/iconRender";
import BasicModal from "../common/pageComponents/basicModal";
import MiniHeader from "../common/pageComponents/miniHeader";
import { popupStyle } from "../../utils/styleUtil";
import TooltipButton from "../common/tooltip/tooltipButton";

import NonFormSelect from "../common/form/nonFormSelect";

const AddBoard = ({ currentBoards, onSubmit, divisions }) => {
  const [addOpen, setAddOpen] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [selectDivisionOpen, setSelectDivisionOpen] = useState(false);

  const [matchLimit, setMatchLimit] = useState("");
  const [selectedDivision, setSelectedDivision] = useState("");

  const raiseSubmit = (info) => {
    onSubmit(info);
    setAddOpen(false);
  };

  const handleSelectDivision = (event) => {
    const id = event.target.value;
    setSelectedDivision(id);
  };

  const handleSelectLimit = (event) => {
    const id = event.target.value;
    setMatchLimit(id);
  };

  const handleAddBoard = () => {
    if (!selectedDivision) return toast.error("Select a division");

    if (selectedType.toLowerCase().includes("matches")) {
      if (!matchLimit)
        return toast.error("Set the number of matches to display");
    }

    raiseSubmit({
      type: selectedType,
      _id: selectedDivision,
      limit: matchLimit,
    });
    setSelectDivisionOpen(false);
    setSelectedDivision("");
    setMatchLimit("");
  };

  return (
    <div>
      <div
        className="clickable scoreboard-add"
        onClick={() => setAddOpen(true)}
        style={{ margin: "auto" }}
      >
        <IconRender name="plus" className="icon-centered" />
      </div>
      <BasicModal
        isOpen={addOpen}
        onClose={setAddOpen}
        style={{ content: { ...popupStyle.content, width: "75%" } }}
        header={<h4 className="text-center">Add New Scoreboard</h4>}
      >
        <MiniHeader>Live Scorecards</MiniHeader>
        <div className="form-divided-section text-center">
          <div className="row">
            {[
              {
                type: "AllLiveScorecards",
                tooltipText:
                  "Display all your ongoing matches with live updates",
                header: "Live Match Scores",
                icon: "live",
              },
              {
                type: "RotatingScorecards",
                tooltipText:
                  "Display a rotating view of the indivdual scorecards for each of your ongoing matches.",
                header: "Rotating Scorecards",
                icon: "refresh",
              },
            ].map((board) => (
              <div className="col" key={board.type}>
                <TooltipButton
                  buttonClass="btn btn-block btn-info"
                  tooltipDirection="top"
                  tooltipText={board.tooltipText}
                  onClick={() =>
                    raiseSubmit({
                      type: board.type,
                    })
                  }
                  disabled={currentBoards.find((b) => board.type === b.type)}
                >
                  <IconRender name={board.icon} /> {board.header}
                </TooltipButton>
              </div>
            ))}
          </div>
        </div>
        <MiniHeader>Division Stats</MiniHeader>
        <div className="form-divided-section text-center">
          <div className="row">
            {[
              {
                type: "Standings",
                tooltipText: "Live standings for a division",
                header: "League Standings",
                icon: "table",
              },
              {
                type: "Brackets",
                tooltipText: "Live playoff brackets for a division",
                header: "Playoff Brackets",
                icon: "bracket",
              },
              {
                type: "Leaderboards",
                tooltipText: "Live leaderboards for a division",
                header: "Individual Leaderboards",
                icon: "leaderboard",
              },
            ].map((board) => (
              <div className="col" key={board.type}>
                <TooltipButton
                  buttonClass="btn btn-block btn-info"
                  tooltipDirection="top"
                  tooltipText={board.tooltipText}
                  onClick={() => {
                    setSelectedType(board.type);
                    setSelectDivisionOpen(true);
                  }}
                >
                  <IconRender name={board.icon} /> {board.header}
                </TooltipButton>
              </div>
            ))}
          </div>
        </div>
        <MiniHeader>Recently Updated Matches</MiniHeader>
        <div className="form-divided-section text-center">
          <div className="row">
            {[
              {
                type: "CompletedMatches",
                tooltipText:
                  "Show recently completed matches (i.e. the scorecard was submitted by the referee, but the match has not yet been reviewed)",
                header: "Completed Matches",
                icon: "clipboard",
              },
              {
                type: "AcceptedMatches",
                tooltipText:
                  "Show recently reviewed and accepted matches, sorted by the time they were reveiwed",
                header: "Accepted Matches",
                icon: "clipboard",
              },
            ].map((board) => (
              <div className="col" key={board.type}>
                <TooltipButton
                  buttonClass="btn btn-block btn-info"
                  tooltipDirection="top"
                  tooltipText={board.tooltipText}
                  onClick={() => {
                    setSelectedType(board.type);
                    setSelectDivisionOpen(true);
                  }}
                >
                  <IconRender name={board.icon} /> {board.header}
                </TooltipButton>
              </div>
            ))}
          </div>
        </div>
      </BasicModal>
      <BasicModal
        isOpen={selectDivisionOpen}
        onClose={setSelectDivisionOpen}
        style={{ content: { ...popupStyle.content, width: "50%" } }}
      >
        <MiniHeader>{selectedType}</MiniHeader>
        <div className="form-divided-section">
          <NonFormSelect
            id="division"
            name="_id"
            label="Select Division:&nbsp;"
            options={
              selectedType.toLowerCase().includes("matches")
                ? [{ _id: "all", name: "All Divisions" }, ...divisions]
                : divisions
            }
            onChange={handleSelectDivision}
            value={selectedDivision}
          />
          {selectedType.toLowerCase().includes("matches") && (
            <NonFormSelect
              id="limit"
              name="_id"
              label="Select number of matches to display:&nbsp;"
              options={[
                { _id: 1, name: 1 },
                { _id: 2, name: 2 },
                { _id: 3, name: 3 },
                { _id: 4, name: 4 },
                { _id: 5, name: 5 },
                { _id: 6, name: 6 },
                { _id: 7, name: 7 },
                { _id: 8, name: 8 },
                { _id: 9, name: 9 },
                { _id: 10, name: 10 },
              ]}
              onChange={handleSelectLimit}
              value={matchLimit}
            />
          )}
          <button className="btn btn-block btn-info" onClick={handleAddBoard}>
            Add Board
          </button>
        </div>
      </BasicModal>
    </div>
  );
};

export default AddBoard;
