import React from "react";
import ProfileStatus from "./profileStatus";

const ImageStatus = ({ org, user }) => {
  if (!user || !org || !org.misc || org.misc.userImages !== "Yes") return null;
  const imageUploaded = user.profileID && user.profileID.hasAvatar;

  return (
    <ProfileStatus
      isComplete={imageUploaded}
      completeText="Profile image uploaded"
      incompleteText="Upload your profile image below"
    />
  );
};

export default ImageStatus;
