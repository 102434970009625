import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";

const Tooltip = (props) => {
  const [active, setActive] = useState(false);
  if (props.isNull) return props.children;

  const toggleTip = () => {
    setActive(active ? false : true);
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setActive(false)}>
      <div
        className={"tooltip-wrapper"}
        onMouseEnter={props.clickable ? null : toggleTip}
        onMouseLeave={props.clickable ? null : toggleTip}
        onClick={toggleTip}
        data-testid={props.testId}
      >
        {props.children}
        {active && (
          <div
            className={
              props.customClass
                ? props.customClass
                : `tooltip-tip light-bg ${props.direction || "bottom"}`
            }
            style={props.overrideStyle}
          >
            {props.content}
          </div>
        )}
      </div>
    </OutsideClickHandler>
  );
};

export default Tooltip;
