import React, { useContext } from "react";

import OrgContext from "../../../context/orgContext";
import allowables from "../../../utils/allowables";

const TeamLogo = ({ team, name, size = 75, borderRadius = 10 }) => {
  const org = useContext(OrgContext);
  if ((org && org.misc && org.misc.userImages === "No") || !team) return null;

  const getInitials = (name) => {
    const split = name.split(" ");
    return (
      split[0].charAt(0).toUpperCase() +
      (split[1] ? split[1].charAt(0).toUpperCase() : "")
    );
  };

  const imageStyle = {
    width: size,
    height: size,
    borderRadius: borderRadius,
    margin: "auto",
  };

  function getFallbackImage(event) {
    event.target.src = allowables.fallbackImage();
  }

  let imageLocation = team.logo;
  if (!imageLocation || imageLocation?.toLowerCase().includes("blocked"))
    imageLocation = team.adminLogo;

  return imageLocation ? (
    <div style={imageStyle}>
      <img
        onError={(e) => getFallbackImage(e)}
        src={
          "https://storage.googleapis.com/ultimatescoreboard-teamlogos/" +
          imageLocation +
          ".png"
        }
        alt={name ? getInitials(name) : "logo"}
        width={size}
        height={size}
        style={imageStyle}
      />
    </div>
  ) : null;
};

export default TeamLogo;
