import React from "react";
import Form from "../common/form/form";

import Joi from "joi-browser";
import { getCurrentUser } from "../../services/userService";
import { getField, saveField } from "../../services/fieldService";
import HeaderContext from "../../context/headerContext";
import toast from "../../utils/toast";
import Map from "../common/pageComponents/mapModal";

class FieldForm extends Form {
  static contextType = HeaderContext;

  state = {
    data: {
      name: "",
      address: "",
      webAddress: "",
      longitude: "",
      latitude: "",
    },
    baseState: {
      name: "",
      address: "",
      webAddress: "",
      longitude: "",
      latitude: "",
    },
    errors: {},
    source: "info",
  };

  async componentDidMount() {
    this.context.setLoading(true);
    let source = this.props.source;
    const user = getCurrentUser();
    if (!user.role.includes("admin")) source = "info";

    if (this.props.fieldID !== "new") {
      const res = await getField(this.props.fieldID);
      if (res.status === 200) {
        this.setState({
          data: this.mapToViewModel(res.data),
          baseState: res.data,
        });
      } else toast.error(res.data);
    }

    this.setState({ source });
    this.context.setLoading(false);
  }

  schema = {
    name: Joi.string().required().min(1).max(100).label("Field Name"),
    address: Joi.string().min(3).max(200).required().label("Address"),
    webAddress: Joi.string()
      .min(3)
      .max(200)
      .optional()
      .allow("")
      .label("Location Website"),
    longitude: Joi.number().optional().label("Longitude").allow(""),
    latitude: Joi.number().optional().label("Latitude").allow(""),
  };

  mapToViewModel(field) {
    return {
      name: field.name || "",
      address: field.address || "",
      webAddress: field.webAddress || "",
      longitude: field.longitude?.toFixed(4) || "",
      latitude: field.latitude?.toFixed(4) || "",
    };
  }

  doSubmit = async () => {
    const data = { ...this.state.data };
    data.orgID = getCurrentUser().orgID;

    const res = await saveField(this.props.fieldID, data);
    if (res.status === 200) {
      toast.success("Field Saved");
      return this.props.history.goBack();
    } else toast.error(res.data);
  };

  render() {
    return (
      <div className="centered-small-input-area">
        {this.state.source === "edit" ? (
          this.renderWholeForm(
            "Field",
            "/fields",
            null,
            <div>
              {this.renderInput("name", "Field Name", "", "", "", "", "", {
                header: "Field Name",
                text: `The name of this field. This should differentiate it from other fields that may share the same address.`,
                direction: "right",
                className: "icon-mobile",
              })}
              {this.renderInput("address", "Address", "", "", "", "", "", {
                header: "Field Address",
                text: `The address of the field. You can use the name of the complex or any colloquial name.
            Clicking on this from the map screen will perform a Google search.`,
                direction: "right",
                className: "icon-mobile",
              })}
              {this.renderInput(
                "webAddress",
                "Location Website",
                "",
                "",
                "",
                "",
                "",
                {
                  header: "Location Website",
                  text: `If the field has a website with more useful information enter it here.
            A hyperlink will be displayed from the map screen.
            Please include https:// in the address to properly navigate to the external site.`,
                  direction: "right",
                  className: "icon-mobile",
                }
              )}
              {this.renderInput(
                "latitude",
                "Latitude",
                "",
                "number",
                "",
                "",
                "",
                {
                  header: "Latitude",
                  text: `The latitude of the field for mapping.
                  A valid entry is a number between -90 and 90. Up to 4 decimal places are accepted.
                  Use the link below to search latitude by address`,
                  direction: "right",
                  className: "icon-mobile",
                  link: "https://www.latlong.net",
                }
              )}
              {this.renderInput(
                "longitude",
                "Longitude",
                "",
                "number",
                "",
                "",
                "",
                {
                  header: "Longitude",
                  text: `The longitude of the field for mapping.
                  A valid entry is a number between -180 and 180. Up to 4 decimal places are accepted.
                  Use the link below to search longitude by address`,
                  direction: "right",
                  className: "icon-mobile",
                  link: "https://www.latlong.net",
                }
              )}
            </div>
          )
        ) : (
          <div>
            <Map location={this.state.baseState} />
          </div>
        )}
      </div>
    );
  }
}

export default FieldForm;
