import React from "react";

import allowables from "../../utils/allowables";
import { CLink } from "../common/customs/customLinks";

const WaiverWireInfo = ({ teamWaiverWire, onCancelBid }) => {
  if (!teamWaiverWire) return null;
  return (
    <div className="pop-box text-center" style={{ width: 300 }}>
      <h5>
        <b>Waiver Wire</b>
      </h5>
      Priority: <b>{teamWaiverWire.priority}</b>
      <br />
      In Use: <b>{teamWaiverWire.inUse ? "Yes" : "No"}</b>
      {teamWaiverWire.inUse ? (
        <div>
          <p>
            Player: <b>{allowables.splitName(teamWaiverWire.player?.name)}</b>
            <br />
            Waiver Expires:{" "}
            <b>
              {allowables.shortDate(teamWaiverWire.player?.expiration, true)}
            </b>
            <br />
            <br />
            <button className="btn btn-sm btn-dark" onClick={onCancelBid}>
              Cancel Bid
            </button>
          </p>
        </div>
      ) : null}
      <CLink
        path="/players?search=&path=name&order=asc&name=Free%20Agents&id=freeAgents&page=1"
        color={true}
      >
        Browse All Free Agents
      </CLink>
    </div>
  );
};

export default WaiverWireInfo;
