import React, { useState, useContext } from "react";
import AvatarEditor from "react-avatar-editor";

import imageCompression from "browser-image-compression";
import toast from "../../../utils/toast";
import HeaderContext from "../../../context/headerContext";

const Avatar = ({
  height,
  width,
  uploadFunction,
  saveTitle,
  minZoom,
  maxZoom,
}) => {
  const { setLoading, setProgress } = useContext(HeaderContext);

  const [file, setFile] = useState("");
  const [zoom, setZoom] = useState(100);
  const [rotate, setRotate] = useState(0);
  const [editor, setEditor] = useState(null);

  const saveImage = async (e) => {
    e.preventDefault();
    if (!file) return;
    if (editor) {
      setLoading(true);
      setProgress([1]);
      const canvasScaled = editor.getImageScaledToCanvas().toDataURL();

      const compressedImage = await compressImage(canvasScaled);
      const reader = new FileReader();
      reader.readAsDataURL(compressedImage);
      reader.onloadend = async function () {
        const base64data = reader.result;
        const response = await uploadFunction(base64data);
        if (response && response.status === 200)
          return window.location.reload(true);
        else {
          toast.error((response && response.data) || "There was an error");
          setLoading(false);
        }
      };
    }
  };

  const compressImage = async (image) => {
    const compressionOptions = {
      maxSizeMB: 1,
    };
    try {
      image = await imageCompression.getFilefromDataUrl(image, "image.jpg");
      const compressedImage = await imageCompression(image, compressionOptions);
      return compressedImage;
    } catch (ex) {
      console.log(ex);
    }
  };

  const setEditorRef = (editor) => setEditor(editor);

  const changeImage = (e) => {
    if (e.target.files && e.target.files[0]) {
      setFile(URL.createObjectURL(e.target.files[0]));
    }
  };

  const slide = (e) => {
    setZoom(e.target.value);
  };

  const rotateImage = (e) => {
    setRotate(rotate + 90);
  };

  return (
    <div>
      <label htmlFor="avatar" className="btn btn-secondary btn-md">
        Choose New File
      </label>
      <input
        type="file"
        name="newImage"
        onChange={changeImage}
        id="avatar"
        style={{ opacity: 0, position: "absolute", zIndex: -1 }}
      />
      <br />
      <br />
      <AvatarEditor
        ref={setEditorRef}
        image={file}
        width={width || 150}
        height={height || 150}
        border={20}
        borderRadius={0}
        rotate={rotate}
        scale={zoom / 100}
      />
      {file && (
        <React.Fragment>
          <br />
          <label htmlFor="zoom" className="text-muted">
            Zoom&nbsp;
          </label>
          <input
            id="zoom"
            type="range"
            onChange={slide}
            min={minZoom || 50}
            max={maxZoom || 200}
            value={zoom}
          />
          <br />
          <button
            onClick={rotateImage}
            id="counter-clockwise"
            className="btn btn-sm btn-secondary"
          >
            Rotate
          </button>
          <br />
          <br />

          <button
            onClick={saveImage}
            className="btn btn-info btn-md"
            disabled={!file}
          >
            {saveTitle}
          </button>
        </React.Fragment>
      )}
    </div>
  );
};

export default Avatar;
