import React from "react";
import IconRender from "../common/icons/iconRender";
import { CLink } from "../common/customs/customLinks";

const ProfileStatus = ({
  isComplete,
  completeText,
  incompleteText,
  link,
  highlight,
}) => {
  const text = (
    <span>
      <IconRender name={isComplete ? "checkedbox" : "uncheckedbox"} />{" "}
      &nbsp;&nbsp;&nbsp;
      {isComplete ? completeText : incompleteText}
    </span>
  );

  const className = `profile-status${highlight ? " pop-box" : ""}`;

  return link ? (
    <CLink path={link} className={"clink " + className}>
      {text}
    </CLink>
  ) : (
    <div className={className}>
      <IconRender name={isComplete ? "checkedbox" : "uncheckedbox"} />{" "}
      &nbsp;&nbsp;&nbsp;
      {isComplete ? completeText : incompleteText}
    </div>
  );
};

export default ProfileStatus;
