import http from "./httpService";

export async function getTactics() {
  try {
    return await http.get(http.tacticsEndpoint);
  } catch (ex) {
    return ex.response;
  }
}

export async function upsertTactic(tactic) {
  try {
    return await http.post(http.tacticsEndpoint, tactic);
  } catch (ex) {
    return ex.response;
  }
}

export async function deleteTactic(tacticID) {
  try {
    return await http.delete(http.tacticsEndpoint + "/" + tacticID);
  } catch (ex) {
    return ex.response;
  }
}
