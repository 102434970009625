import React from "react";

import IconRender from "../icons/iconRender";
import InfoTooltip from "../tooltip/infoTooltip";

const MiniHeader = ({
  onClick,
  iconName,
  tooltip,
  tabIndex,
  paddingTop = 10,
  paddingBottom = 1,
  marginTop = 10,
  marginBottom = 0,
  borderRadiusOverride = null,
  scrollOnClick,
  id,
  children,
  onRemove,
  overrideStyle = {},
}) => {
  const handleEnterPress = (e) => {
    if (e.key.toLowerCase() === "enter") raiseOnClick();
  };

  const raiseOnClick = () => {
    if (onClick) {
      if (scrollOnClick) {
        const header = document.querySelector("#" + id);
        if (header.scrollIntoView)
          header.scrollIntoView({ behavior: "smooth", block: "start" });
      }
      onClick();
    }
  };

  const style = { paddingTop, paddingBottom, marginTop, marginBottom };
  if (borderRadiusOverride !== null) style.borderRadius = borderRadiusOverride;

  return (
    <div
      className={"mini-header" + (onClick ? " clickable btn-info" : "")}
      style={{ ...style, ...overrideStyle }}
      onClick={raiseOnClick}
      tabIndex={tabIndex}
      onKeyDown={handleEnterPress}
      id={id}
    >
      <h5>
        {tooltip ? (
          <div className="text-left" style={{ position: "absolute" }}>
            <InfoTooltip
              header={tooltip.header || ""}
              listHeader={tooltip.listHeader || ""}
              listItems={tooltip.listItems || []}
              direction={tooltip.direction || "right"}
              text={tooltip.text || ""}
              className={tooltip.className || "icon-info-rev"}
            />
          </div>
        ) : null}
        {onRemove ? (
          <div
            style={{ float: "right" }}
            className="clickable"
            onClick={onRemove}
          >
            <IconRender name="close" className="icon-info-rev" />
          </div>
        ) : null}
        <div style={{ display: "flex" }}>
          <div style={{ flex: "10%" }}>
            {iconName ? (
              <IconRender name={iconName} className="icon-info-rev" />
            ) : null}
          </div>
          <div className="text-center" style={{ flex: "80%" }}>
            &nbsp;{children}&nbsp;
          </div>
          <div style={{ flex: "10%" }}>
            {iconName ? (
              <IconRender name={iconName} className="icon-info-rev" />
            ) : null}
          </div>
        </div>
      </h5>
    </div>
  );
};

export default MiniHeader;
