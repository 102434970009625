import React from "react";

import Table from "../common/table/table";
import { CLink } from "../common/customs/customLinks";
import EventImage from "../assignedMatch/eventImage";
import allowables from "../../utils/allowables";

const ImageViewUserTable = ({ users, onSort, sortColumn, selectedTypes }) => {
  let columns = [
    {
      key: "image",
      content: (u) => <EventImage player={u} fromUser={true} />,
    },
    {
      path: "name",
      label: "Name",
      content: (u) =>
        u.role.includes("player") && u.playerID ? (
          <CLink path={`/players?info&q=${u.playerID?._id}`}>
            {allowables.splitName(u.name)}
          </CLink>
        ) : (
          allowables.splitName(u.name)
        ),
    },
    {
      path: "role",
      label: "Role",
      content: (u) => allowables.translateRole(u.role, u.sport),
    },
    {
      path: "teamName",
      label: "Team Name",
      content: (u) => (
        <CLink path={`/teams?info&q=${u.teamID}`}>{u.teamName}</CLink>
      ),
    },
    {
      path: "uploadedBy",
      label: "Uploaded By",
      content: (u) =>
        u.profileID?.hasAvatar &&
        !u.profileID.hasAvatar.toLowerCase().includes("blocked")
          ? "User"
          : u.playerID?.image
          ? u.playerID.image.split("-").at(-1).split("_").join(" ")
          : "",
    },
  ];

  if (selectedTypes.blocked)
    columns.push({
      path: "blocked",
      label: "Reason",
      content: (u) =>
        u.profileID.hasAvatar?.includes("blocked") ? u.profileID.hasAvatar : "",
    });

  return (
    <Table
      columns={columns}
      data={users}
      sortColumn={sortColumn}
      onSort={onSort}
      keyProperty={"_id"}
      tableClass="table-custom"
      headerClass="thead-custom"
      bodyClass="tbody-custom"
      highlightOnHover={true}
    />
  );
};

export default ImageViewUserTable;
