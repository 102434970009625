import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import HeaderContext from "../../../context/headerContext";

export function navigateTo(path, history, header) {
  history.push({
    pathname: path.split("?")[0],
    search: path.split("?")[1],
    state: { from: history.location.pathname + history.location.search },
  });
  header.updateHeader();
}

export const CLink = ({
  path,
  children,
  color,
  button,
  buttonStyle,
  ...rest
}) => {
  const history = useHistory();
  const header = useContext(HeaderContext);

  return (
    <div
      className={
        (button
          ? "btn " + (buttonStyle ? buttonStyle : "btn-sm btn-info")
          : "clink") + (color ? " main-theme" : "")
      }
      onClick={() => navigateTo(path, history, header)}
      {...rest}
    >
      {children}
    </div>
  );
};

export const CLinkSVG = ({
  path,
  x,
  y,
  className,
  onMouseOver,
  onMouseOut,
  style,
  disabled,
  clickHandler,
  boldText,
  textColor,
  children,
}) => {
  const history = useHistory();
  const header = useContext(HeaderContext);

  return (
    <text
      x={x}
      y={y}
      className={`${className} ${disabled ? "" : "svg-link"}`}
      onClick={
        disabled
          ? () => {}
          : clickHandler
          ? () => clickHandler()
          : () => navigateTo(path, history, header)
      }
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      style={{
        ...style,
        fontWeight: boldText ? "bold" : "regular",
        fill: textColor,
      }}
    >
      {children}
    </text>
  );
};

export const CBack = ({ children, block }) => {
  const history = useHistory();
  const header = useContext(HeaderContext);
  const navigate = () => {
    const goTo =
      history.location.state && history.location.state.from
        ? history.location.state.from
        : null;
    if (goTo) {
      history.push(goTo);
      header.updateHeader();
    } else {
      history.goBack();
    }
  };

  return (
    <button
      className={"btn btn-md btn-dark" + (block ? " btn-block" : "")}
      onClick={navigate}
    >
      {children}
    </button>
  );
};

export function navigateBack(history, header) {
  const goTo =
    history?.location?.state && history.location.state.from
      ? history.location.state.from
      : null;
  if (goTo) {
    history?.push(goTo);
    header.updateHeader();
  } else {
    history?.goBack();
  }
}
