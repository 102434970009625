import React, { Component } from "react";

import { sendError } from "../services/errorService";
import MainTitle from "./common/loading/mainTitle";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  async componentDidCatch(error, info) {
    let errorText;
    if (typeof error === "string") errorText = error;
    else errorText = error?.message || "error.message undefined";

    // send the info to the error logging service
    await sendError({
      errorText,
      componentStack: info.componentStack,
      location: window.location.href,
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="text-center">
          <br />
          <br />
          <MainTitle />
          <br />
          <h2>
            <b>Something Went Wrong</b>
          </h2>
          <br />
          <p style={{ margin: 25 }}>
            We've been notified of this error and are rushing to fix it.
          </p>
          <div style={{ margin: 50 }}>
            <button
              className="btn btn-block btn-light"
              onClick={() => window.location.reload()}
            >
              Refresh This Page
            </button>
            <br />
            <br />
            {this.props.user ? (
              <button
                className="btn btn-block btn-info"
                onClick={() => (window.location = "/profile")}
              >
                Go To Profile Page
              </button>
            ) : (
              <button
                className="btn btn-block btn-info"
                onClick={() => (window.location = "/")}
              >
                Go To Home Page
              </button>
            )}
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
