import React from "react";

import EventImage from "../eventImage";
import allowables from "../../../utils/allowables";

const DiamondSelectedPlayer = ({ player, header, onClick }) => {
  return (
    <div onClick={onClick} className="clickable">
      <h6 className="text-center">{header} - click to change</h6>
      <div className="row" onClick={onClick}>
        <div className="col-3 text-center">
          <EventImage player={player} />
        </div>
        <div className="col">
          <h6>
            <b>{allowables.splitName(player.name)}</b>
          </h6>
        </div>
      </div>
    </div>
  );
};

export default DiamondSelectedPlayer;
