import React from "react";

import BasicModal from "../common/pageComponents/basicModal";
import FinePrint from "./finePrint";

const FinePrintModal = ({ finePrintOpen, closeFinePrint }) => {
  return (
    <BasicModal
      id="finePrint"
      isOpen={finePrintOpen}
      closeOnDocumentClick
      onClose={closeFinePrint}
    >
      <FinePrint openAll={true} closeFinePrint={closeFinePrint} />
    </BasicModal>
  );
};

export default FinePrintModal;
