import React from "react";
import allowables from "../../../utils/allowables";
import TennisScoringInfo from "./tennisScoringInfo";

const SimpleScoringInfo = ({ org, match }) => {
  const sport = allowables.translateSportName(org);

  return sport === "Tennis" ? <TennisScoringInfo match={match} /> : null;
};

export default SimpleScoringInfo;
