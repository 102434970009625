import React from "react";
import BasicModal from "../common/pageComponents/basicModal";

import DiamondInstructions from "../assignedMatch/diamond/diamondInstructions";
import SoccerInstructions from "../assignedMatch/soccer/soccerInstructions";
import FootballInstructions from "../assignedMatch/football/footballInstructions";

const RefereeingInstructionsModal = ({ sport, id, isOpen, onClose }) => {
  return (
    <BasicModal id={id} isOpen={isOpen} onClose={onClose}>
      <h5 className="text-center">How to Use your Virtual Scorecard</h5>
      {sport.toLowerCase().includes("diamond") && <DiamondInstructions />}
      {sport.toLowerCase().includes("soccer") && <SoccerInstructions />}
      {sport.toLowerCase().includes("football") && <FootballInstructions />}
    </BasicModal>
  );
};

export default RefereeingInstructionsModal;
